import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import * as utils from "../../utils/Utils";
import TitleContent from "../../components/TitleContent";

/** Modules */
import UserInfo from "./components/UserInfo";
import UserAddress from "./components/UserAddress";
import UserCart from "./components/UserCart";
import UserPayment from "./components/UserPayment";
import UserSubscription from "./components/UserSubscription";
import UserMemo from "./components/UserMemo";
import { UserOrderList } from "./components/UserOrderList";
import UserMessage from "./components/UserMessage";
import UserSigninLog from "./components/UserSigninLog";
import UserSubscriptionChangeLog from "./components/UserSubscriptionChangeLog";
import UserChangeLog from "./components/UserChangeLog";
import UserServiceChangeLog from "./components/UserServiceChangeLog";
import { return_normal } from "../../utils/Utils";
import { AppContext } from "../../index";
import { NewSubscriptionInfo, TbAddress } from "../../api";
import { DetailContext } from "../../DetailContext";
import UserCoupon from "./components/UserCoupon";

const UserDetail = ({ match }) => {
  const ctx = useContext(AppContext);
  /** Parameter에서 고객번호 받아오기 */
  const userid = match.params.uid;

  /** 상품 목록 가져오기 */
  const [itemList, setItemList] = useState();
  const getItemList = async () => {
    const getResult = await utils.getItemList();
    setItemList(getResult);
  };

  /** [주문] 특정 주문의 배송정보 변경하기(기존 배송지 이용) */
  const setOrderAddressID = async (addressData) => {
    await utils.setOrderAddressID(addressData);
    getUserData(userid);
  };

  /// /////////////////////////////

  /** [고객] 주문정보에 해당하는 사용자정보 가져오기 */
  const [userData, setUserData] = useState();
  const getUserData = async (userid) => {
    const getResult = await utils.getUserData(userid);
    if (getResult) {
      if (!Array.isArray(getResult.EasyPaymentInfo)) {
        getResult.EasyPaymentInfo = new Array(getResult.EasyPaymentInfo);
      }

      setUserData(getResult);
    }
  };

  /** [고객] 특정 사용자의 배송지 목록 가져오기 */
  const [userAddress, setUserAddress] = useState([] as TbAddress[]);

  const getUserAddressList = async (userid) => {
    const getResult = await ctx.api.adminUser.getAddresses({ unid: userid });
    getResult.data.sort((a) => (a.IsDefault === "Y" ? -1 : 1));
    setUserAddress(getResult.data as any);
  };

  /** [고객] 특정 사용자의 장바구니 목록 가져오기 */
  const [userCart, setUserCart] = useState();
  const getUserCartList = async (userid) => {
    const getResult = await utils.getUserCartList(userid);
    setUserCart(getResult);
  };

  /** [고객] 특정 사용자의 메모 불러오기 */
  const [userNote, setUserNote] = useState();
  const getUserNote = async (userid) => {
    const getResult = await utils.getNote(userid);
    setUserNote(getResult);
  };

  /** *******************************
   Consumer로 부터 호출 가능한 Method
   ********************************* */

  /** [고객] 특정 사용자의 비밀번호 변경 발송하기 */
  const forgotPassword = async (email, type) => {
    await utils.forgotPassword(email, type);
    getUserData(userid);
  };

  /** [고객] 특정 사용자의 장바구니에서 상품 삭제하기 */
  const deleteCartItem = async (userid, cartid) => {
    const getResult = await utils.deleteCartItem(userid, cartid);
    return getResult;
  };

  /** [고객] 특정 사용자의 정기배송 상태 변경하기 */
  const changeSubscriptionStatus = async ({ UNID, status, ScheduleAt }) => {
    // utils안쓰기운동
    if (status === "Y") {
      const param = {
        UNID,
        ScheduleAt,
      };
      try {
        await ctx.api.adminSubscription.restartSubscription(param);
        getUserData(userid);
      } catch (e) {
        console.log(e);
        toast.error("데이터 변경 요청 실패함", { autoClose: 3000 });
      }
    } else if (status === "N") {
      const param = {
        IsReturnWill: "N" as "Y" | "N",
        Reason: "paused on admin page",
        ReasonDetail: "paused on admin page",
        CancelData: "paused on admin page",
        Comment: "paused on admin page",
      };
      try {
        await ctx.api.adminSubscription.pauseSubscription({
          UNID,
          param,
        });
        getUserData(userid);
      } catch (e) {
        console.log(e);
        toast.error("데이터 변경 요청 실패함", { autoClose: 3000 });
      }
    }
  };

  /** [고객] 특정 사용자의 정기배송 상품 변경하기 */
  const updateSubscriptionItem = async (UNID, subscriptionData) => {
    const param = {
      UNID,
      param: subscriptionData,
    };
    try {
      const getResult = await ctx.api.adminSubscription.updateSubscriptionItem(
        param
      );
      // const getResult = await utils.updateSubscriptionItem(subscriptionData);
      if (getResult) {
        const getUserData = await utils.getUserData(userid);
        setUserData(getUserData);
        return getUserData;
      }
    } catch (e) {
      console.log(e);
      toast.error("데이터 변경 요청 실패함", { autoClose: 3000 });
    }
  };

  /** [고객] 특정 사용자의 정기배송 상품 변경하기 */
  const deleteSubscriptionItem = async (subscriptionData) => {
    // utils안쓰기운동
    const getResult = await ctx.api.adminSubscription.deleteSubscriptionItem(
      subscriptionData
    );
    if (getResult) {
      const getUserData = await utils.getUserData(userid);
      setUserData(getUserData);
      return getUserData;
    }
  };

  /** [고객] 특정 사용자의 정기결제 카드 등록하기(토스PG) */
  const setEasyPaymentToToss = async (cardData) => {
    const result = await ctx.api.adminPayment.setEasyPayment({
      ...cardData,
      unid: userid,
    });

    getUserData(userid);

    return return_normal(result, "Y");
  };

  /** [고객] 특정 사용자에게 SMS 전송 */
  const sendSMS = async (param) => {
    const getResult = await utils.sendSMS(param);
    return getResult;
  };

  /** [고객] 특정 사용자의 비밀번호 변경 */
  const changePassword = async (param) => {
    await utils.changePassword(param);
  };

  /** ******************************* */

  const [subscriptionData, setSubscriptionData] = useState<
    NewSubscriptionInfo | undefined
  >(undefined);

  const reloadSubscription = () =>
    ctx.api.adminSubscription
      .getSubscription({
        unid: userid,
      })
      .then(({ data }) => setSubscriptionData(data));

  const reloadAddresses = (reloadUser: boolean = false) => {
    ctx.api.adminUser.getAddresses({ unid: userid }).then(({ data }) => {
      const addresses = data.sort((a) => (a.IsDefault === "Y" ? -1 : 1));
      setUserAddress(addresses);
    });

    if (reloadUser) {
      getUserData(userid);
    }
  };

  /** Provider에 전달할 변수 설정 */
  const passValue = {
    data: {
      userData,
      userAddress,
      userCart,
      itemList,
      userNote,
      subscriptionData,
    },
    reload: {
      reloadSubscription,
      reloadAddresses,
    },
    actions: {
      getUserData,
      setOrderAddressID,
      forgotPassword,
      getUserAddressList,
      getUserCartList,
      deleteCartItem,
      changeSubscriptionStatus,
      updateSubscriptionItem,
      deleteSubscriptionItem,
      setEasyPaymentToToss,
      sendSMS,
      changePassword,
      getUserNote,
    },
  };

  /** 초기 로딩할 때 1회 실행 */
  useEffect(() => {
    getItemList();
    getUserData(userid);
    getUserAddressList(userid);
    /** 특정 사용자의 배송지 목록 가져오기 */
    getUserCartList(userid);
    /** 특정 사용자의 장바구니 목록 가져오기 */
    getUserNote(userid);
    /** 특정 사용자의 메모 목록 가져오기 */
    reloadSubscription();
    reloadAddresses();
  }, [userid]);

  return (
    <>
      <TitleContent
        title="고객 상세 정보"
        subHeading="고객님 덕분에 저희가 먹고 삽니다. 항상 감사합니다."
      />

      <DetailContext.Provider value={passValue}>
        <div className="row mb-4">
          <div className="col-md-7">
            <UserInfo userid={+userid} />
            <UserSubscription />
            <UserAddress />
            <UserPayment userid={userid} />
            <UserSubscriptionChangeLog />
            <UserServiceChangeLog />
            <UserCart />
            <UserCoupon userid={userid} />
          </div>
          <div className="col-md-5">
            <UserOrderList unid={userid} />
            <UserMemo />
            <UserMessage userid={userid} />
            <UserChangeLog />
            <UserSigninLog />
          </div>
        </div>
      </DetailContext.Provider>
    </>
  );
};

export default UserDetail;
