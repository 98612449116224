import React from "react";

import { IoIosListBox } from "react-icons/io";

const TitleContent = ({ title, subHeading = "", icon = undefined as any }) => (
  <div className="app-page-title pt-3 pb-3 mb-3">
    <div className="page-title-wrapper">
      <div className="page-title-heading">
        <div className="page-title-icon">{icon ?? <IoIosListBox />}</div>
        <div>
          {title}
          {subHeading && (
            <div className="page-title-subheading">{subHeading}</div>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default TitleContent;
