import React from "react";

const Select = ({ name, classNames, value = "", onChange, options }) => (
  <select
    name={name}
    className={classNames.join(" ")}
    value={value}
    onChange={onChange}
  >
    {options.map((option) => {
      const [key, value] = Object.entries(option)[0];
      return (
        <option key={key} value={key}>
          {value as any}
        </option>
      );
    })}
  </select>
);

export default Select;
