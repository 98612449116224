import React from "react";
import { Route, useRouteMatch } from "react-router-dom";

import QueryList from "../pages/admin/QueryList";
import UserSync from "../pages/admin/UserSync";

const AdminRoute = () => {
  const match = useRouteMatch();

  return (
    <>
      <Route path={`${match.path}/queries`} component={QueryList} />
      <Route path={`${match.path}/usersync`} component={UserSync} />
    </>
  );
};

export default AdminRoute;
