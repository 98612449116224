import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { ApiCall, apiMode, LambdaLive, NodeServerDomain } from "../Store";

export function die(msg: string): never {
  throw new Error(msg);
}

/**
 * API Mode 를 유저에게 입력받은 후 기능을 실행하게 함.
 * (ex. 발주 로직)
 * @param callback
 */
export const withConfirmApiMode = (callback: () => any) => {
  const apiModeInput = window.prompt("API 모드를 입력해 주세요 (LIVE, DEV)");
  const requestApiMode = apiMode === "LIVE" ? "LIVE" : "DEV";

  if (!apiModeInput) {
    // 입력 창에서 "취소" 누른 경우, Input 이 null 로 넘어옴
    return;
  }

  if (apiModeInput.toUpperCase() === requestApiMode.toUpperCase()) {
    callback();
  } else {
    alert(`API 모드가 올바르지 않습니다. API Mode : ${requestApiMode}`);
  }
};

export function withAutoToast<T>(fn: T): T {
  return ((...args) =>
    (fn as any)(...args)
      .then((result) => {
        if (result) {
          toast.success("성공함", { autoClose: 3000 });
        }
        return result;
      })
      .catch((err) => {
        console.error(err);
        toast.error(`실패함(Err : ${err})`, {
          autoClose: 3000,
        });
      })) as any;
}

export function withAutoToastCode<T>(fn: T): T {
  return ((...args) =>
    (fn as any)(...args)
      .then((result) => {
        if (result.data.code === "success") {
          toast.success("성공함", { autoClose: 3000 });
          return result;
        }
        toast.error(`실패함(Err : ${result.data.data.message})`, {
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast.error(`실패함(Err : ${err})`, {
          autoClose: 3000,
        });
      })) as any;
}

export const return_normal = (callResult, callSign = "N") => {
  let resultCode;
  let resultDescription;

  if (callResult.data && callResult.data.success === false) {
    toast.error(callResult.data.data.message ?? `실패함`, {
      autoClose: 3000,
    });
    return false;
  }

  if (callResult.status) {
    resultCode = callResult.status.toString();
    resultDescription = callResult.statusText;
  }

  if (callResult.data.ResultCode && callResult.data.ResultCode.Code) {
    resultCode = callResult.data.ResultCode.Code.toString();
    resultDescription = callResult.data.ResultCode.Description;
  }

  if (resultCode === "10000" || resultCode === "200") {
    if (callSign === "Y") {
      toast.success("성공함", { autoClose: 3000 });
    }

    const listTypeData = [
      "EasyPaymentInfo",
      "OrderProcessingInfo",
      "DeliveryAddress",
      "OrderTransactionLog",
      "OrderChangesLog",
    ];

    Object.keys(callResult.data).forEach((key) => {
      if (listTypeData.includes(key)) {
        if (!Array.isArray(callResult.data[key])) {
          callResult.data[key] = new Array(callResult.data[key]);
        }
      }
    });

    return callResult.data;
  }
  toast.error(`실패함(Err : ${resultCode} | ${resultDescription})`, {
    autoClose: 3000,
  });
  console.log(callResult);
  return false;
};

/** 전체 주문목록 가져오기 */
export const getOrderList = async (param) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getOrderList`,
        params: {
          nowPage: param.nowPage,
          perPage: param.perPage,
          orderStatus: param.orderStatus ?? "",
          paymentID: param.paymentID ?? "",
          orderType: param.orderType ?? "",
          isSubscribed: param.isSubscribed ?? "",
          keyword: param.keyword ?? "",
          date_start: param.date_start ?? "",
          date_end: param.date_end ?? "",
        },
      },
      false
    );

    return return_normal(callResult);
  } catch (error) {
    console.log(error);
    toast.error("데이터 불러오기 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 특정 주문의 주문정보 가져오기 */
export const getOrderData = async (orderid) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getOrderInfo`,
        params: {
          orderid,
        },
      },
      false
    );

    return return_normal(callResult);
  } catch (error) {
    console.log(error);
    toast.error("데이터 불러오기 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 전체 회원 리스트 가져오기 */
export const GetUserList = async (param) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getUserList`,
        params: {
          nowpage: param.nowPage,
          perpage: param.perPage,
          keyword: param.keyword ? param.keyword : "",
        },
      },
      false
    );

    return return_normal(callResult);
  } catch (error) {
    console.log(error);
    toast.error("데이터 불러오기 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 특정 주문의 회원정보 가져오기 */
export const getUserData = async (userid) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getUserInfo`,
        params: {
          userid,
        },
      },
      false
    );

    return return_normal(callResult);
  } catch (error) {
    console.log(error);
    toast.error("데이터 불러오기 실패함", { autoClose: 3000 });
    return error;
  }
};

/* 특정 회원의 비밀번호 변경 요청하기 */
export const forgotPassword = async (email, type) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/forgotPassword`,
        params: {
          email,
          type,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (e) {
    console.log(e);
    toast.error("변경 요청 실패함", { autoClose: 3000 });
  }
};

/* 특정 회원의 배송지 목록 가져오기 */
export const getUserAddressList = async (userid) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getUserAddressList`,
        params: {
          userid,
        },
      },
      false
    );

    return return_normal(callResult);
  } catch (error) {
    console.log(error);
    toast.error("데이터 불러오기 실패함", { autoClose: 3000 });
    return error;
  }
};

/* 특정 회원의 장바구니 목록 가져오기 */
export const getUserCartList = async (userid) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getCartItemListByUNID`,
        params: {
          unid: userid,
        },
      },
      false
    );

    if (callResult.data.ResultCode.Code.toString() === "20013") {
      // toast.warning("장바구니 정보를 가져오지 못함", { autoClose: 3000 });
      console.log(callResult);
    } else if (callResult.data.ResultCode.Code.toString() !== "10000") {
      toast.error(
        `실패함(Err : ${callResult.data.ResultCode.Code} | ${callResult.data.ResultCode.Description})`,
        { autoClose: 3000 }
      );
      console.log(callResult);
      return false;
    } else {
      return callResult.data;
    }
  } catch (e) {
    console.log(e);
    toast.error("데이터 변경 요청 실패함", { autoClose: 3000 });
  }
};

/* 특정 회원의 장바구니에서 상품 제거하기 */
export const deleteCartItem = async (unid, item) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/deleteCartItemByUNID`,
        params: {
          unid,
          cartid: item.CartID,
          itemid: item.ItemID,
          optionid: item.OptionID,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (e) {
    console.log(e);
    toast.error("삭제 요청 실패함", { autoClose: 3000 });
  }
};

/* 특정 회원의 장바구니에서 상품 모두 제거하기 */
export const deleteCartItemAll = async (unid) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/deleteCartItemAllByUNID`,
        params: {
          unid,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (e) {
    console.log(e);
    toast.error("삭제 요청 실패함", { autoClose: 3000 });
  }
};

/* 갱신필요함 */
/* 회원 등록하기 */
export const signup = async (userinfo) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/admin/auth/sign-up`,
        params: {
          email: userinfo.email,
          pwd: userinfo.pwd,
          fullName: userinfo.fullname,
          dob: userinfo.dob,
          phone: userinfo.phone,
          gender: userinfo.gender,
          agreeNotifications: userinfo.agreenotifications,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (e) {
    console.log(e);
    toast.error("회원 등록 요청 실패함", { autoClose: 3000 });
  }
};

/* 판매중인 상품 목록 가져오기 */
export const getItemList = async () => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getItemList`,
        params: {
          providerid: 0,
        },
      },
      false
    );

    return return_normal(callResult);
  } catch (e) {
    console.log(e);
    toast.error("데이터 요청 실패함", { autoClose: 3000 });
  }
};

/* 검색결과 가져오기 */
export const getSearchInfo = async (keyword) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/searchInfo`,
        params: {
          keyword,
        },
      },
      false
    );

    return return_normal(callResult);
  } catch (e) {
    console.log(e);
    toast.error("데이터 요청 실패함", { autoClose: 3000 });
  }
};

/* 특정 주문의 배송지 변경하기 */
export const updateOrderAddress = async (orderAddressData) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/updateOrderAddress`,
        params: {
          orderid: orderAddressData.OrderID,
          oaid: orderAddressData.OAID,
          unid: orderAddressData.UNID,
          fullname: orderAddressData.FullName,
          postcode: orderAddressData.Postcode,
          country: orderAddressData.Country,
          city: orderAddressData.City,
          address1: orderAddressData.Address1,
          address2: orderAddressData.Address2,
          phone: orderAddressData.Phone,
          memo: orderAddressData.Memo,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (e) {
    console.log(e);
    toast.error("데이터 변경 요청 실패함", { autoClose: 3000 });
  }
};

/* 특정 주문의 배송지 지정하기 */
export const setOrderAddress = async (orderAddressData) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/setOrderAddress`,
        params: {
          orderid: orderAddressData.OrderID,
          unid: orderAddressData.UNID,
          fullname: orderAddressData.FullName,
          postcode: orderAddressData.Postcode,
          country: orderAddressData.Country,
          city: orderAddressData.City,
          address1: orderAddressData.Address1,
          address2: orderAddressData.Address2,
          phone: orderAddressData.Phone,
          memo: orderAddressData.Memo,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (e) {
    console.log(e);
    toast.error("데이터 변경 요청 실패함", { autoClose: 3000 });
  }
};

// 특정 주문의 배송지를 기존 주소지에서 선택하여 지정하기
export const setOrderAddressID = async (addressData) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/setAddressID`,
        params: {
          unid: addressData.unid,
          orderid: addressData.orderid,
          addressid: addressData.addressid,
        },
      },
      false
    );

    if (callResult.data.ResultCode.Code.toString() === "20031") {
      toast.error("배송지 변경이 불가능한 주문상태입니다.", {
        autoClose: 3000,
      });
    } else if (callResult.data.ResultCode.Code.toString() !== "10000") {
      toast.error(
        `실패함(Err : ${callResult.data.ResultCode.Code} | ${callResult.data.ResultCode.Description})`,
        { autoClose: 3000 }
      );
      console.log(callResult);
      return false;
    } else {
      return callResult.data;
    }
  } catch (e) {
    console.log(e);
    toast.error("데이터 변경 요청 실패함", { autoClose: 3000 });
  }
};

/* 주문 새로 등록하기 */
export const initOrderInfo = async (unid, ordertype, note) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/initOrderInfo`,
        params: {
          unid,
          ordertype,
          note,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (e) {
    console.log(e);
    toast.error("등록 요청 실패함", { autoClose: 3000 });
  }
};

/** 전체 리뷰목록 가져오기 */
export const getReviewList = async (param) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getReviewList`,
        params: {
          page: param.nowPage,
          perpage: param.perPage,
          point: param.point ? param.point : "",
          rgid: param.groupid ? param.groupid : 0,
          optionid: param.optionid ? param.optionid : 0,
          isvisible: param.isvisible ? param.isvisible : "",
          keyword: param.keyword ? param.keyword : "",
          providername: param.providername ? param.providername : "",
        },
      },
      false
    );

    return return_normal(callResult);
  } catch (error) {
    console.log(error);
    toast.error("데이터 불러오기 실패함", { autoClose: 3000 });
    return error;
  }
};

// export const getReviewCount = async () => {
//   const defaultParm = { nowPage: 1, perPage: 15 };
//   const result = await getReviewList(defaultParm);
//
//   return result;
// };
//

/** 전체 정기결제 리스트 가져오기 */
export const GetSubscriptionList = async (param) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getSubscriptionList`,
        params: {
          nowPage: param.nowPage,
          perPage: param.perPage,
          status: param.status ? param.status : "",
          paymentID: param.paymentID ? param.paymentID : "",
          scheduleAt: param.scheduleAt ? param.scheduleAt : "",
          keyword: param.keyword ? param.keyword : "",
        },
      },
      false
    );

    return return_normal(callResult);
  } catch (error) {
    console.log(error);
    toast.error("데이터 불러오기 실패함", { autoClose: 3000 });
    return error;
  }
};

// utils 에 등록하지 않고 ctx.api.admin.send-sms 방식으로 바로 호출 ㄱㄱ
/** 특정 사용자에게 SMS 발송하기 */
export const sendSMS = async (param) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/admin/send-sms`,
        params: {
          unid: param.unid,
          phone: param.phone,
          contents: param.contents,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (error) {
    console.log(error);
    toast.error("SMS 발송 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 특정 사용자에게 발송한 SMS 목록 가져오기 */
export const getSMSNotificationList = async (unid) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/get-notification-log`,
        params: {
          UNID: unid,
          CXAdmin: true,
          PageNumber: 1,
          PerPage: 500,
        },
      },
      false
    );

    return return_normal(callResult);
  } catch (error) {
    console.log(error);
    toast.error("발송 리스트 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 특정 사용자의 비밀번호 변경하기 */
export const changePassword = async (param) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/admin/auth/change-password`,
        params: {
          unid: param.unid,
          newpwd: param.newpwd,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (error) {
    console.log(error);
    toast.error("변경 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 회원 탈퇴하기 */
export const closingAccount = async (unid, params) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/closingAccount`,
        params: {
          unid,
          isreturnwill: params.isreturnwill,
          reason: params.reason,
          reasondetail: params.reasondetail,
          comment: params.comment,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (error) {
    console.log(error);
    toast.error("탈퇴 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/* 카카오 로그인 연결 끊기 */
export const unlinkSocialLogin = async (param) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/unlinkSocialLogin`,
        params: {
          unid: param.unid,
          sid: param.sid,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (e) {
    console.log(e);
    toast.error("연결끊기 요청 실패함", { autoClose: 3000 });
  }
};

/** 고객메모 불러오기 */
export const getNote = async (unid) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getNote`,
        params: {
          unid,
        },
      },
      false
    );

    return return_normal(callResult);
  } catch (error) {
    console.log(error);
    toast.error("메모 리스트 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 고객메모 작성하기 */
export const setNote = async (param) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/setNote`,
        params: {
          memoid: param.memoid ? param.memoid : 0,
          unid: param.unid,
          type: param.type,
          orderid: param.orderid ? param.orderid : 0,
          memo: param.memo,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (error) {
    console.log(error);
    toast.error("메모 작성 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 발주상태 확인 요청하기 */
export const getDeliveryStatus = async (dcid) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getDeliveryStatus`,
        params: {
          dcid,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (error) {
    console.log(error);
    toast.error("발주 상태 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 송장번호 확인 요청하기 */
export const getDeliveryTrackNo = async (dcid) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getDeliveryTrackNo`,
        params: {
          dcid,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (error) {
    console.log(error);
    toast.error("송장번호 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 송장번호 변경 요청하기 */
export const changeTrackNo = async (param) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/changeTrackNo`,
        params: {
          ...param,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (error) {
    console.log(error);
    toast.error("송장번호 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 배송상태 확인 요청하기 */
export const getDeliveryTrackLogs = async (trackno) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getDeliveryTrackLogs`,
        params: {
          trackno,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (error) {
    console.log(error);
    toast.error("배송상태 확인 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 특정 사용자의 주문 목록 가져오기 */
export const getUserOrderList = async (unid) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getUserOrderList`,
        params: {
          unid,
        },
      },
      false
    );

    return return_normal(callResult);
  } catch (error) {
    console.log(error);
    toast.error("주문내역 확인 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 발주서 만들기 */
export const initDeliveryList = async (adminID) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/delivery/init-delivery-list`,
        params: {
          adminUnid: adminID,
          isLive: LambdaLive,
        },
      },
      false
    );

    if (callResult.data.success) {
      toast.success("성공함", { autoClose: 3000 });
      return callResult.data.data;
    }

    toast.error("실패함", { autoClose: 3000 });
    console.log(callResult.data.resultTypeCode);
  } catch (error) {
    console.log(error);
    toast.error("발주서 만들기 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 발주서 취소하기 */
export const cancelDeliveryList = async (dmid, adminID) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/delivery/cancel-delivery-list`,
        params: {
          dmid,
          adminUnid: adminID,
          isLive: LambdaLive,
        },
      },
      false
    );

    if (callResult.data.success) {
      toast.success("성공함", { autoClose: 3000 });
      return callResult.data.data;
    }

    toast.error("실패함", { autoClose: 3000 });
    console.log(callResult.data.resultTypeCode);
  } catch (error) {
    console.log(error);
    toast.error("발주서 취소하기 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 발주서 다운받기 */
export const exportDeliveryItemList = async (dmid, adminID) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/delivery/export-delivery-list`,
        params: {
          dmid,
          adminUnid: adminID,
          isLive: LambdaLive,
        },
      },
      false
    );

    if (callResult.data.success) {
      toast.success("성공함", { autoClose: 3000 });
      return callResult.data.data;
    }

    toast.error("실패함", { autoClose: 3000 });
    console.log(callResult.data.resultTypeCode);
  } catch (error) {
    console.log(error);
    toast.error("발주서 다운받기 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 발주 상세 정보 가져오기 */
export const getDeliveryInfo = async (did) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getDeliveryInfo`,
        params: {
          did,
        },
      },
      false
    );

    return return_normal(callResult);
  } catch (error) {
    console.log(error);
    toast.error("배송내역 확인 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 사용자에게 발송된 메시지 리스트 가져오기 */
export const getNotificationList = async (unid) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/get-notification-log`,
        params: {
          UNID: unid,
          CXAdmin: false,
          PageNumber: 1,
          PerPage: 500,
        },
      },
      false
    );

    callResult.data.forEach((result) => {
      if (result.Reciever.includes("@")) result.SendType = "Email";
      else result.SendType = "AlimTalk";
      result.Description = "";
    });

    const smsLog = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/get-notification-log`,
        params: {
          UNID: unid,
          CXAdmin: true,
          PageNumber: 1,
          PerPage: 500,
        },
      },
      false
    );

    smsLog.data.forEach((sms) => {
      sms.Title = "";
      sms.SendType = "SMS";
      sms.Description = "";
      return sms;
    });

    const newNotificationLog = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/get-new-notification-log`,
        params: {
          UNID: unid,
          CXAdmin: false,
        },
      },
      false
    );

    const newNotiLogAsOldForm = newNotificationLog.data.map((log) => {
      console.log("log", log.infobipStatus?.description ?? "xxxx");

      return {
        AdminUNID: log.adminUNID,
        Contents:
          log.sendType === "ChinguTalk"
            ? log.data.chingutalk.content
            : log.data.content,
        LogData: "",
        NID: 0,
        Reciever: log.receiver,
        RegDate: log.regDateTime,
        Status: log.status,
        TID: log.data.TID,
        Title: log.data.title,
        UNID: log.UNID,
        SendType: log.sendType,
        Description: log.infobipStatus?.description ?? "-",
      };
    });

    callResult.data = newNotiLogAsOldForm
      .concat(callResult.data)
      .concat(smsLog.data);
    callResult.data.sort((a, b) => (a.RegDate < b.RegDate ? 1 : -1));

    return return_normal(callResult);
  } catch (error) {
    console.log(error);
    toast.error("메시지 발송 내역 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

export const getUniqueKey = async () => uuidv4().replace(/-/g, "");

/** 주문의 상태 변경하기 */
export const changeOrderStatus = async (param) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/changeOrderStatus`,
        params: {
          unid: param.unid,
          orderid: param.orderid,
          orderstatus: param.orderstatus,
          note: param.note,
        },
      },
      false
    );

    if (callResult.data.ResultCode.Code.toString() !== "10000") {
      if (callResult.data.ResultCode.Code.toString() === "20029") {
        toast.error(
          `주문 상태 변경 요청을 실패함 - 발주중인 주문이 있는지 확인해 주세요.(Err : ${callResult.data.ResultCode.Code} | ${callResult.data.ResultCode.Description})`,
          { autoClose: 5000 }
        );
      } else {
        toast.error(
          `주문 상태 변경 요청을 실패함 (Err : ${callResult.data.ResultCode.Code} | ${callResult.data.ResultCode.Description})`,
          { autoClose: 3000 }
        );
      }
      console.log(callResult);
    } else {
      toast.success("주문 상태 변경을 요청하였습니다.", { autoClose: 3000 });
      return callResult.data;
    }
  } catch (error) {
    console.log(error);
    toast.error("주문 상태 변경 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 사용자의 0UP 지급여부 확인하기 */
export const getFreeZeroUpStatus = async (unid) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getFreeZeroUpStatus`,
        params: {
          unid,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (error) {
    console.log(error);
    toast.error("상태 확인 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 사용자의 0UP 지급여부 변경하기 */
export const setFreeZeroUpStatus = async (unid, optionid, status) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/setFreeZeroUpStatus`,
        params: {
          unid,
          itemid: 101040000,
          optionid,
          status,
        },
      },
      false
    );

    if (callResult.data.ResultCode.Code.toString() !== "10000") {
      if (callResult.data.ResultCode.Code.toString() === "10036") {
        toast.error("이미 지급된 사용자입니다.", { autoClose: 3000 });
        console.log(callResult);
      } else {
        toast.error(
          `상태변경 요청을 실패함 (Err : ${callResult.data.ResultCode.Code} | ${callResult.data.ResultCode.Description})`,
          { autoClose: 3000 }
        );
        console.log(callResult);
      }
    } else {
      return callResult.data;
    }
  } catch (error) {
    console.log(error);
    toast.error("상태변경 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 고객님의 발주중인 주문 확인하기 */
export const getOrderProcessingInfo = async (unid) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getOrderProcessingInfo`,
        params: {
          unid,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (error) {
    console.log(error);
    toast.error("상태 확인 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 특정 사용자의 로그인 기록 가져오기 */
export const getUserSigninLog = async (unid) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getUserSigninLog`,
        params: {
          unid,
        },
      },
      false
    );

    return return_normal(callResult);
  } catch (error) {
    console.log(error);
    toast.error("로그인 내역 확인 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 주문별 데이터 수 가져오기 */
export const getOrderCount = async () => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getOrderCount`,
      },
      false
    );

    return return_normal(callResult);
  } catch (error) {
    console.log(error);
    toast.error("주문수 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

// 환불금액 지정하기
export const setRefundAmount = async (orderid, refundprice) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/setRefundAmount`,
        params: {
          orderid,
          refundprice,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (error) {
    console.log(error);
    toast.error("환불금액 지정 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 특정 사용자의 정기결제 변경 기록 가져오기 */
export const getUserSubscriptionChangeLog = async (unid) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getUserSubscriptionLog`,
        params: {
          unid,
        },
      },
      false
    );

    return return_normal(callResult);
  } catch (error) {
    console.log(error);
    toast.error("정기결제 변경 내역 확인 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 발주 철회하기 */
export const cancelDeliveryAfterExport = async (did) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/cancelDeliveryAfterExport`,
        params: {
          did,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (error) {
    console.log(error);
    toast.error("발주 철회 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 주문의 변경 기록 가져오기 */
export const getOrderChangesLog = async (unid, orderid) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getOrderChangesLog`,
        params: {
          unid,
          orderid,
        },
      },
      false
    );

    return return_normal(callResult);
  } catch (error) {
    console.log(error);
    toast.error("주문 변경 내역 확인 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 고객정보의 변경 기록 가져오기 */
export const getUserChangesLog = async (unid) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getUserChangesLog`,
        params: {
          unid,
        },
      },
      false
    );

    return return_normal(callResult);
  } catch (error) {
    console.log(error);
    toast.error("고객정보 변경 내역 확인 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 고객의 수신동의 여부 변경하기 */
export const updateUserAgreeNotifications = async (
  userdata,
  agreenotifications
) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/updateUserAgreeNotifications`,
        params: {
          unid: 0,
          phone: userdata.phone,
          email: userdata.email,
          agreenotifications,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (error) {
    console.log(error);
    toast.error("고객정보 변경 내역 확인 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** CX주문 만들때 가격 지정하기 */
export const resetOrderPrice = async (unid, orderid, paymentAmount) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/resetOrderPrice`,
        params: {
          unid,
          orderid,
          paymentAmount,
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (error) {
    console.log(error);
    toast.error("주문 가격 지정 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 고객의 정기구매취소, 정기구매중지, 회원탈퇴 정보의 변경 기록 가져오기 */
export const getUserServiceChangeLog = async (unid) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getUserServiceChangesLog`,
        params: {
          unid,
        },
      },
      false
    );

    return return_normal(callResult);
  } catch (error) {
    console.log(error);
    toast.error("고객정보 변경 내역 확인 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 전체 CX주문목록 가져오기 */
export const getCxOrderList = async (param) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/getOrderList_CX`,
        params: {
          page: param.nowPage,
          perpage: param.perPage,
          startdate: param.startdate,
          enddate: param.enddate,
        },
      },
      false
    );

    return return_normal(callResult);
  } catch (error) {
    console.log(error);
    toast.error("데이터 불러오기 실패함", { autoClose: 3000 });
    return error;
  }
};

// 브랜드별 수신동의여부 변경하기
export const changeNewsletterStatus = async (param) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/wsadmin/changeNewsletterStatus`,
        params: {
          unid: param.unid ? param.unid : "",
          providerid: param.providerid ? param.providerid : "",
          type: param.type ? param.type : "",
          typestatus: param.typestatus ? param.typestatus : "",
          status: param.status ? param.status : "",
        },
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (error) {
    console.log(error);
    toast.error("데이터 불러오기 실패함", { autoClose: 3000 });
    return error;
  }
};

/// ////////////////////////////////////////////////////////////////////////////////////////
/// ////////////////////////////////////////////////////////////////////////////////////////
/// ////////////////////////////////////////////////////////////////////////////////////////
/// ////////////////////////////////////////////////////////////////////////////////////////
/// ////////////////////////////////////////////////////////////////////////////////////////
// NONE ADMIN API
/// ////////////////////////////////////////////////////////////////////////////////////////
/// ////////////////////////////////////////////////////////////////////////////////////////
/// ////////////////////////////////////////////////////////////////////////////////////////
/// ////////////////////////////////////////////////////////////////////////////////////////
/// ////////////////////////////////////////////////////////////////////////////////////////

/** 리뷰 노출상태 변경하기 */
export const changeReviewStatus = async (param) => {
  try {
    const callResult = await ApiCall({
      url: `${NodeServerDomain}/admin/changeReviewStatus`,
      params: {
        rid: param.rid,
        isVisible: param.isvisible,
      },
    });

    return return_normal(callResult, "Y");
  } catch (error) {
    console.log(error);
    toast.error("변경 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 주문 취소하기 */
export const cancelOrder = async (
  unid,
  orderId,
  payType,
  bankInfo,
  refundAmount
) => {
  try {
    let callResult;
    if (payType === "vBank") {
      callResult = await ApiCall({
        url: `${NodeServerDomain}/admin-payment/cancel`,
        params: {
          unid,
          orderId,
          cancelVBankParam: {
            holderName: bankInfo.holdername,
            bank: bankInfo.bankcode,
            accountNumber: bankInfo.banknumber,
            refundAmount,
          },
        },
      });
    } else if (refundAmount > 0) {
      callResult = await ApiCall({
        url: `${NodeServerDomain}/admin-payment/cancel`,
        params: {
          unid,
          orderId,
          amount: refundAmount,
        },
      });
    } else {
      callResult = await ApiCall({
        url: `${NodeServerDomain}/admin-payment/cancel`,
        params: {
          unid,
          orderId,
        },
      });
    }

    if (callResult) {
      return return_normal(callResult, "Y");
    }
  } catch (error) {
    console.log(error);
    toast.error("취소 요청 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 반품 주문 생성 로그 남기기 */
export const setReturnOrderLog = async (param) => {
  try {
    const callResult = await ApiCall({
      url: `${NodeServerDomain}/wsadmin/setCancelLog`,
      params: {
        unid: param.unid,
        type: param.type,
        isreturnwill: param.isreturnwill,
        orderid: param.orderid,
        reason: param.reason,
        reasondetail: param.reasondetail,
        canceldata: param.canceldata,
        comment: param.comment,
        status: param.status,
        regdate: param.regdate,
      },
    });

    if (callResult.data) {
      return callResult.data;
    }
    toast.error("불러온 데이터가 비어있음", { autoClose: 3000 });
    console.log(callResult);
  } catch (error) {
    console.log(error);
    toast.error("데이터 불러오기 실패함", { autoClose: 3000 });
    return error;
  }
};

/** LinkedOrderID 업데이트 */
export const setLinkedOrderID = async (param) => {
  try {
    const callResult = await ApiCall({
      url: `${NodeServerDomain}/wsadmin/setLinkedOrderID`,
      params: {
        orderid: param.orderid,
        linkedid: param.linkedid,
      },
    });

    if (callResult.data) {
      return callResult.data;
    }
    toast.error("불러온 데이터가 비어있음", { autoClose: 3000 });
    console.log(callResult);
  } catch (error) {
    console.log(error);
    toast.error("데이터 불러오기 실패함", { autoClose: 3000 });
    return error;
  }
};

/** 쿼리 리스트 가져오기 */
export const GetQueryList = async () => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/admin/get-query-info`,
      },
      false
    );

    return return_normal(callResult);
  } catch (error) {
    console.log(error);
    toast.error("데이터 불러오기 실패함", { autoClose: 3000 });
    return error;
  }
};
/** 쿼리 실행하기 */
export const ExecuteQuery = async (params) => {
  try {
    const callResult = await ApiCall(
      {
        url: `${NodeServerDomain}/admin/execute-query`,
        params,
      },
      false
    );

    return return_normal(callResult, "Y");
  } catch (error) {
    console.log(error);
    toast.error("데이터 불러오기 실패함", { autoClose: 3000 });
    return error;
  }
};
