import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Calendar from "react-calendar";
import Drawer from "react-drag-drawer";
import moment from "moment";
import { IconContext } from "react-icons";
import { IoIosRefresh } from "react-icons/io";
import { FaToggleOn, FaToggleOff, FaTrashAlt } from "react-icons/fa";
import { sum } from "ramda";

import { numberFormat } from "../../../utils/UtilFunc";
import {
  ImageServerDomain,
  providerList,
  providerColor,
  CancelReason,
} from "../../../Store";
import { withAutoToast } from "../../../utils/Utils";
import { AppContext } from "../../../index";
import { DetailContext, DetailCtx } from "../../../DetailContext";
import { NewSubscriptionInfo, TbSubscriptionItems } from "../../../api";

type ControlItem = {
  item: any;
  option: any;
  matchedItem?: TbSubscriptionItems;
};

const UserSubscription = () => {
  const ctx = useContext(AppContext);
  const detailCtx = useContext(DetailContext);

  // 현재 선택한 브랜드
  const [nowProviderID, setNowProviderID] = useState(100000000);

  // 현재 선택한 상품종류(정기/일반)
  const [nowSubscriptionType, setNowSubscriptionType] = useState("Y");

  // 정기구매, 일회구매 관련 상품 컨트롤 패널 데이터
  const [openControlItems, setOpenControlItems] = useState<boolean>(false);
  const [changes, setChanges] = useState<Record<number, number>>({});

  /** 달력 */
  const [getDate, setDate] = useState(new Date());
  const [getCalendarView, setCalendarView] = useState(false);
  /** Modal2 띄우기 */
  const [isOpened, setIsOpened] = useState(false);

  /// state 정의는 여기서 끝

  const { data, reload } = detailCtx;
  const { itemList, subscriptionData } = data;

  if (!subscriptionData) return <></>;

  const { UNID } = subscriptionData!.subscription;

  const updateItem = withAutoToast(async (option, qty, applyNow = false) => {
    // const isSub = option.SubscriptionType === "Y";
    // if (isSub && qty > 2) {
    //   window.alert("구독 상품은 2개로 제한됩니다.");
    // }
    // const nextQty = max(0, qty);
    setChanges((c) => ({ ...c, [option.OptionID]: qty }));

    if (applyNow) {
      if (qty === 0) {
        await ctx.api.adminSubscription.deleteSubscriptionItem({
          UNID,
          OptionID: option.OptionID,
        });
      } else {
        await ctx.api.adminSubscription.updateSubscriptionItem({
          UNID,
          param: {
            itemId: option.ItemID,
            optionId: option.OptionID,
            isSubscribed: option.SubscriptionType === "Y",
            quantity: qty,
            cycle: option.Cycle,
            term: option.Term === "week" ? "W" : option.Term,
          },
        });
      }
      reload.reloadSubscription();
      return true;
    }
  });

  const restartSubscription = withAutoToast(async () => {
    if (window.confirm("정말로 다시 시작하시겠습니까?")) {
      const { UNID, ScheduleAt } = subscriptionData!.subscription;
      await ctx.api.adminSubscription.restartSubscription({
        UNID,
        ScheduleAt,
      });
      reload.reloadSubscription();
    }
  });

  const pauseSubscription = withAutoToast(async () => {
    if (window.confirm("정말로 일시정지하시겠습니까?")) {
      const { UNID } = subscriptionData!.subscription;
      const reason = "paused on admin page";
      await ctx.api.adminSubscription.pauseSubscription({
        UNID,
        param: {
          IsReturnWill: "N",
          Reason: reason,
          ReasonDetail: reason,
          CancelData: reason,
          Comment: reason,
        },
      });
      reload.reloadSubscription();
    }
  });

  const subscriptionPayNow = withAutoToast(async () => {
    const { UNID } = subscriptionData!.subscription;
    if (window.confirm("정말 지금결제하시겠습니까?")) {
      await ctx.api.adminSubscription.triggerSubscription({
        unid: UNID,
        mode: "admin",
      });
      reload.reloadSubscription();
      return true;
    }
  });

  // 이번에 받기
  const toggleSubscriptionItem = async (item: TbSubscriptionItems) => {
    const receiveThisTime = !(
      item.ScheduledDate === subscriptionData!.subscription.ScheduleAt
    );
    const { UNID } = subscriptionData!.subscription;
    await ctx.api.adminSubscription.skipItem({
      UNID,
      param: {
        skip: false,
        receiveThisTime,
        optionId: item.OptionID,
      },
    });
    reload.reloadSubscription();
  };
  const showModal = () => {
    setIsOpened(true); // Modal 띄우기
  };

  const cycles = [4, 8, 12, 16];

  const SetSubscriptionBaseCycle = withAutoToast(async (cycle) => {
    const { UNID } = subscriptionData!.subscription;
    if (window.confirm("정말 구독주기를 일괄 변경하시겠습니까?")) {
      await ctx.api.adminSubscription.updateSubscription({
        UNID,
        Cycle: cycle,
      });
      reload.reloadSubscription();
      setOpenControlItems(false);
      return true;
    }
  });

  const changChangeSubscriptionScheduleAt = withAutoToast(
    async (scheduleat) => {
      const { UNID } = subscriptionData!.subscription;
      await ctx.api.adminSubscription.updateSubscription({
        UNID,
        ScheduleAt: scheduleat,
      });
      reload.reloadSubscription();
      return true;
    }
  );

  const pushSubscriptionSchedule = (day: number) =>
    changChangeSubscriptionScheduleAt(
      moment(subscriptionData!.subscription.ScheduleAt)
        .add(day, "d")
        .format("YYYY-MM-DD")
    );
  const setSubscriptionSchedule = () => {
    changChangeSubscriptionScheduleAt(moment(getDate).format("YYYY-MM-DD"));
    setCalendarView(false);
  };

  const status = SubscriptionStatusBadge(subscriptionData!.subscription.Status);

  const subscriptionOn = subscriptionData!.subscription.Status === "Y";
  const statusButton = {
    txt: subscriptionOn ? "일시정지" : "다시시작",
    class: subscriptionOn ? "secondary" : "info",
    hide: !["Y", "X", "N"].includes(subscriptionData!.subscription.Status),
    callback: () =>
      subscriptionOn ? pauseSubscription() : restartSubscription(),
  };

  const calendar = getCalendarView && (
    <div style={calendarStyle}>
      <Calendar
        onChange={(date) => setDate(date as any)}
        value={getDate}
        calendarType="US"
        minDate={new Date()}
      />
      <button
        className="btn btn-sm btn-secondary btn-block mt-1"
        onClick={setSubscriptionSchedule}
      >
        변경하기
      </button>
    </div>
  );

  const rows = [
    {
      name: "상태",
      value: status.txt + " : " + subscriptionData!.subscription.SID.toString(),
      classes: status.cls,
      buttons: [statusButton],
    },
    {
      name: "생성일시",
      value: subscriptionData!.subscription.RegDate
        ? moment(subscriptionData!.subscription.RegDate).format(
            "YYYY-MM-DD HH:mm:ss"
          )
        : "-",
    },
    {
      name: "다음결제일",
      value: moment(subscriptionData!.subscription.ScheduleAt).format(
        "YYYY-MM-DD"
      ),
      buttons: [
        {
          txt: "2주미루기",
          callback: () => pushSubscriptionSchedule(14),
        },
        {
          txt: "4주미루기",
          callback: () => pushSubscriptionSchedule(28),
        },
        {
          txt: "결제일시변경",
          callback: () => setCalendarView(!getCalendarView),
        },
      ],
      rightComponent: calendar,
    },
    {
      name: "구독주기",
      value: subscriptionData!.subscription.BaseCycle + "주마다",
      buttons: cycles.map((cycle, idx) => ({
        txt: `${cycle}주마다`,
        class:
          subscriptionData!.subscription.BaseCycle === cycle ? "info" : "light",
        callback: () => SetSubscriptionBaseCycle(cycle),
      })),
    },
    {
      name: "당겨받기",
      value: "",
      buttons: [
        {
          txt: "즉시 당겨받기",
          class: "danger",
          callback: () => subscriptionPayNow(),
        },
      ],
    },
  ].map(NameValueAndButtons);

  const controlItems = itemList
    ? itemList.ItemList.flatMap((item) =>
        itemList.ItemOptionList.filter(
          (optionItem) =>
            item.ProviderID === nowProviderID &&
            item.ItemID === optionItem.ItemID &&
            optionItem.SubscriptionType === nowSubscriptionType
        ).map((option) => {
          const myItems =
            nowSubscriptionType === "Y"
              ? subscriptionData?.subscriptionItems
              : subscriptionData?.onetimeItems;

          const matchedItem = ((myItems ?? []) as any).find(
            (i) => i.OptionID === option.OptionID
          );
          return { item, option, matchedItem };
        })
      )
    : [];

  const itemPriceAmount = sum(
    [
      ...subscriptionData.subscriptionItems.filter(
        (i) => i.ScheduledDate === subscriptionData.subscription.ScheduleAt
      ),
      ...subscriptionData.onetimeItems,
    ].map((i) => i.Quantity * i.ItemOption?.Price!)
  );

  const bodyContents = subscriptionData && (
    <div className="card-body">
      <div>
        <table className="table table-borderless table-sm">
          <tbody>{rows}</tbody>
        </table>
      </div>

      <div>
        <div style={{ borderTop: "1px solid #eee", paddingTop: "10px" }}>
          {SubscriptionTable({
            subscriptionData,
            toggleSubscriptionItem,
          })}
        </div>

        <div style={{ borderTop: "1px solid #eee", paddingTop: "10px" }}>
          {ShppingItemTable(subscriptionData)}
        </div>

        <div className="text-right mb-3">
          <div>총 상품가격</div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.3rem",
            }}
          >
            {numberFormat(itemPriceAmount ?? 0)}
          </div>
        </div>

        <div className="widget-wrapper text-right pb-2">
          <button
            className="btn btn-sm btn-light ml-1"
            type="button"
            onClick={() => {
              setNowSubscriptionType("Y");
              setOpenControlItems(true);
            }}
          >
            정기구매상품변경
          </button>

          <button
            className="btn btn-sm btn-light ml-1"
            type="button"
            onClick={() => {
              setNowSubscriptionType("N");
              setOpenControlItems(true);
            }}
          >
            1회구매상품변경
          </button>
        </div>
      </div>

      {openControlItems && (
        <div style={{ borderTop: "1px solid #ddd" }}>
          <div className="text-right">
            <div role="group" className="m-2 btn-group">
              {Object.keys(providerList).map((key, idx) => (
                <button
                  key={idx}
                  type="button"
                  className={`btn btn-shadow btn-primary ${
                    parseInt(key) === nowProviderID && "active"
                  }`}
                  onClick={() => {
                    setNowProviderID(parseInt(key));
                  }}
                >
                  {providerList[key]}
                </button>
              ))}
            </div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setOpenControlItems(false)}
            >
              닫기
            </button>
          </div>

          <table className="table table-borderless table-hover table-sm mt-3 mb-0">
            <tbody>
              {controlItems?.map((controlItem, idx) =>
                ControlItemComponent(idx, controlItem, changes, updateItem)
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );

  return (
    <>
      <div className="row mb-1">
        <div className="col">
          <div className="card">
            {CardHeader(detailCtx, showModal)}
            {bodyContents}
          </div>
        </div>
      </div>

      <CloseDrawer
        isOpened={isOpened}
        setIsOpened={setIsOpened}
        detailCtx={detailCtx}
      />
    </>
  );
};

// (isOpened, setIsOpened, detailCtx)}
export default UserSubscription;

function ControlItemComponent(
  idx: number,
  controlItem: ControlItem,
  changes: Record<number, number>,
  updateItem: (
    option: any,
    quantity: number,
    applyNow?: boolean
  ) => Promise<any>
) {
  const { item, option, matchedItem } = controlItem;
  const controlQuantity = changes[option.OptionID];
  const matchedQuantity = matchedItem?.Quantity ?? 0;
  const viewQuantity = controlQuantity ?? matchedQuantity;
  const changed =
    controlQuantity !== undefined && controlQuantity !== matchedQuantity;
  const changeQty = (qty) => updateItem(option, qty);
  return (
    <tr key={idx}>
      <td>
        <div className="widget-content p-1">
          <div className="widget-content-wrapper">
            <div className="widget-content-left mr-1">
              {ProviderBadge(item.ProviderID)}
            </div>
            <div className="widget-content-left mr-2">
              {option.SubscriptionType === "Y"
                ? subscriptionBadge
                : regularBadge}
            </div>
            <div className="widget-content-left mr-2">
              <img
                className="rounded-circle"
                src={ImageServerDomain + item.FileKey + item.FileName}
                alt=""
                style={{ width: "40px", height: "40px" }}
              />
            </div>
            <div className="widget-content-left flex2">
              <div className="widget-heading">
                {item.ItemName}
                {option.ItemID === 101040000 && option.Price === 0 && (
                  <span
                    className="text-danger ml-1"
                    style={{
                      fontSize: "0.7em ",
                    }}
                  >
                    무료증정
                  </span>
                )}
                {option.IsVisible === "N" && (
                  <span className="badge badge-warning ml-1">미노출</span>
                )}
              </div>
              <div
                className="widget-subheading opacity-10"
                style={{
                  fontWeight: "normal",
                  fontSize: "0.9em",
                }}
              >
                {option.OptionItemName}
              </div>
            </div>
          </div>
        </div>
      </td>
      <td width="100">
        <div className="input-group input-group-sm">
          <div className="input-group-prepend">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => changeQty(viewQuantity - 1)}
            >
              -
            </button>
          </div>
          <input
            className={`form-control text-center ${
              option.Quantity <= 0 && "bg-light text-secondary"
            }`}
            // TODO: type=number?
            type="text"
            name="Quantity"
            style={{ width: "30px" }}
            value={viewQuantity}
            onChange={(e) => changeQty(+e.target.value)}
          />
          <div className="input-group-append">
            <button
              type="button"
              className="btn btn-light"
              onClick={(e) => changeQty(viewQuantity + 1)}
            >
              +
            </button>
          </div>
        </div>
      </td>
      <td width="120" className="text-right">
        <button
          type="button"
          className={`btn btn-sm mr-1 ${changed ? "btn-danger" : "btn-light"}`}
          onClick={() => updateItem(option, viewQuantity, true)}
        >
          변경
        </button>

        <button
          type="button"
          className="btn btn-light btn-sm"
          onClick={() => updateItem(option, 0, true)}
        >
          제거
        </button>
      </td>
    </tr>
  );
}

function CloseDrawer(props: {
  isOpened: boolean;
  setIsOpened: any;
  detailCtx: DetailCtx;
}) {
  const {
    isOpened,
    setIsOpened,
    detailCtx: { data, reload },
  } = props;
  const ctx = useContext(AppContext);

  const [cancelReason1st, setCancelReason1st] = useState({});
  const [cancelItems, setCancelItems] = useState({});
  const [cancelCheckbox, setCancelCheckbox] = useState({});

  const [closeInfo, setCloseInfo] = useState({
    IsReturnWill: "",
    Reason: "",
    ReasonDetail: "",
    CancelData: "",
    Comment: "[관리자를 통한 정기구매해지]",
  });

  /** Modal2에서 수정하는 내용 임시 저장 */
  const handleChangeCloseInfo = (event) => {
    setCloseInfo({
      ...closeInfo,
      [event.target.name]: event.target.value,
    });
  };

  // 정기구매 해지
  const cancelSubscription = async () => {
    const { UNID } = data.subscriptionData!.subscription;

    // 전달할 데이터 가공
    const sendData = { ...cancelCheckbox };
    const keys = Object.keys(sendData);
    keys.map((keys) => {
      if (sendData[keys + "_text"]) {
        sendData[keys] = sendData[keys + "_text"];
        delete sendData[keys + "_text"];
      }
      return "";
    });
    const params = {
      ...closeInfo,
      Reason: Object.keys(cancelReason1st).join("|"),
      ReasonDetail: cancelCheckbox["99_text"] ? cancelCheckbox["99_text"] : "",
      Comment: closeInfo.Comment,
      CancelData: JSON.stringify(sendData),
      IsReturnWill: "N" as "Y" | "N",
    };

    withAutoToast(async () => {
      await ctx.api.adminSubscription.cancelSubscription({
        UNID,
        param: params,
      });

      reload.reloadSubscription();
      setIsOpened(false);
    })();
  };

  const handleChangeCheckbox = (event) => {
    if (event.target.checked === false) {
      const tempCheckbox = cancelCheckbox;

      // 상위항목 비활성하면 하위항목 모두 비활성화
      const keys = Object.keys(tempCheckbox);
      keys.map((key) => {
        if (key.indexOf(event.target.id) !== -1) {
          delete tempCheckbox[key];
        }
        return "";
      });

      // 제품이 많이 남아서 체크 해제하면 정기구매중인 제품 데이터 지우기
      if (event.target.id === "Q4") {
        const keysItem = Object.keys(cancelItems);
        keysItem.map((key) => {
          delete tempCheckbox[key];
          return "";
        });

        setCancelItems({});
      }

      setCancelCheckbox({ ...tempCheckbox });
    } else {
      // 기타 항목은 value 값 가져와서 저장 | 그게 아니면 체크여부 저장
      setCancelCheckbox({
        ...cancelCheckbox,
        [event.target.id]:
          event.target.id.split("_")[1] === "text"
            ? event.target.value
            : event.target.checked,
      });
    }

    // 상위 항목만 따로 저장
    if (event.target.name === "1st") {
      const temp = {
        ...cancelReason1st,
        [event.target.id]: event.target.checked,
      };
      if (event.target.checked === false) {
        delete temp[event.target.id];
      }
      setCancelReason1st(temp);
    }

    // 정기구매중인 제품만 따로 저장
    if (event.target.name === "items") {
      const temp = {
        ...cancelItems,
        [event.target.id]: event.target.checked,
      };
      if (event.target.checked === false) {
        delete temp[event.target.id];
      }
      setCancelItems(temp);
    }
  };

  return (
    <Drawer
      open={isOpened}
      onRequestClose={() => setIsOpened(!isOpened)}
      allowClose={false}
    >
      <div className="row">
        <div className="col">
          <div className="card mb-3">
            <div className="card-body">
              <div>
                <form>
                  <div className="form-group position-relative">
                    <div className="input-group input-group-sm">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text mb-1"
                          style={{ minWidth: "150px" }}
                        >
                          정기구매 취소 이유
                        </span>
                      </div>

                      <div className="p-2">
                        {CancelReason.map((item, idx) => (
                          <React.Fragment key={idx}>
                            <div className="position-relative form-check pt-1">
                              <input
                                type="checkbox"
                                name="1st"
                                checked={
                                  cancelCheckbox[item.code]
                                    ? cancelCheckbox[item.code]
                                    : false
                                }
                                id={item.code}
                                className="form-check-input"
                                onChange={(e) => handleChangeCheckbox(e)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={item.code}
                              >
                                {item.text}
                              </label>
                              {item.text === "기타" && (
                                /*cancelCheckbox[item.code] &&*/ <div>
                                  <textarea
                                    style={{ height: "30px" }}
                                    disabled={
                                      cancelCheckbox[item.code] !== true
                                    }
                                    className="form-control"
                                    id={item.code + "_text"}
                                    onChange={(e) => handleChangeCheckbox(e)}
                                    value={
                                      cancelCheckbox[item.code + "_text"]
                                        ? cancelCheckbox[item.code + "_text"]
                                        : ""
                                    }
                                  />
                                </div>
                              )}
                            </div>

                            <div
                              style={{ overflow: "auto", maxHeight: "400px" }}
                            >
                              {item.optionList &&
                                item.optionList.map((optionList, idx2) => {
                                  const providers = (
                                    data.subscriptionData?.subscriptionItems ??
                                    []
                                  ).map(
                                    (si) =>
                                      Math.floor(si.OptionID / 100_000_000) *
                                      100_000_000
                                  );

                                  if (
                                    providers.includes(optionList.providerID)
                                  ) {
                                    return (
                                      <React.Fragment key={idx2}>
                                        <div
                                          className="ml-3"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          * {optionList.text}
                                        </div>
                                        {optionList.options.map(
                                          (options, idx3) => (
                                            <div
                                              className="position-relative form-check ml-3"
                                              key={idx3}
                                            >
                                              <input
                                                type="checkbox"
                                                disabled={
                                                  cancelCheckbox[item.code] !==
                                                  true
                                                }
                                                checked={
                                                  cancelCheckbox[options.code]
                                                    ? cancelCheckbox[
                                                        options.code
                                                      ]
                                                    : false
                                                }
                                                id={options.code}
                                                className="form-check-input"
                                                onChange={(e) =>
                                                  handleChangeCheckbox(e)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={options.code}
                                              >
                                                {options.text}
                                              </label>
                                              {options.text === "기타" && (
                                                <div className="position-relative form-group">
                                                  <textarea
                                                    style={{ height: "25px" }}
                                                    disabled={
                                                      cancelCheckbox[
                                                        options.code
                                                      ] !== true
                                                    }
                                                    className="form-control"
                                                    id={options.code + "_text"}
                                                    onChange={(e) =>
                                                      handleChangeCheckbox(e)
                                                    }
                                                    value={
                                                      cancelCheckbox[
                                                        options.code + "_text"
                                                      ]
                                                        ? cancelCheckbox[
                                                            options.code +
                                                              "_text"
                                                          ]
                                                        : ""
                                                    }
                                                  />
                                                </div>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </React.Fragment>
                                    );
                                  }
                                  return <></>;
                                })}
                            </div>

                            {item.showSubscription &&
                              data.subscriptionData?.subscriptionItems && (
                                //cancelCheckbox[item.code] &&
                                <>
                                  <div className="ml-3 pt-1">
                                    * {item.showSubscriptionText}
                                  </div>
                                  {data.subscriptionData?.subscriptionItems.map(
                                    (subscriptionItem, idx4) => (
                                      <div
                                        className="position-relative form-check ml-3"
                                        key={idx4}
                                      >
                                        <input
                                          type="checkbox"
                                          name="items"
                                          disabled={
                                            cancelCheckbox[item.code] !== true
                                          }
                                          checked={
                                            cancelCheckbox[
                                              subscriptionItem.OptionID
                                            ]
                                              ? cancelCheckbox[
                                                  subscriptionItem.OptionID
                                                ]
                                              : false
                                          }
                                          id={subscriptionItem.OptionID.toString()}
                                          className="form-check-input"
                                          onChange={(e) =>
                                            handleChangeCheckbox(e)
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={subscriptionItem.OptionID.toString()}
                                        >
                                          {
                                            subscriptionItem.ItemOption
                                              ?.OptionItemName
                                          }
                                        </label>
                                      </div>
                                    )
                                  )}
                                </>
                              )}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>

                    <div className="input-group input-group-sm">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text mb-1"
                          style={{ minWidth: "150px" }}
                        >
                          정기구매 개선 의견
                        </span>
                      </div>
                      <input
                        className="form-control form-control-sm"
                        name="comment"
                        onChange={handleChangeCloseInfo}
                        value={closeInfo.Comment}
                        style={{ minWidth: "300px" }}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="text-center">
                <button
                  className="btn btn-light"
                  onClick={() => setIsOpened(false)}
                >
                  닫기
                </button>

                <button
                  className="btn btn-danger ml-2"
                  onClick={() => {
                    cancelSubscription();
                  }}
                >
                  정기구매 해지하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

function CardHeader(detailCtx: DetailCtx, showModal: () => void) {
  const { data, reload } = detailCtx;
  const subscription = data.subscriptionData?.subscription;

  return (
    <div className="card-header text-primary">
      고객정기구매정보
      <div className="btn-actions-pane-right">
        <button
          type="button"
          className="btn btn-link p-0"
          onClick={() => {
            reload.reloadSubscription();
          }}
        >
          <IconContext.Provider value={IconStyle}>
            <IoIosRefresh />
          </IconContext.Provider>
        </button>

        {subscription && subscription.Status !== "X" && (
          <button
            type="button"
            className="btn btn-link"
            onClick={() => showModal()}
          >
            <IconContext.Provider value={IconStyle}>
              <FaTrashAlt />
            </IconContext.Provider>
          </button>
        )}
      </div>
    </div>
  );
}

const IconStyle = {
  size: "2em",
  style: {
    verticalAlign: "top",
    padding: "3px",
  },
} as const;

function SubscriptionStatusBadge(status: string) {
  const map = {
    Y: {
      txt: "진행중",
      cls: "info",
    },
    X: {
      txt: "해지됨",
      cls: "danger",
    },
    I: {
      txt: "초기화",
      cls: "secondary",
    },
    N: {
      txt: "일시정지됨",
      cls: "danger",
    },
  };
  const m = map[status];
  return {
    txt: m.txt,
    cls: `badge badge-${m.cls}`,
  };
}

// 정기구독 상품
function SubscriptionTable(param: {
  subscriptionData: NewSubscriptionInfo;
  toggleSubscriptionItem: any;
}): React.ReactNode {
  const { subscriptionData, toggleSubscriptionItem } = param;
  return (
    <table className="table table-borderless table-hover">
      <thead>
        <tr>
          <th>구독중인 상품명</th>
          <th className="text-right">판매가</th>
          <th className="text-right">수량</th>
          <th className="text-right">구매주기</th>
          <th className="text-right">다음결제일</th>
          <th className="text-right">받기</th>
        </tr>
      </thead>
      <tbody>
        {subscriptionData.subscriptionItems
          .filter((v) => v.ItemOption!.SKU !== "DISCOUNT")
          .map((v, idx) =>
            SubscriptionItemRow(
              idx,
              v,
              subscriptionData,
              toggleSubscriptionItem
            )
          )}
      </tbody>
    </table>
  );
}

function SubscriptionItemRow(
  idx: number,
  v: TbSubscriptionItems,
  data: NewSubscriptionInfo,
  toggleSubscriptionItem: any
) {
  const equalCycle = data.subscription.BaseCycle === v.Cycle;
  const receiveThisTime = v.ScheduledDate === data.subscription.ScheduleAt;

  const providerId = v.Item!.ProviderID!;
  return (
    <tr key={idx}>
      <td>
        {widgetOuter(
          <>
            <div className="widget-content-left mr-1">
              {ProviderBadge(providerId)}
            </div>
            <div className="widget-content-left mr-1">
              {v.ItemOption!.SubscriptionType === "Y"
                ? subscriptionBadge
                : regularBadge}
            </div>
            <div>
              <div style={{ fontWeight: "bold" }}>{v.Item!.ItemName}</div>
              <div
                className="opacity-10"
                style={{ fontWeight: "normal", fontSize: "0.9em" }}
              >
                {v.ItemOption!.OptionItemName}
              </div>
            </div>
          </>
        )}
      </td>
      <td className="text-right">
        <div>{numberFormat(v.ItemOption!.Price)}</div>
      </td>
      <td className="text-right">{v.Quantity}개</td>
      <td className="text-right">
        <span
          style={equalCycle ? {} : { backgroundColor: "red", color: "white" }}
        >
          {v.Cycle}
          {v.Term === "W" ? "주" : v.Term}
        </span>
      </td>
      <td className="text-right">
        {moment(v.ScheduledDate).format("YYYY-MM-DD")}
        <div>
          <span className="opacity-4" style={{ fontWeight: "normal" }}>
            <span style={{ fontWeight: "normal", fontSize: "0.8em" }}>
              (다음){" "}
            </span>
            {moment(data.subscription.ScheduleAt)
              .add(v.Cycle * 7, "d")
              .format("YYYY-MM-DD")}
          </span>
        </div>
      </td>
      <td className="text-right p-0">
        <div>
          <button
            className="btn btn-sm p-0"
            onClick={() => toggleSubscriptionItem(v)}
          >
            <IconContext.Provider
              value={{
                size: "3em",
                color: receiveThisTime ? "#3bbef6" : "#cecece",
              }}
            >
              {receiveThisTime ? <FaToggleOn /> : <FaToggleOff />}
            </IconContext.Provider>
          </button>
        </div>
      </td>
    </tr>
  );
}

function widgetOuter(child: any) {
  return (
    <div className="widget-content p-0">
      <div className="widget-content-outer">
        <div className="widget-content-wrapper">{child}</div>
      </div>
    </div>
  );
}

// 배송할 상품
function ShppingItemTable(subscriptionData) {
  return (
    <table className="table table-borderless table-hover">
      <thead>
        <tr>
          <th>이번배송 상품명</th>
          <th className="text-right">판매가</th>
          <th className="text-right">수량</th>
          <th className="text-right">총계</th>
        </tr>
      </thead>
      <tbody>
        {[
          ...subscriptionData!.subscriptionItems.filter(
            (v) => v.ScheduledDate === subscriptionData.subscription.ScheduleAt
          ),
          ...subscriptionData!.onetimeItems,
        ]
          .filter((v) => v.ItemOption?.SKU !== "DISCOUNT")
          .map((v, idx) => (
            <tr key={idx}>
              <td>
                {widgetOuter(
                  <>
                    <div className="widget-content-left mr-1">
                      {ProviderBadge(v.Item!.ProviderID!)}
                    </div>
                    <div className="widget-content-left mr-1">
                      {v.ItemOption?.SubscriptionType === "Y"
                        ? subscriptionBadge
                        : regularBadge}
                    </div>
                    <div>
                      <div>
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {v.Item?.ItemName}
                        </span>
                        {v.ItemID === 101040000 && v.ItemOption?.Price === 0 && (
                          <span
                            className="text-danger ml-1"
                            style={{
                              fontSize: "0.7em ",
                            }}
                          >
                            무료증정
                          </span>
                        )}
                      </div>
                      <div
                        className="opacity-10"
                        style={{
                          fontWeight: "normal",
                          fontSize: "0.9em",
                        }}
                      >
                        {v.ItemOption?.OptionItemName}
                      </div>
                    </div>
                  </>
                )}
              </td>
              <td className="text-right">
                <div>
                  <span>{numberFormat(v.ItemOption?.Price)}</span>
                </div>
              </td>
              <td className="text-right">{v.Quantity}개</td>
              <td className="text-right">
                {numberFormat(v.ItemOption!.Price! * v.Quantity)}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

const calendarStyle = {
  position: "absolute",
  float: "right",
  top: "2.3em",
  right: "0px",
  backgroundColor: "#fff",
  zIndex: 100,
} as const;

type NameValueAndButtonsParam = {
  name: string;
  value: string;
  classes?: string;
  link?: string;
  buttons?: ButtonParam[];
  rightComponent?: any;
};
type ButtonParam = {
  txt: string;
  class?: string;
  hide?: boolean;
  callback: () => void;
};

function NameValueAndButtons(r: NameValueAndButtonsParam, idx: number) {
  const { name, value, link, classes, buttons, rightComponent } = r;

  const cls = classes ? (t) => <span className={classes}>{t}</span> : (t) => t;
  const lnk = link ? (t) => <Link to={r.link}>{t}</Link> : (t) => t;

  const btns = buttons
    ?.filter((btn) => !btn.hide)
    .map((btn, idx) => (
      <button
        key={idx}
        className={`btn btn-sm btn-${btn.class ?? "light"} mr-1`}
        onClick={btn.callback}
      >
        {btn.txt}
      </button>
    ));

  return (
    <tr key={idx}>
      <th style={{ width: "150px" }}>{name}</th>
      <td>
        {lnk(cls(value))}
        <div style={{ float: "right", position: "relative" }}>
          {btns}
          {rightComponent}
        </div>
      </td>
    </tr>
  );
}

// 정기,일반상품,브랜드 배지
const subscriptionBadge = <span className="badge badge-success">정기</span>;
const regularBadge = <span className="badge badge-secondary">일반</span>;

function ProviderBadge(providerID) {
  return (
    <span className={`badge badge-${providerColor[providerID]}`}>
      {providerList[providerID]}
    </span>
  );
}
