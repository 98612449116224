import React, { useEffect } from "react";
import { Card, Form, Input, Button, Upload, Select } from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { WcAdminApiDomain } from "../../../Store";

interface Prop {
  itemInfo?: any;
  type: "edit" | "register";
  fetchItemData?: Function;
}

const DisplayProductForm = ({ itemInfo = {}, type, fetchItemData }: Prop) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    const {
      ItemID,
      ItemName,
      Description,
      DetailInfo1,
      RegularPrice,
      IsVisible,
      IsSubscription,
      ImageUrl,
      FileName,
    } = itemInfo;

    form.setFieldsValue({
      itemId: ItemID,
      itemName: ItemName,
      description: Description,
      detailInfo1: DetailInfo1,
      regularPrice: RegularPrice,
      isVisible: IsVisible,
      isSubscription: IsSubscription,
      mainImage: [
        {
          uid: "1",
          name: FileName,
          status: "done",
          url: ImageUrl,
        },
      ],
    });
  }, [itemInfo]);

  const deleteItem = () => {
    const confirmBol = window.confirm("정말로 상품을 삭제하시겠습니까?");

    if (confirmBol) {
      axios
        .delete(`${WcAdminApiDomain}/products/${itemInfo.ItemID}`)
        .then(() => {
          history.replace("/product/product-list");
        });
    }
  };

  const normFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const onFinish = (e) => {
    console.log(e);

    const formData = new FormData();

    formData.append("itemName", e.itemName);
    formData.append("description", e.description);
    formData.append("detailInfo1", e.detailInfo1);
    formData.append("regularPrice", e.regularPrice);
    formData.append("image", e.upload ? e.upload[0].originFileObj : undefined);

    if (type === "edit") {
      formData.append("orderNo", "0");
      formData.append("isVisible", e.isVisible ? e.isVisible : "N");
      axios
        .patch(`${WcAdminApiDomain}/products/${itemInfo.ItemID}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(({ data }) => {
          console.log(data);

          fetchItemData!();
        });
    } else {
      formData.append("itemId", e.itemId);
      formData.append(
        "isSubscription",
        e.isSubscription ? e.isSubscription : undefined
      );
      axios
        .post(`${WcAdminApiDomain}/products`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(({ data }) => {
          console.log(data);
          history.push("/product/product-list");
        });
    }
  };

  const defaultInfo = () => {
    if (Object.keys(itemInfo).length && type === "edit") {
      return (
        <Card title="기존 전시상품 정보" style={{ width: "40% " }}>
          <div className="table-responsive">
            <table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <th style={{ width: "100px" }}>ItemID</th>
                  <td>{itemInfo.ItemID}</td>
                </tr>
                <tr>
                  <th style={{ width: "100px" }}>ItemName</th>
                  <td>{itemInfo.ItemName}</td>
                </tr>
                <tr>
                  <th style={{ width: "100px" }}>나중에 추가</th>
                  <td>11</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Card>
      );
    }
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {itemInfo && (
          <Card
            title={type === "edit" ? "전시상품 정보 수정" : "전시상품 추가"}
            extra={
              type === "edit" ? (
                <Button type="primary" danger onClick={deleteItem}>
                  상품 삭제
                </Button>
              ) : (
                ""
              )
            }
            style={{ width: Object.keys(itemInfo).length ? "55%" : "100%" }}
          >
            <Form
              form={form}
              name="display-item-form"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="아이템 아이디"
                name="itemId"
                rules={[
                  { required: true, message: "아이템 ID는 필수값입니다." },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="아이템 이름"
                name="itemName"
                rules={[
                  { required: true, message: "아이템 이름은 필수값입니다." },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  { required: true, message: "Description은 필수값입니다." },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="DetailInfo1"
                name="detailInfo1"
                rules={[
                  { required: true, message: "DetailInfo1은 필수값입니다." },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="RegularPrice"
                name="regularPrice"
                rules={[
                  { required: true, message: "RegularPrice는 필수값입니다." },
                ]}
              >
                <Input />
              </Form.Item>
              {type === "edit" && (
                <Form.Item
                  name="isVisible"
                  label="IsVisible"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please select isVisible!",
                    },
                  ]}
                >
                  <Select placeholder="Please select a isVisible">
                    <Option value="Y">Y</Option>
                    <Option value="N">N</Option>
                  </Select>
                </Form.Item>
              )}
              {type === "register" && (
                <Form.Item
                  name="isSubscription"
                  label="IsSubscription"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please select isSubscription!",
                    },
                  ]}
                >
                  <Select placeholder="Please select a isSubscription">
                    <Option value="Y">Y</Option>
                    <Option value="N">N</Option>
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                name="mainImage"
                label="대표 이미지"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: true,
                    message: "이미지는 필수입니다!",
                  },
                ]}
              >
                <Upload
                  name="mainImage"
                  maxCount={1}
                  listType="picture"
                  beforeUpload={() => false}
                >
                  <Button>Click to upload</Button>
                </Upload>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        )}
        {defaultInfo()}
      </div>
    </>
  );
};

export default DisplayProductForm;
