import moment from "moment";
import { SUBSCRIPTION_STATUS } from "../pages/user/constants/Users";
import { getObjectFromArray } from "../utils/UtilFunc";

import {
  SELECT_ORDER_STATUS,
  SELECT_PAYMENT_ID,
  SELECT_ORDER_TYPE,
} from "../pages/order/constants/Orders";
import { SELECT_STATUS } from "../pages/order/constants/OrdersSubscription";
import { SELECT_DELIVERY_STATUS } from "../pages/delivery/constants";
import { ServiceType } from "../Store";

export const formatters = {
  datetime: (d) => (d ? moment(d).format("YYYY-MM-DD HH:mm:ss") : ""),
  date: (d) => (d ? moment(d).format("YYYY-MM-DD") : ""),
  gender: (g) => genderMap[g] ?? "-",
  // TODO: 아.. 병맛.. 하나 없에거나 통합 합시다
  user_subscription_status: (s) => (s === "Y" ? SUBSCRIPTION_STATUS[s] : "-"),
  subscription_own_status: (s) => SubscriptionOwnStatus[s],
  number: (n) => (n ? new Intl.NumberFormat().format(n) : 0),
  payment: (pid) => PaymentName[pid],
  order_status: (s) => OrderStatus[s],
  order_type: (s) => OrderType[s],
  delivery_status: (s) => DeliveryStatus[s],
  // brand_name: (id) => providerList[id],
  service_type: (s) => ServiceType[s],
};

export const PaymentName = getObjectFromArray(SELECT_PAYMENT_ID.options);
const OrderStatus = getObjectFromArray(SELECT_ORDER_STATUS.options);
const SubscriptionOwnStatus = getObjectFromArray(SELECT_STATUS.options);
const DeliveryStatus = getObjectFromArray(SELECT_DELIVERY_STATUS.options);
const OrderType = getObjectFromArray(SELECT_ORDER_TYPE.options);

const genderMap = { M: "남", F: "여" };
