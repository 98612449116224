import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CreateCouponMainInDto } from "../../api/wc-api";
import { AppContext } from "../../index";
import { providerColor, providerList } from "../../Store";

const CreateCouponMain = () => {
  const ctx = useContext(AppContext);

  const [couponMainIn, setCouponMainIn] = useState<CreateCouponMainInDto>();

  const handleCouponMainIn = (event) => {
    const values: { [k: string]: any } = {
      [event.target.name]: event.target.value,
    };
    if (event.target.name === "codeType" && event.target.value !== "Common") {
      values.commonCode = "";
    }
    if (event.target.name === "discountType") {
      values.discountAmount = 0;
      values.discountPercent = 0;
    }
    if (event.target.name === "discountAmount") {
      values.discountPercent = 0;
    }
    if (event.target.name === "discountPercent") {
      values.discountAmount = 0;
    }

    setCouponMainIn({
      ...(couponMainIn as any),
      ...values,
    });
  };

  const createCouponMain = async () => {
    console.log("couponMainIn: ", couponMainIn);
    if (!couponMainIn) {
      toast.error(`실패 (데이터 오류)`, {
        autoClose: 3000,
      });
      return;
    }

    console.log("couponMainIn.validityDay: ", couponMainIn.validityDay);

    if (
      couponMainIn.validityType === "Relative" &&
      couponMainIn.validityDay === undefined
    ) {
      toast.error(`실패 ('발급 후 만료일' 값을 확인해주세요.)`, {
        autoClose: 3000,
      });
      return;
    }

    // 옵셔널값들 디폴트세팅
    if (couponMainIn.validityDay === undefined) {
      couponMainIn.validityDay = 0;
    }

    if (couponMainIn.codeType === "Code") {
      couponMainIn.commonCode = "난수코드";
    }

    for (const k of checkKeysOfCreateCouponMainIn) {
      if (couponMainIn[k.key] === undefined || couponMainIn[k.key] === "") {
        toast.error(`실패 ('${k.name}' 값을 확인해주세요.)`, {
          autoClose: 3000,
        });
        return;
      }

      if (k.key === "optionIDList" && couponMainIn.optionIDList.length === 0) {
        toast.error(`실패 (${k.name} 값을 확인해주세요.)`, {
          autoClose: 3000,
        });
        return;
      }

      // type=number 로 줘도 숫자값이 string 으로 들어옴
      if (
        k.key.endsWith("Amount") ||
        k.key.endsWith("Percent") ||
        k.key.endsWith("Price") ||
        k.key.endsWith("Count") ||
        k.key.endsWith("Day")
      ) {
        couponMainIn[k.key] = +couponMainIn[k.key];
      }
    }

    if (couponMainIn.codeType === "Code") {
      couponMainIn.commonCode = "난수코드";
    }

    // TODO: 정리필요..
    await ctx.wcApi.adminCoupon
      .createCouponMain(couponMainIn)
      .then((result) => {
        if (result.data.code === "success") {
          toast.success("성공함", { autoClose: 3000 });
        } else {
          toast.error(`실패함 (${result.data.data.message})`, {
            autoClose: 3000,
          });
        }
      })
      .catch((e) => {
        console.log(JSON.stringify(e));
        toast.error(`실패함 (${e.message})`, {
          autoClose: 3000,
        });
      });
  };

  const [discountItems, setDiscountItems] = useState<ItemSelector[]>([]);

  // 할인적용상품을 눌렀을 때 고를 수 있는 리스트. 이미 고른 상품은 필터해서 안 보여준다.
  const [itemSelector, setItemSelector] = useState<ItemSelector[]>([]);
  const setItemList = async () => {
    const itemList = await ctx.api.store.getItemsFromAllProviders();
    const itemSelectors: ItemSelector[] = [];
    itemList.data.forEach((item) => {
      (item.ItemOptions ?? []).forEach((option) => {
        if (
          (option.Price ?? 0) > 0 &&
          option.IsSubsidiary === "N" &&
          option.OptionStatus === "Y"
        ) {
          itemSelectors.push({
            OptionID: option.OptionID,
            ProviderID: item.ProviderID,
            ProviderName: getProviderName(item.ProviderID ?? 0),
            IsSubscribed: option.SubscriptionType === "Y",
            ItemName: item.ItemName,
            ItemOptionName: option.OptionItemName,
          } as ItemSelector);
        }
      });
    });

    setItemSelector(itemSelectors);
    setDiscountItems(itemSelectors);
  };

  // 할인적용상품 추가
  const handleOptionIDList = (event) => {
    const values: { [k: string]: any } = {};

    if (event.target.value === "all") {
      values.optionIDList = discountItems.map((item) => item.OptionID);
    } else if (!couponMainIn?.optionIDList.includes(event.target.value)) {
      values.optionIDList = couponMainIn?.optionIDList.concat(
        +event.target.value
      );
    }

    setItemSelector(
      discountItems.filter(
        (item) => !values.optionIDList.includes(item.OptionID)
      )
    );

    setCouponMainIn({
      ...(couponMainIn as any),
      ...values,
    });
  };

  // 할인적용상품 개별삭제
  const removeItemSelector = (optionID: number, removeAll: boolean = false) => {
    const values: { [k: string]: any } = {};

    // 전체삭제
    if (removeAll) {
      values.optionIDList = [];
    } else if (couponMainIn?.optionIDList.includes(optionID)) {
      values.optionIDList = couponMainIn?.optionIDList.filter(
        (id) => id !== optionID
      );
    }

    // 적용상품 삭제시 다시 선택리스트에 노출
    setItemSelector(
      discountItems.filter(
        (item) => !values.optionIDList.includes(item.OptionID)
      )
    );

    setCouponMainIn({
      ...(couponMainIn as any),
      ...values,
    });
  };

  useEffect(() => {
    setCouponMainIn({
      ...(couponMainIn as any),
      optionIDList: [],
    });
    setItemList();
  }, [ctx.user.id]);

  function showSelectedItem() {
    const selectedItems = discountItems.filter((item) =>
      couponMainIn?.optionIDList.includes(item.OptionID)
    );

    return (
      <>
        {selectedItems.map((item, idx) => (
          <div className="widget-content p-1">
            <div className="widget-content-wrapper">
              <div className="widget-content-left mr-2">
                <span
                  className={`badge badge-${providerColor[item.ProviderID]}`}
                >
                  {providerList[item.ProviderID]}
                </span>
              </div>
              <div className="widget-content-left mr-1">
                {item.IsSubscribed ? (
                  <span className="badge badge-success">정기</span>
                ) : (
                  <span className="badge badge-secondary">일반</span>
                )}
              </div>
              <div className="widget-content-left flex2">
                <div className="widget-heading">
                  <span style={{ fontWeight: "bold" }}>{item.ItemName}</span>
                </div>
                <div
                  className="widget-subheading opacity-10"
                  style={{
                    fontWeight: "normal",
                    fontSize: "0.9em",
                  }}
                >
                  <div>{item.ItemOptionName}</div>
                </div>
              </div>
              <div className="widget-content-left flex2">
                <button
                  className="btn btn-sm btn-danger ml-2"
                  style={{ width: "50px" }}
                  onClick={() => removeItemSelector(item.OptionID)}
                >
                  삭제
                </button>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card mb-3">
            <div className="card-header">쿠폰 등록</div>
            <div className="card-body">
              <div className="mt-3">
                {/* 코드조회 입력부 */}
                <div className="input-group input-group-sm mb-2">
                  <span className="m-1" style={{ width: "100px" }}>
                    쿠폰명 :{" "}
                  </span>
                  <input
                    className="form-control form-control-sm"
                    name="couponName"
                    placeholder="쿠폰 혜택을 적어주세요"
                    maxLength={20}
                    style={{ maxWidth: "200px" }}
                    onChange={handleCouponMainIn}
                    required
                  />
                </div>
                <div>
                  <div className="input-group input-group-sm mb-2">
                    <span className="m-1" style={{ width: "100px" }}>
                      쿠폰설명 :{" "}
                    </span>
                    <input
                      className="form-control form-control-sm"
                      name="description"
                      style={{ maxWidth: "300px" }}
                      maxLength={30}
                      placeholder="쿠폰 적용 조건을 적어주세요"
                      onChange={handleCouponMainIn}
                      required
                    />
                  </div>
                </div>
                <div>
                  <div className="input-group input-group-sm mb-2">
                    <span className="m-1" style={{ width: "100px" }}>
                      코드 종류 :{" "}
                    </span>
                    <div>
                      <select
                        className="form-control form-control-sm"
                        style={{ maxWidth: "100px" }}
                        name="codeType"
                        onChange={handleCouponMainIn}
                        required
                      >
                        <option value="">선택</option>
                        <option value="Common">고정 코드</option>
                        <option value="Code">난수 코드</option>
                      </select>
                    </div>
                    <span className="m-1 ml-3" style={{ width: "100px" }}>
                      코드명 :{" "}
                    </span>
                    <input
                      className="form-control form-control-sm"
                      style={{ maxWidth: "100px" }}
                      name="commonCode"
                      maxLength={20}
                      onChange={handleCouponMainIn}
                      required
                      disabled={couponMainIn?.codeType !== "Common"}
                    />
                  </div>
                </div>
                <div>
                  <div className="input-group input-group-sm mb-2">
                    <span className="m-1" style={{ width: "100px" }}>
                      할인 :{" "}
                    </span>
                    <select
                      className="form-control form-control-sm"
                      style={{ maxWidth: "100px" }}
                      name="discountType"
                      onChange={handleCouponMainIn}
                      required
                    >
                      <option value="">선택</option>
                      <option value="Amount">고정금액</option>
                      <option value="Percent">정률</option>
                    </select>
                    {couponMainIn?.discountType ? (
                      couponMainIn?.discountType === "Amount" ? (
                        <>
                          <input
                            className="form-control form-control-sm ml-3"
                            min={0}
                            max={999999}
                            maxLength={6}
                            placeholder="0 ~ 999,999원 입력가능"
                            style={{ maxWidth: "200px" }}
                            name="discountAmount"
                            onChange={handleCouponMainIn}
                          />
                          <span className="m-1">원</span>
                        </>
                      ) : (
                        <>
                          <input
                            className="form-control form-control-sm ml-3"
                            min={0}
                            max={100}
                            maxLength={3}
                            placeholder="0 ~ 100 입력가능"
                            style={{ maxWidth: "200px" }}
                            name="discountPercent"
                            onChange={handleCouponMainIn}
                          />
                          <span className="m-1">%</span>
                        </>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div>
                  <div className="input-group input-group-sm mb-2">
                    <span className="m-1" style={{ width: "100px" }}>
                      최대 혜택 금액 :{" "}
                    </span>
                    <input
                      className="form-control form-control-sm"
                      min={0}
                      max={999999}
                      maxLength={6}
                      placeholder="0 ~ 999,999원 입력가능"
                      style={{ maxWidth: "200px" }}
                      name="maxDiscountAmount"
                      onChange={handleCouponMainIn}
                      required
                    />
                    <span className="m-1">원</span>
                    <span className="m-1 ml-4" style={{ width: "100px" }}>
                      최소 결제 금액 :{" "}
                    </span>
                    <input
                      className="form-control form-control-sm ml-3"
                      min={0}
                      max={999999}
                      maxLength={6}
                      placeholder="0 ~ 999,999원 입력가능"
                      style={{ maxWidth: "200px" }}
                      name="minimumPrice"
                      onChange={handleCouponMainIn}
                    />
                    <span className="m-1">원</span>
                  </div>
                </div>
                <div>
                  <div className="input-group input-group-sm mb-2">
                    <span className="m-1" style={{ width: "100px" }}>
                      만료구분 :{" "}
                    </span>
                    <div>
                      <select
                        className="form-control form-control-sm"
                        style={{ maxWidth: "200px" }}
                        name="validityType"
                        onChange={handleCouponMainIn}
                        required
                      >
                        <option value="">선택</option>
                        <option value="Absolute">유효기간</option>
                        <option value="Relative">
                          유효기간 및 발급일 기준 만료일
                        </option>
                      </select>
                    </div>
                    {couponMainIn?.validityType !== undefined &&
                    couponMainIn.validityType !== "" ? (
                      <>
                        <span className="m-1 ml-3" style={{ width: "100px" }}>
                          유효기간 :{" "}
                        </span>
                        <input
                          className="form-control form-control-sm"
                          style={{ maxWidth: "150px" }}
                          name="startDate"
                          type="date"
                          onChange={handleCouponMainIn}
                          required
                        />
                        <span className="m-1">~</span>
                        <input
                          className="form-control form-control-sm"
                          style={{ maxWidth: "150px" }}
                          name="endDate"
                          type="date"
                          onChange={handleCouponMainIn}
                          required
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    {couponMainIn?.validityType === "Relative" ? (
                      <>
                        <span className="m-1 ml-3" style={{ width: "100px" }}>
                          발급 후 만료일 :{" "}
                        </span>
                        <input
                          className="form-control form-control-sm"
                          style={{ maxWidth: "150px" }}
                          min={0}
                          max={9999}
                          maxLength={4}
                          placeholder="0 ~ 180 입력가능"
                          name="validityDay"
                          onChange={handleCouponMainIn}
                          required
                        />
                        <span className="m-1">일</span>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div>
                  <div className="input-group input-group-sm mb-2">
                    <span className="m-1" style={{ width: "100px" }}>
                      최대 발급 수량 :{" "}
                    </span>
                    <input
                      className="form-control form-control-sm"
                      min={0}
                      max={9999999}
                      maxLength={7}
                      placeholder="0 ~ 9,999,999 입력가능"
                      style={{ maxWidth: "200px" }}
                      name="maxCount"
                      onChange={handleCouponMainIn}
                      required
                    />
                    <span className="m-1">ea</span>
                  </div>
                </div>
                <div>
                  <div className="input-group input-group-sm mb-2">
                    <span className="m-1" style={{ width: "100px" }}>
                      할인 적용 상품 :{" "}
                    </span>
                    <select
                      className="form-control form-control-sm"
                      style={{ maxWidth: "200px" }}
                      name="optionIDList"
                      value="선택"
                      onChange={handleOptionIDList}
                      required
                    >
                      <option>선택</option>
                      <option value="all">전체</option>
                      {itemSelector.map((item, idx) => (
                        <option value={item.OptionID}>
                          {item.ProviderName} /{" "}
                          {item.IsSubscribed ? "정기" : "일반"} /{" "}
                          {item.ItemOptionName}
                        </option>
                      ))}
                    </select>
                    <button
                      className="btn btn-sm btn-danger ml-2"
                      style={{ width: "100px" }}
                      onClick={() => removeItemSelector(0, true)}
                    >
                      전체삭제
                    </button>
                  </div>
                  {showSelectedItem()}
                </div>

                <div className="text-right">
                  <button
                    className="btn btn-sm btn-primary ml-2"
                    style={{ width: "50px" }}
                    onClick={() => createCouponMain()}
                  >
                    등록
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCouponMain;

interface ItemSelector {
  OptionID: number;
  ProviderID: number;
  ProviderName: string;
  IsSubscribed: boolean;
  ItemName: string;
  ItemOptionName: string;
}

function getProviderName(providerId: number): string {
  switch (providerId) {
    case 100000000:
      return "와이즐리";
    case 200000000:
      return "오픈워크";
    case 300000000:
      return "헤드웍스";
    case 400000000:
      return "해비추얼";
    case 500000000:
      return "플로버";
    default:
      return "";
  }
}

const checkKeysOfCreateCouponMainIn: { key: string; name: string }[] = [
  { key: "couponName", name: "쿠폰명" },
  { key: "description", name: "쿠폰설명" },
  { key: "codeType", name: "코드종류" },
  { key: "commonCode", name: "코드명" },
  { key: "discountType", name: "할인종류" },
  { key: "discountAmount", name: "할인금액" },
  { key: "discountPercent", name: "할인율" },
  { key: "maxDiscountAmount", name: "최대혜택금액" },
  { key: "minimumPrice", name: "최소결제금액" },
  { key: "validityType", name: "만료구분" },
  { key: "validityDay", name: "발급 후 만료일" },
  { key: "startDate", name: "유효기간" },
  { key: "endDate", name: "유효기간" },
  { key: "maxCount", name: "최대발급수량" },
  { key: "optionIDList", name: "할인적용상품" },
];
