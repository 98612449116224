import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { ApiCallFormData } from "../../Store";
import Loading from "../../components/Loading";
import { AppContext } from "../../index";

const IssueCoupon = () => {
  const ctx = useContext(AppContext);
  const [createCouponsIn, setCreateCouponsIn] = useState<{
    file: File;
    cmid: string;
    UNID: number;
  }>();
  const [loading, setLoading] = useState(false);

  const handleCreateCouponBulk = (event) => {
    const values: { [k: string]: any } = {};
    if (event.target.name === "file") {
      const file = event.target.files[0];
      values.file = file;
      values.UNID = undefined;
    } else {
      values[event.target.name] = event.target.value;
      if (event.target.name === "UNID") {
        values.file = undefined;
      }
    }

    setCreateCouponsIn({
      ...(createCouponsIn as any),
      ...values,
    });
    console.log("createCouponsIn: ", createCouponsIn);
  };

  const createCoupons = async () => {
    if (!createCouponsIn || _.isEmpty(createCouponsIn.cmid)) {
      toast.error("코드 ID가 유효하지 않습니다.", {
        autoClose: 3000,
      });
      return;
    }

    if (_.isEmpty(createCouponsIn.UNID) && createCouponsIn.file === undefined) {
      toast.error("발급 대상을 지정하세요.", {
        autoClose: 3000,
      });
      return;
    }

    if (createCouponsIn.file) {
      // 파일업로드
      const formData = new FormData();
      formData.append("file", createCouponsIn.file);
      formData.append("cmid", createCouponsIn.cmid);

      setLoading(true);

      const result = await ApiCallFormData(
        "/admin-coupon/create-user-coupons-from-excel",
        formData
      ).catch((err) => {
        if (err.isAxiosError && err.response.status === 413) {
          return err.response;
        }
        throw err;
      });

      if (result.data.code === "success") {
        toast.success("성공함", { autoClose: 3000 });
      } else {
        toast.error(result.data.data.message, {
          autoClose: 3000,
        });
      }
      setLoading(false);
    }
    // UNID 업로드
    else {
      const result = await ctx.wcApi.adminCoupon.createUserCoupon({
        unid: createCouponsIn.UNID,
        cmid: +createCouponsIn.cmid,
      });

      if (result.data.code === "success") {
        toast.success("성공함", { autoClose: 3000 });
      } else {
        toast.error(result.data.data.message, {
          autoClose: 3000,
        });
      }
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card mb-3">
            <div className="card-header">등록된 쿠폰 발급하기</div>
            <div className="card-body">
              <div className="input-group input-group-sm mb-2">
                <span className="m-1" style={{ width: "100px" }}>
                  쿠폰 ID :{" "}
                </span>
                <input
                  className="form-control form-control-sm"
                  name="cmid"
                  style={{ maxWidth: "200px" }}
                  onChange={handleCreateCouponBulk}
                  required
                />
                {/*<button*/}
                {/*  className="btn btn-sm btn-primary btn-block ml-2"*/}
                {/*  style={{ width: "50px" }}*/}
                {/*  // onClick={() => getCouponMain(searchCouponId)}*/}
                {/*>*/}
                {/*  조회*/}
                {/*</button>*/}
              </div>
              <div className="mt-3 mb-3">
                <div
                  className="mb-2"
                  style={{ fontSize: "1em", fontWeight: "bold" }}
                >
                  * csv 파일 양식 (파일사이즈 50MB 제한)
                </div>
                <table
                  className="mb-3 table table-striped table-hover dataTable"
                  style={{ maxWidth: "500px", border: "3px solid #ddd" }}
                >
                  <thead>
                    <tr>
                      <th>UNID</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>394813</td>
                    </tr>
                    <tr>
                      <td>883913</td>
                    </tr>
                    <tr>
                      <td>...</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="mt-3 mb-3">&nbsp;</div>

              <div className="mt-3 mb-3">
                <div className="input-group">
                  <input
                    type="file"
                    name="file"
                    disabled={!_.isEmpty(createCouponsIn?.UNID)}
                    onChange={(e) => handleCreateCouponBulk(e)}
                    className="form-control-file"
                  />
                </div>
              </div>
              <div className="input-group input-group-sm mb-2">
                <span className="m-1" style={{ width: "100px" }}>
                  UNID :{" "}
                </span>
                <input
                  className="form-control form-control-sm"
                  name="UNID"
                  style={{ maxWidth: "200px" }}
                  onChange={handleCreateCouponBulk}
                  disabled={createCouponsIn?.file !== undefined}
                  required
                />
                {/*<button*/}
                {/*  className="btn btn-sm btn-primary btn-block ml-2"*/}
                {/*  style={{ width: "50px" }}*/}
                {/*  // onClick={() => getCouponMain(searchCouponId)}*/}
                {/*>*/}
                {/*  조회*/}
                {/*</button>*/}
              </div>
              <div className="text-right">
                <button
                  className="btn btn-sm btn-primary ml-2"
                  style={{ width: "50px" }}
                  onClick={() => createCoupons()}
                >
                  등록
                </button>
              </div>
              {loading ? (
                <div className="mb-3 mt-3">
                  <Loading />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IssueCoupon;
