import * as utils from "../../../utils/Utils";
import { DetailCtx } from "../../../DetailContext";
import {
  DetailViewCardParam,
  DetailViewCard,
} from "../../../components/DetailViewCard";

const param: DetailViewCardParam = {
  card: {
    type: "list",
    bodyMaxHeight: "360px",
  },
  headerInfo: {
    title: "고객정보변경내역",
    buttons: ["refresh"],
  },
  getData: (ctx: DetailCtx) =>
    utils.getUserChangesLog(ctx?.data.userData?.UserInfo.UNID),
  dataName: "UserChangesLog",
  cardInfo: [
    {
      name: "시간",
      width: 100,
      cell: {
        field: "RegDate",
        fmt: "datetime",
      },
    },
    {
      name: "TYPE",
      cell: {
        field: "MetaKey",
      },
    },
    {
      name: "변경전",
      cell: {
        field: (d) =>
          typeof d.BeforeValue === "object"
            ? JSON.stringify(d.BeforeValue)
            : d.BeforeValue,
      },
    },
    {
      name: "변경후",
      cell: {
        field: (d) =>
          typeof d.AfterValue === "object"
            ? JSON.stringify(d.AfterValue)
            : d.AfterValue,
      },
    },
    {
      name: "메모",
      cell: { field: "Note" },
    },
    {
      name: "변경자",
      cell: { field: "FullName" },
    },
  ],
};

export default () => DetailViewCard(param);
