import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Button, Table, Image } from "antd";
import { WcAdminApiDomain } from "../../Store";
import TitleContent from "../../components/TitleContent";
import { ListHeader } from "../../components/ListHeader";
import DisplayProductForm from "../product/components/displayProductForm";

const EditDisplayProduct = ({ match }) => {
  const { itemId } = match.params;
  const history = useHistory();

  const [itemInfo, setItemInfo] = useState({} as any);
  const [optionItemList, setOptionItemList] = useState([]);

  const fetchItemData = () => {
    axios.get(`${WcAdminApiDomain}/products/${itemId}`).then(({ data }) => {
      console.log(data);
      setItemInfo(data);
    });
  };

  useEffect(() => {
    fetchItemData();
  }, []);

  useEffect(() => {
    setOptionItemList(itemInfo.ItemOptions);
  }, [itemInfo]);

  const optionItemColumns = [
    {
      title: "아이템 ID",
      dataIndex: "OptionID",
      key: "OptionID",
      sorter: (a, b) => a.OptionID - b.OptionID,
    },
    {
      title: "아이템 이름",
      dataIndex: "OptionItemName",
      key: "OptionItemName",
    },
    {
      title: "이미지",
      key: "ImageUrl",
      dataIndex: "ImageUrl",
      render: (value) => <Image width={40} src={value} />,
    },
    {
      title: "아이템 가격",
      dataIndex: "Price",
      key: "Price",
      render: (value) => `${value.toLocaleString()}원`,
    },
    {
      title: "수정",
      key: "action",
      render: (text, record) => (
        <Button
          type="primary"
          onClick={() =>
            history.push(`/product/edit-product/${record.OptionID}`)
          }
        >
          수정
        </Button>
      ),
    },
  ];

  return (
    <>
      <TitleContent title="전시상품 수정" subHeading="전시상품 수정" />
      <DisplayProductForm
        itemInfo={itemInfo}
        type="edit"
        fetchItemData={fetchItemData}
      />
      {optionItemList?.length && (
        <div style={{ marginTop: "40px" }}>
          <ListHeader
            title="상품 목록"
            button={
              <Button
                type="primary"
                onClick={() => history.push("/product/add-product")}
              >
                상품 추가
              </Button>
            }
          />
          <Table
            dataSource={optionItemList}
            columns={optionItemColumns}
            pagination={{ position: ["bottomCenter"] }}
          />
        </div>
      )}
    </>
  );
};

export default EditDisplayProduct;
