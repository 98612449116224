import React, { useState, useEffect, useContext } from "react";
import Drawer from "react-drag-drawer";
import { Link } from "react-router-dom";
import moment from "moment";
import { IconContext } from "react-icons";
import { IoIosRefresh } from "react-icons/io";
import ReactCalendar from "react-calendar";

import * as utils from "../../../utils/Utils";
import OrderContext from "../../../providers/ProviderOrderDetail";
import { numberFormat } from "../../../utils/UtilFunc";
import {
  ClaimReason,
  IsReturnWill_TYPE,
  ClaimStatusDetail,
  providerList,
  PRODUCTS,
  ClaimReasonDetail,
  CLAIM_REASON_GROUP,
} from "../../../Store";
import { SELECT_ORDER_STATUS } from "../../../pages/order/constants/Orders";
import { VBankCode } from "../../user/constants/Payments";
import { Payment } from "../../../constants";
import { AppContext } from "../../..";

const OrderInfo = (props) => {
  const ctx = useContext(AppContext);
  const orderType = {
    0: "기타",
    1: "일반주문",
    2: "관리자주문",
    3: "CX주문",
    4: "반품주문",
  };

  /** Modal 띄우기 */
  const [isOpened, setIsOpened] = useState(false);
  const [isOpened2, setIsOpened2] = useState(false);
  const [showMode, setShowMode] = useState(null);
  const showModal = (data, mode) => {
    setShowMode(mode);

    if (mode === "vbank") {
      setBankInfo({
        holdername: "",
        bankcode: "",
        banknumber: "",
      });
      setRefundAmount(
        data.orderData.OrderInfo.TotalAmount -
          (data.orderData.OrderInfo.RefundAmount ?? 0)
      );
    } else {
      setRefundAmount(
        data.orderData.OrderInfo.TotalAmount -
          (data.orderData.OrderInfo.RefundAmount ?? 0)
      );
    }

    setIsOpened(true); // Modal 띄우기
  };

  const [refundAmount, setRefundAmount] = useState(0);
  const handleChangeRefundAmount = (event) => {
    setRefundAmount(event.target.value);
  };

  const [getBankInfo, setBankInfo] = useState<any>();
  const handleChangebankInfo = (event) => {
    setBankInfo({
      ...getBankInfo,
      [event.target.name]: event.target.value,
    });
  };

  // 일반 결제취소
  const cancelOrder = async (unid, orderid, refreshFunc) => {
    const result = await utils.cancelOrder(unid, orderid, "", {}, refundAmount);
    if (result) {
      refreshFunc.getOrderData(orderid);
    }
  };

  // 가상계좌 결제취소
  const cancelOrderVbank = async (unid, orderid, bankinfo, refreshFunc) => {
    const result = await utils.cancelOrder(
      unid,
      orderid,
      "vBank",
      bankinfo,
      refundAmount
    );
    if (result) {
      refreshFunc.getOrderData(orderid);
    }
  };

  // 결제상태
  const [orderStatus, setOrderStatus] = useState();
  const handleChangeOrderStatus = (event) => {
    setOrderStatus(event.target.value);
  };

  // 반품사유
  const [returnReason, setReturnReason] = useState({
    unid: "",
    type: "",
    isreturnwill: "",
    orderid: "",
    reason: "",
    brand: "",
    product: "",
    reasondetail: "",
    canceldata: "",
    comment: "",
    status: "",
    regdate: "",
    wishDate: "",
  });
  const handleChangeReturn = (event) => {
    setReturnReason({
      ...returnReason,
      [event.target.name]: event.target.value,
    });
  };

  // 반품희망일 변경하기(tbCancelLog의 CancelData 활용)
  const [showCalendar, setShowCalendar] = useState(false);
  const onCalendarChange = (date) => {
    setReturnReason({
      ...returnReason,
      wishDate: moment(date).format("YYYY-MM-DD"),
    });
  };

  // 주문 생성하기
  const [newOrderid, setNewOrderid] = useState(null);
  const [subOrderid, setSubOrderid] = useState(null);
  const orderRegist = (data, orderType) => {
    if (window.confirm("정말로 주문을 만드시겠습니까?")) {
      utils
        .initOrderInfo(data.userData.UserInfo.UNID, orderType, "")
        .then((result) => {
          // 상품 추가하기
          data.orderData.OrderItemList.map((item) => {
            if (item.SKU !== "DISCOUNT") {
              ctx.api.admin.setOrderItem({
                orderId: result.OrderInfo.OrderID,
                optionId: item.OptionID,
                quantity: item.Quantity,
              });
            }
            return null;
          });

          // 배송지 추가
          utils.setOrderAddress({
            ...data.orderData.OrderAddress,
            orderid: result.OrderInfo.OrderID,
            unid: data.userData.UserInfo.UNID,
          });

          // 새로 생성한 주문번호
          setTimeout(() => {
            if (orderType === 2) {
              setNewOrderid(result.OrderInfo.OrderID);
            } else if (orderType === 4) {
              setSubOrderid(result.OrderInfo.OrderID);

              // 반품 주문인 경우 주문의 상태를 반품신청 상태로 변경
              utils.changeOrderStatus({
                unid: data.userData.UserInfo.UNID,
                orderid: result.OrderInfo.OrderID,
                orderstatus: "Requested-Return",
                note: "",
              });

              // 반품 주문의 반품 기록 남기기
              utils.setReturnOrderLog({
                unid: data.userData.UserInfo.UNID,
                type: "Return",
                isreturnwill: returnReason.isreturnwill,
                orderid: result.OrderInfo.OrderID,
                reason: returnReason.reason,
                reasondetail: returnReason.reasondetail,
                canceldata: JSON.stringify({
                  trackNo:
                    (data.orderData.DeliveryInfo &&
                      data.orderData.DeliveryInfo.TrackNo) ||
                    null,
                  wishDate: returnReason.wishDate,
                }),
                comment: returnReason.comment,
                status: "Y",
                regdate: moment().format("YYYY-MM-DD HH:mm:ss"),
              });

              // 원주문번호를 tbOrder 테이블의 LinkedOrderID로 남기기
              utils.setLinkedOrderID({
                orderid: result.OrderInfo.OrderID, // 반품주문
                linkedid: data.orderData.OrderInfo.OrderID, // 원주문
              });

              // 반품 주문인 경우 주문의 결제금액을 0원으로 변경
              utils.resetOrderPrice(
                data.userData.UserInfo.UNID,
                result.OrderInfo.OrderID,
                0
              );

              // 팝업닫기
              setIsOpened2(!isOpened2);
            }
          }, 1000);
        });
    }
  };

  // 주문의 상태 변경하기
  const changeOrderStatus = async (param, actions) => {
    const getResult = await utils.changeOrderStatus({
      ...param,
      orderstatus: orderStatus,
    });
    if (getResult) {
      actions.getOrderData(param.orderid);
    }
  };

  useEffect(() => {
    if (props.orderStatus) {
      setOrderStatus(props.orderStatus.OrderInfo.OrderStatus);
    }
  }, [props]);

  return (
    <OrderContext.Consumer>
      {({ data, actions }) =>
        data.orderData && (
          <>
            {data.orderData.OrderInfo && (
              <div className="row mb-1">
                <div className="col">
                  <div className="card">
                    <div className="card-header text-primary">
                      주문정보
                      <div className="btn-actions-pane-right">
                        <button
                          className="btn btn-link p-0"
                          onClick={() =>
                            actions.refreshOrderData(
                              data.orderData.OrderInfo.OrderID
                            )
                          }
                        >
                          <IconContext.Provider
                            value={{
                              size: "2em",
                              style: { verticalAlign: "top", padding: "3px" },
                            }}
                          >
                            <IoIosRefresh />
                          </IconContext.Provider>
                        </button>
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-borderless table-sm">
                          <tbody>
                            <tr>
                              <th style={{ width: "150px" }}>주문번호</th>
                              <td>
                                {data.orderData.OrderInfo.OrderID}
                                <div
                                  className="opacity-4"
                                  style={{ fontSize: "0.8em" }}
                                >
                                  {data.orderData.OrderInfo.OrderCID}
                                </div>
                              </td>
                            </tr>
                            {data.orderData.OrderInfo.IsSubscribed === "Y" && (
                              <tr>
                                <th>정기결제</th>
                                <td>
                                  <Link to={`/users/detail/${props.userid}`}>
                                    <span className="badge badge-info">
                                      정기구매
                                    </span>
                                  </Link>
                                </td>
                              </tr>
                            )}
                            <tr>
                              <th>주문상태</th>
                              <td>
                                <div className="form-inline">
                                  <select
                                    name="customs"
                                    className="form-control form-control-sm"
                                    value={orderStatus}
                                    onChange={handleChangeOrderStatus}
                                  >
                                    <option value="Initialized-EasyPayment">
                                      간편결제주문초기화
                                    </option>
                                    <option value="Initialized">
                                      주문생성
                                    </option>
                                    <option value="Awaiting-Subscription">
                                      정기결제대기중
                                    </option>
                                    <option value="Processing">
                                      결제진행중
                                    </option>
                                    <option value="Awaiting-vbank">
                                      가상계좌입금대기중
                                    </option>
                                    <option value="Failed">결제실패</option>
                                    <option value="Completed">결제완료</option>
                                    <option value="On-Hold">보류</option>
                                    <option value="Pending-cancel">
                                      보류취소
                                    </option>
                                    <option value="Cancelled">결제취소</option>
                                    <option value="Requested-Refund">
                                      환불요청
                                    </option>
                                    <option value="Partially-Refunded">
                                      부분환불
                                    </option>
                                    <option value="Out-for-Delivery">
                                      배송중
                                    </option>
                                    <option value="Delivered">배달완료</option>
                                    <option value="Requested-Return">
                                      반품신청
                                    </option>
                                    <option value="Requested-Exchange">
                                      교환신청
                                    </option>
                                    <option value="Return-Processing">
                                      반품진행중
                                    </option>
                                    <option value="Exchange-Processing">
                                      교환진행중
                                    </option>
                                    <option value="Returned">반품완료</option>
                                    <option value="Exchanged">교환완료</option>
                                    <option value="Refunded">환불됨</option>
                                    <option value="Fraud">부정결제</option>
                                    <option value="Pending">대기중</option>
                                    <option value="Paid">[D]결제완료</option>
                                    <option value="Order-Processing">
                                      [D]배송접수
                                    </option>
                                    <option value="Out-for-Delivery">
                                      [D]배송중
                                    </option>
                                    <option value="Delivered">
                                      [D]배송완료
                                    </option>
                                  </select>
                                  <button
                                    className="btn btn-sm btn-light ml-1"
                                    onClick={() =>
                                      changeOrderStatus(
                                        {
                                          unid: data.userData.UserInfo.UNID,
                                          orderid:
                                            data.orderData.OrderInfo.OrderID,
                                          note: data.orderData.OrderInfo.Note
                                            ? data.orderData.OrderInfo.Note
                                            : "-",
                                        },
                                        actions
                                      )
                                    }
                                  >
                                    변경
                                  </button>

                                  {data.orderData.OrderInfo.PaymentStatus ===
                                    "Y" &&
                                  (data.orderData.OrderInfo.OrderStatus ===
                                    "Completed" ||
                                    (data.orderData.OrderInfo.OrderStatus ===
                                      "Partially-Refunded" &&
                                      data.orderData.OrderInfo.TotalAmount -
                                        (data.orderData.OrderInfo
                                          .RefundAmount ?? 0) >
                                        0) ||
                                    data.orderData.OrderInfo.OrderStatus ===
                                      "Return-Processing" ||
                                    data.orderData.OrderInfo.OrderStatus ===
                                      "Exchange-Processing" ||
                                    data.orderData.OrderInfo.OrderStatus ===
                                      "Delivered") ? (
                                    data.orderData.PaymentMethodInfo
                                      ?.PaymentName === "vBank" ? (
                                      <button
                                        className="btn btn-danger btn-sm ml-2"
                                        onClick={() => showModal(data, "vbank")}
                                      >
                                        가상계좌 결제취소하기
                                      </button>
                                    ) : (
                                      <>
                                        <button
                                          className="btn btn-danger btn-sm ml-2"
                                          onClick={() => showModal(data, "")}
                                        >
                                          부분 취소
                                        </button>

                                        <button
                                          className="btn btn-danger btn-sm ml-1"
                                          onClick={() => {
                                            if (
                                              window.confirm(
                                                "정말 취소하시겠습니까?"
                                              )
                                            ) {
                                              cancelOrder(
                                                data.userData.UserInfo.UNID,
                                                data.orderData.OrderInfo
                                                  .OrderID,
                                                actions
                                              );
                                            }
                                          }}
                                        >
                                          전체 취소
                                        </button>
                                      </>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>주문유형</th>
                              <td>
                                {data.orderData.OrderInfo.OrderType !== 1 ? (
                                  <div className="text-danger">
                                    {
                                      orderType[
                                        data.orderData.OrderInfo.OrderType
                                      ]
                                    }
                                  </div>
                                ) : (
                                  orderType[data.orderData.OrderInfo.OrderType]
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th>주문일시</th>
                              <td>
                                {moment(
                                  data.orderData.OrderInfo.RegDate
                                ).format("YYYY-MM-DD HH:mm:ss")}
                              </td>
                            </tr>
                            <tr>
                              <th>결제일시</th>
                              <td>
                                {data.orderData.OrderInfo.ApprovedDate
                                  ? moment(
                                      data.orderData.OrderInfo.ApprovedDate
                                    ).format("YYYY-MM-DD HH:mm:ss")
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th>메모</th>
                              <td>
                                {data.orderData.OrderInfo.Note
                                  ? data.orderData.OrderInfo.Note
                                  : "-"}
                              </td>
                            </tr>
                            {data.orderData.UTMInfo && (
                              <>
                                <tr>
                                  <td colSpan={2}>
                                    <div
                                      style={{ borderTop: "1px solid #ddd" }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <th>Source</th>
                                  <td>
                                    {data.orderData.UTMInfo.Source}
                                    {data.orderData.UTMInfo.Source ===
                                      "offerwall" &&
                                      (data.orderData.UTMInfo.Destination ===
                                        "{}" ||
                                        typeof data.orderData.UTMInfo
                                          .Destination === "object") && (
                                        <span className="badge badge-sm badge-success ml-1">
                                          크레딧이 지급되었음
                                        </span>
                                      )}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Medium</th>
                                  <td>{data.orderData.UTMInfo.Medium}</td>
                                </tr>
                                <tr>
                                  <th>Name</th>
                                  <td>{data.orderData.UTMInfo.Compaign}</td>
                                </tr>
                                <tr>
                                  <th>Term</th>
                                  <td>{data.orderData.UTMInfo.Term}</td>
                                </tr>
                                <tr>
                                  <th>Content</th>
                                  <td>{data.orderData.UTMInfo.Content}</td>
                                </tr>
                              </>
                            )}

                            {data.orderData.LinkedOrderIDList &&
                              data.orderData.LinkedOrderIDList.length > 0 && (
                                <tr>
                                  <th>관련주문</th>
                                  <td>
                                    {data.orderData.LinkedOrderIDList.map(
                                      (order) => (
                                        <Link
                                          to={`/orders/detail/${order.OrderID}`}
                                          target="_blank"
                                          key={order.OrderID}
                                        >
                                          <span className="badge badge-sm badge-info mr-1">
                                            주문번호 : {order.OrderID} -{" "}
                                            {
                                              SELECT_ORDER_STATUS.options.filter(
                                                (item) =>
                                                  Object.keys(item)[0] ===
                                                  order.OrderStatus
                                              )[0][order.OrderStatus]
                                            }
                                          </span>
                                        </Link>
                                      )
                                    )}
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      </div>

                      <div className="text-right">
                        {newOrderid ? (
                          <button
                            className="btn btn-sm btn-success"
                            onClick={() => {
                              document.location.href =
                                "/orders/detail/" + newOrderid;
                            }}
                          >
                            복사된 주문보기
                          </button>
                        ) : (
                          <button
                            className="btn btn-sm btn-light"
                            onClick={() => orderRegist(data, 2)}
                          >
                            주문복사하기
                          </button>
                        )}

                        {subOrderid ? (
                          <button
                            className="btn btn-sm btn-success ml-1"
                            onClick={() => {
                              document.location.href =
                                "/orders/detail/" + subOrderid;
                            }}
                          >
                            반픔주문보기
                          </button>
                        ) : (
                          <button
                            className="btn btn-sm btn-light ml-1"
                            onClick={() => setIsOpened2(true)}
                          >
                            반품주문만들기
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Drawer
              open={isOpened}
              onRequestClose={() => setIsOpened(!isOpened)}
              allowClose={false}
            >
              <div className="row">
                <div className="col">
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="card-title">결제취소하기</div>
                      {showMode === "vbank" ? (
                        <>
                          <div>
                            <form>
                              <div className="form-group position-relative">
                                <div className="input-group input-group-sm">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text mb-1"
                                      style={{ minWidth: "150px" }}
                                    >
                                      예금주명
                                    </span>
                                  </div>
                                  <input
                                    className="form-control form-control-sm"
                                    name="holdername"
                                    onChange={handleChangebankInfo}
                                    value={getBankInfo.holdername}
                                    style={{ minWidth: "300px" }}
                                    maxLength={16}
                                    required
                                  />
                                </div>

                                <div className="input-group input-group-sm">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text mb-1"
                                      style={{ minWidth: "150px" }}
                                    >
                                      입금은행
                                    </span>
                                  </div>
                                  <select
                                    className="form-control form-control-sm"
                                    name="bankcode"
                                    onChange={handleChangebankInfo}
                                    value={getBankInfo.bankcode}
                                    style={{ minWidth: "300px" }}
                                  >
                                    <option value="">은행선택</option>
                                    {data.orderData.PaymentMethodInfo
                                      ?.PaymentID ===
                                      Payment.IamportVBankOnetime &&
                                      Object.keys(
                                        VBankCode.nicepay
                                      ).map((key) => (
                                        <option value={key}>
                                          {VBankCode.nicepay[key].displayName}
                                        </option>
                                      ))}
                                    {data.orderData.PaymentMethodInfo
                                      ?.PaymentID ===
                                      Payment.TossVbankOnetime &&
                                      Object.keys(VBankCode.toss).map((key) => (
                                        <option value={key}>
                                          {VBankCode.toss[key].displayName}
                                        </option>
                                      ))}
                                  </select>
                                </div>

                                <div className="input-group input-group-sm">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text mb-1"
                                      style={{ minWidth: "150px" }}
                                    >
                                      계좌번호
                                    </span>
                                  </div>
                                  <input
                                    className="form-control form-control-sm"
                                    name="banknumber"
                                    onChange={handleChangebankInfo}
                                    value={getBankInfo.banknumber}
                                    style={{ minWidth: "300px" }}
                                    maxLength={20}
                                    required
                                  />
                                </div>
                                {data.orderData.OrderInfo.PaymentID ===
                                  Payment.TossVbankOnetime && (
                                  <div className="input-group input-group-sm">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text mb-1"
                                        style={{ minWidth: "150px" }}
                                      >
                                        환불금액
                                      </span>
                                    </div>
                                    <input
                                      className="form-control form-control-sm"
                                      name="banknumber"
                                      onChange={handleChangeRefundAmount}
                                      value={refundAmount}
                                      style={{ minWidth: "300px" }}
                                      maxLength={20}
                                      required
                                    />
                                  </div>
                                )}
                              </div>
                            </form>
                          </div>
                          <div className="text-center">
                            <button
                              className="btn btn-light"
                              onClick={() => setIsOpened(!isOpened)}
                            >
                              닫기
                            </button>

                            <button
                              className="btn btn-success ml-2"
                              onClick={() => {
                                cancelOrderVbank(
                                  data.userData.UserInfo.UNID,
                                  data.orderData.OrderInfo.OrderID,
                                  getBankInfo,
                                  actions
                                );
                                setIsOpened(!isOpened);
                              }}
                            >
                              결제취소하기
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <form>
                              <div className="form-group position-relative">
                                <div className="input-group input-group-sm">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text mb-1"
                                      style={{ minWidth: "150px" }}
                                    >
                                      환불금액(전체{" "}
                                      {numberFormat(
                                        data.orderData.OrderInfo.TotalAmount -
                                          (data.orderData.OrderInfo
                                            .RefundAmount ?? 0)
                                      )}
                                      원)
                                    </span>
                                  </div>
                                  <input
                                    type="number"
                                    className="form-control form-control-sm"
                                    name="refundAmount"
                                    onChange={handleChangeRefundAmount}
                                    value={refundAmount}
                                    style={{ minWidth: "300px" }}
                                    maxLength={16}
                                    required
                                  />
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className="text-center">
                            <button
                              className="btn btn-light"
                              onClick={() => setIsOpened(!isOpened)}
                            >
                              닫기
                            </button>

                            <button
                              className="btn btn-success ml-2"
                              onClick={() => {
                                cancelOrder(
                                  data.userData.UserInfo.UNID,
                                  data.orderData.OrderInfo.OrderID,
                                  actions
                                );
                                setRefundAmount(0);
                                setIsOpened(!isOpened);
                              }}
                            >
                              결제취소하기
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Drawer>

            <Drawer
              open={isOpened2}
              onRequestClose={() => setIsOpened2(!isOpened2)}
              allowClose={false}
            >
              <div className="row">
                <div className="col">
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="card-title">반품주문만들기</div>
                      <div>
                        <form>
                          <div className="form-group position-relative">
                            <div className="input-group input-group-sm">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text mb-1"
                                  style={{ minWidth: "150px" }}
                                >
                                  회수여부
                                </span>
                              </div>
                              <select
                                className="form-control form-control-sm"
                                name="isreturnwill"
                                onChange={handleChangeReturn}
                                value={returnReason.isreturnwill}
                                style={{ minWidth: "300px" }}
                              >
                                <option value="">선택</option>
                                {Object.keys(IsReturnWill_TYPE).map((key) => (
                                  <option value={key} key={key}>
                                    {IsReturnWill_TYPE[key]}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="input-group input-group-sm">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text mb-1"
                                  style={{ minWidth: "150px" }}
                                >
                                  접수내용
                                </span>
                              </div>
                              <select
                                className="form-control form-control-sm"
                                name="reason"
                                onChange={handleChangeReturn}
                                value={returnReason.reason}
                                style={{ minWidth: "300px" }}
                              >
                                <option value="">선택</option>
                                {Object.keys(ClaimReason).map((key) => (
                                  <option value={key} key={key}>
                                    {ClaimReason[key]}
                                  </option>
                                ))}
                              </select>
                            </div>

                            {returnReason.reason === "inferiorProducts" && (
                              <>
                                <div className="input-group input-group-sm">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text mb-1"
                                      style={{ minWidth: "150px" }}
                                    >
                                      브랜드
                                    </span>
                                  </div>
                                  <select
                                    className="form-control form-control-sm"
                                    name="brand"
                                    onChange={handleChangeReturn}
                                    value={returnReason.brand}
                                    style={{ minWidth: "300px" }}
                                  >
                                    <option value="">선택</option>
                                    {Object.keys(providerList).map(
                                      (provider, idx) => (
                                        <option
                                          key={idx}
                                          value={providerList[provider]}
                                        >
                                          {providerList[provider]}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                                <div className="input-group input-group-sm">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text mb-1"
                                      style={{ minWidth: "150px" }}
                                    >
                                      제품 선택
                                    </span>
                                  </div>
                                  <select
                                    className="form-control form-control-sm"
                                    name="product"
                                    onChange={handleChangeReturn}
                                    value={returnReason.product}
                                    style={{ minWidth: "300px" }}
                                  >
                                    <option value="">선택</option>
                                    {PRODUCTS[returnReason.brand] ? (
                                      PRODUCTS[returnReason.brand].map(
                                        (provider, idx) => (
                                          <option key={idx} value={provider}>
                                            {provider}
                                          </option>
                                        )
                                      )
                                    ) : (
                                      <option value="아직없음">아직없음</option>
                                    )}
                                  </select>
                                </div>
                              </>
                            )}
                            <div className="input-group input-group-sm">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text mb-1"
                                  style={{ minWidth: "150px" }}
                                >
                                  상세사유
                                </span>
                                <select
                                  className="form-control form-control-sm"
                                  name="reasondetail"
                                  onChange={handleChangeReturn}
                                  value={returnReason.reasondetail}
                                  style={{ minWidth: "300px" }}
                                >
                                  <option value="">선택</option>
                                  {ClaimReasonDetail[
                                    CLAIM_REASON_GROUP[returnReason.product]
                                  ] &&
                                  returnReason.reason === "inferiorProducts"
                                    ? ClaimReasonDetail[
                                        CLAIM_REASON_GROUP[returnReason.product]
                                      ].map((item, idx) => (
                                        <option
                                          key={`claim-${idx}`}
                                          value={item}
                                        >
                                          {item}
                                        </option>
                                      ))
                                    : ClaimStatusDetail.map((item, idx) => (
                                        <option value={item} key={idx}>
                                          {item}
                                        </option>
                                      ))}
                                </select>
                              </div>
                            </div>
                            <div className="input-group input-group-sm">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text mb-1"
                                  style={{ minWidth: "150px" }}
                                >
                                  자세한설명
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="comment"
                                onChange={handleChangeReturn}
                                value={returnReason.comment}
                                style={{ minWidth: "300px" }}
                                required
                              />
                            </div>
                            <div className="input-group input-group-sm">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text mb-1"
                                  style={{ minWidth: "150px" }}
                                >
                                  방문희망일
                                </span>
                              </div>
                              <div>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  name="wishDate"
                                  value={returnReason.wishDate}
                                  style={{ minWidth: "300px" }}
                                  onFocus={() => setShowCalendar(true)}
                                />
                                {showCalendar && (
                                  <div style={{ position: "relative" }}>
                                    <ReactCalendar
                                      onChange={(date) => {
                                        onCalendarChange(date);
                                        setShowCalendar(false);
                                      }}
                                      calendarType="US"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="text-center">
                        <button
                          className="btn btn-light"
                          onClick={() => setIsOpened2(!isOpened2)}
                        >
                          닫기
                        </button>

                        <button
                          className="btn btn-success ml-2"
                          onClick={() => orderRegist(data, 4)}
                        >
                          만들기
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Drawer>
          </>
        )
      }
    </OrderContext.Consumer>
  );
};

export default OrderInfo;
