import React from "react";
import TitleContent from "../../components/TitleContent";
import IssueCoupon from "./IssueCoupon";
import CreateCodeCoupon from "./CreateCodeCoupon";
import CouponMainList from "./CouponMainList";
import CouponList from "./CouponList";
import CreateCouponMain from "./CreateCouponMain";

const Coupon = () => {
  // to avoid lint
  const title = "쿠폰관리";
  return (
    <>
      <TitleContent title={title} />

      {/* PAGE BODY  */}
      {CreateCouponMain()}
      {CouponMainList()}
      {CouponList()}
      {IssueCoupon()}
      {CreateCodeCoupon()}
    </>
  );
};

export default Coupon;
