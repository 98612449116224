import React from "react";
import { Route, useRouteMatch } from "react-router-dom";

import DeliveryDetail from "../pages/delivery/DeliveryDetail";
import { DeliveryExport } from "../pages/delivery/DeliveryExport";
import DeliveryDivideExport from "../pages/delivery/DeliveryDivideExport";

const DeliveryRoute = () => {
  const match = useRouteMatch();

  return (
    <>
      <Route path={`${match.path}/export`} component={DeliveryExport} />
      <Route
        path={`${match.path}/divide-and-export`}
        component={DeliveryDivideExport}
      />
      <Route path={`${match.path}/detail/:did`} component={DeliveryDetail} />
    </>
  );
};

export default DeliveryRoute;
