import moment from "moment";
import FileSaver from "file-saver";
import XLSX from "xlsx";

import * as utils from "../../utils/Utils";
import {
  CALENDAR_DATE_START,
  CALENDAR_DATE_END,
  BUTTON_SEARCH,
  BUTTON_DOWNLOAD,
} from "./constants/OrdersCx";
// import { BUTTON_SEARCH } from "../../constants";
import {
  ListViewPage,
  ListViewPageParam,
} from "../../components/ListViewSearchPage";
// import { TablePresets } from "../../components/TablePresets";

const param: ListViewPageParam<any> = {
  defaultQueryParam: {
    nowPage: 1,
    perPage: 1000,
    startdate: moment().format("YYYY-MM-DD"),
    enddate: moment().format("YYYY-MM-DD"),
  },
  fetchItems: utils.getCxOrderList,
  pluckItems: (r) => r.OrderList,
  searchArray: [
    {
      type: "CALENDAR",
      constant: CALENDAR_DATE_START,
    },
    {
      type: "CALENDAR",
      constant: CALENDAR_DATE_END,
    },
    {
      type: "BUTTON",
      constant: BUTTON_SEARCH,
      onClick: ({ setParam }) => () => setParam(true)({ nowPage: 1 }),
    },
    {
      type: "BUTTON",
      constant: BUTTON_DOWNLOAD,
      onClick: ({ state, setParam }) => () => {
        down(state.items);
        setParam(true)();
      },
    },
  ],
  tableInfo: ({ searchParams }) => [
    {
      name: "번호",
      cell: { field: "RN" },
    },
    {
      name: "주문번호",
      cell: { field: "OrderID" },
    },
    {
      name: "주문등록일/주문상태",
      cells: [
        { field: "RegDate", fmt: "datetime" },
        { field: "OrderStatus", fmt: "order_status", className: "opacity-5" },
      ],
    },
    {
      name: "송장번호",
      cell: { field: "TrackNo" },
    },
    {
      name: "이름/전화/주소/메모",
      cells: [
        { field: "FullName" },
        { field: "Phone" },
        { field: (c) => `${c.Address1}  ${c.Address2}` },
        { field: "Memo", className: "opacity-5" },
      ],
    },
    {
      name: "상품명/상품코드",
      cells: [
        { field: "OptionItemName" },
        { field: "ProductCode", className: "opacity-5" },
      ],
    },
    {
      name: "CX사유",
      cell: { field: "Note" },
    },
  ],
  texts: {
    title: "CX주문 리스트",
    sub: "CX주문 리스트를 조회합니다.",
    cardTitle: "CX주문 리스트",
  },
};

export default () => ListViewPage(param);

const down = (orders) => {
  if (window.confirm("정말 다운로드 하시겠습니까?")) {
    if (orders.length === 0) {
      window.alert("처리할 데이터가 없습니다.");
      return;
    }

    // 배송타입과 배송타입별 데이터 재생성
    const uniqueArr = orders.map((item) => ({
      OrderID: item.OrderID,
      RegDate: moment(item.RegDate).format("YYYY-MM-DD_HH:mm:ss"),
      OrderStatus: item.OrderStatus,
      UNID: item.UNID,
      ShippingStartDate: moment(item.ShippingStartDate).format(
        "YYYY-MM-DD_HH:mm:ss"
      ),
      DID: item.DID,
      DCID: item.DCID,
      TrackNo: item.TrackNo,
      FullName: item.FullName,
      Phone: item.Phone,
      Address1: item.Address1,
      Address2: item.Address2,
      Memo: item.Memo,
      OptionItemName: item.OptionItemName,
      ProductCode: item.ProductCode.toString(),
      Note: item.Note,
    }));

    const resultData = {};
    resultData["CX주문"] = XLSX.utils.json_to_sheet(uniqueArr);

    // XLSX 만들기
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xls";
    const wb = { Sheets: resultData, SheetNames: ["CX주문"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xls", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      `wisely_cx_orderlist_${moment().format(
        "YYYYMMDD_HHmmss"
      )}${fileExtension}`
    );
  }
};
