export const VBankCode: {
  [key: string]: { [key: string]: { code: string; displayName: string } };
} = {
  toss: {
    // KB국민은행
    "kb-kookmin": {
      code: "국민",
      displayName: "KB국민은행",
    },
    // SC제일은행
    "sc-jaeil": { code: "SC제일", displayName: "SC제일은행" },
    // 경남은행
    gyeongnam: { code: "경남", displayName: "경남은행" },
    // 광주은행
    gwangjoo: { code: "광주", displayName: "광주은행" },
    // 기업은행
    ibk: { code: "기업", displayName: "기업은행" },
    // 대구은행
    daegoo: { code: "대구", displayName: "대구은행" },
    // 농협
    nonghyup: { code: "농협", displayName: "농협" },
    // 부산은행
    busan: { code: "부산", displayName: "부산은행" },
    // 산업은행
    kdb: { code: "산업", displayName: "산업은행" },
    // 새마을금고
    saemaeul: { code: "새마을", displayName: "새마을금고" },
    // 수협
    suhyup: { code: "수협", displayName: "수협" },
    // 신한은행
    shinhan: { code: "신한", displayName: "신한은행" },
    // 신협
    shinhyup: { code: "신협", displayName: "신협" },
    // 우리은행
    woori: { code: "우리", displayName: "우리은행" },
    // 우체국
    epostbank: { code: "우체국", displayName: "우체국" },
    // 전북은행
    jeonbuk: { code: "전북", displayName: "전북은행" },
    // 카카오
    kakao: { code: "카카오", displayName: "카카오뱅크" },
    // 케이뱅크
    "k-bank": { code: "케이뱅크", displayName: "케이뱅크" },
    // 하나은행(서울은행)
    hana: { code: "하나", displayName: "하나은행" },
    // 한국씨티은행(한미은행)
    "city-bank": { code: "씨티", displayName: "씨티은행" },
    jeju: { code: "제주", displayName: "제주은행" },
  },
  nicepay: {
    // KB국민은행

    "kb-kookmin": { code: "004", displayName: "KB국민은행" },
    // SC제일은행
    "sc-jaeil": { code: "023", displayName: "SC제일은행" },
    // 경남은행
    gyeongnam: { code: "039", displayName: "경남은행" },
    // 광주은행
    gwangjoo: { code: "034", displayName: "광주은행" },
    // 기업은행
    ibk: { code: "003", displayName: "기업은행" },
    // 대구은행
    daegoo: { code: "031", displayName: "대구은행" },
    // 농협
    nonghyup: { code: "011", displayName: "농협" },
    // 부산은행
    busan: { code: "032", displayName: "부산은행" },
    // 산업은행
    kdb: { code: "002", displayName: "산업은행" },
    // 새마을금고
    saemaeul: { code: "045", displayName: "새마을금고" },
    // 수협
    suhyup: { code: "007", displayName: "수협" },
    // 신한은행
    shinhan: { code: "088", displayName: "신한은행" },
    // 신협
    shinhyup: { code: "048", displayName: "신협" },
    // 외환은행
    keb: { code: "005", displayName: "외환은행" },
    // 우리은행
    woori: { code: "020", displayName: "우리은행" },
    // 우체국
    epostbank: { code: "071", displayName: "우체국" },
    // 전북은행
    jeonbuk: { code: "037", displayName: "전북은행" },
    // 카카오
    kakao: { code: "090", displayName: "카카오뱅크" },
    // 케이뱅크
    "k-bank": { code: "089", displayName: "케이뱅크" },
    // 하나은행(서울은행)
    hana: { code: "081", displayName: "하나은행" },
    // 한국씨티은행(한미은행)
    "city-bank": { code: "027", displayName: "씨티은행" },
  },
};
