export const SELECT_DELIVERY_STATUS = {
  name: "deliverystatus",
  classNames: ["form-control", "form-control-sm"],
  options: [
    // { "": "전체배송상태" },
    { Paid: "결제완료" },
    { Cancelled: "배송취소" },
    { "Order-Processing": "배송접수" },
    { "Out-for-Delivery": "배송중" },
    { Delivered: "배송완료" },
    { "Track-Number-Issued": "송장 번호 발급됨" },
  ],
};
