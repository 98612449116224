import React, { useState, useEffect, useCallback, useContext } from "react";
import moment from "moment";
import { IconContext } from "react-icons";
import { IoIosRefresh } from "react-icons/io";

import * as utils from "../../../utils/Utils";
import { AppContext } from "../../..";
import { DetailContext } from "../../../DetailContext";

const UserSubscriptionChangeLog = () => {
  const ctx = useContext(AppContext);
  const detailCtx = useContext(DetailContext);
  const { data } = detailCtx;

  const [getList, setList] = useState(null as any);
  const getUserSubscriptionChangeLog = useCallback(async () => {
    const userid = data.userData?.UserInfo?.UNID;
    if (!userid) {
      return;
    }
    const getResult = await utils.getUserSubscriptionChangeLog(userid);
    if (getResult && getResult.UserSubscriptionLog) {
      const subscriptionID = data.subscriptionData?.subscription.SID;
      if (subscriptionID) {
        const newLog = await ctx.api.adminSubscription.subscriptionSnapshot({
          sid: subscriptionID,
        });
        const controlLog = newLog.data.map((d, i) => ({
          ...d,
          MetaKey: d.Action,
          MetaValue: d.Param,
        }));
        const allLog = controlLog.concat(getResult.UserSubscriptionLog);

        setList(allLog);
      }
    }
  }, [data]);

  /** 초기 로딩할 때 1회 실행 */
  useEffect(() => {
    getUserSubscriptionChangeLog();
  }, [getUserSubscriptionChangeLog]);

  return (
    <>
      <div className="card mb-1">
        <div className="card-header text-primary">
          고객정기구매변경내역
          <div className="btn-actions-pane-right">
            <button
              className="btn btn-link p-0"
              onClick={() => getUserSubscriptionChangeLog()}
            >
              <IconContext.Provider
                value={{
                  size: "2em",
                  style: { verticalAlign: "top", padding: "3px" },
                }}
              >
                <IoIosRefresh />
              </IconContext.Provider>
            </button>
          </div>
        </div>
        {getList && getList.length > 0 && (
          <div
            className="card-body mb-3"
            style={{ overflow: "auto", maxHeight: "360px" }}
          >
            <div className="table-responsive">
              <table className="table table-striped table-sm">
                <tbody>
                  <tr>
                    <th style={{ width: "170px" }}>시간</th>
                    <th style={{ width: "170px" }}>TYPE</th>
                    <th>내용</th>
                  </tr>
                  {getList.map((item, idx) => (
                    <tr key={idx}>
                      <td>
                        {moment(item.RegDate).format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      <td>
                        <div>{item.MetaKey}</div>
                      </td>
                      <td>
                        {item.MetaValue}
                        <div
                          className="opacity-6 text-info"
                          style={{ fontSize: "0.8em" }}
                        >
                          {item.FullName}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserSubscriptionChangeLog;
