import React from "react";
import Select from "../atoms/Select";
import Button from "../atoms/Button";
import Calendar from "../atoms/Calendar";
import Input from "../atoms/Input";

const SearchCondition = ({ conditions, onChange }) => (
  <>
    {conditions.map(({ type, constant, value, onCalendarChange, onClick }) => {
      switch (type) {
        case "SELECT":
          return (
            <div key={constant.name} className="mr-1">
              <Select
                name={constant.name}
                classNames={constant.classNames}
                value={value}
                onChange={onChange}
                options={constant.options}
              />
            </div>
          );
        case "CALENDAR":
          return (
            <div
              key={constant.name}
              className="mr-1"
              style={{ position: "relative" }}
            >
              <Calendar
                name={constant.name}
                value={value}
                onInputChange={onChange}
                onCalendarChange={
                  onCalendarChange ??
                  ((d) =>
                    onChange({ target: { name: constant.name, value: d } }))
                }
                placeholder={constant.placeholder}
                inputWidth={constant.inputWidth}
              />
            </div>
          );
        case "INPUT":
          return (
            <div key={constant.name} className="mr-1">
              <Input
                name={constant.name}
                classNames={constant.classNames}
                value={value}
                onChange={onChange}
                placeholder={constant.placeholder}
              />
            </div>
          );
        case "BUTTON":
          return (
            <div key={constant.name} className="mr-1">
              <Button
                name={constant.name}
                classNames={constant.classNames}
                onClick={onClick}
              />
            </div>
          );
        default:
          return <></>;
      }
    })}
  </>
);

export default SearchCondition;
