import React, { useEffect, useState } from "react";
import TitleContent from "../../components/TitleContent";
import * as utils from "../../utils/Utils";
import QueryDetail from "./QueryDetail";

const QueryList = () => {
  /* 전체 List 받기 */
  const [list, setGetList] = useState([] as any[]);
  const [selected, setSelected] = useState(-1);
  const getData = async () => {
    const getResult = await utils.GetQueryList();
    setGetList(getResult.data.queryInfo);
  };

  const [query, setQuery] = useState(null as any);
  const changeQuery = (query) => {
    setQuery(query);
  };

  /* 화면 로딩 초기에 리스트 불러오기 */
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <TitleContent title="쿼리 실행" subHeading="쿼리 실행" />

      <div className="row">
        <div className="col-lg-12">
          <div className="card mb-3">
            <div className="card-header">쿼리목록</div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="mb-3 table table-striped table-hover dataTable">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th style={{ width: "250px" }}>제목</th>
                      <th>설명</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.length > 0 &&
                      list.map((item, idx) => (
                        <tr
                          key={idx}
                          onClick={() => {
                            setSelected(idx);
                            changeQuery(item);
                          }}
                          style={{
                            color: selected === idx ? "red" : "",
                            cursor: "pointer",
                          }}
                        >
                          <td>{idx + 1}</td>
                          <td>{item.title}</td>
                          <td>{item.description}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {query !== null ? <QueryDetail query={query} /> : <></>}
        </div>
      </div>
    </>
  );
};

export default QueryList;
