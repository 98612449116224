import React from "react";
import { Link } from "react-router-dom";
import * as R from "ramda";

import { die } from "../utils/Utils";
import { Column, Cell } from "./types";
import { formatters } from "./const";

export function Table(props: {
  items: any[];
  columns: Column[];
  handlerParams?: any;
}) {
  const { items, columns } = props;

  return (
    <div className="table-responsive">
      <table className="mb-3 table table-striped table-hover dataTable">
        <thead>
          <tr>{Header(columns)}</tr>
        </thead>
        <tbody>
          {items.map((user, idx) => (
            <tr key={idx}>
              {columns.map((c, i) => {
                const cellInfos = c.cells ?? [c.cell ?? die("cell 정의 빠짐")];
                // const cells = cellInfos.map((cellInfo, i) => (
                //   // TODO: 여기 키 붙이느라 아무거나 감쌌는데 span 좀 이상함. 떼어내기
                //   <span key={i}>{Cell(cellInfo, user)}</span>
                // ));
                // const breaks = R.range(0, cellInfos.length).map((i) => (
                //   <br key={i + cellInfos.length} />
                // ));
                // const contents = R.zip(cells, breaks);

                const contents = cellInfos.map((cellInfo, i) => (
                  // TODO: 여기 키 붙이느라 아무거나 감쌌는데 span 좀 이상함. 떼어내기
                  <div key={i}>{Cell(cellInfo, user, props.handlerParams)}</div>
                ));

                return (
                  <td key={i} style={c.tdStyle ?? {}}>
                    {contents}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  function Cell(cell: Cell, item: any, handlerParam: any) {
    const { field, className, fmt, link, component, buttons } = cell;

    if (component) {
      return component(item);
    }

    if (buttons) {
      return (
        <>
          {buttons
            .filter((b) => (b.show ?? R.T)(handlerParam, item))
            .map((b, i) => (
              <button
                key={i}
                className={
                  b.className ? b.className : "btn btn-sm btn-light mr-1"
                }
                onClick={b.callback(handlerParam, item)}
              >
                {b.name}
              </button>
            ))}
        </>
      );
    }

    if (!field) die("component 없으면 field 는 있어야함");

    const v = typeof field === "string" ? item[field] : field(item);

    if (link) {
      return <Link to={link(item)}>{v}</Link>;
    }

    const cls = className
      ? typeof className === "string"
        ? className
        : className(item)
      : "";

    const fmtFn = typeof fmt === "string" ? formatters[fmt] : fmt ?? ((v) => v);
    return <span className={cls}>{fmtFn(v)}</span>;
  }
}

function Header(columns: Column[]) {
  return columns.map((c, i) => {
    const style = {
      ...(c.width ? { width: `${c.width}px` } : {}),
      ...(c.minWidth ? { minWidth: `${c.minWidth}px` } : {}),
      ...(c.center ? { className: "text-center" } : {}),
    };
    return (
      <th key={i} style={style}>
        {c.name}
      </th>
    );
  });
}
