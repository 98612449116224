import React, { useContext, useEffect, useState } from "react";

import TitleContent from "../../components/TitleContent";
import { AppContext } from "../..";
import { TbAlimtalkTemplate } from "../../api";

const AlimtalkTemplate = () => {
  const ctx = useContext(AppContext);
  /* 전체 List 받기 */
  const [templates, setTemplates] = useState([] as TbAlimtalkTemplate[]);
  const [template, setTemplate] = useState<Partial<TbAlimtalkTemplate>>({});

  /* 화면 로딩 초기에 리스트 불러오기 */
  useEffect(() => {
    ctx.api.admin.getAlimtalkTemplates().then((res) => {
      setTemplates(res.data);
    });
  }, [ctx.api]);

  const send = () =>
    template.id &&
    template.code &&
    ctx.api.admin
      .sendAlimtalkTemplate({
        templateCode: template.code,
        // TODO: ...?
        args: {
          user: 10000317,
        },
        test: true,
      })
      .then(console.log);

  const save = () =>
    template &&
    ctx.api.admin.saveAlimtalkTemplate(trim(template)).then(console.log);

  const setAttr = (attr) => (ev) => {
    const v = ev.target.value;
    if (template) {
      setTemplate({ ...template, ...{ [attr]: v } });
    }
  };

  const select = (idx) => () => {
    const newTemplate = templates[idx];
    if (template?.code === newTemplate.code) {
      setTemplate({});
    } else {
      setTemplate(newTemplate);
    }
  };

  return (
    <>
      <TitleContent title="알림톡 관리" subHeading="알림톡 관리" />

      <div className="row">
        {half(
          "템플릿",
          simpleTable(
            templates,
            tableFields,
            select,
            (idx) => templates[idx].id === template?.id
          )
        )}
        {half(
          "테스트",
          <>
            {fields.map((f, idx) => {
              const attrs = {
                disabled: f.disabled,
                value: template?.[f.name] ?? "",
                onChange: f.disabled ? () => {} : setAttr(f.name),
              };
              return (
                <div key={idx}>
                  {f.name}
                  <div>
                    {f.textarea ? (
                      <textarea
                        style={{ width: "100%", height: "200px" }}
                        {...attrs}
                      />
                    ) : (
                      <input type={f.type ?? "text"} {...attrs} />
                    )}
                  </div>
                </div>
              );
            })}
            <div>
              <input type="submit" value="Save" onClick={save} />
              <input type="submit" value="Test" onClick={send} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AlimtalkTemplate;

export const simpleTable = (items, fields: any[], select, selected) => (
  <table className="mb-3 table table-striped table-hover dataTable">
    <thead>
      <tr>
        {fields.map((f, idx) => (
          <th key={idx}>{f}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {items.map((item, idx) => (
        <tr
          key={idx}
          onClick={select(idx)}
          style={{ color: (selected ? selected(idx) : false) ? "red" : "" }}
        >
          {fields.map((f, idx) => (
            <td key={idx}>{item[f]}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

export const half = (title, content) => (
  <div className="col-md-6">
    <div className="card mb-3">
      <div className="card-header">{title}</div>
      <div className="card-body">{content}</div>
    </div>
  </div>
);

function trim<T>(obj: T): T {
  fields.forEach((f) => {
    if (f.type !== "number") {
      obj[f.name] = obj[f.name]?.trim();
    }
  });
  return obj;
}

const fields = [
  { name: "id", disabled: true, type: "number" },
  { name: "name" },
  { name: "code" },
  { name: "content", textarea: true },
  { name: "button1Name" },
  { name: "button1Url" },
  { name: "button2Name" },
  { name: "button2Url" },
];

const tableFields = ["id", "name", "code", "createdAt"];
