import React, { useContext, useState } from "react";
import { FaStarOfLife } from "react-icons/fa";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import { CouponMainOutDto } from "../../api/wc-api";
import { AppContext } from "../../index";
import { numberFormat } from "../../utils/UtilFunc";
import Loading from "../../components/Loading";

const CreateCodeCoupon = () => {
  const ctx = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const [couponMain, setCouponMain] = useState<CouponMainOutDto>();
  const getCouponMain = async (cmid: number) => {
    const result = await ctx.wcApi.adminCoupon.getCouponMain({ id: cmid });
    if (result.data.code === "success") {
      if (result.data.data.CodeType !== "Code") {
        toast.error(`실패함 (코드 생성은 난수 코드 쿠폰만 가능합니다.)`, {
          autoClose: 3000,
        });
        return;
      }

      setCouponMain(result.data.data);
      setCreateCouponParams({ couponMain: result.data.data, count: 0 });
    } else {
      // TODO: 공통함수로 빼기
      setCouponMain(undefined);
      toast.error(`실패함 (${result.data.data.message})`, {
        autoClose: 3000,
      });
    }
  };

  const [searchCouponId, setSearchCouponId] = useState(0);
  const handleSearchCouponId = (event) => {
    setSearchCouponId(event.target.value);
    setCreateCouponParams(undefined);
  };

  const [createCouponParams, setCreateCouponParams] = useState<{
    couponMain: CouponMainOutDto;
    count: number;
  }>();
  const handleCreateCouponParams = (event) => {
    if (couponMain === undefined) return;
    setCreateCouponParams({
      couponMain: couponMain,
      count: event.target.value,
    });
  };

  const createCoupons = async () => {
    console.log("createCouponParams: ", createCouponParams);
    if (
      createCouponParams?.count === undefined ||
      createCouponParams?.couponMain === undefined
    ) {
      toast.error("쿠폰정보 혹은 발급수량이 올바르지 않습니다.", {
        autoClose: 3000,
      });
      return;
    }

    if (
      window.confirm(
        `정말로 생성하시겠습니까?
- 쿠폰ID : ${createCouponParams.couponMain.CMID}
- 쿠폰명  : ${createCouponParams.couponMain.CouponName}  
- 발급수량 : ${createCouponParams.count}`
      )
    ) {
      const groupKey = uuidv4().replace(/-/g, "").slice(0, 5).toUpperCase();

      setLoading(true);
      const result = await ctx.wcApi.adminCoupon.createCodeCoupons({
        cmid: createCouponParams.couponMain.CMID,
        count: createCouponParams.count,
        groupKey: groupKey,
      });
      if (result.data.code === "success") {
        toast.success("성공함", { autoClose: 3000 });
        await download(groupKey, createCouponParams.couponMain);

        // download
      } else {
        toast.error(`실패함(${result.data.data.message})`, {
          autoClose: 3000,
        });
      }
      setLoading(false);
    }
  };

  const download = async (groupKey: string, couponMain: CouponMainOutDto) => {
    // groupKey 로 쿠폰 데이터 조회
    const getCouponResult = await ctx.wcApi.adminCoupon.getCouponsByGroupKey({
      groupKey,
    });

    if (getCouponResult.data.code !== "success") {
      return;
    }

    const couponsForExcel = getCouponResult.data.data.map((item) => ({
      CID: item.CID,
      Code: item.Code,
    }));

    const sheetName = `${couponMain.CMID}-${couponMain.CouponName}-${groupKey}`;
    const resultData = {};
    resultData[sheetName] = XLSX.utils.json_to_sheet(couponsForExcel);

    // XLSX 만들기
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xls";
    const wb = { Sheets: resultData, SheetNames: [sheetName] };
    const excelBuffer = XLSX.write(wb, { bookType: "xls", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      `coupon_${couponMain.CouponName}_${groupKey}${fileExtension}`
    );
  };

  // 대량 발급하는 API
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card mb-3">
            <div className="card-header">쿠폰 코드 생성</div>
            <div className="card-body">
              <div className="mt-3">
                {/* 코드조회 입력부 */}
                <div className="input-group input-group-sm mb-2">
                  {required}
                  <span className="m-1" style={{ width: "100px" }}>
                    쿠폰 ID :{" "}
                  </span>
                  <input
                    className="form-control form-control-sm"
                    name="couponId"
                    style={{ maxWidth: "200px" }}
                    onChange={handleSearchCouponId}
                    required
                    // ref={newUserInput}
                  />
                  <button
                    className="btn btn-sm btn-primary btn-block ml-2"
                    style={{ width: "50px" }}
                    onClick={() => getCouponMain(searchCouponId)}
                  >
                    조회
                  </button>
                </div>
                <div className="input-group input-group-sm mb-2">
                  <span className="m-1" style={{ width: "100px" }}>
                    최대 발급 수량 :{" "}
                  </span>
                  <span className="m-1" style={{ width: "100px" }}>
                    {couponMain !== undefined
                      ? couponMain.MaxCount !== null
                        ? `${numberFormat(couponMain.MaxCount)}개`
                        : "제한없음"
                      : ""}
                  </span>
                </div>
              </div>
              <hr />
              {/* 코드생성 입력부 */}
              {createCouponParams !== undefined ? (
                <div>
                  <div className="input-group input-group-sm mb-2 mt-3">
                    {required}
                    <span className="m-1" style={{ width: "100px" }}>
                      코드 생성 수량 :{" "}
                    </span>
                    <input
                      className="form-control form-control-sm"
                      name="couponCountInput"
                      maxLength={7}
                      style={{ maxWidth: "200px" }}
                      onChange={handleCreateCouponParams}
                      required
                    />
                    <button
                      className="btn btn-sm btn-danger btn-block ml-2"
                      style={{ width: "150px" }}
                      onClick={() => createCoupons()}
                    >
                      코드 생성 및 다운로드
                    </button>
                  </div>
                  {loading ? (
                    <div className="mb-3">
                      <Loading />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCodeCoupon;

const required = (
  <FaStarOfLife
    style={{
      position: "absolute",
      left: "-1.5em",
      top: "1.8em",
      fontSize: "0.5em",
      color: "red",
    }}
  />
);
