import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { IconContext } from "react-icons";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import queryString from "query-string";
import { numberFormat } from "../../utils/UtilFunc";
import { withAutoToast } from "../../utils/Utils";
import { AppContext } from "../../index";

const CouponList = () => {
  const ctx = useContext(AppContext);

  const history = useHistory();
  const location = useLocation();
  const query = useMemo(() => queryString.parse(location.search), [location]);

  const [getCode, setCode] = useState(query.code || "");
  const [pageCount, setPageCount] = useState(0);
  const [totalCnt, setTotalCnt] = useState(0);

  // 페이지 번호 바꾸기
  const changePage = (nextPage) => {
    history.push(
      `${location.pathname}?${queryString.stringify({
        ...query,
        pageNumber: nextPage,
      })}`
    );
  };

  /** 정보 가져오기 */
  const [searchResult, setSearchResult] = useState<any>();
  const getSearchResult = useCallback(async () => {
    setSearchResult("loading" as any);
    try {
      const couponResult = await ctx.wcApi.adminCoupon.getCoupons({
        // TODO: 이게 최선인가 1
        page: query.pageNumber as any,
        perPage: query.perPage as any,
        code: getCode as string,
      });

      if (couponResult.data.code === "success") {
        const {
          couponDtos,
          pagination,
          // TODO: 이게 최선인가 2
        } = (couponResult.data as any).data;

        // Data Pre processing
        const dataField = ["ModifiedDate", "Regdate", "UsedDate", "IssuedDate"];
        couponDtos.map((item, idx1) =>
          Object.keys(item).map((k) => {
            if (item[k] && dataField.includes(k)) {
              couponDtos[idx1][k] = moment(item[k]).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            }
            return "";
          })
        );

        // Set Data
        setSearchResult(couponDtos);
        setPageCount(pagination.totalPage ?? 0);
        setTotalCnt(pagination.totalCount ?? 0);
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  }, [query]);

  /** 비활성화 시키기 */
  const changeCouponActivation = withAutoToast(async (cid, status) => {
    if (window.confirm("정말로 처리하시겠습니까?")) {
      setSearchResult("loading" as any);
      const result = await ctx.api.adminCoupon.updateCoupon({
        cid,
        status,
      });

      if (result.data.success) {
        getSearchResult();
      } else {
        throw new Error(result.data.data.message);
      }
    }
  });

  useEffect(() => {
    getSearchResult();
  }, [getSearchResult, query]);

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card mb-3">
          <div className="card-header">고객 쿠폰 리스트</div>
          <div className="card-body">
            {getSearchField()}
            {getCouponList()}
          </div>
        </div>
      </div>
    </div>
  );

  function getSearchField() {
    return (
      <div className="input-group ptb-3">
        <input
          className="form-control"
          type="text"
          value={getCode}
          onChange={(e) => setCode(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              history.push(`${location.pathname}?code=${getCode}`);
            }
          }}
          placeholder="쿠폰코드 검색"
        />
        <button
          className="btn btn-primary btn-sm ml-1"
          onClick={() => history.push(`${location.pathname}?code=${getCode}`)}
        >
          검색
        </button>
      </div>
    );
  }

  function getCouponList() {
    return searchResult === "loading" ? (
      <div className="progress mb-3">
        <div
          className="progress-bar progress-bar-animated progress-bar-striped bg-success"
          aria-valuenow={100}
          aria-valuemin={0}
          aria-valuemax={100}
          style={{ width: "100%" }}
        >
          Loading....
        </div>
      </div>
    ) : (
      <>
        {Array.isArray(searchResult) && searchResult.length > 0 && (
          <div className="table-responsive">
            <table className="mb-3 table table-striped table-hover dataTable">
              <thead>
                <tr>
                  {Object.keys(couponTableHeader).map((k, idx) => (
                    <th key={idx}>{couponTableHeader[k]}</th>
                  ))}
                </tr>
              </thead>
              <tbody>{getCouponTableRow()}</tbody>
            </table>
          </div>
        )}

        {getPaging()}
      </>
    );
  }

  function getCouponTableRow() {
    return (
      searchResult &&
      searchResult.map((item, idx) => (
        <tr key={idx}>
          {Object.keys(couponTableHeader).map((k, idx2) => {
            if (k in couponTableHeader) {
              switch (k) {
                case "OrderID":
                  return (
                    <td key={idx2}>
                      {item.Status === "Y" ? (
                        <Link to={`/orders/detail/${item[k]}`}>{item[k]}</Link>
                      ) : (
                        <></>
                      )}
                    </td>
                  );
                case "UNID":
                  return (
                    <td key={idx2}>
                      <Link to={`/users/detail/${item[k]}`}>{item[k]}</Link>
                    </td>
                  );
                case "EndDate":
                  return (
                    <td key={idx2}>
                      {item.Status === "N" ? (
                        <div>
                          <div>({item.RemainDay}일 남음)</div>
                          <div>
                            {item[k] === "unlimited"
                              ? "기간제한없음"
                              : `${moment(item[k]).format("YYYY-MM-DD")}`}
                          </div>
                        </div>
                      ) : (
                        "-"
                      )}
                    </td>
                  );
                case "UsedDate":
                  return item.Status === "Y" ? (
                    <td key={idx2}>{item[k]}</td>
                  ) : (
                    <td>-</td>
                  );
                case "IsAvailable":
                  return <td key={idx2}>{item.IsAvailable ? "Y" : "N"}</td>;
                case "Activate":
                  return (
                    <td>
                      <button
                        className="btn btn-sm p-0"
                        onClick={() =>
                          changeCouponActivation(
                            item.CID,
                            item.Status === "Y" ? "N" : "Y"
                          )
                        }
                      >
                        <IconContext.Provider
                          value={{
                            size: "3em",
                            color: item.Status === "Y" ? "#cecece" : "#3bbef6",
                          }}
                        >
                          {item.Status === "Y" ? (
                            <FaToggleOff />
                          ) : (
                            <FaToggleOn />
                          )}
                        </IconContext.Provider>
                      </button>
                    </td>
                  );
                default:
                  return <td key={idx2}>{item[k]}</td>;
              }
            } else return <></>;
          })}
          <td />
        </tr>
      ))
    );
  }

  function getPaging() {
    return (
      <div className="col-lg-12">
        <div className="text-right">
          <div style={{ display: "flex" }}>
            <div className="pt-2">총 {numberFormat(totalCnt)}건</div>
            <div className="ml-auto">
              <ReactPaginate
                forcePage={query.pageNumber ? (query.pageNumber as any) - 1 : 0}
                previousLabel="이전"
                nextLabel="다음"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={pageCount + 1}
                marginPagesDisplayed={1}
                pageRangeDisplayed={9}
                onPageChange={(data) =>
                  changePage(data.selected ? data.selected + 1 : 1)
                }
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                activeClassName="active"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CouponList;

const couponTableHeader = {
  CID: "ID",
  Code: "쿠폰코드",
  OrderID: "주문ID",
  UNID: "고객ID",
  Status: "사용여부",
  UsedDate: "사용일시",
  IsAvailable: "사용가능여부",
  EndDate: "만료일자",
  IssuedDate: "발급일시",
  ModifiedDate: "수정일시",
  AdminUNID: "관리자ID",
  Regdate: "생성일시",
  Activate: "활성처리",
};
