import { useContext } from "react";
import * as utils from "../../utils/Utils";

import { ListViewPage } from "../../components/ListViewSearchPage";
import { BUTTON_SEARCH } from "../../constants";
import {
  INPUT_KEYWORD,
  SELECT_INVISIBLE,
  SELECT_OPTION_ID,
  SELECT_POINT,
  SELECT_PROVIDER_NAME,
} from "./constants/Reviews";
import { AppContext } from "../../index";

export const Reviews = () => {
  const ctx = useContext(AppContext);

  return ListViewPage({
    texts: {
      title: "리뷰 리스트",
      sub: "리뷰 리스트를 조회합니다",
      cardTitle: "리뷰 리스트",
    },
    defaultQueryParam: {
      nowPage: 1,
      perPage: 15,
    },
    fetchItems: ctx.wcApi.adminReview.getReviews,
    pluckItems: (r) => r.data.data.reviewDtos,
    searchArray: [
      {
        type: "SELECT",
        constant: SELECT_PROVIDER_NAME,
      },
      {
        type: "SELECT",
        constant: SELECT_POINT,
      },
      {
        type: "SELECT",
        constant: SELECT_OPTION_ID,
        // fetchItemOptions 계속 호출되는 이슈 해결 필요.
        optionFunction: async () => {
          const result = await ctx.wcApi.store.fetchItemOptions({
            status: "Y",
            isSubscription: "N",
            isFree: "N",
            isSubsidiary: "N",
          });
          const itemList =
            result.data.code === "success" ? result.data.data : [];

          return [
            { "": "옵션전체" },
            ...itemList.map(({ OptionID, OptionItemName }) => ({
              [OptionID]: OptionItemName,
            })),
          ];
        },
      },
      {
        type: "SELECT",
        constant: SELECT_INVISIBLE,
      },
      // 이거 상태가 좀 이상한데..?
      {
        type: "INPUT",
        constant: INPUT_KEYWORD,
      },
      {
        type: "BUTTON",
        constant: BUTTON_SEARCH,
        onClick: ({ setParam }) => () => setParam(true)({ nowPage: 1 }),
      },
    ],
    // 필요없을 것 같아서 일단 주석처리 해둠 - 필터된 리뷰의 개수가 아니라 그냥 전체 리뷰 개수만 뿌려주고 있음.
    // searchCount: {
    //   param: SEARCH_COUNT,
    //   fetchCounts: utils.getReviewCount,
    //   pluckCounts: (r) => {
    //     const count = {};
    //
    //     r.ReviewCount.forEach(({ Point, cnt }) => {
    //       count[Point] = cnt;
    //     });
    //
    //     return count;
    //   },
    //   onClick: ({ setParam }) => (e) => setParam(true)({ point: e }),
    // },
    tableInfo: () => [
      {
        width: 70,
        name: "리뷰번호",
        cell: { field: "RID" },
      },
      {
        width: 160,
        name: "등록일자",
        cell: { field: "RegDate", fmt: "datetime" },
      },
      {
        width: 100,
        name: "고객명",
        cells: [
          { field: "FullName", link: (d) => `/users/detail/${d.UNID}` },
          { field: (d) => `${d.Age}세`, className: "opacity-5" },
        ],
      },
      {
        width: 100,
        name: "브랜드",
        cell: { field: "BrandName" },
      },
      {
        width: 200,
        name: "상품명",
        cells: [
          { field: "ItemName" },
          {
            field: (d) =>
              `${d.SubscriptionType === "Y" ? "[정기]" : "[일반]"} ${
                d.OptionItemName
              } ${d.OptKey}${d.OptValue} ${d.OptKey2}${d.OptValue2} ${
                d.OptKey3
              }${d.OptValue3}`,
          },
        ],
      },
      {
        width: 60,
        name: "별점",
        center: true,
        cell: { field: "Point" },
      },
      {
        name: "내용",
        cell: {
          field: "Contents",
          className: (d) =>
            d.IsVisible === "N" || d.IsVisible === "D" ? "opacity-4" : "",
        },
      },
      {
        width: 120,
        name: "처리한 관리자ID",
        center: true,
        cell: { field: (d) => d.AdminUNID > 0 && d.AdminUNID },
      },
      {
        width: 60,
        name: "상태",
        center: true,
        cell: {
          field: "IsVisible",
          className: (d) =>
            d.IsVisible === "N" || d.IsVisible === "D" ? "opacity-4" : "",
        },
      },
      {
        width: 100,
        name: "비고",
        center: true,
        cells: [
          {
            field: (d) => (d.IsVisible === "D" ? "삭제함" : ""),
            className: "opacity-5",
          },
          {
            buttons: [
              {
                name: "미노출",
                show: (_, item) => item.IsVisible === "Y",
                callback: (p, item) => async () => {
                  await utils.changeReviewStatus({
                    rid: item.RID,
                    isvisible: "N",
                  });
                  p.setParam(true)();
                },
              },
              {
                name: "노출",
                className: "btn btn-sm btn-success",
                show: (_, item) =>
                  item.IsVisible !== "D" && item.IsVisible !== "Y",
                callback: (p, item) => async () => {
                  await utils.changeReviewStatus({
                    rid: item.RID,
                    isvisible: "Y",
                  });
                  p.setParam(true)();
                },
              },
            ],
          },
        ],
      },
    ],
  });
};
