import { useContext } from "react";
import moment from "moment";
import {
  DetailViewCard,
  DetailViewCardParam,
} from "../../../components/DetailViewCard";
import { AppContext } from "../../../index";

const OrderCoupon = ({ orderId }) => {
  const ctx = useContext(AppContext);

  const param: DetailViewCardParam = {
    card: {
      type: "list",
      bodyMaxHeight: "360px",
    },
    headerInfo: {
      title: "쿠폰 주문 적용 내역",
      buttons: ["refresh"],
    },
    getData: async () => ctx.api.adminOrder.getOrderCoupon({ orderId }),
    dataName: "data",
    cardInfo: cardInfo,
  };

  return DetailViewCard(param);
};

const cardInfo = [
  {
    name: "ID",
    width: 60,
    cells: [
      {
        field: (cp) => cp.CID,
      },
    ],
  },
  {
    name: "코드",
    cells: [
      {
        field: (cp) => cp.Code,
      },
    ],
  },
  {
    name: "쿠폰명",
    width: 200,
    cells: [
      {
        field: (cp) => cp.CouponMain.CouponName,
      },
    ],
  },
  {
    name: "혜택",
    width: 200,
    cells: [
      {
        field: (cp) => cp.CouponMain.BenefitDescription,
      },
    ],
  },
  {
    name: "적용조건",
    width: 200,
    cells: [
      {
        field: (cp) => cp.CouponMain.ConditionDescription,
      },
    ],
  },
  {
    name: "만료일자",
    cells: [
      {
        field: (cp) =>
          cp.EndDate === "unlimited"
            ? "기간 제한 없음"
            : moment(cp.EndDate).format("YYYY-MM-DD"),
      },
    ],
  },
  {
    name: "등록일자",
    cells: [
      {
        field: (cp) => moment(cp.Regdate).format("YYYY-MM-DD"),
      },
    ],
  },
];

export default OrderCoupon;
