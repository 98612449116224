import React, { createContext } from "react";
import ReactDOM from "react-dom";
import * as msal from "msal";

import App from "./App";
import * as API from "./api";
import * as WCAPI from "./api/wc-api";
import { ADMIN_AUTH_COOKIE } from "./constants";

export const msalInstance = new msal.UserAgentApplication({
  auth: {
    clientId: "4ccc9138-813e-4ac2-abb2-0addf6800d6f",
    authority:
      "https://login.microsoftonline.com/303ddbd4-59ab-4df3-80d1-fd710741a266",
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
});

login().then(({ user }) => {
  ReactDOM.render(
    <GlobalContextProvider user={user} />,
    document.getElementById("root")
  );
});

function GlobalContextProvider({ user }: { user: API.AdminUser }) {
  const token = getAccessToken();
  const api = new API.Api({
    baseURL: getApiBasePath("api"),
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  });
  const wcApi = new WCAPI.Api({
    baseURL: getApiBasePath("wcApi"),
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  });

  // HeadContents.tsx 의 changeAPI 제거하기 전까지는 쓰면 안됨
  // const [config, setConfig] = useState<Config>({
  //   apiMode: getApiMode() as any,
  // });

  return (
    <AppContext.Provider
      value={{
        user,
        api,
        wcApi,
        // config,
        // setConfig,
      }}
    >
      <App />
    </AppContext.Provider>
  );
}

export interface IAppContext {
  user: API.AdminUser;
  api: API.Api<unknown>;
  wcApi: WCAPI.Api<unknown>;
  // config: Config;
  // setConfig: React.Dispatch<React.SetStateAction<Config>>;
}

// interface Config {
//   apiMode: "DEV" | "LOCAL" | "LIVE";
// }

export const AppContext = createContext<IAppContext>({} as any);

async function login() {
  // MS Callback 등록
  console.log("start msal login");
  msalInstance.handleRedirectCallback(() => {
    // console.log("handle redirected : ", response, error);
  });

  if (!msalInstance.getAccount()) {
    console.log("msal account not exists");
    msalInstance.loginRedirect({ scopes: ["User.ReadWrite"] });
    throw new Error('msal account not exists');

  }

  console.log("msal account exists");

  try {
    const tokenResponse = await msalInstance.acquireTokenSilent({
      scopes: ["user.read"],
    });
    console.log("successfully acquired token silently");

    // 여기 조금 이상해보일 수 있는데 admin db 는 api 가 혼용되고 있어서 그렇다. 정리 필요.
    console.log("api path ", getApiBasePath());
    const api = new API.Api({ baseURL: getApiBasePath() });

    try {
      const user = await api.admin
        .signIn(
          { accessToken: tokenResponse.accessToken },
          { withCredentials: true }
        )
        .then((res) => res.data.data);
      console.log("sign-in succeeded", user);
      return { user };
    } catch (error: any) {
      console.error("sign-in error", error);
      if (error.response && error.response.data === "UserAlreadyLoggedIn") {
        console.log("이미 로그인 되어 있음.");
      } else {
        alert("wisely 계정에 로그인이 되지 않습니다.");
        console.log(error);
      }
      never();
    }
  } catch (err) {
    console.log("failed to acquire token silently");
    msalInstance.acquireTokenRedirect({ scopes: ["user.read"] });
    // 이게 없으면 login 의 return type 이 string | undefined 가 됨
    // 정확히는 msalInstance.acquireTokenRedirect 의 return type 이 never 인데 void 로 잘못 적혀 있어서 생기는 문제
    never();
  }
}

function getApiBasePath(type: "api" | "wcApi" = "api") {
  const basePaths =
    type === "api"
      ? {
          DEV: "https://n-api-dev.wiselycompany.com",
          LOCAL: "https://dev.wiselycompany.com:3001",
          LIVE: isAdminDevServer()
            ? "https://n-api-stage.wiselycompany.com"
            : "https://n-api.wiselycompany.com",
        }
      : {
          DEV: "https://wc-api-dev.wiselycompany.com",
          LOCAL: "https://dev.wiselycompany.com:3000",
          LIVE: isAdminDevServer()
            ? "https://wc-api-stage.wiselycompany.com"
            : "https://wc-api.wiselycompany.com",
        };
  return basePaths[getApiMode()];
}

export function isAdminDevServer() {
  return (
    window.location.href.includes("ws-admin-dev") ||
    process.env.NODE_ENV === "development"
  );
}
function getApiMode() {
  return localStorage.getItem("apiMode") ?? "LIVE";
}

function getAccessToken() {
  return getCookie(ADMIN_AUTH_COOKIE) ?? never();
}

// TODO: 주석..?
function getCookie(name: string) {
  const value = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return value ? value[2] : null;
}

function never(): never {
  throw new Error("never");
}
