import { useContext } from "react";
import moment from "moment";
import FileSaver from "file-saver";
import XLSX from "xlsx";

import { ListViewPage } from "../../components/ListViewSearchPage";
import { AppContext } from "../../index";
import * as utils from "../../utils/Utils";

export const DeliveryExport = () => {
  const ctx = useContext(AppContext);

  return ListViewPage({
    defaultQueryParam: {
      nowPage: 1,
      perPage: 15,
    },
    fetchItems: ctx.wcApi.adminDelivery.getDeliveryMainList,
    pluckItems: (r) => r.data.data.deliveryMains,
    searchArray: [
      {
        type: "BUTTON",
        constant: { name: "발주서 처리시작" },
        onClick: ({ state, setState, setParam, ctx }) => () =>
          utils.withConfirmApiMode(async () => {
            if (window.confirm("발주서 처리를 시작하시겠습니까?")) {
              setState({ ...state, items: undefined });
              // TODO: 이거 에러나는 경우 처리?
              await utils.initDeliveryList(ctx.user?.unid);
              setParam(true)();
            }
          }),
      },
    ],
    tableInfo: () => [
      { name: "차수", width: 50, cell: { field: "DMID" } },
      {
        name: "처리상태",
        width: 80,
        cell: {
          field: "Status",
          fmt: (v) =>
            ({
              X: "무효화됨",
              I: "생성완료",
              P: "다운로드됨",
            }[v]),
        },
      },
      { name: "생성일", cell: { field: "ExportedDate", fmt: "date" } },
      { name: "발주갯수", cell: { field: "DeliveryCount", fmt: "number" } },
      { name: "처리주문수", cell: { field: "OrderCount", fmt: "number" } },
      { name: "처리상품수", cell: { field: "ItemCount", fmt: "number" } },
      { name: "합배송건수", cell: { field: "CombinedCount", fmt: "number" } },
      { name: "발주처리자", width: 100, cell: { field: "FullName" } },
      { name: "변경시간", cell: { field: "ModifiedDate", fmt: "datetime" } },
      { name: "등록시간", cell: { field: "RegDate", fmt: "datetime" } },
      {
        name: "비고",
        cell: {
          buttons: [
            {
              name: "취소",
              show: (_, item) => item.Status === "I" || item.Status === "P",
              callback: (p, item) => () =>
                utils.withConfirmApiMode(async () => {
                  if (window.confirm("정말로 취소하시겠습니까?")) {
                    await utils.cancelDeliveryList(item.DMID, p.ctx.user?.unid);
                    p.setParam(true)();
                  }
                }),
            },
            {
              name: "다운로드",
              show: (_, item) => item.Status === "I" || item.Status === "P",
              callback: (p, item) => async () => {
                console.log("p", p);
                const results = await utils.exportDeliveryItemList(
                  item.DMID,
                  p.ctx.user?.unid
                );
                down(results.deliveryItemList, item.DMID);
                p.setParam(true)();
              },
            },
          ],
        },
      },
    ],
    texts: {
      title: "발주서 리스트",
      sub: "발주서 리스트를 조회합니다.",
      cardTitle: "발주서 리스트",
    },
  });
};

const down = (deliveryItems, DMID) => {
  if (deliveryItems.length < 1) {
    window.alert("처리할 데이터가 없습니다.");
    return;
  }

  /// ////////////////////////////////////////////////////////////////
  // 배송 타입별 sheet로 나눠보기
  /// ////////////////////////////////////////////////////////////////
  // // 배송타입과 배송타입별 데이터 재생성
  // const unique = [];
  // const uniqueArr = [];
  // getData.map((item) => {
  // 	if (unique.indexOf(item.DeliveryType) === -1) {
  // 		unique.push(item.DeliveryType);
  // 		uniqueArr[item.DeliveryType] = [];
  // 	}
  // 	uniqueArr[item.DeliveryType].push({
  // 		"몰명(또는 몰코드)": item.BrandName,
  // 		출하의뢰번호: item.DCID,
  // 		출하의뢰항번: item.ExportItemNo,
  // 		고객주문번호: item.OrderID,
  // 		상품명: item.OptionItemName,
  // 		제품코드: item.ProductCode,
  // 		주문수량: item.Quantity,
  // 		주문자명: "",
  // 		주문자연락처1: "",
  // 		주문자연락처2: "",
  // 		수취인명: item.RecipientFullName,
  // 		수취인연락처1: item.RecipientPhone,
  // 		수취인연락처2: item.RecipientPhone,
  // 		수취인우편번호: item.RecipientPostcode,
  // 		수취인주소1: item.RecipientAddress,
  // 		수취인주소2: "",
  // 		배송메시지: item.Memo,
  // 		송장번호: "",
  // 		택배사명: "",
  // 		브로셔타입: item.DeliveryType,
  // 	});
  // 	return null;
  // });

  // // 배송타입 순서로 sheet를 만들기 위해 정렬 후 sheet별 XLSX 데이터 생성
  // const resultData = {};
  // unique.sort();
  // unique.map((typeName) => {
  // 	resultData[typeName] = XLSX.utils.json_to_sheet(uniqueArr[typeName]);
  // 	return null;
  // });

  /// ////////////////////////////////////////////////////////////////
  // 하나의 sheet로 모아서 보기
  /// ////////////////////////////////////////////////////////////////
  const unique = ["WISELY"];
  const uniqueArr = [] as any[];

  deliveryItems.map((item) => {
    uniqueArr.push({
      "몰명(또는 몰코드)": item.BrandName,
      출하의뢰번호: item.DCID,
      출하의뢰항번: item.ExportItemNo,
      고객주문번호: item.OrderID,
      상품명: item.OptionItemName,
      제품코드: item.ProductCode.toString(),
      주문수량: item.Quantity,
      주문자명: item.SenderFullName,
      주문자연락처1: "",
      주문자연락처2: "",
      수취인명: item.RecipientFullName,
      수취인연락처1: item.RecipientPhone,
      수취인연락처2: item.RecipientPhone,
      수취인우편번호: item.RecipientPostcode,
      수취인주소1: item.RecipientAddress,
      수취인주소2: "",
      배송메시지: item.Memo,
      송장번호: "",
      택배사명: "",
      브로셔타입: item.DeliveryType,
      포함된상품수: item.ItemRowCount,
      결제일시: item.ApprovedDate
        ? moment(item.ApprovedDate).format("YYYY-MM-DD HH:mm:ss")
        : item.ApprovedDate,
    });
    return null;
  });

  // 배송타입 순서로 sheet를 만들기 위해 정렬 후 sheet별 XLSX 데이터 생성
  const resultData: any = {};
  resultData.WISELY = XLSX.utils.json_to_sheet(uniqueArr);

  // XLSX 만들기
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xls";
  const wb = { Sheets: resultData, SheetNames: unique };
  const excelBuffer = XLSX.write(wb, { bookType: "xls", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(
    data,
    `wisely_exported_${moment().format(
      "YYYYMMDD_HHmmss"
    )}_no${DMID}${fileExtension}`
  );
};
