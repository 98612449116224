import React, { useState, useRef } from "react";
import Drawer from "react-drag-drawer";
import DaumPostcode from "react-daum-postcode";
import moment from "moment";
import { IconContext } from "react-icons";
import { IoIosRefresh, IoIosSettings } from "react-icons/io";
import { FaBarcode, FaPrint, FaTruck } from "react-icons/fa";

import OrderContext from "../../../providers/ProviderOrderDetail";
import * as utils from "../../../utils/Utils";

const OrderShipping = () => {
  const orderStatus = {
    Completed: "결제완료",
    "On-Hold": "보류",
    Cancelled: "결제취소",
    Processing: "결제진행중",
    "Awaiting-vbank": "가상계좌입금대기중",
    "Cancelled-vbank": "가상계좌결제취소",
    Failed: "결제실패",
    Refunded: "환불됨",
    "Pending-cancel": "보류취소",
    "Partially-Refunded": "부분환불",
    "Requested-Refund": "환불요청",
    Fraud: "부정결제",
    Initialized: "주문생성",
    "Awaiting-Subscription": "정기결제대기중",
    "Initialized-EasyPayment": "간편결제주문초기화",
    "Requested-Return": "반품신청",
    "Requested-Exchange": "교환신청",
    "Return-Processing": "반품진행중",
    "Exchange-Processing": "교환진행중",
    Returned: "반품완료",
    Exchanged: "교환완료",
    "Out-for-Delivery": "배송중",
    Delivered: "배달완료",
    Paid: "결제완료",
    "Order-Processing": "배송접수",
    Pending: "대기중",
    "Track-Number-Issued": "송장번호발급됨",
  };

  /** Modal 띄우기 */
  const [isOpened, setIsOpened] = useState(false);
  const showModal = (data) => {
    if (!data) data = {};

    setItem(data); // 임시로 값 저장
    setIsOpened(true); // Modal 띄우기
  };

  /** Modal에서 수정하는 내용 임시 저장 */
  const [getItem, setItem] = useState<any>(null);
  const handleChangeItem = (event) => {
    setItem({
      ...(getItem as any),
      [event.target.name]: event.target.value,
    });
  };

  /** daum 우편번호로 주소 검색하기 */
  const [postAreaStyle, setPostAreaStyle] = useState({ display: "none" });
  const handleAddress = (data) => {
    setItem({
      ...(getItem as any),
      Country: "KR",
      Postcode: data.zonecode,
      City: data.sido,
      Address1: data.address,
    });
    setPostAreaStyle({ display: "none" });
  };

  /** 배송지 변경하기(기존 배송지 이용) */
  const addressSelector = useRef<any>();

  // 발주상태 확인하기
  const [deliveryStatus, setDeliveryStatus] = useState(null);
  const getDeliveryStatus = async (orderid) => {
    const getResult = await utils.getDeliveryStatus(orderid);
    if (getResult) {
      setDeliveryStatus(
        getResult.DeliveryStatus.DeliveryStatus[0].STS
          ? getResult.DeliveryStatus.DeliveryStatus[0].STS
          : getResult.DeliveryStatus.DeliveryStatus
      );
    }
  };

  // 송장번호 확인하기
  const [trackNo, setTrackNo] = useState(null);
  const getDeliveryTrackNo = async (orderid) => {
    const getResult = await utils.getDeliveryTrackNo(orderid);
    if (getResult) {
      setTrackNo(getResult.TrackNo.TrackNo);
    }
  };

  // 배송상태 확인하기
  const [trackLogs, setTrackLogs] = useState<any>(null);
  const getDeliveryTrackLogs = async () => {
    if (trackNo) {
      const getResult = await utils.getDeliveryTrackLogs(trackNo);
      if (getResult) {
        const TrackLog =
          getResult.TrackLogs.TrackLogs === ""
            ? "-"
            : getResult.TrackLogs.TrackLogs;
        setTrackLogs(TrackLog);
      }
    } else {
      alert("발급된 송장번호가 없습니다.");
    }
  };

  // 송장번호 변경하기
  const [showTrack, setShowTrack] = useState(false);
  const toggleTrack = () => {
    setShowTrack(!showTrack);
  };

  const [trackNoNew, setTrackNoNew] = useState();
  const handleTrackNoNew = (event) => {
    setTrackNoNew(event.target.value);
  };

  // 발주상태 확인하기
  const changeTrackNo = async (param) => {
    if (window.confirm("정말로 변경하시겠습니까?")) {
      const datas = {
        unid: param.unid,
        orderid: param.orderid,
        dcid: param.dcid,
        trackno: param.trackno,
        tracknonew: trackNoNew,
      };
      const getResult = await utils.changeTrackNo(datas);
      if (getResult) {
        param.actions.refreshOrderData();
        setShowTrack(!showTrack);
      }
    }
  };

  return (
    <OrderContext.Consumer>
      {({ data, actions }) =>
        data.orderData && (
          <>
            {data.orderData.OrderAddress && (
              <div className="card mb-1">
                <div className="card-header text-primary">
                  배송정보
                  <div className="btn-actions-pane-right">
                    <button
                      className="btn btn-link p-0"
                      onClick={() => actions.refreshOrderData()}
                    >
                      <IconContext.Provider
                        value={{
                          size: "2em",
                          style: { verticalAlign: "top", padding: "3px" },
                        }}
                      >
                        <IoIosRefresh />
                      </IconContext.Provider>
                    </button>
                    <button
                      className="btn btn-link"
                      onClick={() => showModal(data.orderData.OrderAddress)}
                    >
                      <IconContext.Provider
                        value={{
                          size: "2em",
                          style: { verticalAlign: "top", padding: "3px" },
                        }}
                      >
                        <IoIosSettings />
                      </IconContext.Provider>
                    </button>
                    <button
                      className="btn btn-link pl-0"
                      onClick={() =>
                        getDeliveryStatus(data.orderData.OrderDCID.DCID)
                      }
                      data-toggle="tooltip"
                      data-placement="top"
                      title="출고상태조회"
                    >
                      <IconContext.Provider
                        value={{
                          size: "2em",
                          style: { verticalAlign: "top", padding: "3px" },
                        }}
                      >
                        <FaBarcode />
                      </IconContext.Provider>
                    </button>
                    <button
                      className="btn btn-link pl-0"
                      onClick={() =>
                        getDeliveryTrackNo(data.orderData.OrderDCID.DCID)
                      }
                      data-toggle="tooltip"
                      data-placement="top"
                      title="송장번호조회"
                    >
                      <IconContext.Provider
                        value={{
                          size: "2em",
                          style: { verticalAlign: "top", padding: "3px" },
                        }}
                      >
                        <FaPrint />
                      </IconContext.Provider>
                    </button>
                    {trackNo && (
                      <button
                        className="btn btn-link pl-0"
                        onClick={() => getDeliveryTrackLogs()}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="배송조회"
                      >
                        <IconContext.Provider
                          value={{
                            size: "2em",
                            style: { verticalAlign: "top", padding: "3px" },
                          }}
                        >
                          <FaTruck />
                        </IconContext.Provider>
                      </button>
                    )}
                  </div>
                </div>
                <div className="card-body">
                  {data.orderData.OrderAddress && (
                    <div className="table-responsive">
                      <table className="table table-borderless table-sm">
                        <tbody>
                          {data.userAddress && data.userAddress.length > 0 && (
                            <tr>
                              <th style={{ width: "150px" }}>배송지선택</th>
                              <td>
                                <div className="form-inline">
                                  <select
                                    className="form-control form-control-sm"
                                    defaultValue={
                                      data.orderData.OrderAddress.AID
                                    }
                                    ref={addressSelector as any}
                                  >
                                    {data.userAddress.map((item, idx) => (
                                      <option key={idx} value={item.AID}>
                                        {`${item.Address1} ${item.Address2}`}
                                      </option>
                                    ))}
                                  </select>
                                  <button
                                    className="btn btn-sm btn-light ml-1"
                                    onClick={() =>
                                      actions.setOrderAddressID({
                                        unid: data.userData.UserInfo.UNID,
                                        orderid:
                                          data.orderData.OrderInfo.OrderID,
                                        addressid:
                                          addressSelector.current.value,
                                      })
                                    }
                                  >
                                    배송지 변경
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )}
                          <tr>
                            <th>주문배송지ID</th>
                            <td>{data.orderData.OrderAddress.OAID}</td>
                          </tr>
                          <tr>
                            <th>받으시는분</th>
                            <td>{data.orderData.OrderAddress.FullName}</td>
                          </tr>
                          <tr>
                            <th>받으시는분전화</th>
                            <td>{data.orderData.OrderAddress.Phone}</td>
                          </tr>
                          <tr>
                            <th>받으시는주소</th>
                            <td>
                              [{data.orderData.OrderAddress.Postcode}]{" "}
                              {data.orderData.OrderAddress.Address1}{" "}
                              {data.orderData.OrderAddress.Address2}
                            </td>
                          </tr>
                          <tr>
                            <th>배송메모</th>
                            <td>
                              {data.orderData.OrderAddress.Memo
                                ? data.orderData.OrderAddress.Memo
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <th>발주상태</th>
                            <td>
                              {data.orderData.DeliveryInfo?.DeliveryStatus && (
                                <span className="badge badge-secondary p-2 mr-1">
                                  DB상태 :{" "}
                                  {
                                    orderStatus[
                                      data.orderData.DeliveryInfo.DeliveryStatus
                                    ]
                                  }
                                </span>
                              )}

                              {deliveryStatus && (
                                <span className="badge badge-info p-2 mr-1">
                                  상세상태 : {deliveryStatus}
                                </span>
                              )}

                              <button
                                className="btn btn-sm btn-light mr-1"
                                onClick={() =>
                                  getDeliveryStatus(
                                    data.orderData.OrderDCID.DCID
                                  )
                                }
                              >
                                취소가능여부 확인하기
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <th>송장번호</th>
                            <td>
                              <span>
                                {data.orderData.DeliveryInfo &&
                                  data.orderData.DeliveryInfo.TrackNo && (
                                    <a
                                      href={`https://www.doortodoor.co.kr/parcel/doortodoor.do?fsp_action=PARC_ACT_002&fsp_cmd=retrieveInvNoACT&invc_no=${data.orderData.DeliveryInfo.TrackNo.toString()
                                        .split("-")
                                        .join("")}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="badge badge-dark p-2">
                                        {data.orderData.DeliveryInfo.TrackNo}
                                      </span>
                                    </a>
                                  )}
                              </span>
                              <span>
                                {trackNo && (
                                  <a
                                    href={`https://www.doortodoor.co.kr/parcel/doortodoor.do?fsp_action=PARC_ACT_002&fsp_cmd=retrieveInvNoACT&invc_no=${trackNo}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <span className="badge badge-info p-2 ml-2">
                                      {trackNo}
                                    </span>
                                  </a>
                                )}
                              </span>

                              {((data.orderData.DeliveryInfo &&
                                data.orderData.DeliveryInfo.TrackNo) ||
                                trackNo) && (
                                <>
                                  <button
                                    className="btn btn-sm btn-light ml-2"
                                    onClick={() => toggleTrack()}
                                  >
                                    송장번호변경
                                  </button>

                                  {showTrack && (
                                    <div className="form-inline mt-1">
                                      <div className="position-relative form-group">
                                        <input
                                          className="form-control form-control-sm"
                                          name="trackNoNew"
                                          onChange={handleTrackNoNew}
                                          defaultValue={
                                            data.orderData.DeliveryInfo.TrackNo
                                              ? data.orderData.DeliveryInfo
                                                  .TrackNo
                                              : trackNo || ""
                                          }
                                          style={{ minWidth: "200px" }}
                                        />
                                      </div>
                                      <button
                                        className="btn btn-success ml-1"
                                        onClick={() =>
                                          changeTrackNo({
                                            unid: data.userData.UserInfo.UNID,
                                            orderid:
                                              data.orderData.OrderInfo.OrderID,
                                            dcid: data.orderData.OrderDCID.DCID,
                                            trackno: data.orderData.DeliveryInfo
                                              .TrackNo
                                              ? data.orderData.DeliveryInfo
                                                  .TrackNo
                                              : trackNo || "",
                                            actions,
                                          })
                                        }
                                      >
                                        변경
                                      </button>
                                    </div>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th>배송상태</th>
                            <td>
                              <div>
                                {trackLogs && (
                                  <span className="badge badge-info">
                                    {trackLogs === "-"
                                      ? trackLogs
                                      : trackLogs.state.id}
                                  </span>
                                )}
                              </div>
                              {trackLogs &&
                                trackLogs.progresses &&
                                trackLogs.progresses.length > 0 && (
                                  <table className="table table-borderless table-striped table-sm mt-2">
                                    <tbody>
                                      {trackLogs.progresses.map((item) => (
                                        <tr>
                                          <td>
                                            <div style={{ fontSize: "0.8em" }}>
                                              <span className="text-info">
                                                {item.status.text}
                                              </span>
                                            </div>
                                            <div style={{ fontSize: "0.8em" }}>
                                              <span
                                                style={{ fontWeight: "bold" }}
                                              >
                                                {moment(item.time).format(
                                                  "YYYY-MM-DD HH:mm"
                                                )}
                                              </span>
                                              <span> - </span>
                                              <span>{item.location.name}</span>
                                            </div>
                                            <div style={{ fontSize: "0.8em" }}>
                                              {item.description}
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                )}
                            </td>
                          </tr>
                          {/*
														<tr>
															<th>송장번호</th>
															<td>
																<select>
																	<option>CJ대한통운</option>
																	<option>우체국</option>
																</select>
																<input type="text" defaultValue="" />
																<button>송장등록/취소</button>
															</td>
														</tr>
													*/}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            )}

            {getItem && (
              <Drawer
                open={isOpened}
                onRequestClose={() => setIsOpened(!isOpened)}
                allowClose={false}
              >
                <div className="row">
                  <div className="col">
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="card-title">변경</div>
                        <div>
                          <form>
                            <div className="form-group position-relative">
                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text mb-1"
                                    style={{ minWidth: "150px" }}
                                  >
                                    받으시는분
                                  </span>
                                </div>
                                <input
                                  className="form-control form-control-sm"
                                  name="FullName"
                                  onChange={handleChangeItem}
                                  value={getItem.FullName}
                                  style={{ minWidth: "300px" }}
                                />
                              </div>

                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text mb-1"
                                    style={{ minWidth: "150px" }}
                                  >
                                    받으시는분전화
                                  </span>
                                </div>
                                <input
                                  className="form-control form-control-sm"
                                  name="Phone"
                                  onChange={handleChangeItem}
                                  value={getItem.Phone}
                                  style={{ minWidth: "300px" }}
                                />
                              </div>

                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text mb-1"
                                    style={{ minWidth: "150px" }}
                                  >
                                    받으시는주소
                                  </span>
                                </div>
                                <input
                                  className="form-control"
                                  name="Address1"
                                  onChange={handleChangeItem}
                                  value={getItem.Address1}
                                  style={{
                                    minWidth: "300px",
                                    display: "inline",
                                  }}
                                  onClick={() =>
                                    setPostAreaStyle({ display: "block" })
                                  }
                                  readOnly
                                />
                              </div>
                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text mb-1"
                                    style={{ minWidth: "150px" }}
                                  >
                                    상세주소
                                  </span>
                                </div>
                                <input
                                  className="form-control"
                                  name="Address2"
                                  onChange={handleChangeItem}
                                  value={getItem.Address2}
                                  style={{ minWidth: "300px" }}
                                />
                              </div>

                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text mb-1"
                                    style={{ minWidth: "150px" }}
                                  >
                                    배송메모
                                  </span>
                                </div>
                                <input
                                  className="form-control"
                                  name="Memo"
                                  onChange={handleChangeItem}
                                  value={getItem.Memo}
                                  style={{ minWidth: "300px" }}
                                />
                              </div>

                              <div>
                                <div style={{ maxWidth: "100%" }}>
                                  <DaumPostcode
                                    onComplete={handleAddress}
                                    style={postAreaStyle}
                                    animation
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="text-center">
                          <button
                            className="btn btn-light"
                            onClick={() => setIsOpened(!isOpened)}
                          >
                            닫기
                          </button>

                          <button
                            className="btn btn-success ml-2"
                            onClick={() => {
                              actions.updateOrderAddress(getItem);
                              setIsOpened(!isOpened);
                            }}
                          >
                            변경하기
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Drawer>
            )}
          </>
        )
      }
    </OrderContext.Consumer>
  );
};

export default OrderShipping;
