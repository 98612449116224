import { useContext } from "react";
import moment from "moment";
import {
  DetailViewCard,
  DetailViewCardParam,
} from "../../../components/DetailViewCard";
import { AppContext } from "../../../index";
import { numberFormat } from "../../../utils/UtilFunc";

const UserCoupon = ({ userid }) => {
  const ctx = useContext(AppContext);

  const param: DetailViewCardParam = {
    card: {
      type: "list",
      bodyMaxHeight: "360px",
    },
    headerInfo: {
      title: "고객쿠폰 발급 및 사용 내역",
      buttons: ["refresh"],
    },
    getData: async () => {
      const result = await ctx.wcApi.adminCoupon.getUserCoupons({
        unid: userid,
      });
      return result.data.code === "success" ? result.data : [];
    },
    dataName: "data",
    cardInfo: cardInfo,
  };

  return DetailViewCard(param);
};

const cardInfo = [
  {
    name: "ID",
    width: 60,
    cells: [
      {
        field: (cp) => cp.CID,
      },
    ],
  },
  {
    name: "코드",
    cells: [
      {
        field: (cp) => cp.Code,
      },
    ],
  },
  {
    name: "쿠폰명",
    width: 200,
    cells: [
      {
        field: (cp) => cp.CouponMain.CouponName,
      },
    ],
  },
  {
    name: "최대혜택금액",
    width: 200,
    cells: [
      {
        field: (cp) => `${numberFormat(cp.CouponMain.MaxDiscountAmount)}원`,
      },
    ],
  },
  {
    name: "쿠폰설명",
    width: 200,
    cells: [
      {
        field: (cp) => cp.CouponMain.Description,
      },
    ],
  },
  {
    name: "사용여부",
    width: 60,
    cells: [
      {
        field: (cp) => cp.Status,
      },
    ],
  },
  {
    name: "사용가능여부",
    width: 100,
    cells: [
      {
        field: (cp) => (cp.IsAvailable ? "Y" : "N"),
      },
    ],
  },
  {
    name: "만료일자",
    cells: [
      {
        field: (cp) =>
          cp.EndDate === "unlimited"
            ? "기간 제한 없음"
            : moment(cp.EndDate).format("YYYY-MM-DD"),
      },
    ],
  },
  {
    name: "등록일자",
    cells: [
      {
        field: (cp) => moment(cp.Regdate).format("YYYY-MM-DD"),
      },
    ],
  },
  {
    name: "주문번호",
    cells: [
      {
        field: (cp) => (cp.Status === "Y" ? cp.OrderID : ""),
        link: (cp) => `/orders/detail/${cp.OrderID}`,
      },
    ],
  },
];

export default UserCoupon;
