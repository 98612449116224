import { useContext } from "react";
import { DetailViewCard } from "../../../components/DetailViewCard";
import { AppContext } from "../../../index";

export const UserOrderList = ({ unid }) => {
  const ctx = useContext(AppContext);

  return DetailViewCard({
    card: {
      type: "list",
      bodyMaxHeight: "360px",
    },
    headerInfo: {
      title: "고객주문내역",
      buttons: ["refresh"],
    },
    getData: async () => {
      const { data } = await ctx.wcApi.adminUser.getUserOrders({
        unid,
      });

      if (data.code === "fail") {
        return [];
      }

      return data;
    },
    dataName: "data",
    cardInfo: [
      {
        name: "주문번호",
        width: 100,
        cells: [
          { field: "OrderID", link: (d) => `/orders/detail/${d.OrderID}` },
          {
            field: "OrderStatus",
            style: { fontSize: "0.8em" },
            fmt: "order_status",
          },
        ],
      },
      {
        name: "타입",
        cell: {
          field: (d) => (d.IsSubscribed === "Y" ? "정기" : "일반"),
          style: { fontSize: "0.7em" },
          className: (d) =>
            d.IsSubscribed === "Y"
              ? "badge badge-success p-1"
              : "badge badge-secondary p-1",
        },
      },
      {
        name: "주문명",
        cells: [
          {
            field: (d) =>
              d.OrderType === 0 || d.OrderType === 1 ? null : d.OrderType,
            style: { fontSize: "0.7em" },
            className: "text-danger",
            fmt: "order_type",
          },
          { field: "OrderName" },
          {
            field: (d) => (d.Source !== "" ? d.Source : ""),
            style: { fontSize: "0.7em" },
            className: "text-primary",
          },
        ],
      },
      {
        name: "결제",
        center: true,
        cell: {
          field: (d) => (d.PaymentStatus === "Y" ? "O" : "X"),
          style: { fontSize: "0.8em" },
          className: (d) => (d.PaymentStatus === "Y" ? "" : "opacity-4"),
        },
      },
      {
        name: "등록일/변경일",
        width: 120,
        cellCommonStyle: { fontSize: "0.8em", width: 120 },
        cells: [
          { field: "RegDate", fmt: "datetime" },
          {
            field: (d) => (d.ModifiedDate ? d.ModifiedDate : ""),
            className: "opacity-5",
            fmt: "datetime",
          },
        ],
      },
    ],
  });
};
