import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { AppContext } from "../../index";
import { numberFormat } from "../../utils/UtilFunc";

const CouponMainList = () => {
  const ctx = useContext(AppContext);

  const [couponMains, setCouponMains] = useState<any>([]);
  const getCouponMains = async () => {
    setCouponMains("loading" as any);

    try {
      const result = await ctx.wcApi.adminCoupon.getCouponMains();

      if (result.data.code === "success") {
        // Set Data
        setCouponMains(result.data.data);
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  // TODO: 한번만 실행되게 하려면 이렇게 하는건가.. 변하지 않을 값에 걸어둔다?
  useEffect(() => {
    getCouponMains();
  }, [ctx.user.id]);

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card mb-3">
          <div className="card-header">등록 쿠폰 목록</div>
          <div className="card-body">{getCouponMainList()}</div>
        </div>
      </div>
    </div>
  );

  function getCouponMainTableRow() {
    return (
      couponMains &&
      couponMains.map((item, idx) => (
        <tr key={idx}>
          {Object.keys(couponMainTableHeader).map((k, idx2) => {
            if (k in couponMainTableHeader) {
              switch (k) {
                case "CommonCode":
                  return (
                    <td key={idx2}>
                      {item.CodeType === "Common"
                        ? item.CommonCode
                        : "난수 코드"}
                    </td>
                  );
                case "MinimumPrice":
                  return (
                    <td key={idx2} style={{ minWidth: "100px" }}>
                      {item[k] === 0 ? "-" : `${numberFormat(item[k])}원`}
                    </td>
                  );
                case "MaxDiscountAmount":
                  return (
                    <td key={idx2} style={{ minWidth: "100px" }}>
                      {item[k] === 0 ? "-" : `${numberFormat(item[k])}원`}
                    </td>
                  );
                case "ValidPeriod":
                  return (
                    <td key={idx2} style={{ minWidth: "200px" }}>
                      {`${moment(item.StartDate).format("YYYY-MM-DD")} ~ ${
                        item.EndDate === "unlimited"
                          ? "기간제한없음"
                          : moment(item.EndDate).format("YYYY-MM-DD")
                      }`}
                    </td>
                  );
                case "ValidityDay":
                  return (
                    <td key={idx2} style={{ minWidth: "100px" }}>
                      {item.ValidityType === "Relative"
                        ? `${item.ValidityDay}일`
                        : "-"}
                    </td>
                  );
                case "RegDate":
                  return (
                    <td key={idx2} style={{ minWidth: "200px" }}>
                      {moment(item[k]).format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                  );
                default:
                  return <td key={idx2}>{item[k]}</td>;
              }
            } else return <></>;
          })}
          <td />
        </tr>
      ))
    );
  }

  function getCouponMainList() {
    return couponMains === "loading" ? (
      <div className="progress mb-3">
        <div
          className="progress-bar progress-bar-animated progress-bar-striped bg-success"
          aria-valuenow={100}
          aria-valuemin={0}
          aria-valuemax={100}
          style={{ width: "100%" }}
        >
          Loading....
        </div>
      </div>
    ) : (
      <>
        {Array.isArray(couponMains) && couponMains.length > 0 && (
          <div className="table-responsive" style={{ maxHeight: "450px" }}>
            <table className="mb-3 table table-striped table-hover dataTable">
              <thead>
                <tr>
                  {Object.keys(couponMainTableHeader).map((k, idx) => (
                    <th key={idx}>{couponMainTableHeader[k]}</th>
                  ))}
                </tr>
              </thead>
              <tbody>{getCouponMainTableRow()}</tbody>
            </table>
          </div>
        )}
      </>
    );
  }
};

export default CouponMainList;

const couponMainTableHeader = {
  CMID: "ID",
  CouponName: "쿠폰명",
  Description: "쿠폰설명",
  CommonCode: "코드명",
  MinimumPrice: "최소결제금액",
  MaxDiscountAmount: "최대혜택금액",
  ValidPeriod: "유효기간",
  ValidityDay: "발급 후 만료일",
  RegDate: "등록일시",
};
