import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { IconContext } from "react-icons";
import { apiMode } from "../Store";

import { menus, MenuGroup } from "../configs/menu";
import { AppContext } from "..";

const SideContents = () => {
  const ctx = useContext(AppContext);

  const visibleMenus = menus
    .filter((m) => apiMode === "DEV" || !m.devView)
    .map((m) => {
      const links = m.links.filter((l) =>
        l.scope ? l.scope.some((s) => ctx.user.scopes.includes(s)) : true
      );
      return {
        ...m,
        links,
      };
    });

  const menu = visibleMenus.map((m, idx) => <Menus menus={m} key={idx} />);

  return (
    <>
      <div id="mobile-menu" style={mobileStyle}>
        <ul className="vertical-nav-menu">{menu}</ul>
      </div>

      <div className="app-sidebar sidebar-shadow" style={{ overflow: "auto" }}>
        <div className="scrollbar-sidebar">
          <div className="app-sidebar__inner">
            <ul className="vertical-nav-menu">{menu}</ul>
          </div>
        </div>
      </div>
    </>
  );
};

const Menus = ({ menus }: { menus: MenuGroup }) => (
  <>
    <li className="app-sidebar__heading">{menus.title}</li>
    <li>
      {menus.links.map((link, idx) => (
        <NavLink
          activeStyle={{ color: "#3f6ad8", fontWeight: "bold" }}
          to={link.to}
          exact
          key={idx}
        >
          <i className="metismenu-icon">
            <IconContext.Provider
              value={{
                size: "1.4em",
                style: { verticalAlign: "top", padding: "3px" },
              }}
            >
              {link.icon && <link.icon />}
            </IconContext.Provider>
          </i>
          {link.name}
        </NavLink>
      ))}
    </li>
  </>
);

const mobileStyle = {
  backgroundColor: "#fff",
  position: "fixed",
  top: "60px",
  left: "0px",
  width: "100%",
  height: "100%",
  overflowY: "auto",
  zIndex: 100,
  borderBottom: "2px solid #ddd",
  padding: "10px 10px 100px 10px",
  display: "none",
} as const;

export default SideContents;
