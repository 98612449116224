import React from "react";
import ReactJson from "react-json-view";
import { sha256 } from "js-sha256";

import OrderContext from "../../../providers/ProviderOrderDetail";

const OrderHistory = () => (
  <OrderContext.Consumer>
    {({ data }) =>
      data.orderData && (
        <>
          <div className="card mb-1">
            <div className="card-header text-primary">Transaction Log</div>
            {data.orderData.OrderTransactionLog &&
              data.orderData.OrderTransactionLog.length > 0 && (
                <div className="card-body">
                  {getReceipt(data.orderData)}
                  <ul
                    className="list-group mb-3"
                    style={{ maxHeight: "400px", overflow: "scroll" }}
                  >
                    {data.orderData.OrderTransactionLog.map((v, idx) => (
                      <li className="list-group-item" key={idx}>
                        <div
                          className="bg-secondary text-white rounded mb-2"
                          style={{
                            padding: "0.1em 0.5em",
                            fontSize: "0.8em",
                            fontWeight: "bold",
                          }}
                        >
                          [{v.TransactionID}] {v.RegDate}
                        </div>
                        <ReactJson
                          src={v.LogData}
                          enableClipboard={false}
                          displayDataTypes={false}
                          style={{
                            wordBreak: "break-all",
                            lineHeight: "0.7em",
                          }}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
          </div>
        </>
      )
    }
  </OrderContext.Consumer>
);
export default OrderHistory;

function getReceipt(orderData) {
  return (
    <div className="mb-2">
      {orderData.OrderTransactionLog.map((v, idx) => (
        <span key={idx}>
          {/* nicepay */}
          {v.LogData.response && v.LogData.response.receipt_url !== undefined && (
            <a
              href={v.LogData.response.receipt_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn btn-sm btn-light mr-1">
                매출전표(나이스)
              </button>
            </a>
          )}

          {/* iamport */}
          {v.LogData.TID !== undefined && (
            <a
              href={`https://npg.nicepay.co.kr/issue/IssueLoader.do?TID=${v.LogData.TID}&type=0&InnerWin=Y`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn btn-sm btn-light mr-1">
                매출전표(아임포트)
              </button>
            </a>
          )}

          {/* toss */}
          {v.LogData.data &&
            v.LogData.data.card &&
            v.LogData.data.card.receiptUrl && (
              <a
                href={v.LogData.data.card.receiptUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn btn-sm btn-light mr-1">
                  매출전표(TOSS)
                </button>
              </a>
            )}

          {/* kakaopay */}
          {v.LogData.data && v.LogData.data.cid && !v.LogData.data.status && (
            <div>
              <a
                href={`https://pg-web.kakao.com/v1/confirmation/p/${
                  v.LogData.data.tid
                }/${sha256(
                  v.LogData.data.cid +
                    v.LogData.data.tid +
                    v.LogData.data.partner_order_id +
                    v.LogData.data.partner_user_id
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn btn-sm btn-light mr-1">
                  {v.LogData.data.status && v.LogData.data.status} 매출전표
                </button>
              </a>
              <a
                href={`https://pg-web.kakao.com/v1/cashreceipt/p/${
                  v.LogData.data.tid
                }/${sha256(
                  v.LogData.data.cid +
                    v.LogData.data.tid +
                    v.LogData.data.partner_order_id +
                    v.LogData.data.partner_user_id
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn btn-sm btn-light mr-1">
                  {v.LogData.data.status && v.LogData.data.status} 현금영수증
                </button>
              </a>
            </div>
          )}
        </span>
      ))}
    </div>
  );
}
