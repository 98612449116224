import { createContext } from "react";
import { NewSubscriptionInfo, TbAddress } from "./api";

export type DetailCtx = {
  data: {
    subscriptionData?: NewSubscriptionInfo;
    // TODO: 아래는 점점 없에기
    userData?;
    userAddress: TbAddress[];
    userCart?;
    itemList?: { ItemList: any[]; ItemOptionList: any[] };
    userNote?;
  };
  reload: {
    reloadSubscription: () => any;
    reloadAddresses: (reloadUser: boolean) => any;
  };
  actions: any;
};

export const DetailContext = createContext<DetailCtx>({
  data: {
    userAddress: [],
  },
  reload: {} as any,
  actions: {},
});
