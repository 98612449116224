export const ADMIN_AUTH_COOKIE = "_wat_ws";
export const BUTTON_SEARCH = {
  name: "검색",
  classNames: ["btn", "btn-primary", "btn-sm", "he-100"],
};
/* eslint-disable no-unused-vars */
export enum Payment {
  KakaoPayOnetime = 1,
  IamportCardOnetime = 2,
  IamportVBankOnetime = 3,
  IamportPhoneOnetime = 4,
  IamportPaycoOnetime = 5,
  NaverPayOnetime = 6,
  KakaoPaySubscription = 7,
  NicePaymentsCardSubscription = 8,
  IamportCardSubscription = 9,
  NaverPaySubscription = 10,
  TossOnetime = 11,
  TossSubscription = 12,
  TossVbankOnetime = 13,
  TossPhoneOnetime = 14,
  TossPayOnetime = 15,
  TossPaySubscription = 16,
}
/* eslint-disable no-unused-vars */
export const billingKeyCheckablePayments = [
  Payment.KakaoPaySubscription,
  Payment.NaverPaySubscription,
  Payment.TossPaySubscription,
];
