import React, { useState } from "react";
import ReactCalendar from "react-calendar";

const Calendar = ({
  name,
  classNames = ["form-control", "form-control-sm"],
  value = "",
  onInputChange,
  placeholder,
  onCalendarChange,
  calendarWrapperClassNames = ["calendar-wrapper"],
  inputWidth = "",
}) => {
  const [showCalendar, setShowCalendar] = useState(false);

  return (
    <>
      <input
        name={name}
        className={classNames.join(" ")}
        value={value}
        onFocus={() => setShowCalendar(true)}
        // onBlur 할 경우, 달력 Calendar 의 onChange 가 트리거되지 않음.
        // onBlur={() => setShowCalendar(false)}
        onChange={onInputChange}
        placeholder={placeholder}
        style={{ width: inputWidth }}
      />
      {showCalendar && (
        <div className={calendarWrapperClassNames.join(" ")}>
          <ReactCalendar
            onChange={(date) => {
              onCalendarChange(date);
              setShowCalendar(false);
            }}
            calendarType="US"
          />
          <button
            className="btn btn-sm btn-secondary btn-block mt-1"
            onClick={() => setShowCalendar(false)}
          >
            닫기
          </button>
        </div>
      )}
    </>
  );
};

export default Calendar;
