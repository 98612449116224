import React from "react";
import { Route, useRouteMatch } from "react-router-dom";

import Orders from "../pages/order/Orders";
import { OrdersSubscription } from "../pages/order/OrdersSubscription";
import OrdersAdd from "../pages/order/OrdersAdd";
import OrdersDetail from "../pages/order/OrdersDetail";
import OrdersCx from "../pages/order/OrdersCx";

const OrdersRoute = () => {
  const match = useRouteMatch();

  return (
    <>
      <Route
        path={`${match.path}/subscription`}
        component={OrdersSubscription}
      />
      <Route path={`${match.path}/add`} component={OrdersAdd} />
      <Route path={`${match.path}/cx`} component={OrdersCx} />
      <Route path={`${match.path}/detail/:orderid`} component={OrdersDetail} />
      <Route path={match.path} component={Orders} exact />
    </>
  );
};

export default OrdersRoute;
