import React from "react";
import { Route, useRouteMatch } from "react-router-dom";

import UsersDetail from "../pages/user/UsersDetail";

const UserRoute = () => {
  const match = useRouteMatch();

  return (
    <>
      <Route path={`${match.path}/detail/:uid`} component={UsersDetail} />
    </>
  );
};

export default UserRoute;
