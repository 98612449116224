import moment from "moment";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import { fromPairs } from "ramda";

import * as utils from "../../utils/Utils";
import {
  SELECT_IS_SUBSCRIBED,
  SELECT_ORDER_STATUS,
  SELECT_PAYMENT_ID,
  SELECT_ORDER_TYPE,
  CALENDAR_DATE_START,
  CALENDAR_DATE_END,
  INPUT_KEYWORD,
  SEARCH_COUNT,
  BUTTON_RETURN_ORDER,
  BUTTON_DOWN,
  SELECT_PER_PAGE,
} from "./constants/Orders";
import { BUTTON_SEARCH } from "../../constants";
import { ClaimReason, IsReturnWill_TYPE } from "../../Store";
import {
  ListViewPage,
  ListViewPageParam,
} from "../../components/ListViewSearchPage";
import { TablePresets } from "../../components/TablePresets";

const param: ListViewPageParam<any> = {
  defaultQueryParam: {
    nowPage: 1,
    perPage: 15,
  },
  fetchItems: utils.getOrderList,
  pluckItems: (r) => r.OrderList,
  searchArray: [
    {
      type: "SELECT",
      constant: SELECT_ORDER_STATUS,
    },
    {
      type: "SELECT",
      constant: SELECT_PAYMENT_ID,
    },
    {
      type: "SELECT",
      constant: SELECT_IS_SUBSCRIBED,
    },
    {
      type: "SELECT",
      constant: SELECT_ORDER_TYPE,
    },
    {
      type: "CALENDAR",
      constant: CALENDAR_DATE_START,
    },
    {
      type: "CALENDAR",
      constant: CALENDAR_DATE_END,
    },
    {
      type: "INPUT",
      constant: INPUT_KEYWORD,
    },
    {
      type: "BUTTON",
      constant: BUTTON_SEARCH,
      onClick: ({ setParam }) => () => setParam(true)({ nowPage: 1 }),
    },
    {
      type: "BUTTON",
      constant: BUTTON_RETURN_ORDER,
      onClick: ({ setParam }) => () =>
        setParam(true)({
          orderType: "4",
          orderStatus: "Requested-Return",
          nowPage: 1,
        }),
    },
    {
      type: "BUTTON",
      constant: BUTTON_DOWN,
      onClick: ({ state, setParam }) => () => {
        // items == orders
        down(state.items);
        setParam(true)();
      },
      show: (q) => q.orderStatus === "Requested-Return",
    },
    {
      type: "SELECT",
      constant: SELECT_PER_PAGE,
      show: (q) => q.orderStatus === "Requested-Return",
    },
  ],
  tableInfo: ({ searchParams }) =>
    TablePresets.OrderTable(searchParams.keyword),
  texts: {
    title: "주문 리스트",
    sub: "주문 리스트를 조회합니다.",
    cardTitle: "일반 주문 리스트",
  },
  searchCount: {
    param: SEARCH_COUNT,
    fetchCounts: utils.getOrderCount,
    pluckCounts: (r) => {
      const pairs = r.OrderCount.map(({ OrderStatus, Cnt }) => [
        OrderStatus,
        Cnt,
      ]);
      return fromPairs(pairs);
    },
    onClick: ({ setParam }) => (e) => setParam(true)({ orderStatus: e }),
  },
};

export default () => ListViewPage(param);

const down = (orders) => {
  if (orders.length === 0) {
    window.alert("처리할 데이터가 없습니다.");
    return;
  }

  const uniqueArr = orders.map((item) => ({
    접수일시: item.CancelRegDate
      ? moment(item.CancelRegDate).format("YYYY-MM-DD HH:mm:ss")
      : "",
    원주문결제일시: item.Origin_Order_ApprovedDate
      ? moment(item.Origin_Order_ApprovedDate).format("YYYY-MM-DD HH:mm:ss")
      : "",
    방문희망일시:
      item.CancelData && item.CancelData.wishDate
        ? item.CancelData && item.CancelData.wishDate
        : "",
    고객명: item.rFullName ? item.rFullName : "",
    원송장번호:
      moment(item.RegDate).add(90, "d") <= moment()
        ? item.rAddress1
          ? `${item.rAddress1} ${item.rAddress2}`
          : ""
        : item.CancelData && item.CancelData.trackNo
        ? item.CancelData.trackNo.toString()
        : item.rAddress1
        ? `${item.rAddress1} ${item.rAddress2}`
        : "",
    전화번호: item.rPhone ? item.rPhone : "",
    주소: item.rAddress1 ? `${item.rAddress1} ${item.rAddress2}` : "",
    반품제품: item.OptionItemNames ? item.OptionItemNames : "",
    접수내용: item.Reason ? ClaimReason[item.Reason] : "",
    회수여부: item.IsReturnWill ? IsReturnWill_TYPE[item.IsReturnWill] : "",
    반품사유: item.ReasonDetail ? item.ReasonDetail : "",
    자세한설명: item.Comment ? item.Comment : "",
    반품완료여부: item.Status ? (item.Status === "E" ? "완료" : "") : "",
    주문번호: item.OrderID ? item.OrderID : "",
  }));

  // XLSX 만들기
  const excelBuffer = XLSX.write(
    {
      Sheets: {
        returnOrder: XLSX.utils.json_to_sheet(uniqueArr),
      },
      SheetNames: ["returnOrder"],
    },
    {
      bookType: "xls",
      type: "array",
    }
  );
  const data = new Blob([excelBuffer], {
    type:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  });
  FileSaver.saveAs(
    data,
    `wisely_exported_return_order${moment().format("YYYYMMDD_HHmmss")}.xls`
  );

  orders.map((item) =>
    changeOrderStatus({
      unid: item.UNID,
      orderid: item.OrderID,
      orderstatus: "Return-Processing",
      note: "",
    })
  );
};

const changeOrderStatus = async (param) => {
  const getResult = await utils.changeOrderStatus({ ...param });
  return getResult;
};
