import React, { useContext } from "react";

import {
  SELECT_STATUS,
  SELECT_PAYMENT_ID,
  CALENDAR_SCHEDULE_AT,
  INPUT_KEYWORD,
} from "./constants/OrdersSubscription";
import { CellPresets } from "../../components/CellPresets";
import { ListViewPage } from "../../components/ListViewSearchPage";
import { BUTTON_SEARCH } from "../../constants";
import { PaymentName } from "../../components/const";
import { AppContext } from "../../index";

// TODO: 이거 제거(공통화)
function Payment({ EPID, PaymentID }) {
  if (PaymentID !== 0) {
    return (
      <>
        <div className="opacity-4">{EPID}</div>
        {PaymentName[PaymentID]}
      </>
    );
  }
}

export const OrdersSubscription = () => {
  const ctx = useContext(AppContext);
  return ListViewPage({
    defaultQueryParam: {
      nowPage: 1,
      perPage: 15,
    },
    fetchItems: ctx.wcApi.adminSubscription.getSubscriptionInfo,
    pluckItems: (r) => r.data.data.subscriptions,
    searchArray: [
      {
        type: "SELECT",
        constant: SELECT_STATUS,
      },
      {
        type: "SELECT",
        constant: SELECT_PAYMENT_ID,
      },
      {
        type: "CALENDAR",
        constant: CALENDAR_SCHEDULE_AT,
      },
      {
        type: "INPUT",
        constant: INPUT_KEYWORD,
      },
      {
        type: "BUTTON",
        constant: BUTTON_SEARCH,
        onClick: ({ setParam }) => () => setParam(true)({ nowPage: 1 }),
      },
    ],
    tableInfo: () => [
      CellPresets.subscription_id,
      {
        width: 180,
        name: "등록일시",
        cell: { field: "RegDate", fmt: "datetime" },
      },
      {
        width: 120,
        name: "다음결제일",
        cell: { field: "ScheduleAt", fmt: "date" },
      },
      {
        width: 80,
        name: "상태",
        cell: {
          field: "Status",
          fmt: "subscription_own_status",
          className: (v) => (v === "Y" ? "" : "opacity-5"),
        },
      },
      CellPresets.username,
      { width: 200, name: "결제수단", cell: { component: (s) => Payment(s) } },
      { width: 100, name: "카드명", cell: { field: "CardName" } },
      {
        width: 100,
        name: "카드끝자리",
        cell: { field: "CardNo", fmt: (v) => (v ? `**** ${v}` : undefined) },
      },
    ],
    texts: {
      title: "정기구매 리스트",
      sub: "정기구매 리스트를 조회합니다.",
      cardTitle: "정기구매 리스트",
    },
  });
};
