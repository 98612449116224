import React, { useState, useEffect, useRef, useContext } from "react";
import DaumPostcode from "react-daum-postcode";
import { FaRegWindowRestore, FaStarOfLife } from "react-icons/fa";
import { toast } from "react-toastify";
import _ from "lodash";
import * as utils from "../../utils/Utils";
import {
  ImageServerDomain,
  providerList,
  ClaimReasonDetail,
  PRODUCTS,
  CLAIM_REASON_GROUP,
} from "../../Store";

import TitleContent from "../../components/TitleContent";
import Loading from "../../components/Loading";
import InputFormat from "../../utils/InputFormat";
import { AppContext } from "../..";
import { withAutoToastCode } from "../../utils/Utils";
import {
  CreateAddressInDto,
  FetchItemOptionsOutDto,
  ItemOptionInDto,
} from "../../api/wc-api";

const Orders = ({ history }) => {
  const ctx = useContext(AppContext);
  const [keyword, setKeyword] = useState("");
  const handleChangeKeyword = (event) => {
    setKeyword(event.target.value);
  };

  // 기존 회원 조회
  const [userList, setUserList] = useState([] as any);
  const getSearchInfo = async () => {
    setUserList("loading" as any);
    const getResult = await utils.GetUserList({
      nowPage: 1,
      perPage: 100,
      keyword,
    });
    setUserList(getResult.UserList);
  };

  // 사용자 선택하기
  const [selectedUserName, setSelectedUserName] = useState(null as any);
  const [selectedUser, setSelectedUser] = useState<number>(null as any);
  const putUserinfo = (userInfo) => {
    setSelectedUser(userInfo.UNID);
    setSelectedUserName(userInfo.FullName);
    setUserTab("init");
    getUserAddressList(userInfo.UNID);
  };

  // 사용자 초기화
  const [userinfo, setUserinfo] = useState({
    fullName: "",
    email: "",
    pwd: "",
    phone: "",
    dob: "",
    gender: "",
    agreenotifications: "N",
  });
  const handleChangeUserinfo = (event) => {
    setUserinfo({
      ...userinfo,
      [event.target.name]:
        event.target.name === "phone" || event.target.name === "dob"
          ? event.target.value.split("/").join("").split("-").join("")
          : event.target.value,
    });
  };

  const [userTab, setUserTab] = useState("init");

  // 회원 등록하기
  const signup = withAutoToastCode(async () => {
    const result = await ctx.wcApi.adminAuth.signUp({
      ...userinfo,
      pwd: userinfo.phone,
      adSms: userinfo.agreenotifications,
      adEmail: userinfo.agreenotifications,
      serviceSms: userinfo.agreenotifications,
      serviceEmail: userinfo.agreenotifications,
    });

    if (result.data.code === "success") {
      const user = result.data.data;
      putUserinfo(user);
      setAddressInfo({
        ...addressInfo,
        fullName: user.FullName,
        phone: user.Phone,
        isDefault: "Y",
      });
    }

    return result;
  });

  const [getItemOptions, setItemOptions] = useState<FetchItemOptionsOutDto[]>(
    []
  );
  const getItemOptionList = async () => {
    const result = await ctx.wcApi.store.fetchItemOptions({
      // isFree: "N",
      isSubscription: "N", // 어드민에서 생성하는 주문은 1회주문밖에 없음.
      isSubsidiary: "N",
    });

    if (result.data.code === "success") {
      setItemOptions(result.data.data);
    }
  };

  const [ordertype, setOrderType] = useState(2);
  const selectOrderType = (event) => {
    setOrderType(event.target.value);
    if (event.target.value === "2") {
      setPaymentAmount(null);
    } else {
      setPaymentAmount(0);
    }

    // 주문사유 초기화
    setNote_brand("");
    setNote_reason("");
    setNote_product("");
    setNote_reason_detail("");
    setNote_reason_detail_write("");
  };

  // Note - 브랜드(와이즐리/오픈워크)
  const [note_brand, setNote_brand] = useState("");
  const selectNote_brand = (event) => {
    setNote_brand(event.target.value);
  };

  // Note - CX주문사유
  const [note_reason, setNote_reason] = useState("");
  const selectNote_reason = (event) => {
    setNote_reason(event.target.value);
    setNote_product("");
    setNote_reason_detail("");
    setNote_reason_detail_write("");
  };

  // Note - 제품(면도기/면도날/쉐이빙젤/애프터쉐이브)
  const [note_product, setNote_product] = useState("");
  const selectNote_product = (event) => {
    setNote_product(event.target.value);
    setNote_reason_detail("");
    setNote_reason_detail_write("");
  };

  // Note - CX주문사유 상세
  const [note_reason_detail, setNote_reason_detail] = useState("");
  const selectNote_reason_detail = (event) => {
    setNote_reason_detail(event.target.value);
    setNote_reason_detail_write("");
  };

  // Note - CX주문사유 상세 직접입력
  const [note_reason_detail_write, setNote_reason_detail_write] = useState("");
  const selectNote_reason_detail_write = (event) => {
    setNote_reason_detail_write(event.target.value);
  };

  // CX주문 만들때 가격 지정하기
  const [paymentAmount, setPaymentAmount] = useState(null as any);
  const handlePaymentAmount = (event) => {
    setPaymentAmount(event.target.value);
  };

  const [itemParams, setItemParams] = useState<ItemOptionInDto[]>([]);
  const handleItemParams = (optionId: number, mode: "plus" | "minus") => {
    const itemParam = itemParams.find((item) => item.optionId === optionId);

    if (!itemParam && mode === "plus") {
      const newItemParam: ItemOptionInDto = {
        optionId,
        quantity: 1,
      };
      setItemParams(itemParams.concat(newItemParam));
    }

    if (itemParam) {
      itemParam.quantity =
        mode === "plus" ? itemParam.quantity + 1 : itemParam.quantity - 1;
      setItemParams(
        itemParams
          .filter((item) => item.optionId !== optionId)
          .concat(itemParam)
      );
    }
  };

  // 주문 생성하기
  const [process, setProcess] = useState("normal");
  const [newOrderid, setNewOrderid] = useState<number>(null as any);
  const orderRegist = async () => {
    setProcess("process");

    if (paymentAmount === "") {
      setProcess("normal");
      alert("총 결제할 금액이 비어 있습니다.");
      return;
    }

    // validation 은 API 에서 걸러야 할 듯.
    if (itemParams.length === 0) {
      setProcess("normal");
      toast.error("상품이 존재하지 않습니다.", {
        autoClose: 3000,
      });
      return;
    }

    // 주소 생성은 별도의 액션을 통해서 생성하고 주문 생성 시에는 주문ID 만 넘겨야 깔끔할 것 같다..
    if (
      addressInfo &&
      (_.isEmpty(addressInfo.fullName) ||
        _.isEmpty(addressInfo.address1) ||
        _.isEmpty(addressInfo.city) ||
        _.isEmpty(addressInfo.postCode))
    ) {
      setProcess("normal");
      toast.error("주소 오류", {
        autoClose: 3000,
      });
      return;
    }

    // 주문사유 조합하기
    const datas = [
      note_brand,
      note_reason,
      note_product,
      note_reason_detail,
      note_reason_detail_write,
    ];
    let summary = "";
    datas.map((item, idx) => {
      if (item) {
        summary = summary + (idx !== 0 ? "-" : "") + item;
      }
      return summary;
    });

    const result = await ctx.wcApi.adminOrder.adminInitOnetimeOrder({
      unid: selectedUser,
      items: itemParams,
      ...(addressId ? { aid: addressId } : { address: addressInfo }),
      note: summary,
      orderType: String(ordertype),
    });

    if (result.data.code === "addressNotExists") {
      toast.error(
        "실패함(Err : 주소가 입력되지 않았거나, 기본배송지가 존재하지 않습니다.)",
        {
          autoClose: 3000,
        }
      );
      setProcess("normal");
      return;
    }

    if (result.data.code !== "success") {
      setProcess("normal");
      return;
    }

    const orderId = result.data.data.order.OrderID;
    // 반품 주문인 경우 주문의 상태를 반품신청 상태로 변경
    if ((ordertype as any) === "4") {
      await utils.changeOrderStatus({
        unid: selectedUser,
        orderid: orderId,
        orderstatus: "Requested-Return",
        note: "ADMIN",
      });
    }

    // 주문에 가격 지정하기
    setTimeout(() => {
      if (paymentAmount === null) {
        //
      } else if (paymentAmount !== "") {
        utils.resetOrderPrice(selectedUser, orderId, paymentAmount);
      }

      // 새로 생성한 주문번호
      setNewOrderid(orderId);
      setProcess("normal");
    }, 1000);
  };

  const [addressId, setAddressId] = useState<number | undefined>(undefined);

  /** daum 우편번호로 주소 검색하기 */
  const [postAreaStyle, setPostAreaStyle] = useState({ display: "none" });
  const [addressInfo, setAddressInfo] = useState<CreateAddressInDto>(
    null as any
  );
  const handleChangeAddressinfo = (event) => {
    setAddressInfo({
      ...addressInfo,
      [event.target.name]: event.target.value,
    });
  };

  // 우편번호
  const setPost = (data) => {
    setAddressInfo({
      ...addressInfo,
      postCode: data.zonecode,
      city: data.sido,
      address1: data.address,
    });
    setPostAreaStyle({ display: "none" });
  };

  // 선택된 회원의 배송지 가져오기
  const [userAddress, setUserAddress] = useState<any>(null);
  const getUserAddressList = async (UNID) => {
    const getResult = await utils.getUserAddressList(UNID);
    setUserAddress(getResult);
  };

  // 배송지 선택
  const selectAddress = (event) => {
    const getAddressInfo = userAddress.filter(
      (item) => parseInt(event.target.value) === item.AID
    );

    const selectedAddress = getAddressInfo[0];
    setAddressInfo({
      fullName: selectedAddress.FullName,
      postCode: selectedAddress.Postcode,
      city: selectedAddress.City,
      address1: selectedAddress.Address1,
      address2: selectedAddress.Address2,
      phone: selectedAddress.Phone,
      memo: selectedAddress.Memo,
    });

    setAddressId(selectedAddress.AID);
  };

  // UniqueKey 가져오기
  const getUniqueKey = async () => {
    const getResult = await utils.getUniqueKey();
    if (getResult) {
      setUserinfo({
        ...userinfo,
        email: `[GUEST]${getResult}@wisely.com`,
      });
    }
  };

  const selectedUserInput = useRef();
  const newUserInput = useRef<any>();

  // 현재 선택한 브랜드
  const [nowProviderID, setNowProviderID] = useState(100000000);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    getItemOptionList();
  }, []);

  useEffect(() => {
    if (newUserInput.current) {
      newUserInput.current.focus();
    }
  }, [userTab]);

  function getItemOptionInfo(itemOption: FetchItemOptionsOutDto) {
    return (
      <div className="widget-content p-1">
        <div className="widget-content-wrapper">
          <div className="widget-content-left mr-2">
            <img
              className="rounded-circle"
              src={
                itemOption.FileName
                  ? ImageServerDomain + itemOption.FileKey + itemOption.FileName
                  : "/assets/images/favicon.png"
              }
              alt=""
              style={{
                width: "40px",
                height: "40px",
              }}
            />
          </div>
          <div className="widget-content-left flex2">
            <div className="widget-heading">
              <span style={{ fontWeight: "bold" }}>
                {itemOption.OptionItemName}
              </span>
              {itemOption.ItemID === 101040000 && itemOption.Price === 0 && (
                <span
                  className="text-danger ml-1"
                  style={{
                    fontSize: "0.7em ",
                  }}
                >
                  무료증정
                </span>
              )}
              {itemOption.IsVisible === "N" && (
                <span className="badge badge-warning ml-1">미노출</span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <TitleContent
        title="주문 등록하기"
        subHeading="수동으로 새로운 주문을 등록합니다."
      />

      {/* PAGE BODY  */}
      <div className="row">
        <div className="col-lg-12">
          <div className="card mb-1">
            <div className="card-header text-primary">주문 등록하기</div>
            <div className="card-body">
              <div className="form-group position-relative">
                <div className="card-title mb-3">
                  <FaRegWindowRestore /> 회원정보
                  <button
                    className="btn btn-light btn-sm ml-1"
                    onClick={() => {
                      setSelectedUserName("");
                      setUserTab("newMember");
                    }}
                  >
                    신규회원 등록
                  </button>
                </div>
                <div>
                  <form
                    className="input-group"
                    onSubmit={(e) => {
                      e.preventDefault();
                      setUserTab("member");
                      getSearchInfo();
                    }}
                  >
                    <input
                      className="form-control"
                      type="text"
                      value={keyword}
                      placeholder="전화번호, 이메일, 이름으로 검색"
                      onChange={handleChangeKeyword}
                      ref={selectedUserInput as any}
                      style={{ minWidth: "300px" }}
                    />
                    <button className="btn btn-primary btn-sm ml-1 p-2">
                      기존회원 검색
                    </button>
                  </form>

                  <div>
                    {selectedUserName && (
                      <input
                        className="form-control mt-3"
                        type="text"
                        defaultValue={selectedUserName}
                        placeholder="회원을 입력해 주세요."
                        readOnly
                      />
                    )}
                  </div>
                </div>

                {userTab === "member" && (
                  <div className="mt-3">
                    <div>
                      {(userList as any) === "loading" ? (
                        <div className="card mb-3">
                          <div className="card-body">
                            <Loading />
                          </div>
                        </div>
                      ) : (
                        userList.length > 0 && (
                          <div className="card mb-3">
                            <div className="card-body">
                              <div className="table-responsive">
                                <table className="mb-0 table table-striped table-hover dataTable">
                                  <tbody>
                                    {userList.map((item, idx) => (
                                      <tr key={idx}>
                                        <td>{item.UNID}</td>
                                        <td>{item.FullName}</td>
                                        <td>{item.Email}</td>
                                        <td className="text-right">
                                          <button
                                            className="btn btn-sm btn-light"
                                            onClick={() => putUserinfo(item)}
                                          >
                                            선택
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}

                {userTab === "newMember" && (
                  <div className="mt-3">
                    <div className="input-group mb-2">
                      <FaStarOfLife
                        style={{
                          position: "absolute",
                          left: "-1.5em",
                          top: "1.8em",
                          fontSize: "0.5em",
                          color: "red",
                        }}
                      />
                      <input
                        className="form-control"
                        type="text"
                        name="fullName"
                        value={userinfo.fullName}
                        placeholder="이름"
                        onChange={handleChangeUserinfo}
                        required
                        ref={newUserInput}
                      />
                    </div>
                    <div className="input-group mb-2">
                      <FaStarOfLife
                        style={{
                          position: "absolute",
                          left: "-1.5em",
                          top: "1.8em",
                          fontSize: "0.5em",
                          color: "red",
                        }}
                      />
                      <InputFormat
                        formatType="phone"
                        className="form-control"
                        type="text"
                        name="phone"
                        value={userinfo.phone}
                        placeholder="전화번호 (010-1234-5678)"
                        onChange={handleChangeUserinfo}
                        required
                      />
                    </div>
                    <div className="input-group mb-2">
                      <FaStarOfLife
                        style={{
                          position: "absolute",
                          left: "-1.5em",
                          top: "1.8em",
                          fontSize: "0.5em",
                          color: "red",
                        }}
                      />
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        value={userinfo.email}
                        placeholder="이메일"
                        onChange={handleChangeUserinfo}
                        required
                      />
                      <button
                        className="btn btn-sm btn-light ml-1"
                        onClick={() => getUniqueKey()}
                      >
                        임시 이메일발급
                      </button>
                    </div>
                    <div className="input-group mb-2">
                      <InputFormat
                        formatType="birth"
                        className="form-control"
                        type="text"
                        name="dob"
                        value={userinfo.dob}
                        placeholder="생년월일 (92/04/11)"
                        onChange={handleChangeUserinfo}
                      />
                    </div>
                    <div className="input-group mb-2">
                      <select
                        className="form-control"
                        name="gender"
                        value={userinfo.gender}
                        onChange={handleChangeUserinfo}
                      >
                        <option value="">성별</option>
                        <option value="M">남성</option>
                        <option value="F">여성</option>
                      </select>
                    </div>
                    <div className="input-group mb-2">
                      <select
                        className="form-control"
                        name="agreenotifications"
                        value={userinfo.agreenotifications}
                        onChange={handleChangeUserinfo}
                      >
                        <option value="">동의여부</option>
                        <option value="Y">동의함</option>
                        <option value="N">동의안함</option>
                      </select>
                    </div>
                    <button
                      className="btn btn-sm btn-primary btn-block"
                      onClick={() => signup()}
                    >
                      등록하기
                    </button>
                  </div>
                )}
              </div>

              {/** 배송지 선택 */}
              {selectedUser && (
                <>
                  <div className="card-title pt-3">
                    <FaRegWindowRestore /> 주문타입
                  </div>
                  <div>
                    <select
                      className="form-control mb-2"
                      onChange={selectOrderType}
                    >
                      <option value="2">관리자주문</option>
                      <option value="3">CX주문</option>
                      {/* <option value="4">반품주문</option> */}
                    </select>
                  </div>

                  {(ordertype as any) === "3" && (
                    <>
                      <div className="card-title pt-3">
                        <FaRegWindowRestore /> 주문사유
                      </div>

                      <div>
                        <select
                          className="form-control mb-2"
                          onChange={selectNote_brand}
                          value={note_brand}
                        >
                          <option value="">-- 브랜드 선택 --</option>
                          {Object.keys(providerList).map((provider, idx) => (
                            <option key={idx} value={providerList[provider]}>
                              {providerList[provider]}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div>
                        <select
                          className="form-control mb-2"
                          onChange={selectNote_reason}
                          value={note_reason}
                        >
                          <option value="">-- 주문사유 선택 --</option>
                          <option value="고객대응">고객대응</option>
                          <option value="주소지변경">주소지변경</option>
                          <option value="제품불량">제품불량</option>
                          <option value="제품누락">제품누락</option>
                          <option value="오피킹">오피킹</option>
                          <option value="단순반품">단순반품</option>
                          <option value="파손접수">파손접수</option>
                          <option value="특별발송">특별발송</option>
                          <option value="분실의심">분실의심</option>
                        </select>
                      </div>

                      {note_reason === "제품불량" && (
                        <div>
                          <select
                            className="form-control mb-2"
                            onChange={selectNote_product}
                            value={note_product}
                          >
                            <option value="">-- 제품 선택 --</option>
                            {note_brand ? (
                              PRODUCTS[note_brand].map((item) => (
                                <option value={item}>{item}</option>
                              ))
                            ) : (
                              <option value="아직없음">아직없음</option>
                            )}
                          </select>
                        </div>
                      )}

                      {note_reason === "제품불량" && (
                        <div>
                          <select
                            className="form-control mb-2"
                            onChange={selectNote_reason_detail}
                            value={note_reason_detail}
                          >
                            <option value="">-- 주문사유상세 선택 --</option>

                            {note_product ? (
                              [
                                ...ClaimReasonDetail[
                                  CLAIM_REASON_GROUP[note_product]
                                ].map((item, idx) => (
                                  <option key={`claim-${idx}`} value={item}>
                                    {item}
                                  </option>
                                )),
                                <option value="품질관련(피드백)">
                                  품질관련(피드백)
                                </option>,
                                <option value="기타">기타</option>,
                              ]
                            ) : (
                              <option value="아직없음">아직없음</option>
                            )}
                          </select>
                        </div>
                      )}

                      {(note_reason_detail === "직접입력-기타" ||
                        note_reason_detail === "직접입력-개선" ||
                        note_reason_detail === "직접입력-피부트러블") && (
                        <>
                          <div>
                            <div className="form-group position-relative">
                              <div className="input-group mb-2">
                                <input
                                  className="form-control"
                                  type="text"
                                  value={note_reason_detail_write}
                                  placeholder="제품불량 사유"
                                  onChange={selectNote_reason_detail_write}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="card-title pt-3">
                        <FaRegWindowRestore /> 총 결제금액
                        <span
                          onClick={() => setPaymentAmount(0)}
                          className="badge badge-sm badge-dark ml-1"
                          style={{ cursor: "pointer" }}
                        >
                          무료
                        </span>
                        <span
                          onClick={() => setPaymentAmount(3000)}
                          className="badge badge-sm badge-dark ml-1"
                          style={{ cursor: "pointer" }}
                        >
                          2,500
                        </span>
                        <span
                          onClick={() => setPaymentAmount(3000)}
                          className="badge badge-sm badge-dark ml-1"
                          style={{ cursor: "pointer" }}
                        >
                          3,000
                        </span>
                        <span
                          onClick={() => setPaymentAmount(5000)}
                          className="badge badge-sm badge-dark ml-1"
                          style={{ cursor: "pointer" }}
                        >
                          5,000
                        </span>
                        <span
                          onClick={() => setPaymentAmount(5500)}
                          className="badge badge-sm badge-dark ml-1"
                          style={{ cursor: "pointer" }}
                        >
                          5,500
                        </span>
                      </div>
                      <div>
                        <div className="form-group position-relative">
                          <div className="input-group mb-2">
                            <input
                              className="form-control"
                              type="text"
                              name="paymentAmount"
                              value={paymentAmount}
                              placeholder="총 결제금액"
                              onChange={handlePaymentAmount}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="card-title pt-3">
                    <FaRegWindowRestore /> 배송지
                  </div>
                  <div>
                    {userAddress && userAddress.length > 0 && (
                      <select
                        className="form-control mb-2"
                        onChange={selectAddress}
                      >
                        <option>배송지 선택하기</option>
                        {userAddress.map((address, idx) => (
                          <option key={idx} value={address.AID}>
                            [{address.FullName}] {address.Address1}{" "}
                            {address.Address2}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>

                  <div className="form-group position-relative">
                    <div className="input-group mb-2">
                      <input
                        className="form-control"
                        type="text"
                        name="fullName"
                        value={addressInfo?.fullName ?? ""}
                        placeholder="이름"
                        onChange={handleChangeAddressinfo}
                      />
                    </div>
                    <div className="input-group mb-2">
                      <input
                        className="form-control"
                        type="text"
                        name="phone"
                        value={addressInfo?.phone ?? ""}
                        placeholder="전화번호"
                        onChange={handleChangeAddressinfo}
                      />
                    </div>
                    <div className="input-group mb-2">
                      <input
                        className="form-control"
                        type="text"
                        name="address1"
                        value={addressInfo?.address1 ?? ""}
                        placeholder="주소"
                        onClick={() => setPostAreaStyle({ display: "block" })}
                        readOnly
                      />
                    </div>
                    <div className="input-group mb-2">
                      <input
                        className="form-control"
                        type="text"
                        name="address2"
                        value={addressInfo?.address2 ?? ""}
                        placeholder="상세주소"
                        onChange={handleChangeAddressinfo}
                      />
                    </div>
                    <div>
                      <div style={{ maxWidth: "100%" }}>
                        <DaumPostcode
                          onComplete={setPost}
                          style={postAreaStyle}
                          animation
                        />
                      </div>
                    </div>
                    <div className="input-group mb-2">
                      <input
                        className="form-control"
                        type="text"
                        name="memo"
                        value={addressInfo?.memo ?? ""}
                        placeholder="배송메모"
                        onChange={handleChangeAddressinfo}
                      />
                    </div>
                  </div>
                </>
              )}

              {/** 상품선택 */}
              {selectedUser && (
                <>
                  <div className="card-title pt-3">
                    <FaRegWindowRestore /> 상품 선택하기
                  </div>
                  <div>
                    {getItemOptions && (
                      <div>
                        <div className="text-right mt-3">
                          <div role="group" className="m-2 btn-group">
                            {Object.keys(providerList).map((key, idx) => (
                              <button
                                key={idx}
                                className={`btn btn-shadow btn-primary ${
                                  parseInt(key) === nowProviderID && "active"
                                }`}
                                onClick={() => setNowProviderID(parseInt(key))}
                              >
                                {providerList[key]}
                              </button>
                            ))}
                          </div>

                          {showAll ? (
                            <button
                              className="btn btn-info mr-1"
                              onClick={() => setShowAll(false)}
                            >
                              노출 상품만 보기
                            </button>
                          ) : (
                            <button
                              className="btn btn-info mr-1"
                              onClick={() => {
                                setShowAll(true);
                                getItemOptionList();
                              }}
                            >
                              상품 모두보기
                            </button>
                          )}
                        </div>

                        <table className="table table-borderless table-hover table-sm mt-0 mb-0">
                          <tbody>
                            <div>
                              {getFilteredItemOptionList(
                                getItemOptions,
                                nowProviderID,
                                showAll
                              ).map((itemOption, idx) => (
                                <tr key={idx}>
                                  <td>{getItemOptionInfo(itemOption)}</td>
                                  <td>
                                    <div className="input-group input-group-sm">
                                      <div className="input-group-prepend">
                                        <button
                                          className="btn btn-light"
                                          onClick={() =>
                                            handleItemParams(
                                              itemOption.OptionID,
                                              "minus"
                                            )
                                          }
                                        >
                                          -
                                        </button>
                                      </div>
                                      <input
                                        className={`form-control text-center ${
                                          itemParams.find(
                                            (item) =>
                                              item.optionId ===
                                              itemOption.OptionID
                                          ) && "bg-light text-secondary"
                                        }`}
                                        type="text"
                                        name="Quantity"
                                        style={{ width: "30px" }}
                                        value={
                                          itemParams.find(
                                            (item) =>
                                              item.optionId ===
                                              itemOption.OptionID
                                          )?.quantity ?? 0
                                        }
                                      />
                                      <div className="input-group-append">
                                        <button
                                          className="btn btn-light"
                                          onClick={(e) =>
                                            handleItemParams(
                                              itemOption.OptionID,
                                              "plus"
                                            )
                                          }
                                        >
                                          +
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </div>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>

                  {newOrderid ? (
                    <button
                      className="btn btn-success btn-block mt-3 p-3"
                      onClick={() =>
                        history.push(`/orders/detail/${newOrderid}`)
                      }
                    >
                      주문 확인하기
                    </button>
                  ) : process === "normal" ? (
                    <button
                      className="btn btn-primary btn-block mt-3 p-3"
                      onClick={() => orderRegist()}
                    >
                      주문 등록하기
                    </button>
                  ) : (
                    <button className="btn btn-warning btn-block mt-3 p-3">
                      잠시 명상의 시간! 처리중입니다..
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function getFilteredItemOptionList(
  itemOptionList: FetchItemOptionsOutDto[],
  providerId: number,
  showAll: boolean
): FetchItemOptionsOutDto[] {
  return itemOptionList
    .filter((option) => providerId === getProviderIdFromItemId(option.ItemID))
    .filter((option) => (showAll ? true : option.IsVisible === "Y"));
}

function getProviderIdFromItemId(itemId: number): number {
  return Math.floor(itemId / 100000000) * 100000000;
}

export default Orders;
