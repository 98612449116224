import React, { useState } from "react";
import moment from "moment";
import { FaRegEdit } from "react-icons/fa";

import { DetailContext } from "../../../DetailContext";
import * as utils from "../../../utils/Utils";

const UserMemo = () => {
  const [message, setMessage] = useState("");
  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
  };

  // 메모 작성하기
  const setNote = async (unid, actions) => {
    if (message.length < 1) {
      alert("내용이 없습니다.");
      return;
    }
    const getResult = await utils.setNote({
      memoid: memoid,
      unid: unid,
      type: "Admin",
      orderid: "",
      memo: message,
    });

    // 갱신하기
    if (getResult) {
      actions.getUserNote(unid);
      setMessage("");
      setMemoid("");
    }
  };

  // 메모 수정하기 준비
  const [memoid, setMemoid] = useState("");
  const modifyNote = (memoid, memo) => {
    setMemoid(memoid);
    setMessage(memo);
  };

  return (
    <DetailContext.Consumer>
      {({ data, actions }) =>
        data.userData && (
          <>
            <div className="card mb-1">
              <div className="card-header text-primary">고객메모</div>
              <div className="card-body">
                <ul
                  className="list-group mb-3"
                  style={{ overflow: "auto", maxHeight: "265px" }}
                >
                  {data.userNote &&
                    data.userNote.NoteList.map((item, idx) => (
                      <li key={idx} className="list-group-item p-2">
                        <div className="widget-content p-0">
                          <div className="widget-content-outer">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left">
                                <div className="ml-2">
                                  {item.Memo.toString()
                                    .split("\n")
                                    .map((word, idx2) => (
                                      <span key={idx2}>
                                        {word}
                                        <br />
                                      </span>
                                    ))}
                                </div>
                              </div>
                              <div className="widget-content-right">
                                <div
                                  style={{
                                    fontSize: "0.7em",
                                    textAlign: "center",
                                    width: "70px",
                                  }}
                                >
                                  {item.ModifiedDate
                                    ? moment(item.ModifiedDate).format(
                                        "YYYY-MM-DD HH:mm:ss"
                                      )
                                    : moment(item.RegDate).format(
                                        "YYYY-MM-DD HH:mm:ss"
                                      )}
                                  <div>{item.FullName}</div>
                                  <button
                                    className="btn btn-sm btn-link pb-0"
                                    onClick={() =>
                                      modifyNote(item.MemoID, item.Memo)
                                    }
                                  >
                                    <FaRegEdit />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
                <div>
                  <textarea
                    className="form-control mb-2"
                    name="message"
                    value={message}
                    onChange={handleChangeMessage}
                    placeholder="메모를 남겨 주세요."
                    style={{ width: "100%", height: "60px", fontSize: "1em" }}
                    required
                  />
                  <div className="text-right">
                    <button
                      className="btn btn-light btn-sm"
                      onClick={() =>
                        setNote(data.userData.UserInfo.UNID, actions)
                      }
                    >
                      메모 남기기
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
    </DetailContext.Consumer>
  );
};

export default UserMemo;
