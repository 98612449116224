import React from "react";
import * as utils from "../../../utils/Utils";
import { TypeReason, CancelReason } from "../../../Store";
import { DetailCtx } from "../../../DetailContext";
import {
  DetailViewCardParam,
  DetailViewCard,
} from "../../../components/DetailViewCard";

const param: DetailViewCardParam = {
  card: {
    type: "list",
    bodyMaxHeight: "360px",
  },
  headerInfo: {
    title: "고객서비스변경내역",
    buttons: ["refresh"],
  },
  getData: (ctx: DetailCtx) =>
    utils.getUserServiceChangeLog(ctx?.data.userData?.UserInfo.UNID),
  dataName: "UserServiceChangesLog",
  cardInfo: [
    {
      name: "시간",
      width: 170,
      cell: {
        field: "RegDate",
        fmt: "datetime",
      },
    },
    {
      name: "TYPE",
      width: 100,
      cell: {
        field: "Type",
        fmt: "service_type",
      },
    },
    {
      name: "내용",
      cell: {
        field: (d, ctx: DetailCtx) =>
          d.CancelData
            ? matchDataWithCancelReasons(d, ctx.data.itemList)
            : makeCancelReason(d),
      },
    },
  ],
};

export default () => DetailViewCard(param);

function matchDataWithCancelReasons(data, productList) {
  const { CancelData, Comment } = data;

  return (
    <>
      {data &&
        CancelReason.map((reason, _) => {
          const { code, text, optionList } = reason;

          return Object.keys(CancelData)
            .sort()
            .map((dataCode, i) => (
              <>
                {code === dataCode && (
                  <>
                    <div key={i}>
                      {text === "기타"
                        ? "기타 : " + CancelData[dataCode]
                        : text}
                    </div>

                    {
                      // 제품이 남았을 때 제품명 가져오기 위한 매칭
                      code === "Q4" && getProductName(CancelData, productList)
                    }

                    {
                      // 하위 선택 항목이 있을 때를 위한 매칭
                      optionList &&
                        matchDataWithSubOptions(optionList, CancelData)
                    }
                  </>
                )}
              </>
            ));
        })}

      <div className="mt-1">{Comment}</div>
    </>
  );
}

function getProductName(cancelData, productList) {
  return Object.keys(cancelData)
    .sort()
    .map((dataCode3, _) =>
      productList.ItemOptionList.filter(
        (product, _) => product.OptionID.toString() === dataCode3
      ).map((filteredProduct, index) => (
        <div key={index} className="ml-3">
          {filteredProduct.ItemName}
        </div>
      ))
    );
}

function matchDataWithSubOptions(optionList, cancelData) {
  return optionList.map((list, _) => {
    const { options, text } = list;
    let optionTitle = "";

    return options.map((option, _) =>
      Object.keys(cancelData)
        .sort()
        .map((dataCode2, i) => {
          const { code } = option;

          if (code === dataCode2) {
            const result = (
              <div key={i} className="ml-3">
                <div>{optionTitle !== text && text}</div>
                <div className="ml-3">
                  {text === "기타" ? "기타 : " + cancelData[dataCode2] : text}
                </div>
              </div>
            );

            optionTitle = text;

            return result;
          }

          return <></>;
        })
    );
  });
}

function makeCancelReason(data) {
  const { Reason, Type, ReasonDetail, Comment } = data;

  return (
    <>
      {data &&
        Reason.toString()
          .split("|")
          .map((reason, i) => <div key={i}>{TypeReason[Type][reason]}</div>)}

      {data && (
        <>
          <div className="mt-1">
            <strong>{ReasonDetail && JSON.stringify(ReasonDetail)}</strong>
          </div>

          <div className="mt-1">{Comment}</div>
        </>
      )}
    </>
  );
}
