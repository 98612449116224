export const INPUT_KEYWORD = {
  name: "keyword",
  classNames: ["form-control", "form-control-sm"],
  placeholder: "전화번호, 이메일, 이름",
};

export const SUBSCRIPTION_STATUS = {
  I: "초기화",
  Y: "활성",
  N: "비활성",
  X: "해지",
};
