import React, { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import XLSX from "xlsx";
import FileSaver from "file-saver";
import { ApiCallFormData } from "../../Store";
import Loading from "../../components/Loading";
import TitleContent from "../../components/TitleContent";
import { TransformDeliveryListOutDto } from "../../api/wc-api";

const DeliveryDivideExport = () => {
  const [deliverListFile, setDeliveryListFile] = useState<{
    file: File;
  }>();
  const [loading, setLoading] = useState(false);

  const insertDeliveryListFile = (event) => {
    const file = event.target.files[0];

    setDeliveryListFile({
      file,
    });
  };

  const processRegist = async () => {
    if (!deliverListFile?.file) {
      console.log("파일없음");
      return;
    }

    const formData = new FormData();
    formData.append("file", deliverListFile.file);

    setLoading(true);

    const result = await ApiCallFormData(
      "/admin-delivery/transform-delivery-list",
      formData
    ).catch((err) => {
      if (err.isAxiosError && err.response.status === 413) {
        return err.response;
      }
      throw err;
    });

    if (result.data.code === "success") {
      const data = result.data.data as TransformDeliveryListOutDto[];

      down(data[0]);
      down(data[1]);

      toast.success("성공함", { autoClose: 3000 });
    } else {
      toast.error(result.data.data.message, {
        autoClose: 3000,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <TitleContent
        title="발주서 수정"
        subHeading="발주서 원본을 HY용, 다원용 발주서 2개의 파일로 분리합니다."
      />

      {/* PAGE BODY  */}
      <div className="row">
        <div className="col-lg-12">
          <div className="card mb-3">
            <div className="card-header">발주서 수정</div>
            <div className="card-body">
              <div className="mt-3 mb-3">
                <div
                  className="mb-2"
                  style={{ fontSize: "1em", fontWeight: "bold" }}
                >
                  * csv 파일 양식
                </div>
                <table
                  className="mb-3 table table-striped table-hover dataTable"
                  style={{ maxWidth: "500px", border: "3px solid #ddd" }}
                >
                  <thead>
                    <tr>
                      <th>출하의뢰번호</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>DN6468838</td>
                    </tr>
                    <tr>
                      <td>DN6468841</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="mt-3 mb-3">&nbsp;</div>

              <div className="mt-3 mb-3">
                <div className="input-group">
                  <input
                    type="file"
                    onChange={(e) => insertDeliveryListFile(e)}
                    className="form-control-file"
                  />
                  <button
                    className="btn btn-primary mt-3 mb-3"
                    onClick={() => processRegist()}
                  >
                    등록하기
                  </button>
                </div>
              </div>
              {loading ? (
                <div className="mb-3 mt-3">
                  <Loading />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryDivideExport;

const down = (deliveryData: TransformDeliveryListOutDto) => {
  console.log("deliveryData: ", deliveryData);
  if (deliveryData.deliveryItemList.length < 1) {
    window.alert("처리할 데이터가 없습니다.");
    return;
  }

  const unique = ["WISELY"];
  const uniqueArr = [] as any[];

  deliveryData.deliveryItemList.map((item: any) => {
    console.log("item: ", item);
    uniqueArr.push({
      "몰명(또는 몰코드)": item.BrandName,
      출하의뢰번호: item.DCID,
      출하의뢰항번: item.ExportItemNo,
      고객주문번호: item.OrderID,
      상품명: item.OptionItemName,
      제품코드: item.ProductCode.toString(),
      주문수량: item.Quantity,
      주문자명: item.SenderFullName,
      주문자연락처1: "",
      주문자연락처2: "",
      수취인명: item.RecipientFullName,
      수취인연락처1: item.RecipientPhone,
      수취인연락처2: item.RecipientPhone,
      수취인우편번호: item.RecipientPostcode,
      수취인주소1: item.RecipientAddress,
      수취인주소2: "",
      배송메시지: item.Memo,
      송장번호: "",
      택배사명: "",
      브로셔타입: item.DeliveryType,
      포함된상품수: item.ItemRowCount,
      결제일시: item.ApprovedDate
        ? moment(item.ApprovedDate).format("YYYY-MM-DD HH:mm:ss")
        : item.ApprovedDate,
    });
    return null;
  });

  // 배송타입 순서로 sheet를 만들기 위해 정렬 후 sheet별 XLSX 데이터 생성
  const resultData: any = {};
  resultData.WISELY = XLSX.utils.json_to_sheet(uniqueArr);

  // XLSX 만들기
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xls";
  const wb = { Sheets: resultData, SheetNames: unique };
  const excelBuffer = XLSX.write(wb, { bookType: "xls", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(
    data,
    `wisely_exported_${moment().format("YYYYMMDD_HHmmss")}_${
      deliveryData.warehouseType
    }_${fileExtension}`
  );
};
