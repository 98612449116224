// SELECT ~ name / classNames / options
// CALENDAR ~ name / placeholder
// INPUT ~ name / classNames / placeholder
// BUTTON ~ name / classNames

export const SELECT_ORDER_STATUS = {
  name: "orderStatus",
  classNames: ["form-control", "form-control-sm"],
  options: [
    { "": "주문상태" },
    { Completed: "결제완료" },
    { Cancelled: "결제취소" },
    { Processing: "결제진행중" },
    { "Awaiting-vbank": "가상계좌입금대기중" },
    { "Cancelled-vbank": "가상계좌결제취소" },
    { Failed: "결제실패" },
    { "On-Hold": "보류" },
    { "Pending-cancel": "보류취소" },
    { Pending: "대기중" },
    { "Partially-Refunded": "부분환불" },
    { "Requested-Refund": "환불요청" },
    { Refunded: "환불됨" },
    { "Requested-Return": "반품신청" },
    { "Return-Processing": "반품진행중" },
    { Returned: "반품완료" },
    { "Requested-Exchange": "교환신청" },
    { "Exchange-Processing": "교환진행중" },
    { Exchanged: "교환완료" },
    { Fraud: "부정결제" },
    { Initialized: "주문생성" },
    { "Awaiting-Subscription": "정기결제대기중" },
    { "Initialized-EasyPayment": "간편결제주문초기화" },
    { Paid: "[배송]결제완료" },
    { "Order-Processing": "[배송]배송접수" },
    { "Out-for-Delivery": "[배송]배송중" },
    { Delivered: "[배송]배송완료" },
  ],
};

export const SELECT_PAYMENT_ID = {
  name: "paymentID",
  classNames: ["form-control", "form-control-sm"],
  options: [
    { "": "결제수단" },
    { 1: "카카오페이" },
    { 2: "신용/체크카드" },
    { 3: "무통장입금/가상계좌" },
    { 4: "휴대폰소액결제" },
    { 5: "페이코" },
    { 6: "네이버페이" },
    { 7: "[정기]카카오페이" },
    { 8: "[정기-나이스]신용/체크카드" },
    { 9: "[정기-아임포트]신용/체크카드" },
    { 10: "[정기]네이버페이" },
  ],
};

export const SELECT_IS_SUBSCRIBED = {
  name: "isSubscribed",
  classNames: ["form-control", "form-control-sm"],
  options: [{ "": "정기여부" }, { Y: "정기" }, { N: "일반" }],
};

export const SELECT_ORDER_TYPE = {
  name: "orderType",
  classNames: ["form-control", "form-control-sm"],
  options: [
    { "": "주문타입" },
    { 1: "일반주문" },
    { 2: "관리자주문" },
    { 3: "CX주문" },
    { 4: "반품주문" },
    { 0: "기타주문" },
  ],
};

export const CALENDAR_DATE_START = {
  name: "date_start",
  placeholder: "시작날짜",
  inputWidth: "100px",
};

export const CALENDAR_DATE_END = {
  name: "date_end",
  placeholder: "종료날짜",
  inputWidth: "100px",
};

export const INPUT_KEYWORD = {
  name: "keyword",
  classNames: ["form-control", "form-control-sm"],
  placeholder: "전화,메일,이름,TID 검색",
};

export const SEARCH_COUNT = {
  Completed: "결제완료",
  "Order-Processing": "배송접수",
  "Out-for-Delivery": "배송중",
  "On-Hold": "보류",
  "Requested-Refund": "환불요청",
  "Requested-Return": "반품신청",
  "Return-Processing": "반품진행중",
  Returned: "반품완료",
  "Requested-Exchange": "교환신청",
  "Exchange-Processing": "교환진행중",
  Exchanged: "교환완료",
};

export const BUTTON_RETURN_ORDER = {
  name: "반품주문보기",
  classNames: ["btn", "btn-light", "btn-sm", "he-100"],
};

export const BUTTON_DOWN = {
  name: "다운로드",
  classNames: ["btn", "btn-danger", "btn-sm", "he-100"],
};

export const SELECT_PER_PAGE = {
  name: "perPage",
  classNames: ["form-control", "form-control-sm"],
  options: [
    { 15: "15개씩" },
    { 30: "30개씩" },
    { 50: "50개씩" },
    { 100: "100개씩" },
    { 150: "150개씩" },
    { 300: "300개씩" },
    { 500: "500개씩" },
  ],
};
