/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type String = object;

export interface NoteDto {
  MemoID: number;
  UNID: number;
  Type: string;
  Memo: string;
  AdminUNID: number;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
}

export interface NoteCreateInDto {
  unid: number;
  type: string;
  memo: string;
}

export interface NoteUpdateInDto {
  memoId: number;
  unid: number;
  memo: string;
}

export type Boolean = object;

export interface NoteDeleteInDto {
  memoId: number;
  unid: number;
}

export interface GetCartMetaInfoOutDto {
  itemCount: number;
}

export interface CartItemsOutDto {
  OptionID: number;
  Quantity: number;
}

export interface CartOutDto {
  CartItems: CartItemsOutDto[];
  Type: string;
  Cycle?: number;
  Term?: string;
}

export interface PriceInfo {
  shippingFee: number;
  additionalShippingFee: number;
  discountAmount: number;
  itemPriceAmount: number;
  totalAmount: number;
  freeShippingAmount: number;
  remainingFreeShippingAmount: number;
}

export interface ItemsInfoDto {
  optionId: number;
  itemName: string;
  optionDescription: string;
  filePath: string;
  categoryName: string;
  optValue: string;
  optSubvalue: string;
  description: string;
  quantity: number;
  price: number;
  amount: number;
}

export interface CheckoutOutDto {
  priceInfo: PriceInfo;
  itemsInfo: ItemsInfoDto[];
}

export interface CartCheckoutDto {
  cart: CartOutDto;
  checkoutInfo: CheckoutOutDto;
}

export interface GetCartOutDto {
  onetime: CartCheckoutDto;
  subscription: CartCheckoutDto;
}

export interface ItemOptionInDto {
  optionId: number;
  quantity: number;
}

export interface GetCartPriceOutDto {
  type: "onetime" | "subscription";
  items: ItemOptionInDto[];
}

export interface AddCartItemInDto {
  type: "onetime" | "subscription";
  items: ItemOptionInDto[];
}

export interface CartItemInDto {
  type: "onetime" | "subscription";
  items: ItemOptionInDto[];
}

export interface DeleteCartItemInDto {
  type: "onetime" | "subscription";
  items: number[];
}

export interface GetSubscriptionCartInfoOutDto {
  subscribingBrands: number[];
  nextScheduledDate: string;
}

export interface SubscriptionOutDto {
  BaseCycle: number;
  BaseTerm: string;

  /** @format date-time */
  ScheduleAt: string;
  Status: string;
}

export interface SubscriptionItemsOutDto {
  ItemName: string;
  OptionDescription: string;
  OptionID: number;
  Quantity: number;
}

export interface GetSubscriptionOutDto {
  subscription: SubscriptionOutDto;
  subscriptionItems: SubscriptionItemsOutDto[];
}

export interface AdminSubscriptionInfoOutDto {
  SID: number;

  /** @format date-time */
  RegDate: string;

  /** @format date-time */
  ScheduleAt: string;
  Status: string;
  FullName: string;
  Phone: string;
  Email: string;
  PaymentID: number;
  CardName: string;
  CardNo: string;
}

export interface PaginationOutDto {
  totalCount: object;
  currentPage: object;
  displayPage: object;
  displayRow: object;
  range: object;
  rangeSize: object;
  startRowNum: object;
  endRowNum: object;
  totalPage: object;
  beginPage: object;
  endPage: object;
  hasPrev: object;
  hasNext: object;
}

export interface AdminGetSubscriptionInfoOutDto {
  subscriptions: AdminSubscriptionInfoOutDto[];
  pagination: PaginationOutDto;
}

export interface AdminGetSubscriptionInfoInDto {
  status?: string;
  paymentId?: number;
  scheduleAt?: string;
  keyword?: string;
  nowPage: number;
  perPage: number;
}

export interface TbOrderAddress {
  OAID: number;
  OrderID: number;
  AID: number;
  UNID: number;
  FullName: string;
  Phone: string;
  Postcode: string;
  Country: string;
  City: string;
  Address1: string;
  Address2?: string;
  Memo?: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
  Order?: TbOrder;
}

export interface TbOrderVBank {
  NID: number;
  OrderID: number;
  BankCode: string;
  BankName: string;
  BankNumber: string;
  BankDate: string;
  BankHolder?: string;
  BankIssuedAt: string;
  Status: string;

  /** @format date-time */
  RegDate: string;
  Order?: TbOrder;
}

export interface TbCouponMain {
  CMID: number;
  CouponName: string;
  CodeType: string;
  CommonCode: string;
  Description?: string;
  ConditionDescription: string;
  ValidityType: string;
  ValidityDay?: number;
  BenefitDescription: string;
  MinimumPrice: number;
  OptionIDList: string;
  MaxCount?: number;
  DiscountType: string;
  DiscountAmount: number;
  DiscountPercent: number;
  MaxDiscountAmount: number;
  ShippingFee: string;
  AdditionalShippingFee: string;
  AdminUNID?: number;
  Status: string;

  /** @format date-time */
  StartDate: string;

  /** @format date-time */
  EndDate: string;

  /** @format date-time */
  ModifiedDate: string;

  /** @format date-time */
  RegDate: string;
}

export interface TbCoupon {
  CID: number;
  Code: string;
  CMID: number;
  CouponType: string;
  DiscountRate?: number;
  OrderID?: number;
  UNID?: number;
  Status: string;
  IsActivated: string;
  AdminUNID?: number;
  Note?: string;

  /** @format date-time */
  StartDate: string;

  /** @format date-time */
  ExpiryDate: string;

  /** @format date-time */
  UsedDate?: string;

  /** @format date-time */
  IssuedDate: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  Regdate: string;
  Order?: TbOrder;
  CouponMain?: TbCouponMain;
}

export interface TbDeliveryItems {
  NID: number;
  DID: number;
  OrderID: number;
  OrderItemID?: number;
  ItemID: number;
  OptionID: number;
  Quantity: number;
  Status: string;
  AdminUNID?: number;
  IsSubsidiary: string;
  Note?: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
  Delivery?: TbDelivery;
}

export interface TbDelivery {
  DID: number;
  DCID?: string;
  UNID: number;
  OAID?: number;
  UserGroup?: string;
  NextUserGroup?: string;
  DeliveryType?: string;
  CarrierCode?: string;
  CarrierName?: string;
  TrackNo?: string;
  DeliveryStatus: string;

  /** @format date-time */
  DeliveredDate?: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
  DeliveryOrders?: TbDeliveryOrders[];
  DeliveryItems?: TbDeliveryItems[];
}

export interface TbDeliveryOrders {
  DOID: number;
  OrderID: number;
  UNID: number;
  DID: number;
  DMID?: number;
  MasteryType: string;
  Status: string;

  /** @format date-time */
  RegDate: string;
  Order?: TbOrder;
  Delivery?: TbDelivery;
}

export interface TbCategory {
  ID: number;
  ParentsCategoryID?: number;
  CategoryName: string;
  Description?: string;
  Depth: number;
  Status: string;

  /** @format date-time */
  RegDate: string;

  /** @format date-time */
  ModifiedDate: string;
  CategoryItems?: TbCategoryItem[];
  ChildCategories?: TbCategory[];
  ParentCategory?: TbCategory;
}

export interface TbCategoryItem {
  ID: number;
  CategoryID?: number;
  ItemID?: number;

  /** @format date-time */
  RegDate: string;

  /** @format date-time */
  ModifiedDate: string;
  Item?: TbItemV2;
  Category?: TbCategory;
}

export interface TbOptionValueMap {
  ID: number;
  ItemID: number;
  OptionID: number;
  OptionValue1?: number;
  OptionValue2?: number;
  OptionValue3?: number;
  OptionValue4?: number;
  OptionValue5?: number;
  OptionValue6?: number;
  IsSubscribed: string;
  Status: string;

  /** @format date-time */
  RegDate: string;

  /** @format date-time */
  ModifiedDate: string;
  ItemOption?: TbItemOptionV2;
}

export interface TbItemOptionV2 {
  OptionID: number;
  ItemID: number;
  OptionItemName: string;
  SKU: string;
  ProductCode: string;
  BarCode: string;
  OptKey?: string;
  OptValue?: string;
  OptSubValue?: string;
  OptKey2?: string;
  OptValue2?: string;
  OptSubValue2?: string;
  OptKey3?: string;
  OptValue3?: string;
  OptSubValue3?: string;
  Price?: number;
  PriceTax?: number;
  Width?: number;
  Length?: number;
  Height?: number;
  Weight?: number;
  Stock?: number;
  IsSubsidiary: string;
  FileKey?: string;
  FileName?: string;
  IsVisible: string;
  SubscriptionType: string;
  SubscriptionDisplay: string;
  OptionStatus: string;
  OptionDescription?: string;

  /** @format date-time */
  DiscountStartDate?: string;

  /** @format date-time */
  DiscountEndDate?: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
  OptionValueMap?: TbOptionValueMap;
  Item?: TbItemV2;
}

export interface TbOptionValue {
  ID: number;
  OID: number;
  OptionValueName: string;
  ImageURL?: string;

  /** @format date-time */
  RegDate: string;

  /** @format date-time */
  ModifiedDate: string;
  Option?: TbOption;
}

export interface TbOption {
  ID: number;
  ItemID: number;
  OptionKey: string;
  Position: number;
  Description?: string;

  /** @format date-time */
  RegDate: string;

  /** @format date-time */
  ModifiedDate: string;
  Item?: TbItemV2;
  OptionValues?: TbOptionValue[];
}

export interface TbItemImageV2 {
  ID: number;
  ItemID: number;
  ImageName: string;
  ImageURL: string;
  Status: string;
  OrderNo: number;
  ImageType?: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate?: string;
  Item?: TbItemV2;
}

export interface TbItemV2 {
  ItemID: number;
  ProviderID?: number;
  ShippingID: number;
  OrderNo: number;
  CategoryName?: string;
  ModalType?: string;
  ItemName: string;
  DetailInfo1: string;
  DetailInfo2: string;
  Description?: string;
  RegularPrice: number;
  DiscountPrice?: number;
  SubscriptionPrice?: number;
  FileKey?: string;
  FileName?: string;
  Status: string;
  IsVisible: string;
  SubscriptionDisplay: string;

  /** @format date-time */
  PublishingDate?: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
  CategoryItem?: TbCategoryItem;
  ItemOptions?: TbItemOptionV2[];
  Options?: TbOption[];
  ItemImages?: TbItemImageV2[];
}

export interface TbOrderItems {
  OrderItemID: number;
  OrderID: number;
  ItemID: number;
  OptionID: number;
  Quantity: number;
  Price: number;
  Tax: number;

  /** @format date-time */
  RegDate: string;
  Order?: TbOrder;
  Item?: TbItemV2;
  ItemOption?: TbItemOptionV2;
}

export interface TbPaymentV2 {
  PaymentID: number;
  OrderNo: number;
  PaymentName: string;
  DisplayName?: string;
  ImgPath?: string;
  PaymentType: string;
  IsEscrow: string;
  IsDirect: string;
  GatewayName: string;
  Description?: string;
  Status: string;
}

export interface TbOrder {
  OrderID: number;
  PaymentID?: number;
  UNID: number;
  AID?: number;
  OrderCID: string;
  TransactionID?: string;
  UserIP: string;
  CacheKey: string;
  OrderType?: string;
  OrderName: string;
  ItemCount: number;
  DiscountItemCount?: number;
  ShippingFee: number;
  AdditionalShippingFee?: number;
  TaxAmount?: number;
  TotalAmount: number;
  ItemPriceAmount: number;
  DiscountAmount: number;
  BonusAmount?: number;
  RefundAmount?: number;
  IsSubscribed: string;
  SubscriptionID?: number;
  PaymentStatus: string;
  OrderStatus: string;
  Note?: string;
  AdminUNID?: number;
  LinkedOrderID?: number;
  ReserveID?: string;

  /** @format date-time */
  ApprovedDate?: string;

  /** @format date-time */
  CancelDate?: string;

  /** @format date-time */
  RefundDate?: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
  OrderAddress?: TbOrderAddress;
  VBank?: TbOrderVBank;
  Coupon?: TbCoupon;
  DeliveryOrders?: TbDeliveryOrders;
  OrderItems?: TbOrderItems[];
  Payment?: TbPaymentV2;
}

export interface InitOrderOutDto {
  order: TbOrder;
  payments: TbPaymentV2[];
  checkoutInfo: CheckoutOutDto;
}

export interface OnetimeInitOrderInDto {
  items: ItemOptionInDto[];
  couponIds?: number[];
  aid?: number;
  utmData: object;
}

export interface CouponParam {
  cid: number;
  token: string;
}

export interface SubscriptionInitOrderInDto {
  items: ItemOptionInDto[];
  cycle: number;
  coupon?: CouponParam;
  couponIds?: number[];
  aid?: number;
  utmData: object;
}

export interface TrialInitOrderInDto {
  trialItems: ItemOptionInDto[];
  subscriptionItems: ItemOptionInDto[];
  cycle: number;
  tsid: number;
  providerId: number;
  couponIds?: number[];
  aid?: number;
  utmData: object;
}

export interface CouponMainOutDto {
  CMID: number;
  CouponName: string;
  CodeType: string;
  CommonCode: string;
  Description?: string;
  ValidityType: string;
  ValidityDay?: number;
  MinimumPrice: number;
  OptionIDList: string;
  MaxCount?: number;
  DiscountType: string;
  DiscountAmount: number;
  DiscountPercent: number;
  MaxDiscountAmount: number;
  ShippingFee: string;
  AdditionalShippingFee: string;

  /** @format date-time */
  StartDate: string;

  /** @format date-time */
  EndDate: string;

  /** @format date-time */
  RegDate: string;
}

export interface AvailableCouponOutDto {
  EndDate: string;
  RemainDay: number;
  CouponMain: CouponMainOutDto;
  IsAvailable: boolean;
  CID: number;
  Code: string;
  Status: string;

  /** @format date-time */
  StartDate: string;

  /** @format date-time */
  UsedDate?: string;

  /** @format date-time */
  IssuedDate: string;

  /** @format date-time */
  Regdate: string;

  /** @format date-time */
  ModifiedDate?: string;
  AdminUNID?: number;
  UNID?: number;
  OrderID?: number;
}

export interface CreateAddressInDto {
  fullName: string;
  postCode: string;
  city: string;
  address1: string;
  address2: string;
  phone: string;
  memo: string;
  isDefault?: string;
}

export interface AdminOnetimeInitOrderInDto {
  unid: number;
  items: ItemOptionInDto[];
  aid?: number;
  address?: CreateAddressInDto;
  note?: string;
  orderType?: string;
}

export interface UserDetailInfoOutDto {
  ID: number;
  UNID: number;
  UserToken: string;
  OrderCount: number;
  AdSMS: string;
  AdEmail: string;
  ServiceSMS: string;
  ServiceEmail: string;

  /** @format date-time */
  ModifiedDate: string;

  /** @format date-time */
  RegDate: string;
}

export interface UserInfoOutDto {
  PasswordExists: boolean;
  UserDetailInfo: UserDetailInfoOutDto;
  UNID: number;
  Email: string;
  FullName: string;
  DOB: string;
  Phone: string;
  Gender: string;
  UserType: string;
  SignupOrigin: string;
  UserStatus: string;

  /** @format date-time */
  LastLoggedInDate?: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
}

export interface GetSocialLoginOutDto {
  SID: number;
  UNID?: number;
  UserIP?: string;
  Status?: string;
  SNType: string;
  SNID: string;
  NickName?: string;
  FullName?: string;
  FirstName?: string;
  LastName?: string;
  MiddleName?: string;
  Email?: string;
  Phone?: string;

  /** @format date-time */
  LastLoggedInDate?: string;

  /** @format date-time */
  RegDate: string;
}

export interface GetAddressOutDto {
  AID: number;
  UNID: number;
  FullName: string;
  Phone: string;
  Postcode: string;
  Country: string;
  City: string;
  Address1: string;
  Address2?: string;
  IsDefault: string;
  Status: string;
  Memo?: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
}

export interface PublicEasyPaymentOutDto {
  EPID: number;
  UNID: number;
  PaymentID: number;
  IsDefault: string;
  Status: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
}

export interface GetUserOutDto {
  user: UserInfoOutDto;
  socialLogin?: GetSocialLoginOutDto;
  address?: GetAddressOutDto;
  easypay?: PublicEasyPaymentOutDto;
}

export interface UpdateNotificationInDto {
  adSms?: string;
  adEmail?: string;
  serviceSms?: string;
  serviceEmail?: string;
  unid?: number;
}

export interface UpdateUserInDto {
  fullName?: string;
  email?: string;
  dob?: string;
  phone?: string;
  gender?: string;
  unid?: number;
}

export interface GetUserOrderInfoOutDto {
  OrderID: number;
  OrderName: string;
  OrderStatus: string;
  PaymentStatus: string;
  ShippingFee: number;
  AdditionalShippingFee?: number;
  TotalAmount: number;
  ItemPriceAmount: number;
  DiscountAmount: number;
  RefundAmount?: number;

  /** @format date-time */
  ApprovedDate?: string;

  /** @format date-time */
  CancelDate?: string;

  /** @format date-time */
  RegDate: string;

  /** @format date-time */
  ModifiedDate?: string;
  IsSubscribed: string;
  Source?: string;
}

export interface CouponRegisterInDto {
  couponCode: string;
}

export interface GetUserCouponsInDto {
  items?: ItemOptionInDto[];
  orderType?: string;
}

export interface CreateCouponMainInDto {
  couponName: string;
  codeType: string;
  commonCode: string;
  description: string;
  validityType: string;

  /**
   * @min 0
   * @max 9999
   */
  validityDay?: number;

  /**
   * @min 0
   * @max 999999
   */
  minimumPrice: number;
  optionIDList: number[];

  /**
   * @min 0
   * @max 999999
   */
  maxCount?: number;

  /**
   * @min 0
   * @max 999999
   */
  maxDiscountAmount: number;
  discountType: string;

  /**
   * @min 0
   * @max 999999
   */
  discountAmount: number;

  /**
   * @min 0
   * @max 100
   */
  discountPercent: number;

  /** @format date-time */
  startDate: string;

  /** @format date-time */
  endDate: string;
  adminUnid?: number;
}

export interface AdminGetCouponsOutDto {
  couponDtos: AvailableCouponOutDto[];
  pagination: PaginationOutDto;
}

export interface CreateCodeCouponOutDto {
  cmid: number;
  couponName: string;
  totalCount: number;
  successCount: number;
}

export interface CreateCodeCouponInDto {
  cmid: number;
  count: number;
  groupKey: string;
}

export interface CreateUserCouponInDto {
  unid: number;
  cmid: number;
}

export interface UpdateCouponInDto {
  cid: number;
  status?: string;
}

export interface OptionValueOutDto {
  ID: number;
  OptionValueName: string;
  ImageURL?: string;
}

export interface OptionOutDto {
  OptionValues: OptionValueOutDto[];
  ID: number;
  OptionKey: string;
  Position: number;
  Description?: string;
}

export interface ReviewMainOutDto {
  TotalRate: number;
  P1: number;
  P2: number;
  P3: number;
  P4: number;
  P5: number;
  TotalCount: number;
  MaxPoint: number;
}

export interface ItemImageV2OutDto {
  ItemID: number;
  ImageName: string;
  ImageURL: string;
  OrderNo: number;
  ImageType?: string;
}

export interface StoreItemOutDto {
  Options: OptionOutDto[];
  ReviewMain?: ReviewMainOutDto;
  MainImages: ItemImageV2OutDto[];
  DetailImages: ItemImageV2OutDto[];
  LegalImages: ItemImageV2OutDto[];
  ItemID: number;
  ItemName: string;
  DetailInfo1: string;
  DetailInfo2: string;
  Description?: string;
  RegularPrice: number;
  DiscountPrice?: number;
  SubscriptionPrice?: number;
  FileKey?: string;
  FileName?: string;
  Status: string;
  OrderNo: number;
  IsVisible: string;
  SubscriptionDisplay: string;
  CategoryItem?: TbCategoryItem;
}

export interface CategoryItemOutDto {
  Item: StoreItemOutDto;
  ID: number;
}

export interface CategoryOutDto {
  CategoryItems: CategoryItemOutDto[];
  ID: number;
  CategoryName: string;
  Depth: number;
  Status: string;
  ChildCategories?: TbCategory[];
}

export interface FetchCategoryInDto {
  categoryOrItemIds?: number[];
  isVisible?: "Y" | "N";
}

export interface FetchItemInDto {
  itemId: number;
}

export interface FetchItemsInDto {
  itemIds: number[];
  isVisible?: "Y" | "N";
}

export interface ItemInDto {
  itemId: number;
  isSubscribed: string;
  options?: number[];
  quantity: number;
}

export interface FetchItemInfoInDto {
  items: ItemInDto[];
}

export interface StoreMainItemOutDto {
  type: string;
  items: StoreItemOutDto[];
}

export interface FetchItemOptionsOutDto {
  OptionID: number;
  ItemID: number;
  OptionItemName: string;
  SKU: string;
  OptKey?: string;
  OptValue?: string;
  OptSubValue?: string;
  OptKey2?: string;
  OptValue2?: string;
  OptSubValue2?: string;
  OptKey3?: string;
  OptValue3?: string;
  OptSubValue3?: string;
  Price?: number;
  PriceTax?: number;
  Width?: number;
  Length?: number;
  Height?: number;
  Weight?: number;
  Stock?: number;
  IsSubsidiary: string;
  FileKey?: string;
  FileName?: string;
  IsVisible: string;
  SubscriptionType: string;
  OptionStatus: string;
  OptionDescription?: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
}

export interface FetchItemOptionsInDto {
  optionIds?: number[];
  status?: string;
  isSubsidiary?: string;
  isFree?: string;
  isSubscription?: string;
  isVisible?: string;
}

export interface GetReviewsOutDto {
  RID: number;
  UNID: number;
  FullName: string;
  Age?: number;
  AgeGroup?: string;
  ItemID?: number;
  OptionID: number;
  OrderID: number;
  Point: number;
  Contents: string;
  IsVisible: string;
  Comment?: string;
  ProviderID: number;
  BrandName: string;
  AdminUNID?: number;

  /** @format date-time */
  RegDate: string;
  OptionItemName: string;
  OptKey?: string;
  OptValue?: string;
  OptKey2?: string;
  OptValue2?: string;
  OptKey3?: string;
  OptValue3?: string;
}

export interface AdminGetReviewsOutDto {
  reviewDtos: GetReviewsOutDto[];
  pagination: PaginationOutDto;
}

export interface SignUpInDto {
  email: string;
  pwd: string;
  fullName: string;
  dob: string;
  phone: string;
  gender: string;
  adSms: string;
  adEmail: string;
  serviceSms: string;
  serviceEmail: string;
  referralType?: string;
  referralNote?: string;
}

export interface AdminSignUpInDto {
  email: string;
  pwd: string;
  fullName: string;
  dob: string;
  phone: string;
  gender: string;
  adSms: string;
  adEmail: string;
  serviceSms: string;
  serviceEmail: string;
  referralType?: string;
  referralNote?: string;
}

export interface DeliveryMainOutDto {
  DMID: number;

  /** @format date-time */
  ExportedDate?: string;
  DeliveryCount: number;
  OrderCount: number;
  ItemCount: number;
  CombinedCount: number;
  Status: string;

  /** @format date-time */
  RegDate: string;

  /** @format date-time */
  ModifiedDate?: string;
}

export interface GetDeliveryMainListOutDto {
  deliveryMains: DeliveryMainOutDto[];
  pagination: PaginationOutDto;
}

export interface AdminListInDto {
  nowPage: number;
  perPage: number;
}

export interface TransformDeliveryListOutDto {
  deliveryItemList: object[];
  warehouseType: object;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  private instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;

  constructor({ securityWorker, secure, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  public request = async <T = any, E = any>({
    secure,
    path,
    type,
    query,
    format = "json",
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: format,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Wiselycompany API Document
 * @version 1.0.0
 * @contact
 *
 * API for NestJS
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  dev = {
    /**
     * No description
     *
     * @name Ping
     * @request GET:/dev/ping
     */
    ping: (params: RequestParams = {}) =>
      this.request<{ code: "success"; data: string } | { code: "fail"; data: { message: string } }, any>({
        path: `/dev/ping`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  notes = {
    /**
     * No description
     *
     * @name CreateNote
     * @request POST:/notes/create-note
     * @secure
     */
    createNote: (data: NoteCreateInDto, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: NoteDto } | { code: "fail"; data: { message: string } }, any>({
        path: `/notes/create-note`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name FindNotes
     * @request GET:/notes
     */
    findNotes: (query: { unid: number }, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: NoteDto[] } | { code: "fail"; data: { message: string } }, any>({
        path: `/notes`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateNote
     * @request POST:/notes/update-note
     */
    updateNote: (data: NoteUpdateInDto, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: NoteDto } | { code: "fail"; data: { message: string } }, any>({
        path: `/notes/update-note`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteNote
     * @request POST:/notes/delete-note
     */
    deleteNote: (data: NoteDeleteInDto, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: boolean } | { code: "fail"; data: { message: string } }, any>({
        path: `/notes/delete-note`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  cart = {
    /**
     * No description
     *
     * @name GetCartMetaInfo
     * @request GET:/cart/get/meta
     * @secure
     */
    getCartMetaInfo: (params: RequestParams = {}) =>
      this.request<{ code: "success"; data: GetCartMetaInfoOutDto } | { code: "fail"; data: { message: string } }, any>(
        {
          path: `/cart/get/meta`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        },
      ),

    /**
     * No description
     *
     * @name GetCart
     * @request GET:/cart/get
     * @secure
     */
    getCart: (params: RequestParams = {}) =>
      this.request<{ code: "success"; data: GetCartOutDto } | { code: "fail"; data: { message: string } }, any>({
        path: `/cart/get`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetItemsPrice
     * @request POST:/cart/items/price
     */
    getItemsPrice: (data: GetCartPriceOutDto, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: CartCheckoutDto } | { code: "fail"; data: { message: string } }, any>({
        path: `/cart/items/price`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AddItems
     * @request POST:/cart/items/add
     * @secure
     */
    addItems: (data: AddCartItemInDto, params: RequestParams = {}) =>
      this.request<
        | { code: "success"; data: CartCheckoutDto }
        | { code: "fail"; data: { message: string } }
        | { code: "cannotSubscribeSameBrand"; data?: unknown },
        any
      >({
        path: `/cart/items/add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateItem
     * @request POST:/cart/item/update
     * @secure
     */
    updateItem: (data: CartItemInDto, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: CartCheckoutDto } | { code: "fail"; data: { message: string } }, any>({
        path: `/cart/item/update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteItem
     * @request POST:/cart/item/delete
     * @secure
     */
    deleteItem: (data: DeleteCartItemInDto, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: CartCheckoutDto } | { code: "fail"; data: { message: string } }, any>({
        path: `/cart/item/delete`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SetItems
     * @request POST:/cart/items/set
     * @secure
     */
    setItems: (data: CartItemInDto, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: CartCheckoutDto } | { code: "fail"; data: { message: string } }, any>({
        path: `/cart/items/set`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MergeItems
     * @request POST:/cart/items/merge
     * @secure
     */
    mergeItems: (data: CartItemInDto, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: CartCheckoutDto } | { code: "fail"; data: { message: string } }, any>({
        path: `/cart/items/merge`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  subscription = {
    /**
     * No description
     *
     * @name GetSubscriptionCartInfo
     * @request GET:/subscription/cart/info
     * @secure
     */
    getSubscriptionCartInfo: (params: RequestParams = {}) =>
      this.request<
        { code: "success"; data: GetSubscriptionCartInfoOutDto } | { code: "fail"; data: { message: string } },
        any
      >({
        path: `/subscription/cart/info`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetSubscription
     * @request GET:/subscription/get
     * @secure
     */
    getSubscription: (params: RequestParams = {}) =>
      this.request<{ code: "success"; data: GetSubscriptionOutDto } | { code: "fail"; data: { message: string } }, any>(
        {
          path: `/subscription/get`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        },
      ),
  };
  adminSubscription = {
    /**
     * No description
     *
     * @name GetSubscriptionInfo
     * @request POST:/admin-subscription/get-subscription-info
     * @secure
     */
    getSubscriptionInfo: (data: AdminGetSubscriptionInfoInDto, params: RequestParams = {}) =>
      this.request<
        { code: "success"; data: AdminGetSubscriptionInfoOutDto[] } | { code: "fail"; data: { message: string } },
        any
      >({
        path: `/admin-subscription/get-subscription-info`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name Test
     * @request GET:/admin-subscription/test
     */
    test: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin-subscription/test`,
        method: "GET",
        ...params,
      }),
  };
  order = {
    /**
     * No description
     *
     * @name InitOnetimeOrder
     * @request POST:/order/init-onetime-order
     * @secure
     */
    initOnetimeOrder: (data: OnetimeInitOrderInDto, params: RequestParams = {}) =>
      this.request<
        | { code: "success"; data: InitOrderOutDto }
        | { code: "fail"; data: { message: string } }
        | (
            | { code: "addressNotExists"; data?: unknown }
            | { code: "couponNotExists"; data?: unknown }
            | { code: "trialNotAvailable"; data?: unknown }
            | { code: "cannotSubscribeSameBrand"; data?: unknown }
          ),
        any
      >({
        path: `/order/init-onetime-order`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name InitSubscriptionOrder
     * @request POST:/order/init-subscription-order
     * @secure
     */
    initSubscriptionOrder: (data: SubscriptionInitOrderInDto, params: RequestParams = {}) =>
      this.request<
        | { code: "success"; data: InitOrderOutDto }
        | { code: "fail"; data: { message: string } }
        | (
            | { code: "addressNotExists"; data?: unknown }
            | { code: "couponNotExists"; data?: unknown }
            | { code: "trialNotAvailable"; data?: unknown }
            | { code: "cannotSubscribeSameBrand"; data?: unknown }
          ),
        any
      >({
        path: `/order/init-subscription-order`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name InitTrialOrder
     * @request POST:/order/init-trial-order
     * @secure
     */
    initTrialOrder: (data: TrialInitOrderInDto, params: RequestParams = {}) =>
      this.request<
        | { code: "success"; data: InitOrderOutDto }
        | { code: "fail"; data: { message: string } }
        | (
            | { code: "addressNotExists"; data?: unknown }
            | { code: "couponNotExists"; data?: unknown }
            | { code: "trialNotAvailable"; data?: unknown }
            | { code: "cannotSubscribeSameBrand"; data?: unknown }
          ),
        any
      >({
        path: `/order/init-trial-order`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  adminOrder = {
    /**
     * No description
     *
     * @name GetOrderCoupon
     * @request GET:/admin-order/get-order-coupon
     * @secure
     */
    getOrderCoupon: (params: RequestParams = {}) =>
      this.request<
        { code: "success"; data: AvailableCouponOutDto[] } | { code: "fail"; data: { message: string } },
        any
      >({
        path: `/admin-order/get-order-coupon`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AdminInitOnetimeOrder
     * @request POST:/admin-order/init-onetime-order
     * @secure
     */
    adminInitOnetimeOrder: (data: AdminOnetimeInitOrderInDto, params: RequestParams = {}) =>
      this.request<
        | { code: "success"; data: InitOrderOutDto }
        | { code: "fail"; data: { message: string } }
        | (
            | { code: "addressNotExists"; data?: unknown }
            | { code: "couponNotExists"; data?: unknown }
            | { code: "trialNotAvailable"; data?: unknown }
            | { code: "cannotSubscribeSameBrand"; data?: unknown }
          ),
        any
      >({
        path: `/admin-order/init-onetime-order`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @name GetUser
     * @request GET:/user/get-user
     * @secure
     */
    getUser: (params: RequestParams = {}) =>
      this.request<{ code: "success"; data: GetUserOutDto } | { code: "fail"; data: { message: string } }, any>({
        path: `/user/get-user`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateNotification
     * @request POST:/user/update-notification
     * @secure
     */
    updateNotification: (data: UpdateNotificationInDto, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: UserInfoOutDto } | { code: "fail"; data: { message: string } }, any>({
        path: `/user/update-notification`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  adminUser = {
    /**
     * No description
     *
     * @name GetUser
     * @request GET:/admin-user/get-user
     * @secure
     */
    getUser: (query: { unid: number }, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: GetUserOutDto } | { code: "fail"; data: { message: string } }, any>({
        path: `/admin-user/get-user`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateUser
     * @request POST:/admin-user/update-user
     * @secure
     */
    updateUser: (data: UpdateUserInDto, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: UserInfoOutDto } | { code: "fail"; data: { message: string } }, any>({
        path: `/admin-user/update-user`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateNotification
     * @request POST:/admin-user/update-notification
     * @secure
     */
    updateNotification: (data: UpdateNotificationInDto, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: UserInfoOutDto } | { code: "fail"; data: { message: string } }, any>({
        path: `/admin-user/update-notification`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetUserOrders
     * @request GET:/admin-user/get-user-orders
     * @secure
     */
    getUserOrders: (query: { unid: number }, params: RequestParams = {}) =>
      this.request<
        { code: "success"; data: GetUserOrderInfoOutDto[] } | { code: "fail"; data: { message: string } },
        any
      >({
        path: `/admin-user/get-user-orders`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  coupon = {
    /**
     * No description
     *
     * @name RegisterCoupon
     * @request POST:/coupon/register-coupon
     * @secure
     */
    registerCoupon: (data: CouponRegisterInDto, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: AvailableCouponOutDto } | { code: "fail"; data: { message: string } }, any>(
        {
          path: `/coupon/register-coupon`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        },
      ),

    /**
     * No description
     *
     * @name GetUserCoupons
     * @request POST:/coupon/get-user-coupons
     * @secure
     */
    getUserCoupons: (data: GetUserCouponsInDto, params: RequestParams = {}) =>
      this.request<
        { code: "success"; data: AvailableCouponOutDto[] } | { code: "fail"; data: { message: string } },
        any
      >({
        path: `/coupon/get-user-coupons`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  adminCoupon = {
    /**
     * No description
     *
     * @name CreateCouponMain
     * @request POST:/admin-coupon/create-coupon-main
     * @secure
     */
    createCouponMain: (data: CreateCouponMainInDto, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: CouponMainOutDto } | { code: "fail"; data: { message: string } }, any>({
        path: `/admin-coupon/create-coupon-main`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetCouponMain
     * @request GET:/admin-coupon/get-coupon-main
     * @secure
     */
    getCouponMain: (query: { id: number }, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: CouponMainOutDto } | { code: "fail"; data: { message: string } }, any>({
        path: `/admin-coupon/get-coupon-main`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetCouponMains
     * @request GET:/admin-coupon/get-coupon-mains
     * @secure
     */
    getCouponMains: (params: RequestParams = {}) =>
      this.request<{ code: "success"; data: CouponMainOutDto[] } | { code: "fail"; data: { message: string } }, any>({
        path: `/admin-coupon/get-coupon-mains`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetUserCoupons
     * @request GET:/admin-coupon/get-user-coupons
     * @secure
     */
    getUserCoupons: (query: { unid: number }, params: RequestParams = {}) =>
      this.request<
        { code: "success"; data: AvailableCouponOutDto[] } | { code: "fail"; data: { message: string } },
        any
      >({
        path: `/admin-coupon/get-user-coupons`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetCoupons
     * @request GET:/admin-coupon/get-coupons
     * @secure
     */
    getCoupons: (query?: { page?: number; perPage?: number; code?: string }, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: AdminGetCouponsOutDto } | { code: "fail"; data: { message: string } }, any>(
        {
          path: `/admin-coupon/get-coupons`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        },
      ),

    /**
     * No description
     *
     * @name GetCouponsByGroupKey
     * @request GET:/admin-coupon/get-coupons-by-groupkey
     * @secure
     */
    getCouponsByGroupKey: (query: { groupKey: string }, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: TbCoupon[] } | { code: "fail"; data: { message: string } }, any>({
        path: `/admin-coupon/get-coupons-by-groupkey`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateCodeCoupons
     * @request POST:/admin-coupon/create-code-coupons
     * @secure
     */
    createCodeCoupons: (data: CreateCodeCouponInDto, params: RequestParams = {}) =>
      this.request<
        { code: "success"; data: CreateCodeCouponOutDto } | { code: "fail"; data: { message: string } },
        any
      >({
        path: `/admin-coupon/create-code-coupons`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateUserCouponsFromExcel
     * @request POST:/admin-coupon/create-user-coupons-from-excel
     * @secure
     */
    createUserCouponsFromExcel: (params: RequestParams = {}) =>
      this.request<{ code: "success"; data: boolean } | { code: "fail"; data: { message: string } }, any>({
        path: `/admin-coupon/create-user-coupons-from-excel`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateUserCoupon
     * @request POST:/admin-coupon/create-user-coupon
     * @secure
     */
    createUserCoupon: (data: CreateUserCouponInDto, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: AvailableCouponOutDto } | { code: "fail"; data: { message: string } }, any>(
        {
          path: `/admin-coupon/create-user-coupon`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        },
      ),

    /**
     * No description
     *
     * @name UpdateCoupon
     * @request POST:/admin-coupon/update-coupon
     * @secure
     */
    updateCoupon: (data: UpdateCouponInDto, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: AvailableCouponOutDto } | { code: "fail"; data: { message: string } }, any>(
        {
          path: `/admin-coupon/update-coupon`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        },
      ),
  };
  store = {
    /**
     * No description
     *
     * @name FetchCategories
     * @request POST:/store/categories
     */
    fetchCategories: (data: FetchCategoryInDto, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: CategoryOutDto[] } | { code: "fail"; data: { message: string } }, any>({
        path: `/store/categories`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name FetchItem
     * @request POST:/store/item
     */
    fetchItem: (data: FetchItemInDto, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: StoreItemOutDto } | { code: "fail"; data: { message: string } }, any>({
        path: `/store/item`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name FetchItems
     * @request POST:/store/items
     */
    fetchItems: (data: FetchItemsInDto, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: StoreItemOutDto[] } | { code: "fail"; data: { message: string } }, any>({
        path: `/store/items`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name FetchItemInfoWithOption
     * @request POST:/store/item/option
     */
    fetchItemInfoWithOption: (data: FetchItemInfoInDto, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: ItemsInfoDto[] } | { code: "fail"; data: { message: string } }, any>({
        path: `/store/item/option`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name FetchMainPageItems
     * @request POST:/store/items/main-page
     */
    fetchMainPageItems: (params: RequestParams = {}) =>
      this.request<{ code: "success"; data: StoreMainItemOutDto[] } | { code: "fail"; data: { message: string } }, any>(
        {
          path: `/store/items/main-page`,
          method: "POST",
          format: "json",
          ...params,
        },
      ),

    /**
     * No description
     *
     * @name FetchItemOptions
     * @request POST:/store/items/item-options
     */
    fetchItemOptions: (data: FetchItemOptionsInDto, params: RequestParams = {}) =>
      this.request<
        { code: "success"; data: FetchItemOptionsOutDto[] } | { code: "fail"; data: { message: string } },
        any
      >({
        path: `/store/items/item-options`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name FetchRecommendedItems
     * @request GET:/store/items/recommendations
     */
    fetchRecommendedItems: (query: { optionIds: number[] }, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: StoreItemOutDto[] } | { code: "fail"; data: { message: string } }, any>({
        path: `/store/items/recommendations`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  adminReview = {
    /**
     * No description
     *
     * @name GetReviews
     * @request GET:/admin-review/get-reviews
     * @secure
     */
    getReviews: (
      query: {
        point?: number;
        optionId?: number;
        isVisible?: string;
        providerId?: number;
        keyword?: string;
        nowPage: number;
        perPage: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<{ code: "success"; data: AdminGetReviewsOutDto } | { code: "fail"; data: { message: string } }, any>(
        {
          path: `/admin-review/get-reviews`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        },
      ),
  };
  auth = {
    /**
     * No description
     *
     * @name SignUp
     * @request POST:/auth/sign-up
     */
    signUp: (data: SignUpInDto, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: UserInfoOutDto } | { code: "fail"; data: { message: string } }, any>({
        path: `/auth/sign-up`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  adminAuth = {
    /**
     * No description
     *
     * @name SignUp
     * @request POST:/admin-auth/sign-up
     */
    signUp: (data: AdminSignUpInDto, params: RequestParams = {}) =>
      this.request<{ code: "success"; data: UserInfoOutDto } | { code: "fail"; data: { message: string } }, any>({
        path: `/admin-auth/sign-up`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  adminDelivery = {
    /**
     * No description
     *
     * @name GetDeliveryMainList
     * @request POST:/admin-delivery/delivery-main-list
     * @secure
     */
    getDeliveryMainList: (data: AdminListInDto, params: RequestParams = {}) =>
      this.request<
        { code: "success"; data: GetDeliveryMainListOutDto } | { code: "fail"; data: { message: string } },
        any
      >({
        path: `/admin-delivery/delivery-main-list`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name TransformDeliveryList
     * @request POST:/admin-delivery/transform-delivery-list
     * @secure
     */
    transformDeliveryList: (params: RequestParams = {}) =>
      this.request<
        { code: "success"; data: TransformDeliveryListOutDto[] } | { code: "fail"; data: { message: string } },
        any
      >({
        path: `/admin-delivery/transform-delivery-list`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
