import React, { useState, useEffect, useContext } from "react";
import * as utils from "../../utils/Utils";
import OrderContext from "../../providers/ProviderOrderDetail";
import TitleContent from "../../components/TitleContent";

/** Modules */
import OrderInfo from "./components/OrderInfo";
import OrderItems from "./components/OrderItems";
import OrderPayment from "./components/OrderPayment";
import OrderShipping from "./components/OrderShipping";
import OrderHistory from "./components/OrderHistory";
import OrderChangeLog from "./components/OrderChangeLog";

import UserInfo from "../user/components/UserInfo";
import UserAddress from "../user/components/UserAddress";
import UserPayment from "../user/components/UserPayment";
import { UserOrderList } from "../user/components/UserOrderList";
import { AppContext } from "../../index";
import { return_normal } from "../../utils/Utils";
import { NewSubscriptionInfo, TbAddress } from "../../api";
import { DetailContext } from "../../DetailContext";
import OrderCoupon from "./components/OrderCoupon";

const OrdersDetail = ({ match }) => {
  const ctx = useContext(AppContext);
  /** Parameter에서 주문번호 받아오기 */
  const { orderid } = match.params;

  /** 상품 목록 가져오기 */
  const [itemList, setItemList] = useState();
  const getItemList = async () => {
    const getResult = await utils.getItemList();
    setItemList(getResult);
  };

  /// /////////////////////////////

  /** [주문] 주문정보 가져오기 */
  const [orderData, setOrderData] = useState(null);
  const [userid, setUserid] = useState();
  const getOrderData = async (orderid) => {
    const getResult = await utils.getOrderData(orderid);
    if (getResult) {
      setOrderData(getResult);
      setUserid(getResult.OrderInfo.UNID);
    }
  };

  /** [고객] 주문정보에 해당하는 사용자정보 가져오기 */
  const [userData, setUserData] = useState();
  const getUserData = async (userid) => {
    const getResult = await utils.getUserData(userid);
    if (getResult) {
      if (!Array.isArray(getResult.EasyPaymentInfo)) {
        getResult.EasyPaymentInfo = new Array(getResult.EasyPaymentInfo);
      }

      setUserData(getResult);
    }
  };

  /** [고객] 특정 사용자의 배송지 목록 가져오기 */
  const [userAddress, setUserAddress] = useState([] as TbAddress[]);
  const getUserAddressList = async (userid) => {
    const getResult = await utils.getUserAddressList(userid);
    setUserAddress(getResult);
  };

  /** [고객] 특정 사용자의 메모 불러오기 */
  const [userNote, setUserNote] = useState();
  const getUserNote = async (userid) => {
    const getResult = await utils.getNote(userid);
    setUserNote(getResult);
  };
  /** *******************************
   Consumer로 부터 호출 가능한 Method
   ********************************* */

  /** 주문정보와 사용자정보 갱신하기 */
  const refreshOrderData = () => {
    getOrderData(orderid);
    getUserData(userid);
  };

  /** [주문] 특정 주문의 배송정보 변경하기 */
  const updateOrderAddress = async (orderAddressData) => {
    await utils.updateOrderAddress(orderAddressData);
    getOrderData(orderid);
  };

  /** [주문] 특정 주문의 배송정보 변경하기(기존 배송지 이용) */
  const setOrderAddressID = async (addressData) => {
    await utils.setOrderAddressID(addressData);
    getOrderData(orderid);
  };

  /** [주문] 주문에 상품 변경하기 */
  const setOrderItem = async (item) => {
    const getResult = await ctx.api.admin.setOrderItem({
      orderId: item.orderId,
      optionId: item.optionId,
      quantity: item.quantity,
    });
    if (getResult) {
      getOrderData(orderid);
      return getResult;
    }
  };

  /** [주문] 주문에 상품 삭제하기 */
  const deleteOrderItem = async (item) => {
    const getResult = await ctx.api.admin.setOrderItem({
      orderId: item.orderId,
      optionId: item.optionId,
      quantity: 0,
    });
    if (getResult) {
      getOrderData(orderid);
      return getResult;
    }
  };

  /// /////////////////////////////
  /** [고객] 특정 사용자의 비밀번호 변경 발송하기 */
  const forgotPassword = async (email, type) => {
    await utils.forgotPassword(email, type);
    getUserData(userid);
  };

  /** [고객] 특정 사용자의 정기결제 카드 등록하기(토스PG) */
  const setEasyPaymentToToss = async (cardData) => {
    const result = await ctx.api.adminPayment.setEasyPayment({
      ...cardData,
      unid: userid,
    });

    getUserData(userid);

    return return_normal(result, "Y");
  };

  const [subscriptionData, setSubscriptionData] = useState<
    NewSubscriptionInfo | undefined
  >(undefined);

  const reloadSubscription = () =>
    ctx.api.adminSubscription
      .getSubscription({
        unid: userid!,
      })
      .then(({ data }) => setSubscriptionData(data));

  const reloadAddresses = (reloadUser: boolean = false) => {
    ctx.api.adminUser.getAddresses({ unid: userid! }).then(({ data }) => {
      const addresses = data.sort((a) => (a.IsDefault === "Y" ? -1 : 1));
      setUserAddress(addresses);
    });

    if (reloadUser) {
      getUserData(userid);
    }
  };

  /** Provider에 전달할 변수 설정 */
  const passValue = {
    data: {
      orderData,
      userData,
      userAddress,
      itemList,
      userNote,
      subscriptionData,
    },
    reload: {
      reloadSubscription,
      reloadAddresses,
    },
    actions: {
      getUserData,
      getOrderData,
      refreshOrderData,
      forgotPassword,
      getUserAddressList,
      updateOrderAddress,
      setOrderAddressID,
      setEasyPaymentToToss,
      setOrderItem,
      deleteOrderItem,
      getUserNote,
    },
  };

  /** 초기 로딩할 때 1회 실행 */
  useEffect(() => {
    getOrderData(orderid);
    getItemList();
  }, [orderid]);

  useEffect(() => {
    if (userid) {
      getUserData(userid);
      getUserAddressList(userid);
      /** 특정 사용자의 배송지 목록 가져오기 */
      getUserNote(userid); /** 특정 사용자의 메모 목록 가져오기 */
    }
  }, [userid]);

  return (
    <>
      <TitleContent
        title="주문 상세 정보"
        subHeading="고객님 덕분에 저희가 먹고 삽니다. 항상 감사합니다."
      />

      <DetailContext.Provider value={passValue}>
        <OrderContext.Provider value={passValue}>
          <div className="row mb-4">
            <>
              <div className="col-md-7">
                {orderid && (
                  <>
                    <OrderInfo
                      orderid={orderid}
                      orderStatus={orderData}
                      userid={userid}
                    />
                    {userid && (
                      <OrderChangeLog userid={userid} orderid={orderid} />
                    )}
                    <OrderItems />
                    <OrderCoupon orderId={orderid} />
                    <OrderShipping />
                    <OrderPayment />
                    <OrderHistory />
                  </>
                )}
              </div>
              <div className="col-md-5">
                {userid && (
                  <>
                    <UserOrderList unid={userid} />
                    <UserInfo userid={userid} />
                    <UserAddress />
                    <UserPayment />
                  </>
                )}
              </div>
            </>
          </div>
        </OrderContext.Provider>
      </DetailContext.Provider>
    </>
  );
};

export default OrdersDetail;
