import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { IconContext } from "react-icons";
import { IoIosRefresh } from "react-icons/io";

import * as utils from "../../../utils/Utils";

const OrderChangeLog = ({ userid, orderid }) => {
  const orderStatus = {
    Completed: "결제완료",
    "On-Hold": "보류",
    Cancelled: "결제취소",
    Processing: "결제진행중",
    "Awaiting-vbank": "가상계좌입금대기중",
    "Cancelled-vbank": "가상계좌결제취소",
    Failed: "결제실패",
    Refunded: "환불됨",
    "Pending-cancel": "보류취소",
    "Partially-Refunded": "부분환불",
    "Requested-Refund": "환불요청",
    Fraud: "부정결제",
    Initialized: "주문생성",
    "Awaiting-Subscription": "정기결제대기중",
    "Initialized-EasyPayment": "간편결제주문초기화",
    "Requested-Return": "반품신청",
    "Requested-Exchange": "교환신청",
    "Return-Processing": "반품진행중",
    "Exchange-Processing": "교환진행중",
    Returned: "반품완료",
    Exchanged: "교환완료",
    "Out-for-Delivery": "배송중",
    Delivered: "배달완료",
    Paid: "결제완료",
    "Order-Processing": "배송접수",
    Pending: "대기중",
  };

  const [getList, setList] = useState<any>(null);
  const getOrderChangesLog = useCallback(async () => {
    const getResult = await utils.getOrderChangesLog(userid, orderid);
    if (getResult) {
      setList(getResult.OrderChangesLog);
    }
  }, [userid, orderid]);

  /** 초기 로딩할 때 1회 실행 */
  useEffect(() => {
    getOrderChangesLog();
  }, [getOrderChangesLog]);

  return (
    <>
      <div className="card mb-1">
        <div className="card-header text-primary">
          주문변경내역
          <div className="btn-actions-pane-right">
            <button
              className="btn btn-link p-0"
              onClick={() => getOrderChangesLog()}
            >
              <IconContext.Provider
                value={{
                  size: "2em",
                  style: { verticalAlign: "top", padding: "3px" },
                }}
              >
                <IoIosRefresh />
              </IconContext.Provider>
            </button>
          </div>
        </div>
        {getList && getList.length > 0 && (
          <div
            className="card-body mb-3"
            style={{ overflow: "auto", maxHeight: "160px" }}
          >
            <div className="table-responsive">
              <table className="table table-striped table-sm">
                <tbody>
                  <tr>
                    <th style={{ width: "170px" }}>시간</th>
                    <th>TYPE</th>
                    <th>변경전</th>
                    <th>변경후</th>
                    <th>메모</th>
                    <th>변경자</th>
                  </tr>
                  {getList.map((item, idx) => (
                    <tr key={idx}>
                      <td>
                        {moment(item.RegDate).format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      <td>{item.Section}</td>
                      <td>
                        {orderStatus[item.BeforeValue]
                          ? orderStatus[item.BeforeValue]
                          : item.BeforeValue}
                      </td>
                      <td>
                        {orderStatus[item.AfterValue]
                          ? orderStatus[item.AfterValue]
                          : item.AfterValue}
                      </td>
                      <td>{item.Note}</td>
                      <td>{item.FullName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OrderChangeLog;
