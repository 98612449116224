import React, { useState } from "react";
import XLSX from "xlsx";
import * as utils from "../../utils/Utils";

import TitleContent from "../../components/TitleContent";

const Users = () => {
  const [getData, setData] = useState<any>();

  // 엑셀에서 데이터 읽어오기
  const getdata = (e) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target!.result as any);
      const workbook = XLSX.read(data, { type: "array" });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const datas = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as any;

      let isFirst = "Y";
      const originData = [] as any;
      for (const unid of datas) {
        if (isFirst !== "Y") {
          originData.push({
            no: unid[0],
            phone: unid[1] ? unid[1] : "",
            email: unid[2] ? unid[2] : "",
          });
        }
        isFirst = "N";
      }

      setData(originData);
    };

    const { files } = e.target;
    const f = files[0];
    reader.readAsArrayBuffer(f);
  };

  // 읽어온 전체 데이터를 루프 돌면서 처리쪽으로 보내기
  const processRegist = () => {
    let i = 0;
    for (const userdata of getData) {
      setTimeout(() => {
        sendData(userdata);
      }, i * 200);
      i += 1;
    }
  };

  // 루프 처리 담당부 - setState의 비동기를 해결하기 위해 setData 내부에서 직전 state를 tmpData로 넘겨주어서 변경한 후 리턴받음
  const sendData = async (userdata) => {
    const getResult = await utils.updateUserAgreeNotifications(userdata, "N");

    // 정상 리턴 받았으면 항목에서 제거하기
    if (getResult.ResultCode[0].Code === "10000") {
      setData((tmpData) => tmpData.filter((item) => item.no !== userdata.no));
    }
  };

  return (
    <>
      <TitleContent
        title="고객 수신거부 등록"
        subHeading="고객 수신거부 정보를 등록합니다."
      />

      {/* PAGE BODY  */}
      <div className="row">
        <div className="col-lg-12">
          <div className="card mb-3">
            <div className="card-header">고객 수신거부 등록</div>
            <div className="card-body">
              <div className="mt-3 mb-3">
                <div
                  className="mb-2"
                  style={{ fontSize: "1em", fontWeight: "bold" }}
                >
                  * 엑셀 양식
                </div>
                <table
                  className="mb-3 table table-striped table-hover dataTable"
                  style={{ maxWidth: "500px", border: "3px solid #ddd" }}
                >
                  <thead>
                    <tr>
                      <th>No(일련번호)</th>
                      <th>전화번호</th>
                      <th>이메일</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>010-1234-5678</td>
                      <td />
                    </tr>
                    <tr>
                      <td>2</td>
                      <td />
                      <td>email@email.com</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="mt-3 mb-3">&nbsp;</div>

              <div className="mt-3 mb-3">
                <div className="input-group">
                  <input
                    type="file"
                    onChange={(e) => getdata(e)}
                    className="form-control-file"
                  />
                  <button
                    className="btn btn-primary mt-3 mb-3"
                    onClick={() => processRegist()}
                  >
                    수신거부 등록하기
                  </button>
                </div>
              </div>

              {getData && (
                <div className="table-responsive">
                  <table className="mb-3 table table-striped table-hover dataTable">
                    <thead>
                      <tr>
                        <th colSpan={3} style={{ width: "70px" }}>
                          대상 회원 UNID{" "}
                          {getData && <span>({getData.length}건)</span>}
                        </th>
                      </tr>
                      <tr>
                        <th>No</th>
                        <th>전화번호</th>
                        <th>이메일</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getData.map((item, idx) => (
                        <tr key={idx}>
                          <td>{item.no}</td>
                          <td>{item.phone}</td>
                          <td>{item.email}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
