import React from "react";
import { Route, useRouteMatch } from "react-router-dom";

import { Reviews } from "../pages/review/Reviews";

const ReviewRoute = () => {
  const match = useRouteMatch();

  return (
    <>
      <Route path={match.path} component={Reviews} exact />
    </>
  );
};

export default ReviewRoute;
