import React, { useState, useRef, useContext } from "react";
import Drawer from "react-drag-drawer";
import DaumPostcode from "react-daum-postcode";
import { IconContext } from "react-icons";
import { IoIosRefresh, IoMdAdd } from "react-icons/io";

import { DetailContext } from "../../../DetailContext";
import { AppContext } from "../../../index";
import { withAutoToast } from "../../../utils/Utils";

const UserAddress = () => {
  const ctx = useContext(AppContext);
  const { data, reload, actions } = useContext(DetailContext);

  /** Modal 띄우기 */
  const [isOpened, setIsOpened] = useState(false);
  const showModal = (data) => {
    setItem(data); // 임시로 값 저장
    setIsOpened(true); // Modal 띄우기
  };

  /** Modal에서 수정하는 내용 임시 저장 */
  const [getItem, setItem] = useState(null as any);
  const handleChangeItem = (event) => {
    setItem({
      ...(getItem as any),
      [event.target.name]: event.target.value,
    });
  };

  /** writeModal 띄우기 */
  const [isWriteOpened, setIsWriteOpened] = useState(false);
  const showWriteModal = () => {
    setIsWriteOpened(true); // Modal 띄우기
  };

  /** writeModal에서 수정하는 내용 임시 저장 */
  const inputUnid = useRef<any>();
  const [getWriteItem, setWriteItem] = useState(null as any);
  const handleChangeWriteItem = (event) => {
    setWriteItem({
      ...(getWriteItem as any),
      UNID: inputUnid.current.value,
      [event.target.name]: event.target.value,
    });
  };

  /** daum 우편번호로 주소 검색하기 */
  const [postAreaStyle, setPostAreaStyle] = useState({ display: "none" });
  const handleAddress = (data) => {
    setItem({
      ...(getItem as any),
      Country: "KR",
      Postcode: data.zonecode,
      City: data.sido,
      Address1: data.address,
    });
    setPostAreaStyle({ display: "none" });
  };

  const handleAddressWrite = (data) => {
    setWriteItem({
      ...getWriteItem,
      UNID: inputUnid.current.value,
      Country: "KR",
      Postcode: data.zonecode,
      City: data.sido,
      Address1: data.address,
    });
    setPostAreaStyle({ display: "none" });
  };

  const createUserAddress = withAutoToast(async (address) => {
    address.AddressType = address.AddressType ?? 0;
    address.IsDefault = address.IsDefault ?? "N";
    address.Memo = address.Memo ?? "";

    const UNID = inputUnid.current.value;
    await ctx.api.adminUser.createAddress({
      address: { ...address },
      unid: UNID,
    });

    reload.reloadAddresses(address.IsDefault === "Y");
    return true;
  });

  const updateUserAddress = withAutoToast(async (address) => {
    await ctx.api.adminUser.updateAddress({
      unid: address.UNID,
      address: { ...address },
    });

    reload.reloadAddresses(address.IsDefault === "Y");
    return true;
  });

  /** [고객] 특정 사용자의 배송지 삭제하기 */
  const deleteUserAddress = withAutoToast(async (aid, unid) => {
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      await ctx.api.adminUser.deleteAddress({ unid: unid, AID: aid });
      reload.reloadAddresses(false);
    }
    return true;
  });

  if (!data.userData || !data.userAddress) return <></>;

  return (
    data.userData &&
    data.userAddress && (
      <>
        {data.userAddress && (
          <div className="card mb-1">
            <div className="card-header text-primary">
              고객배송지정보
              <div className="btn-actions-pane-right">
                <button
                  className="btn btn-link p-0"
                  onClick={() =>
                    actions.getUserAddressList(data.userData.UserInfo.UNID)
                  }
                >
                  <IconContext.Provider
                    value={{
                      size: "2em",
                      style: { verticalAlign: "top", padding: "3px" },
                    }}
                  >
                    <IoIosRefresh />
                  </IconContext.Provider>
                </button>

                <button
                  className="btn btn-link"
                  onClick={() => showWriteModal()}
                >
                  <IconContext.Provider
                    value={{
                      size: "2em",
                      style: { verticalAlign: "top", padding: "3px" },
                    }}
                  >
                    <IoMdAdd />
                  </IconContext.Provider>
                </button>
              </div>
            </div>
            {data.userAddress.length > 0 && (
              <div className="card-body" style={{ position: "relative" }}>
                <ul className="list-group">
                  {data.userAddress.map((item, idx) => (
                    <li className="list-group-item" key={idx}>
                      <div className="widget-content p-0">
                        <div className="widget-content-outer">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                              <div className="widget-heading">
                                #{item.AID} {item.FullName}
                                {item.IsDefault === "Y" && (
                                  <span className="badge badge-success ml-2">
                                    기본
                                  </span>
                                )}
                              </div>
                              <div className="mt-2">{item.Phone}</div>
                              <div>
                                <div>
                                  [{item.Postcode}] {item.Address1}
                                </div>
                                <div>{item.Address2}</div>
                              </div>
                              <div className="opacity-6">{item.Memo}</div>
                            </div>
                            <div className="widget-content-right">
                              {item.IsDefault !== "Y" && (
                                <button
                                  className="btn btn-sm btn-light mr-1 mb-1"
                                  onClick={() =>
                                    updateUserAddress({
                                      ...item,
                                      IsDefault: "Y",
                                    })
                                  }
                                >
                                  기본배송지로
                                </button>
                              )}
                              <button
                                className="btn btn-sm btn-light mr-1 mb-1"
                                onClick={() => {
                                  setPostAreaStyle({ display: "none" });
                                  showModal(item);
                                }}
                              >
                                변경
                              </button>
                              {item.IsDefault !== "Y" && (
                                <button
                                  className="btn btn-sm btn-light mb-1"
                                  onClick={() =>
                                    deleteUserAddress(item.AID, item.UNID)
                                  }
                                >
                                  삭제
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}

        {getItem && (
          <Drawer
            open={isOpened}
            onRequestClose={() => setIsOpened(!isOpened)}
            allowClose={false}
          >
            <div className="row">
              <div className="col">
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="card-title">변경</div>
                    <div>
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="form-group position-relative">
                          <div className="input-group input-group-sm">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text mb-1"
                                style={{ minWidth: "150px" }}
                              >
                                기본배송지
                              </span>
                            </div>
                            <input
                              className="form-control"
                              name="IsDefault"
                              onChange={handleChangeItem}
                              value={getItem.IsDefault}
                              style={{ minWidth: "300px" }}
                            />
                          </div>

                          <div className="input-group input-group-sm">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text mb-1"
                                style={{ minWidth: "150px" }}
                              >
                                이름
                              </span>
                            </div>
                            <input
                              className="form-control"
                              name="FullName"
                              onChange={handleChangeItem}
                              value={getItem.FullName}
                              style={{ minWidth: "300px" }}
                            />
                          </div>

                          <div className="input-group input-group-sm">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text mb-1"
                                style={{ minWidth: "150px" }}
                              >
                                전화번호
                              </span>
                            </div>
                            <input
                              className="form-control"
                              name="Phone"
                              onChange={handleChangeItem}
                              value={getItem.Phone}
                              style={{ minWidth: "300px" }}
                            />
                          </div>

                          <div className="input-group input-group-sm">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text mb-1"
                                style={{ minWidth: "150px" }}
                              >
                                우편번호
                              </span>
                            </div>
                            <input
                              className="form-control"
                              name="Postcode"
                              onChange={handleChangeItem}
                              value={getItem.Postcode}
                              style={{
                                minWidth: "300px",
                                display: "inline",
                              }}
                              onClick={() =>
                                setPostAreaStyle({ display: "block" })
                              }
                            />
                          </div>
                          <div className="input-group input-group-sm">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text mb-1"
                                style={{ minWidth: "150px" }}
                              >
                                주소
                              </span>
                            </div>
                            <input
                              className="form-control"
                              name="Address1"
                              onChange={handleChangeItem}
                              value={getItem.Address1}
                              style={{
                                minWidth: "300px",
                                display: "inline",
                              }}
                              onClick={() =>
                                setPostAreaStyle({ display: "block" })
                              }
                            />
                          </div>
                          <div className="input-group input-group-sm">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text mb-1"
                                style={{ minWidth: "150px" }}
                              >
                                상세주소
                              </span>
                            </div>
                            <input
                              className="form-control"
                              name="Address2"
                              onChange={handleChangeItem}
                              value={getItem.Address2}
                              style={{ minWidth: "300px" }}
                            />
                          </div>

                          <div className="input-group input-group-sm">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text mb-1"
                                style={{ minWidth: "150px" }}
                              >
                                배송메모
                              </span>
                            </div>
                            <input
                              className="form-control"
                              name="Memo"
                              onChange={handleChangeItem}
                              value={getItem.Memo}
                              style={{ minWidth: "300px" }}
                            />
                          </div>

                          <div>
                            <div style={{ maxWidth: "100%" }}>
                              <DaumPostcode
                                onComplete={handleAddress}
                                style={postAreaStyle}
                                animation
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="text-center">
                      <button
                        className="btn btn-light"
                        onClick={() => setIsOpened(!isOpened)}
                      >
                        닫기
                      </button>

                      <button
                        className="btn btn-success ml-2"
                        onClick={() => {
                          updateUserAddress({
                            ...getItem,
                          });
                          setIsOpened(!isOpened);
                        }}
                      >
                        변경하기
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
        )}

        <Drawer
          open={isWriteOpened}
          onRequestClose={() => setIsWriteOpened(!isWriteOpened)}
          allowClose={false}
        >
          <div className="row">
            <div className="col">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="card-title">추가</div>
                  <div>
                    <form>
                      <input
                        type="hidden"
                        name="UNID"
                        onChange={handleChangeWriteItem}
                        defaultValue={data.userData.UserInfo.UNID}
                        ref={inputUnid}
                        required
                      />
                      <div className="form-group position-relative">
                        <div className="input-group input-group-sm">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text mb-1"
                              style={{ minWidth: "150px" }}
                            >
                              기본배송지
                            </span>
                          </div>
                          <input
                            className="form-control"
                            name="IsDefault"
                            onChange={handleChangeWriteItem}
                            defaultValue="N"
                            style={{ minWidth: "300px" }}
                            required
                          />
                        </div>
                        <div className="input-group input-group-sm">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text mb-1"
                              style={{ minWidth: "150px" }}
                            >
                              이름
                            </span>
                          </div>
                          <input
                            className="form-control"
                            name="FullName"
                            onChange={handleChangeWriteItem}
                            defaultValue=""
                            style={{ minWidth: "300px" }}
                            required
                          />
                        </div>
                        <div className="input-group input-group-sm">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text mb-1"
                              style={{ minWidth: "150px" }}
                            >
                              전화번호
                            </span>
                          </div>
                          <input
                            className="form-control"
                            name="Phone"
                            onChange={handleChangeWriteItem}
                            defaultValue=""
                            style={{ minWidth: "300px" }}
                            required
                          />
                        </div>
                        <div className="input-group input-group-sm">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text mb-1"
                              style={{ minWidth: "150px" }}
                            >
                              주소
                            </span>
                          </div>
                          <input
                            className="form-control"
                            name="Address1"
                            onChange={handleChangeWriteItem}
                            defaultValue={
                              getWriteItem ? getWriteItem.Address1 : ""
                            }
                            style={{ minWidth: "300px" }}
                            onClick={() =>
                              setPostAreaStyle({ display: "block" })
                            }
                            readOnly
                            required
                          />
                        </div>
                        <div className="input-group input-group-sm">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text mb-1"
                              style={{ minWidth: "150px" }}
                            >
                              상세주소
                            </span>
                          </div>
                          <input
                            className="form-control"
                            name="Address2"
                            onChange={handleChangeWriteItem}
                            defaultValue=""
                            style={{ minWidth: "300px" }}
                          />
                        </div>
                        <div className="input-group input-group-sm">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text mb-1"
                              style={{ minWidth: "150px" }}
                            >
                              배송메모
                            </span>
                          </div>
                          <input
                            className="form-control"
                            name="Memo"
                            onChange={handleChangeWriteItem}
                            defaultValue=""
                            style={{ minWidth: "300px" }}
                            required
                          />
                        </div>

                        <div>
                          <div style={{ maxWidth: "100%" }}>
                            <DaumPostcode
                              onComplete={handleAddressWrite}
                              style={postAreaStyle}
                              animation
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="text-center">
                    <button
                      className="btn btn-light"
                      onClick={() => setIsWriteOpened(!isWriteOpened)}
                    >
                      닫기
                    </button>

                    <button
                      className="btn btn-success ml-2"
                      onClick={() => {
                        createUserAddress(getWriteItem);
                        setIsWriteOpened(!isWriteOpened);
                      }}
                    >
                      추가하기
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </>
    )
  );
};

export default UserAddress;
