import { numberFormat } from "../utils/UtilFunc";
import { OrderType } from "./Misc";
import { Column } from "./types";

const c = (_c: Column) => _c;

export const CellPresets = {
  userid: c({ name: "회원ID", width: 70, cell: { field: "UNID" } }),
  username_simple: c({
    name: "고객명",
    tdStyle: { wordBreak: "break-all" },
    cells: [{ field: "FullName", link: (o) => `/users/detail/${o.UNID}` }],
  }),
  username: c({
    name: "고객명",
    tdStyle: { wordBreak: "break-all" },
    minWidth: 170,
    cells: [
      { field: "FullName", link: (o) => `/users/detail/${o.UNID}` },
      { field: "Phone", className: "opacity-4" },
      { field: "Email", className: "opacity-4" },
    ],
  }),

  delivery: (keyword: string) =>
    c({
      name: "배송정보",
      minWidth: 100,
      cells: [
        {
          field: "rFullName",
          className: (v) => (v.rFullName === keyword ? "bg-warning" : ""),
        },
        {
          field: "rPhone",
          className: (v) => (v.rPhone === keyword ? "bg-warning" : ""),
        },
      ],
    }),

  orderid: c({
    name: "주문번호",
    width: 80,
    cell: { field: "OrderID", link: (o) => `/orders/detail/${o.OrderID}` },
  }),
  ordername: c({
    minWidth: 100,
    name: "배송정보",
    cell: { field: "OrderName" },
  }),
  orderdiscount: c({
    width: 100,
    name: "할인",
    cell: {
      field: "DiscountAmount",
      fmt: (v) => (v !== 0 ? numberFormat(v) : ""),
    },
  }),
  orderpayinfo: c({
    width: 200,
    name: "결제정보",
    cells: [
      { field: "TotalAmount", fmt: "number" },
      { field: "PaymentID", fmt: "payment", className: "opacity-4" },
      { field: "ApprovedDate", fmt: "datetime" },
    ],
  }),
  orderdates: c({
    width: 170,
    name: "등록/변경일시",
    cells: [
      { field: "RegDate", fmt: "datetime" },
      { field: "ModifiedDate", fmt: "datetime", className: "opacity-4" },
    ],
  }),
  ordertype: c({
    width: 70,
    name: "주문타입",
    center: true,
    cell: { field: "", component: (o) => OrderType({ order: o }) },
  }),
  orderstatus: c({
    width: 170,
    name: "주문상태",
    cells: [
      { field: "OrderStatus", fmt: "order_status" },
      { field: "OrderStatus", className: "opacity-4" },
    ],
  }),

  subscription_id: c({
    width: 100,
    name: "정기구매ID",
    cell: { field: "SID" },
  }),
} as const;
