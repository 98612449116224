/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback, useRef } from "react";
import moment from "moment";
import { IconContext } from "react-icons";
import { IoIosRefresh } from "react-icons/io";

import { DetailContext } from "../../../DetailContext";
import * as utils from "../../../utils/Utils";
import Loading from "../../../components/Loading";

const UserMessage = ({ userid }) => {
  const [notifications, setNotifications] = useState(null as any);
  const getNotificationList = useCallback(async () => {
    setNotifications(null);
    const getResult = await utils.getNotificationList(userid);
    if (getResult) {
      setNotifications(getResult);
    }
  }, [userid]);

  const [notificationType, setNotificationType] = useState("");
  const changeNotificationType = (notiType) => {
    setNotificationType(notiType);
  };

  const notificationTypeFilters = [
    ["", "전체"],
    ["AlimTalk", "알림톡"],
    ["Email", "이메일"],
    ["SMS", "SMS"],
    ["ChinguTalk", "친구톡"],
  ];

  /** 초기 로딩할 때 1회 실행 */
  useEffect(() => {
    getNotificationList();
  }, [getNotificationList]);

  const [message, setMessage] = useState("");
  const handleChangeMessage = (event) => {
    let msg;
    if (
      ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"].includes(
        event.target.value
      )
    ) {
      msg = SMSPreset[event.target.value];
    } else {
      msg = event.target.value;
    }
    setMessage(msg);
  };

  const phone = useRef<any>();
  const sendMessage = async (unid, actions) => {
    if (window.confirm("정말로 SMS를 보내시겠습니까?")) {
      const getResult = await actions.sendSMS({
        unid: unid,
        phone: phone.current.value,
        contents: message,
      });
      if (getResult) {
        setMessage("");
        getNotificationList();
      }
    }
  };

  return (
    <DetailContext.Consumer>
      {({ data, actions }) =>
        data.userData && (
          <div className="card mb-1">
            <div className="card-header text-primary">
              고객메시지발송내역
              <div className="btn-actions-pane-right">
                <button
                  className="btn btn-link p-0"
                  onClick={() => getNotificationList()}
                >
                  <IconContext.Provider
                    value={{
                      size: "2em",
                      style: { verticalAlign: "top", padding: "3px" },
                    }}
                  >
                    <IoIosRefresh />
                  </IconContext.Provider>
                </button>
              </div>
            </div>
            {notifications !== null ? (
              <div className="card-body pt-2">
                <div className="mb-2">
                  {notificationTypeFilters.map((tf, idx) =>
                    notificationType === tf[0] ? (
                      <button
                        key={idx}
                        className="btn btn-sm btn-success mr-1 mb-2 mt-2"
                        onClick={() => {
                          changeNotificationType(tf[0]);
                        }}
                      >
                        {tf[1]}
                      </button>
                    ) : (
                      <button
                        key={idx}
                        className="btn btn-sm btn-light mr-1 mb-2 mt-2"
                        onClick={() => {
                          changeNotificationType(tf[0]);
                        }}
                      >
                        {tf[1]}
                      </button>
                    )
                  )}
                </div>
                <ul
                  className="list-group mb-3"
                  style={{ overflow: "scroll", maxHeight: "600px" }}
                >
                  {notifications
                    .filter((item) => {
                      if (notificationType !== "")
                        return notificationType === item.SendType;
                      return true;
                    })
                    .map((item, idx) => (
                      <li key={idx}>
                        <div>
                          <div
                            style={{
                              backgroundColor: "#eee",
                              borderRadius: "0.3em",
                              marginBottom: "0.7em",
                              padding: "0.3em 0.5em 0.2em 0.5em",
                            }}
                          >
                            <>
                              <div
                                style={{
                                  borderTopLeftRadius: "0.3em",
                                  borderTopRightRadius: "0.3em",
                                  padding: "0.7em",
                                  paddingBottom: "0em",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.Title}
                              </div>
                              <div style={{ padding: "0.7em" }}>
                                {item.Contents &&
                                  (item.Contents.indexOf("DOCTYPE%20html") !==
                                    -1 ||
                                  item.Contents.indexOf("DOCTYPE html") !==
                                    -1 ? (
                                    <div className="opacity-6">
                                      이메일 내용은 표시하지 않습니다.
                                    </div>
                                  ) : !item.Reciever.includes("@") &&
                                    item.TID < 1 ? (
                                    item.Contents.split("\\n").map(
                                      (word, idx2) => (
                                        <span key={idx2}>
                                          {word}
                                          <br />
                                        </span>
                                      )
                                    )
                                  ) : (
                                    item.Contents.split("<br/>")
                                      .join("\n")
                                      .split("\n")
                                      .map((word, idx2) => (
                                        <span key={idx2}>
                                          {word}
                                          <br />
                                        </span>
                                      ))
                                  ))}
                              </div>
                              <div
                                style={{
                                  borderBottomLeftRadius: "0.3em",
                                  borderBottomRightRadius: "0.3em",
                                  padding: "0.7em",
                                  paddingBottom: "1.2em",
                                  fontSize: "0.8em",
                                }}
                              >
                                {notificationType === "" && (
                                  <span className="badge badge-light mr-1">
                                    {notificationTypeFilters.map(
                                      (n) => n[0] === item.SendType && n[1]
                                    )}
                                  </span>
                                )}
                                <span>
                                  {item.Status === "succeeded" ? (
                                    <div className="mr-1 badge badge-success">
                                      성공
                                    </div>
                                  ) : item.Status === "succeeded-sms" ? (
                                    <div className="mr-1 badge badge-success">
                                      성공(SMS)
                                    </div>
                                  ) : item.Status === "failed" ? (
                                    <>
                                      <div className="mr-1 badge badge-danger">
                                        실패
                                      </div>
                                    </>
                                  ) : item.Status === "requested" ? (
                                    <div className="mr-1 badge badge-primary">
                                      처리중
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </span>
                                <span>
                                  {moment(item.RegDate).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )}
                                </span>
                                <span className="p-2">|</span>
                                <span>{item.Reciever}</span>
                                {item.Status === "failed" && (
                                  <div
                                    className="p-2 bg-dark text-white"
                                    style={{
                                      marginTop: "0.3em",
                                      borderRadius: "0.3em",
                                      padding: "0.7em",
                                      fontSize: "0.8em",
                                    }}
                                  >
                                    {item.Description}
                                  </div>
                                )}
                              </div>
                            </>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            ) : (
              <div className="mb-3 m-2 mt-3">
                <Loading />
              </div>
            )}
            <>
              <div className="border-top" />
              <div className="mb-1">
                <div className="card-header text-primary">고객SMS</div>
                <div className="card-body">
                  <div>
                    <div className="mb-2">
                      <div className="input-group input-group-sm">
                        <div className="input-group-prepend">
                          <span className="input-group-text mb-1">
                            연락받으실 전화번호
                          </span>
                        </div>
                        <input
                          className="form-control mb-1"
                          type="text"
                          defaultValue={data.userData.UserInfo.Phone}
                          ref={phone}
                        />
                      </div>
                      <div>
                        <select
                          className="form-control form-control-sm mb-1"
                          onChange={handleChangeMessage}
                        >
                          <option value="">미리 작성된 내용 선택</option>
                          <option value="1">콜백</option>
                          <option value="2">반품비용</option>
                          <option value="3">기사운송장</option>
                          <option value="4">로그인</option>
                          <option value="5">리뷰케어</option>
                          <option value="6">교환절차</option>
                          <option value="7">반품절차</option>
                          <option value="8">미수령 확인 안내</option>
                          <option value="9">주소지 변경 안내</option>
                          <option value="10">와이즐리</option>
                        </select>
                      </div>
                      <textarea
                        className="form-control"
                        name="message"
                        value={message}
                        onChange={handleChangeMessage}
                        placeholder="메시지를 작성해 주세요."
                        style={{
                          width: "100%",
                          height: "200px",
                          fontSize: "1em",
                        }}
                      />
                    </div>
                    <div className="text-right">
                      <button
                        className="btn btn-light btn-sm"
                        onClick={() =>
                          sendMessage(data.userData.UserInfo.UNID, actions)
                        }
                      >
                        SMS 보내기
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        )
      }
    </DetailContext.Consumer>
  );
};

export default UserMessage;

const SMSPreset = {
  "1": `[와이즐리]
안녕하세요. 고객님. 와이즐리입니다. 고객님께 전화를 다시 드렸으나 통화가 어려우신 것 같습니다.
아래의 카카오톡에 문의를 남겨주시면, 통화없이도 상담이 가능합니다. 감사합니다.
☞ 카카오톡으로 문의하기 : http://bit.ly/2w9OLeT`,
  "2": `[와이즐리]
안녕하세요. 고객님. 입금정보 안내드립니다.

입금 계좌 기업은행
284-078350-01-017
예금주는 와이즐리(주) 입니다.

위 계좌로 배송비 ( 원) 입금 부탁드립니다. 감사합니다.`,
  "3": `[와이즐리]
안녕하세요. 기사님.
연락드린 와이즐리 입니다.

운송장 번호 안내드립니다.
< >
번거로우시겠지만 꼭 확인 부탁드립니다.
감사합니다.`,
  "4": `[와이즐리]
안녕하세요. 고객님.
와이즐리 입니다. 고객님의 로그인 정보를 안내드립니다.

이메일:

임시비밀번호:

감사합니다.`,
  "5": `[와이즐리]
안녕하세요. 고객님. 와이즐리 입니다.

소중한 시간 내셔서 남겨주신 리뷰와 관련하여 고객님의 의견을 듣고자 연락드렸으나 바쁘신 관계로 통화가 연결되지 못했습니다.

고객님 추후 시간되실때 저희 고객센터로 다시 연락주시면 고객님의 의견을 듣고 대화하는 시간을 가졌으면 합니다.

또한 말씀주신 내용들은 만족감을 드릴 수 있는 제품을 개발하는데 있어 적극 반영될 수 있도록 제품 개발팀으로 전달완료하였습니다.

남은 하루도 좋은 일만 가득하시길 바라겠습니다. 감사합니다.`,
  "6": `[와이즐리 컴퍼니 - 교환 절차 안내]

안녕하세요 고객님,
즐거운 고객 경험을 제공하는 와이즐리 CX 팀입니다.

교환 절차 안내드립니다.

- 수령한 상태 그대로 상품 재포장
- 재포장 시 메모 동봉(성함, 연락처, 교환 사유)
- 외부 박스 상단에 '와이즐리 교환' 기재
- 회수까지 평일 기준 1~3일 소요

교환의 경우 기다리시지 않도록 맞교환 처리 도와드렸습니다.
상품 출고와 수거 접수가 동시에 진행되어 빠른 수령 가능합니다.
(수거 지연 시 연락드릴 수 있는 점 참고 부탁드립니다.)

회수가 지연되거나 궁금한 사항이 있으실 경우 고객센터로 문의 부탁드립니다.

전화 : 1833-9133
카톡 : http://pf.kakao.com/_xkFAmxl/93596434

와이즐리와 함께 행복한 하루 보내세요 : )

감사합니다.`,
  "7": `[와이즐리 컴퍼니 - 반품 절차 안내]

안녕하세요 고객님,
즐거운 고객 경험을 제공하는 와이즐리 CX 팀입니다.

반품 절차 안내드립니다.

- 수령한 상태 그대로 상품 재포장
- 재포장 시 메모 동봉(성함, 연락처, 반품 사유)
- 외부 박스 상단에 '와이즐리 반품' 기재
- 회수까지 평일 기준 1~3일 소요

환불의 경우 기다리시지 않도록 선환불 처리 도와드렸습니다.
결제 수단에 따라 1~3일 소요될 수 있습니다.
(수거 지연 시 연락드릴 수 있는 점 참고 부탁드립니다.)

회수가 지연되거나 궁금한 사항이 있으실 경우 고객센터로 문의 부탁드립니다.

전화 : 1833-9133
카톡 : http://pf.kakao.com/_xkFAmxl/93596434

와이즐리와 함께 행복한 하루 보내세요 : )

감사합니다.`,
  "8": `[와이즐리 컴퍼니 - 미수령 확인 안내]

안녕하세요 고객님,
즐거운 고객 경험을 제공하는 와이즐리입니다.
미수령 확인 요청이 접수 되었습니다.
확인 후 배송 매니저가 고객님께 연락 드릴 예정입니다.
추가 문의 사항이 있으시면 언제든지 와이즐리를 찾아주세요.
감사합니다.

고객센터: 1833-9133
카카오톡: http://pf.kakao.com/_xkFAmxl`,
  "9": `[와이즐리 컴퍼니 - 주소지 변경 안내]

안녕하세요 즐거운 고객 경험을 제공하는 와이즐리입니다.
주소지 변경이 완료 되었습니다.
추가 문의 사항이 있으시면 언제든지 와이즐리를 찾아주세요.
감사합니다.

고객센터: 1833-9133
카카오톡: http://pf.kakao.com/_xkFAmxl`,
  "10": `[와이즐리 컴퍼니]

안녕하세요 즐거운 고객 경험을 제공하는 와이즐리입니다.
번거로우시겠지만
010-8245-3773으로 사진/영상 전송 해주시면 확인 후 빠른 처리 도와드리겠습니다.
해당 번호는 수신 전용으로 통화가 어려운점 양해 부탁드립니다.
추가 문의 사항이 있으시면 언제든지 와이즐리를 찾아주세요.
감사합니다.

고객센터: 1833-9133
카카오톡: http://pf.kakao.com/_xkFAmxl`,
};
