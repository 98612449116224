// 천 단위로 , 추가하는 함수
// eslint-disable-next-line no-confusing-arrow
export const numberFormat = (num: any) =>
  num ? new Intl.NumberFormat().format(num) : 0;

// arr: [{key: value}, ...] 형태
// 내부 객체는 하나의 key 와 value 만 존재
export const getObjectFromArray = (arr) =>
  arr.reduce((resultObj, currentObj) => ({ ...resultObj, ...currentObj }), {});

// formatType 에 맞는 형태 반환
export const showUpValue = (value, formatType) => {
  const len = value.length;

  if (formatType === "phone") {
    if (len < 4) {
      return value;
    }
    if (len < 8) {
      return `${value.slice(0, 3)}-${value.slice(3)}`;
    }
    return `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}`;
  }
  if (formatType === "birth") {
    if (len < 3) {
      return value;
    }
    if (len < 5) {
      return `${value.slice(0, 2)}/${value.slice(2)}`;
    }
    return `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4)}`;
  }
};
