import { CellPresets } from "./CellPresets";
import { Column } from "./types";

const t = (_t: Column[]) => _t;

export const TablePresets = {
  OrderTable: (keyword) =>
    t([
      CellPresets.orderid,
      CellPresets.orderdates,
      CellPresets.ordertype,
      CellPresets.orderstatus,
      CellPresets.username,
      CellPresets.delivery(keyword),
      CellPresets.ordername,
      CellPresets.orderdiscount,
      CellPresets.orderpayinfo,
    ]),
} as const;
