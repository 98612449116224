import * as icons from "react-icons/io";
import { IconType } from "react-icons/lib";

export type MenuGroup = {
  title?: string;
  devView?: boolean;
  links: Menu[];
};

export type Menu = {
  name: string;
  to: string;
  icon: IconType;
  // 다음 scope 중 하나라도 있어야 보여줌
  scope?: string[];
};

export const menus: MenuGroup[] = [
  {
    links: [
      {
        name: "HOME",
        to: "/",
        icon: icons.IoIosHome,
      },
    ],
  },
  {
    title: "주문 관리",
    links: [
      {
        name: "일반주문 리스트",
        to: "/orders",
        icon: icons.IoMdPaper,
      },
      {
        name: "정기구매 리스트",
        to: "/orders/subscription",
        icon: icons.IoMdPaper,
      },
      {
        name: "주문 만들기",
        to: "/orders/add",
        icon: icons.IoMdPaper,
      },
      {
        name: "CX주문 리스트",
        to: "/orders/cx",
        icon: icons.IoMdPaper,
      },
    ],
  },
  {
    title: "배송 관리",
    links: [
      {
        name: "발주서 리스트",
        to: "/delivery/export",
        icon: icons.IoIosAlbums,
      },
      {
        name: "발주서 수정",
        to: "/delivery/divide-and-export",
        icon: icons.IoIosReorder,
      },
    ],
  },
  {
    title: "리뷰 관리",
    links: [
      {
        name: "리뷰 관리",
        to: "/reviews",
        icon: icons.IoIosBrush,
      },
    ],
  },
  {
    title: "기타",
    links: [
      {
        name: "체리피커의심자",
        to: "/cherrypicker",
        icon: icons.IoIosHappy,
      },
      {
        name: "쿠폰관리",
        to: "/coupon",
        icon: icons.IoMdWallet,
      },
      {
        name: "알림톡 템플릿",
        to: "/alimtalk-template",
        icon: icons.IoMdWallet,
        scope: ["*.*"],
      },
      {
        name: "알림톡 이벤트",
        to: "/alimtalk-event",
        icon: icons.IoMdWallet,
        scope: ["*.*"],
      },
    ],
  },
  {
    title: "관리자",
    links: [
      {
        name: "고객 수신거부 등록",
        to: "/admin/usersync",
        icon: icons.IoIosPeople,
      },
      {
        name: "데이터 추출",
        to: "/admin/queries",
        icon: icons.IoIosGitCommit,
      },
    ],
  },
  {
    title: "상품",
    links: [
      {
        name: "전시상품 리스트",
        to: "/product/product-list",
        icon: icons.IoIosList,
      },
      {
        name: "전시상품 추가하기",
        to: "/product/add-display-product",
        icon: icons.IoIosCloudUpload,
      },
    ],
  },
];
