import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { providerList } from "../../Store";
import * as utils from "../../utils/Utils";

import TitleContent from "../../components/TitleContent";
import Loading from "../../components/Loading";

import { Table } from "../../components/Table";
import { Card, PageBody, Pagination } from "../../components/Layout";
import { CellPresets } from "../../components/CellPresets";
import { TablePresets } from "../../components/TablePresets";
import { Column } from "../../components/types";

const Home = () => {
  const history = useHistory();
  const location = useLocation();

  const searchFormRef = useRef<any>();

  const query = useMemo(() => queryString.parse(location.search), [location]);

  const [keyword, setKeyword] = useState(query.keyword || "");

  /* 페이징 처리 */
  const [pagination, setPagination] = useState<any>(undefined);

  // List 가져오기
  const [orders, setOrders] = useState<any>([]);
  const getData = useCallback(async () => {
    setOrders(undefined);
    const tmpParam = {
      nowPage: query.nowPage ? query.nowPage : 1,
      keyword: query.keyword ? query.keyword : "",
      perPage: 10,
      orderStatus: "",
      paymentID: "",
      orderType: "",
      isSubscribed: "",
      date_start: "",
      date_end: "",
    };

    // 데이터 불러오기
    const getResult = await utils.getOrderList(tmpParam);
    setPagination(getResult.Pagination);
    setOrders(getResult.OrderList);
  }, [query]);

  // 검색결과 가져오기
  const [users, setUsers] = useState<any>([]);
  const getSearchInfo = useCallback(async (keywords) => {
    setUsers(undefined);
    const getResult = await utils.getSearchInfo(keywords);
    if (!Array.isArray(getResult.UserInfo)) {
      getResult.UserInfo = new Array(getResult.UserInfo);
    }
    setUsers(getResult.UserInfo);
  }, []);

  // 페이지 번호 바꾸기
  const changePage = (nextPage) => {
    const nextUrl = `?${queryString.stringify({
      ...query,
      nowPage: nextPage,
    })}`;
    history.push(nextUrl);
  };

  const searchHandler = () => {
    history.push(`?keyword=${keyword}`);
  };

  useEffect(() => {
    if (query.keyword) getSearchInfo(query.keyword);
  }, [query.keyword, getSearchInfo]);

  useEffect(() => {
    if (query.keyword) getData();
  }, [query, getData]);

  useEffect(() => {
    searchFormRef.current.focus();
  }, []);

  const querySection = (
    <div className="form-group position-relative">
      <div className="input-group">
        <input
          className="form-control"
          name="searchKey"
          type="text"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              searchHandler();
            }
          }}
          placeholder="전화,메일,이름,TID 검색"
          ref={searchFormRef}
        />
        <button className="btn btn-primary btn-sm ml-1" onClick={searchHandler}>
          검색
        </button>
      </div>
    </div>
  );

  const userSection =
    users === undefined ? (
      <Loading />
    ) : (
      Table({ items: users, columns: UserTableInfo })
    );

  const orderSection = (
    <>
      {orders === undefined ? (
        <Loading />
      ) : (
        <Table
          items={orders}
          columns={TablePresets.OrderTable(query.keyword)}
        />
      )}
      {pagination ? (
        <Pagination
          pagination={pagination}
          query={query}
          changePage={changePage}
        />
      ) : undefined}
    </>
  );

  return (
    <>
      <TitleContent title="HOME" subHeading={"Let's go! Young & Rich!!"} />

      {PageBody({
        childs: [
          Card({ title: "검색하기", body: querySection }),
          Card({ title: "회원", body: userSection }),
          Card({ title: "검색된 주문", body: orderSection }),
        ],
      })}
    </>
  );
};

export default Home;

export const UserTableInfo: Column[] = [
  CellPresets.userid,
  CellPresets.username_simple,
  {
    name: "상태",
    cell: {
      field: "UserStatus",
      fmt: (s) => (s === "N" ? "탈퇴" : "가입"),
      className: (v) => (v.UserStatus === "N" ? "text-danger" : ""),
    },
  },
  { name: "Email", cell: { field: "Email" } },
  { name: "전화번호", cell: { field: "Phone" } },
  { name: "기본배송지", cell: { field: "Phone", fmt: () => "기본배송지" } },
  { name: "구독여부", cell: { field: "SubscriptionStatus" } },
  {
    name: "구독중인브랜드",
    cell: {
      field: "Subscription_ProviderIDs",
      component: (v) => {
        if (v.Subscription_ProviderIDs) {
          return (
            <>
              {v.Subscription_ProviderIDs.toString()
                .split(",")
                .map((providerId, i) =>
                  Badge(providerList[providerId].substring(0, 1), i)
                )}
            </>
          );
        }
      },
    },
  },
];

function Badge(txt: string, key) {
  return (
    <span key={key} className="badge badge-sm badge-info ml-1">
      {txt}
    </span>
  );
}
