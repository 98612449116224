import React from "react";
import { numberFormat } from "../../UtilFunc";

const SearchCount = ({ onClick, getCount, category }) => (
  <>
    {getCount && (
      <div className="mb-2">
        {Object.keys(category).map((key) => (
          <span
            key={key}
            onClick={() => onClick(key)}
            className="sort-condition mrl-1"
          >
            {category[key]}({numberFormat(getCount[key])})
          </span>
        ))}
      </div>
    )}
  </>
);

export default SearchCount;
