import React from "react";

import { IconContext } from "react-icons";
import { IoMdApps, IoIosLogOut } from "react-icons/io";
import { msalInstance } from "..";
import { apiMode } from "../Store";

const HeaderContents = () => {
  const showMenu = () => {
    const mMenu = document.getElementById("mobile-menu");
    if (mMenu!.style.display === "none") {
      mMenu!.style.display = "block";
    } else {
      mMenu!.style.display = "none";
    }
  };

  const changeAPI = (mode) => {
    if (window.confirm("정말 API를 변경하시겠습니까?")) {
      localStorage.setItem("apiMode", mode);
      document.location.reload();
    }
  };

  return (
    <>
      {/* HEADER AREA */}
      <div className="app-header header-shadow">
        {/* LOGO & HAMBURGER */}
        <div className="app-header__logo mt-3" style={{ display: "block" }}>
          <div style={{ display: "inline-block", position: "relative" }}>
            <div
              id="hamburg"
              style={{ left: "-4px", top: "-4px", position: "relative" }}
              onClick={() => showMenu()}
            >
              <IconContext.Provider
                value={{
                  size: "2em",
                }}
              >
                <IoMdApps />
              </IconContext.Provider>
            </div>
          </div>
          <div
            id="topLogo"
            className="logo-src"
            style={{
              display: "inline-block",
              width: "94px",
              height: "16px",
              marginTop: "15px",
              backgroundImage:
                "url(https://wiselystatic.s3.ap-northeast-2.amazonaws.com/WHALE/assets/images/wiselyLogo/wiselyLogo.svg)",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
          />
        </div>

        {/* HEADER CONTENTS */}
        <div className="app-header__content">
          {["DEV", "LOCAL"].includes(apiMode ?? "") && (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: "red",
              }}
            >
              <p
                style={{
                  color: "white",
                  fontSize: "30px",
                }}
              >
                개 발 서 버 입 니 다.
              </p>
            </div>
          )}

          {/* HEADER CONTENTS : RIGHT */}
          <div className="app-header-right">
            <div className="header-btn-lg pr-0">
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-right mr-2">
                    <div>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm btn-shadow mt-1"
                        onClick={() => msalInstance.logout()}
                      >
                        <IconContext.Provider value={{ size: "2em" }}>
                          <IoIosLogOut />
                        </IconContext.Provider>
                      </button>
                    </div>
                  </div>
                  <div className="widget-content-left mr-2">
                    <div>
                      {!localStorage.getItem("apiMode") ||
                      localStorage.getItem("apiMode") === "LIVE" ? (
                        <div
                          onClick={() => changeAPI("LIVE")}
                          style={{ fontSize: "0.7em" }}
                          className="btn btn-sm btn-success pt-0 pb-0"
                        >
                          LIVE
                        </div>
                      ) : (
                        <div
                          onClick={() => changeAPI("LIVE")}
                          style={{ fontSize: "0.7em" }}
                          className="btn btn-sm btn-secondary pt-0 pb-0"
                        >
                          LIVE
                        </div>
                      )}
                    </div>
                    <div>
                      {localStorage.getItem("apiMode") === "DEV" ? (
                        <div
                          onClick={() => changeAPI("DEV")}
                          style={{ fontSize: "0.7em" }}
                          className="btn btn-sm btn-success pt-0 pb-0"
                        >
                          DEV
                        </div>
                      ) : (
                        <div
                          onClick={() => changeAPI("DEV")}
                          style={{ fontSize: "0.7em" }}
                          className="btn btn-sm btn-secondary pt-0 pb-0"
                        >
                          DEV
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderContents;
