import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import QueryString from "query-string";
import moment from "moment";
import { AiTwotoneExperiment } from "react-icons/ai";

import { Table } from "./Table";
import { Card, PageBody, Pagination } from "./Layout";
import TitleContent from "../components/TitleContent";
import Loading from "./Loading";
import SearchCondition from "../utils/table/molecules/SearchCondition";
import SearchCount from "../utils/table/molecules/SearchCount";
import { AppContext } from "..";
import { Column, CommonHandlerParam, OnClickHandler } from "./types";

export type ListViewPageParam<T> = {
  texts: {
    title: string;
    sub: string;
    cardTitle: string;
  };
  defaultQueryParam: any;
  fetchItems: (param: any) => Promise<any>;
  pluckItems: (result: any) => T[];
  searchArray: {
    type: string;
    constant: Constant;
    show?: (query: any) => boolean;
    onClick?: OnClickHandler;
    optionFunction?: () => any;
  }[];
  tableInfo: (p: CommonHandlerParam) => Column[];

  searchCount?: {
    param: any;
    fetchCounts: (param: any) => Promise<any>;
    pluckCounts?: (result: any) => any;
    onClick: OnClickHandler;
  };
};

type Constant = {
  name: string;
  classNames?: string[];
  options?: any;
};

type ListViewPageState = {
  items?: any[];
  pagination?: any;
  counts?: any;
  searchArrayConditions?: any;
};

export function ListViewPage<T extends { Pagination: any }>(
  t: ListViewPageParam<T>
) {
  const {
    defaultQueryParam,
    fetchItems,
    pluckItems,
    searchArray,
    tableInfo,
    texts,
    searchCount,
  } = t;

  const ctx = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const query = useMemo(() => QueryString.parse(location.search), [location]);

  const [state, setState] = useState<ListViewPageState>({});

  const [searchParams, setSearchParams] = useState({
    ...defaultQueryParam,
    ...query,
  });

  const setParam = (reload: boolean) => (paramOrEvent) => {
    const params = paramOrEvent?.target
      ? { [paramOrEvent.target.name]: paramOrEvent.target.value }
      : paramOrEvent ?? {};

    if (params) {
      Object.keys(params).forEach((k) => {
        if (params[k] instanceof Date) {
          params[k] = moment(params[k]).format("YYYY-MM-DD");
        }
      });
      setSearchParams({
        ...searchParams,
        ...params,
      });
    }

    if (reload) {
      history.push(
        `${location.pathname}?${QueryString.stringify({
          ...searchParams,
          ...params,
        })}`
      );
    }
  };

  // 검색 조건이 바뀌면서 URL이 변경되면 데이터 다시 불러오기
  useEffect(() => {
    setState({ ...state, items: undefined });

    const data = fetchItems({
      ...defaultQueryParam,
      ...query,
    });
    const searchData = (searchCount
      ? searchCount.fetchCounts
      : () => Promise.resolve(undefined))(1);

    Promise.all([data, searchData]).then(([result, counts]) => {
      setState((s) => ({
        ...s,
        items: pluckItems(result),
        pagination:
          result.Pagination ??
          result.pagination ??
          result.data.data.Pagination ??
          result.data.data.pagination,
        counts: (searchCount?.pluckCounts ?? ((v) => v))(counts),
      }));
    });
  }, [query]);

  useEffect(() => {
    makeConditions().then((conditions) =>
      setState((s) => ({
        ...s,
        searchArrayConditions: conditions,
      }))
    );
  }, [searchParams]);

  const handlerParam = {
    state,
    setState,
    searchParams,
    setParam,
    ctx,
  };

  const content = (
    <>
      {state.searchArrayConditions && (
        <div className="input-group ptb-3">
          <SearchCondition
            conditions={state.searchArrayConditions}
            onChange={setParam(false)}
          />
        </div>
      )}

      {searchCount && state.counts && (
        <SearchCount
          onClick={searchCount.onClick(handlerParam)}
          getCount={state.counts}
          category={searchCount.param}
        />
      )}

      {state.items === undefined ? (
        <Loading />
      ) : (
        <Table
          items={state.items}
          columns={tableInfo(handlerParam)}
          handlerParams={handlerParam}
        />
      )}

      {state.pagination && (
        <Pagination
          pagination={state.pagination}
          query={query}
          changePage={(page) => setParam(true)({ nowPage: page })}
        />
      )}
    </>
  );

  return (
    <>
      <TitleContent
        title={texts.title}
        subHeading={texts.sub}
        icon={<AiTwotoneExperiment color="blue" />}
      />
      <PageBody childs={[Card({ title: texts.cardTitle, body: content })]} />
    </>
  );

  function makeConditions() {
    return Promise.all(
      searchArray
        .filter((s) => (s.show ? s.show(query) : true))
        .map(async (s) => ({
          ...s,
          ...(s.constant.name ? { value: searchParams[s.constant.name] } : {}),
          ...(s.onClick ? { onClick: s.onClick(handlerParam) } : {}),
          ...(s.optionFunction
            ? { constant: { ...s.constant, options: await s.optionFunction() } }
            : {}),
        }))
    );
  }
}
