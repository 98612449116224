import React from "react";
import TitleContent from "../../components/TitleContent";
import DisplayProductForm from "../product/components/displayProductForm";

const AddDisplayProduct = () => (
  <>
    <TitleContent title="전시상품 추가" subHeading="전시상품 추가" />
    <DisplayProductForm type="register" itemInfo={{}} />
  </>
);

export default AddDisplayProduct;
