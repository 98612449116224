import * as utils from "../../../utils/Utils";
import { DetailCtx } from "../../../DetailContext";
import {
  DetailViewCardParam,
  DetailViewCard,
} from "../../../components/DetailViewCard";

const param: DetailViewCardParam = {
  card: {
    type: "list",
    bodyMaxHeight: "360px",
  },
  headerInfo: {
    title: "고객로그인내역",
    buttons: ["refresh"],
  },
  getData: (ctx: DetailCtx) =>
    utils.getUserSigninLog(ctx?.data.userData?.UserInfo.UNID),
  dataName: "UserSigninLog",
  cardInfo: [
    {
      name: "시간",
      width: 100,
      cell: {
        field: "RegDate",
        fmt: "datetime",
      },
    },
    {
      name: "CODE",
      cell: {
        field: "ExceptionCode",
      },
    },
    {
      name: "IP",
      cell: { field: "UserIP" },
    },
    {
      name: "접속기기",
      cell: { field: "UserAgent" },
    },
  ],
};

export default () => DetailViewCard(param);
