import React, { ReactElement } from 'react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';

interface ListHeaderProps {
    title: string;
    button?: ReactElement
  }
  
const ProductListHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    color: inherit;
    background-color: #fff;
    border-bottom: 1px solid rgba(26, 54, 126, 0.125);
`;

const ProductListHeaderTitle = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
`;

export function ListHeader({ title, button }: ListHeaderProps): EmotionJSX.Element {
    return (
      <ProductListHeader>
          <ProductListHeaderTitle>{title}</ProductListHeaderTitle>
          {button}
        </ProductListHeader>
    );
};