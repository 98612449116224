import React from "react";

import { SELECT_ORDER_TYPE } from "../pages/order/constants/Orders";
import { getObjectFromArray } from "../utils/UtilFunc";

export function OrderType({ order }) {
  return (
    <>
      {order.IsSubscribed === "Y" ? <strong>정기</strong> : "일반"}
      {order.OrderType !== 1 && (
        <div className="text-danger" style={{ fontSize: "0.7em" }}>
          {OrderTypes[order.OrderType]}
        </div>
      )}
      {order.OrderType === 3 && (
        <div className="text-primary" style={{ fontSize: "0.7em" }}>
          {order.Note}
        </div>
      )}
    </>
  );
}

const OrderTypes = getObjectFromArray(SELECT_ORDER_TYPE.options);
