import React, { useState, useEffect } from "react";
import moment from "moment";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import TitleContent from "../../components/TitleContent";
import { GetWithJwt, ApiCall, NodeServerDomain } from "../../Store";
import Loading from "../../components/Loading";

function toDateTime(timestamp) {
  return moment(timestamp).format("YYYY/MM/DD HH:mm");
}

const downloadExcel = async (record) => {
  const res = await ApiCall(
    {
      url: `${NodeServerDomain}/admin/orders.list`,
      params: { orderIds: record.orderIds },
    },
    false
  );

  if (res.data.length === 0) {
    window.alert("다운받을 의심 사용자가 없습니다.");
    return;
  }

  let orders = res.data;
  const groupedOrders = [] as any;

  function partition(arr, pred) {
    return [arr.filter(pred), arr.filter((a) => !pred(a))];
  }

  while (true) {
    const one = orders.pop();
    if (one === undefined) {
      break;
    }
    const [phoneMatch, left1] = partition(
      orders,
      (o) => o.OrderAddress.Phone === one.OrderAddress.Phone
    );
    const [addrMatch, left2] = partition(
      left1,
      (o) => o.OrderAddress.Address1 === one.OrderAddress.Address1
    );

    groupedOrders.push(one, ...phoneMatch, ...addrMatch, -1);
    orders = left2;
  }

  const arr = groupedOrders.map((item) => {
    if (item === -1) {
      return {};
    }
    return {
      OrderID: item.OrderID,
      UNID: item.UNID,
      FullName: item.OrderAddress.FullName,
      Phone: item.OrderAddress.Phone,
      Address1: item.OrderAddress.Address1,
      Address2: item.OrderAddress.Address2,
      OrderStatus: item.OrderStatus,
    };
  });

  // 배송타입 순서로 sheet를 만들기 위해 정렬 후 sheet별 XLSX 데이터 생성
  const resultData = {
    data: XLSX.utils.json_to_sheet(arr),
  };

  // XLSX 만들기
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const wb = { Sheets: resultData, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xls", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(
    data,
    `체리피커의심자목록_${toDateTime(record.timestamp)}.xls`
  );
};

const Cherrypicker = () => {
  // List 가져오기
  const [loading, setLoading] = useState(false);
  const [getList, setGetList] = useState([] as any);

  const getData = async () => {
    setLoading(true);

    const res = await GetWithJwt(`${NodeServerDomain}/admin/cherrypickers`);
    setGetList(res.data);

    setLoading(false);
  };

  // 검색 조건이 바뀌면서 URL이 변경되면 데이터 다시 불러오기
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <TitleContent title="체리피커 의심자 다운로드" />

      <div className="row">
        <div className="col-lg-12">
          <div className="card mb-3">
            <div className="card-header">체리피커 의심자 목록</div>
            <div className="card-body">
              {loading ? (
                <div className="mb-3">
                  <Loading />
                </div>
              ) : (
                <div className="table-responsive">
                  <table className="mb-3 table table-striped table-hover dataTable">
                    <thead>
                      <tr>
                        <th style={{ width: "100px" }}>검사시각</th>
                        <th style={{ width: "140px" }} className="text-center">
                          의심자 수
                        </th>
                        <th style={{ width: "100px" }}>엑셀 다운로드</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getList.length > 0 &&
                        getList.map((item, idx) => (
                          <tr key={idx}>
                            <td>{toDateTime(item.timestamp)}</td>
                            <td className="text-center">
                              {item.orderIds.length}
                            </td>
                            <td>
                              <button
                                className="btn btn-primary btn-sm ml-1"
                                onClick={() => downloadExcel(item)}
                              >
                                다운로드
                              </button>
                              {/* {item.ModifiedDate &&
                                moment(item.ModifiedDate).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )} */}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {/* <div className="text-right">
                <div style={{ display: "flex" }}>
                  <div className="ml-auto">
                    <ReactPaginate
                      forcePage={parseInt(
                        query.nowPage ? query.nowPage - 1 : 0
                      )}
                      previousLabel={"이전"}
                      nextLabel={"다음"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      // pageCount={pageCount + 1}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={3}
                      onPageChange={(data) =>
                        changePage(data.selected ? data.selected + 1 : 1)
                      }
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cherrypicker;
