/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type SUCCESS_RESULT_TYPE_CODE = "success";

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickTbCouponTypeofCouponDtoFieldsAtNumber {
  /** @format double */
  CID: number;
  Code: string;
  Status: string;

  /** @format date-time */
  StartDate: string;

  /** @format date-time */
  UsedDate?: string;

  /** @format date-time */
  IssuedDate: string;

  /** @format date-time */
  Regdate: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format double */
  AdminUNID?: number;

  /** @format double */
  UNID?: number;

  /** @format double */
  OrderID?: number;
}

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickTbCouponMainTypeofCouponMainDtoFieldsAtNumber {
  /** @format date-time */
  StartDate: string;

  /** @format double */
  CMID: number;
  CouponName: string;
  CodeType: string;
  CommonCode?: string;
  ConditionDescription: string;
  ValidityType: string;

  /** @format double */
  ValidityDay?: number;
  BenefitDescription: string;

  /** @format date-time */
  EndDate: string;

  /** @format date-time */
  RegDate: string;
}

export type CouponMainDto = PickTbCouponMainTypeofCouponMainDtoFieldsAtNumber;

export interface AdditionalField {
  IsAvailable: boolean;
  CouponMain: CouponMainDto;

  /** @format double */
  RemainDay: number;
  EndDate: string | "unlimited";
}

export type CouponDto = PickTbCouponTypeofCouponDtoFieldsAtNumber & AdditionalField;

export interface WiselyApiSuccessWithSuccessCouponCouponDto {
  success: true;
  resultTypeCode: SUCCESS_RESULT_TYPE_CODE;
  data: { coupon: CouponDto };
}

export type DEFAULT_RESULT_TYPE_CODE = "unknown";

export interface WiselyApiSuccessWithResultCodeDEFAULTRESULTTYPECODE {
  success: true;
  resultTypeCode: DEFAULT_RESULT_TYPE_CODE;
}

export interface WiselyApiFail {
  success: false;
  data: { message: string };
}

export type WiselyApiResponseCouponCouponDto =
  | WiselyApiSuccessWithSuccessCouponCouponDto
  | WiselyApiSuccessWithResultCodeDEFAULTRESULTTYPECODE
  | WiselyApiFail
  | (WiselyApiSuccessWithSuccessCouponCouponDto & WiselyApiSuccessWithResultCodeDEFAULTRESULTTYPECODE & WiselyApiFail);

export interface WiselyApiSuccessWithSuccessCouponDtoArray {
  success: true;
  resultTypeCode: SUCCESS_RESULT_TYPE_CODE;
  data: CouponDto[];
}

export type WiselyApiResponseCouponDtoArray =
  | WiselyApiSuccessWithSuccessCouponDtoArray
  | WiselyApiSuccessWithResultCodeDEFAULTRESULTTYPECODE
  | WiselyApiFail
  | (WiselyApiSuccessWithSuccessCouponDtoArray & WiselyApiSuccessWithResultCodeDEFAULTRESULTTYPECODE & WiselyApiFail);

export interface ItemParam {
  /** @format double */
  optionId: number;

  /** @format double */
  quantity: number;
}

export type WiselyOrderType = "onetime" | "subscription" | "trial";

export interface CallMethodResponse {
  ResultCode: { Description: string; Code: string; Location: string; IsException: boolean };
}

export interface TbAddress {
  /** @format double */
  AID: number;

  /** @format double */
  UNID: number;
  FullName: string;
  Phone: string;
  Postcode: string;
  Country: string;
  City: string;
  Address1: string;
  Address2?: string;

  /** @format double */
  AddressType?: number;
  IsDefault: string;
  Status: string;
  Memo?: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
}

export interface TbNotificationLog {
  /** @format double */
  NID: number;

  /** @format double */
  TID: number;
  Reciever: string;

  /** @format double */
  UNID?: number;
  Title?: string;
  Contents?: string;

  /** @format double */
  AdminUNID?: number;
  Status: string;
  LogData?: string;

  /** @format date-time */
  RegDate: string;
}

/**
 * Construct a type with a set of properties K of type T
 */
export type RecordStringUnknown = object;

export interface DynamoNotificationLog {
  UUID: string;
  receiver: string;
  messageId: string;

  /** @format double */
  UNID: number;

  /** @format double */
  adminUNID: number;
  data: {
    button2?: { buttonUrl: string; buttonName: string };
    button1?: { buttonUrl: string; buttonName: string };
    params: string;
    content: string;
    title: string;
    templateCode: string;
    TID: number;
  };
  sendType: string;
  status: string;

  /** Construct a type with a set of properties K of type T */
  infobipStatus: RecordStringUnknown;

  /** Construct a type with a set of properties K of type T */
  infobipError: RecordStringUnknown;
  lastTriedDateTime: string;
  regDateTime: string;
  regDate: string;
  updateDateTime: string;
}

export interface TbOrder {
  /** @format double */
  OrderID: number;

  /** @format double */
  PaymentID?: number;

  /** @format double */
  UNID: number;

  /** @format double */
  AID?: number;
  OrderCID: string;
  TransactionID?: string;
  UserIP: string;
  CacheKey: string;
  OrderType?: string;
  OrderName: string;

  /** @format double */
  ItemCount: number;

  /** @format double */
  DiscountItemCount?: number;

  /** @format double */
  ShippingFee: number;

  /** @format double */
  AdditionalShippingFee?: number;

  /** @format double */
  TaxAmount?: number;

  /** @format double */
  TotalAmount: number;

  /** @format double */
  ItemPriceAmount: number;

  /** @format double */
  DiscountAmount: number;

  /** @format double */
  BonusAmount?: number;

  /** @format double */
  RefundAmount?: number;
  IsSubscribed: string;

  /** @format double */
  SubscriptionID?: number;
  PaymentStatus: string;
  OrderStatus: string;
  Note?: string;

  /** @format double */
  AdminUNID?: number;

  /** @format double */
  LinkedOrderID?: number;
  ReserveID?: string;

  /** @format date-time */
  ApprovedDate?: string;

  /** @format date-time */
  CancelDate?: string;

  /** @format date-time */
  RefundDate?: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
  OrderAddress?: TbOrderAddress;
  VBank?: TbOrderVBank;
  Coupon?: TbCoupon;
  DeliveryOrders?: TbDeliveryOrders;
  OrderItems?: TbOrderItems[];
  Payment?: TbPaymentV2;
}

export interface TbOrderAddress {
  /** @format double */
  OAID: number;

  /** @format double */
  OrderID: number;

  /** @format double */
  AID: number;

  /** @format double */
  UNID: number;
  FullName: string;
  Phone: string;
  Postcode: string;
  Country: string;
  City: string;
  Address1: string;
  Address2?: string;
  Memo?: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
  Order?: TbOrder;
}

export interface TbOrderVBank {
  /** @format double */
  NID: number;

  /** @format double */
  OrderID: number;
  BankCode: string;
  BankName: string;
  BankNumber: string;
  BankDate: string;
  BankHolder?: string;
  BankIssuedAt: string;
  Status: string;

  /** @format date-time */
  RegDate: string;
  Order?: TbOrder;
}

export interface TbCoupon {
  /** @format double */
  CID: number;
  Code: string;

  /** @format double */
  CMID: number;
  CouponType: string;

  /** @format double */
  DiscountRate?: number;

  /** @format double */
  OrderID?: number;

  /** @format double */
  UNID?: number;
  Status: string;
  IsActivated: string;

  /** @format double */
  AdminUNID?: number;
  Note?: string;

  /** @format date-time */
  StartDate: string;

  /** @format date-time */
  ExpiryDate: string;

  /** @format date-time */
  UsedDate?: string;

  /** @format date-time */
  IssuedDate: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  Regdate: string;
  Order?: TbOrder;
  CouponMain?: TbCouponMain;
}

export interface TbDeliveryOrders {
  /** @format double */
  DOID: number;

  /** @format double */
  OrderID: number;

  /** @format double */
  UNID: number;

  /** @format double */
  DID: number;

  /** @format double */
  DMID?: number;
  MasteryType: string;
  Status: string;

  /** @format date-time */
  RegDate: string;
  Order?: TbOrder;
  Delivery?: TbDelivery;
}

export interface TbDelivery {
  /** @format double */
  DID: number;
  DCID?: string;

  /** @format double */
  UNID: number;

  /** @format double */
  OAID?: number;
  UserGroup?: string;
  NextUserGroup?: string;
  DeliveryType?: string;
  CarrierCode?: string;
  CarrierName?: string;
  TrackNo?: string;
  DeliveryStatus: string;

  /** @format date-time */
  DeliveredDate?: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
  DeliveryOrders?: TbDeliveryOrders[];
  DeliveryItems?: TbDeliveryItems[];
}

export interface TbDeliveryItems {
  /** @format double */
  NID: number;

  /** @format double */
  DID: number;

  /** @format double */
  OrderID: number;

  /** @format double */
  OrderItemID?: number;

  /** @format double */
  ItemID: number;

  /** @format double */
  OptionID: number;

  /** @format double */
  Quantity: number;
  Status: string;

  /** @format double */
  AdminUNID?: number;
  IsSubsidiary: string;
  Note?: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
  Delivery?: TbDelivery;
}

export interface TbItemV2 {
  /** @format double */
  ItemID: number;

  /** @format double */
  ProviderID?: number;

  /** @format double */
  ShippingID: number;

  /** @format double */
  OrderNo: number;
  CategoryName?: string;
  ModalType?: string;
  ItemName: string;
  Description?: string;

  /** @format double */
  RegularPrice: number;

  /** @format double */
  DiscountPrice?: number;

  /** @format double */
  SubscriptionPrice?: number;
  FileKey?: string;
  FileName?: string;
  Status: string;
  IsVisible: string;
  SubscriptionDisplay: string;

  /** @format date-time */
  PublishingDate?: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
  ItemOptions?: TbItemOptionV2[];
}

export interface TbItemOptionV2 {
  /** @format double */
  OptionID: number;

  /** @format double */
  ItemID: number;
  OptionItemName: string;
  SKU: string;
  ProductCode: string;
  BarCode: string;
  OptKey?: string;
  OptValue?: string;
  OptSubValue?: string;
  OptKey2?: string;
  OptValue2?: string;
  OptSubValue2?: string;
  OptKey3?: string;
  OptValue3?: string;
  OptSubValue3?: string;

  /** @format double */
  Price?: number;

  /** @format double */
  PriceTax?: number;

  /** @format double */
  Width?: number;

  /** @format double */
  Length?: number;

  /** @format double */
  Height?: number;

  /** @format double */
  Weight?: number;

  /** @format double */
  Stock?: number;
  IsSubsidiary: string;
  FileKey?: string;
  FileName?: string;
  IsVisible: string;
  SubscriptionType: string;
  SubscriptionDisplay: string;
  OptionStatus: string;
  OptionDescription?: string;

  /** @format date-time */
  DiscountStartDate?: string;

  /** @format date-time */
  DiscountEndDate?: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
  Item?: TbItemV2;
}

export interface TbOrderItems {
  /** @format double */
  OrderItemID: number;

  /** @format double */
  OrderID: number;

  /** @format double */
  ItemID: number;

  /** @format double */
  OptionID: number;

  /** @format double */
  Quantity: number;

  /** @format double */
  Price: number;

  /** @format double */
  Tax: number;

  /** @format date-time */
  RegDate: string;
  Order?: TbOrder;
  Item?: TbItemV2;
  ItemOption?: TbItemOptionV2;
}

export interface TbPaymentV2 {
  /** @format double */
  PaymentID: number;

  /** @format double */
  OrderNo: number;
  PaymentName: string;
  DisplayName?: string;
  ImgPath?: string;
  PaymentType: string;
  IsEscrow: string;
  IsDirect: string;
  GatewayName: string;
  Description?: string;
  Status: string;
}

export interface TbCouponMain {
  /** @format double */
  CMID: number;
  CouponName: string;
  CodeType: string;
  CommonCode?: string;
  ConditionDescription: string;
  ValidityType: string;

  /** @format double */
  ValidityDay?: number;
  BenefitDescription: string;

  /** @format double */
  AdminUNID?: number;
  Status: string;

  /** @format date-time */
  StartDate: string;

  /** @format date-time */
  EndDate: string;

  /** @format date-time */
  ModifiedDate: string;

  /** @format date-time */
  RegDate: string;
}

/**
 * Result object returned by DeleteQueryBuilder execution.
 */
export interface DeleteResult {
  /** Raw SQL result returned by executed query. */
  raw: any;

  /**
   * Number of affected rows/documents
   * Not all drivers support this
   * @format double
   */
  affected?: number | null;
}

/**
 * Interface of the simple literal object with any string keys.
 */
export type ObjectLiteral = Record<string, any>;

/**
 * Result object returned by InsertQueryBuilder execution.
 */
export interface InsertResult {
  /**
   * Contains inserted entity id.
   * Has entity-like structure (not just column database name and values).
   */
  identifiers: ObjectLiteral[];

  /**
   * Generated values returned by a database.
   * Has entity-like structure (not just column database name and values).
   */
  generatedMaps: ObjectLiteral[];

  /** Raw SQL result returned by executed query. */
  raw: any;
}

/**
 * Result object returned by UpdateQueryBuilder execution.
 */
export interface UpdateResult {
  /** Raw SQL result returned by executed query. */
  raw: any;

  /**
   * Number of affected rows/documents
   * Not all drivers support this
   * @format double
   */
  affected?: number;

  /**
   * Generated values returned by a database.
   * Has entity-like structure (not just column database name and values).
   */
  generatedMaps: ObjectLiteral[];
}

export interface AdminUser {
  /** @format double */
  id: number;
  email: string;
  scopes: string[];
  name?: string;
  note?: string;

  /** @format double */
  unid?: number;
}

export interface Pagination {
  /** @format double */
  totalCount?: number;

  /** @format double */
  currentPage?: number;

  /** @format double */
  displayPage?: number;

  /** @format double */
  displayRow?: number;

  /** @format double */
  range?: number;

  /** @format double */
  rangeSize?: number;

  /** @format double */
  startRowNum?: number;

  /** @format double */
  endRowNum?: number;

  /** @format double */
  totalPage?: number;

  /** @format double */
  beginPage?: number;

  /** @format double */
  endPage?: number;
  hasPrev?: boolean;
  hasNext?: boolean;
}

export interface AdminReview {
  /** @format double */
  RID: number;

  /** @format double */
  RGID: number;

  /** @format double */
  UNID: number;
  FullName: string;

  /** @format double */
  Age?: number;
  AgeGroup?: string;

  /** @format double */
  ItemID?: number;

  /** @format double */
  OptionID?: number;

  /** @format double */
  OrderID?: number;

  /** @format double */
  Point?: number;
  Contents: string;
  IsVisible?: string;
  Comment?: string;

  /** @format double */
  Version?: number;

  /** @format double */
  ProviderID?: number;
  ProviderName?: string;

  /** @format double */
  AdminUNID?: number;

  /** @format date-time */
  RegDate?: string;

  /** @format date-time */
  ModifiedDate?: string;
  ItemName: string;
  OptionItemName: string;
  SubscriptionType: string;
  OptKey: string;
  OptValue: string;
  OptSubValue: string;
  OptKey2: string;
  OptSubValue2: string;
  OptKey3: string;
  OptValue3: string;
  OptSubValue3: string;
}

export interface AdminReviewStatusParam {
  /** @format double */
  rid: number;
  isVisible: string;
}

export interface TbAdmin {
  /** @format double */
  AID: number;

  /** @format double */
  UNID: number;
  UserName: string;
  GroupName: string;

  /** @format double */
  UserType: number;
  Status: string;

  /** @format date-time */
  RegDate: string;
}

export interface TbAlimtalkTemplate {
  /** @format double */
  id: number;
  name: string;
  code: string;
  content: string;
  button1Name?: string;
  button1Url?: string;
  button2Name?: string;
  button2Url?: string;

  /** @format date-time */
  createdAt?: string;
  protected: string;
}

export interface TbAlimtalkEvent {
  /** @format double */
  id: number;
  description?: string;
  name?: string;

  /** @format double */
  templateId?: number;
  Template?: TbAlimtalkTemplate;
}

/**
 * Make all properties in T optional
 */
export type PartialAny = object;

/**
 * Make all properties in T optional
 */
export interface PartialTbAlimtalkTemplate {
  /** @format double */
  id?: number;
  name?: string;
  code?: string;
  content?: string;
  button1Name?: string;
  button1Url?: string;
  button2Name?: string;
  button2Url?: string;

  /** @format date-time */
  createdAt?: string;
  protected?: string;
}

export interface TbUserInfo {
  /** @format double */
  UNID: number;
  Email: string;
  UserPassword?: string;
  Salt?: string;
  OldPassword?: string;
  UserIP?: string;
  FullName: string;
  DOB: string;
  Phone: string;
  Gender: string;
  AgreeNotifications: string;
  SignupOrigin: string;
  SignupTag?: string;
  PayingCustomer: string;
  SubscriptionStatus: string;
  IsVerifiedEmail: string;
  IsVerifiedPhone: string;
  UserType: string;
  UserGroup: string;
  UserStatus: string;

  /** @format date-time */
  ClosedDate?: string;
  SupportSkipCycle: string;

  /** @format date-time */
  LastLoggedInDate?: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
  Subscription?: TbSubscription;
}

export interface TbEasyPayment {
  /** @format double */
  EPID: number;

  /** @format double */
  UNID: number;

  /** @format double */
  PaymentID: number;
  SubscriptionKey?: string;
  Passcode?: string;
  CardNo?: string;
  CardCode?: string;
  CardName?: string;
  IsDefault: string;
  Status: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
  Payment?: TbPaymentV2;
}

export interface TbSubscription {
  /** @format double */
  SID: number;

  /** @format double */
  UNID: number;

  /** @format double */
  EPID?: number;

  /** @format double */
  AID?: number;

  /** @format double */
  BaseCycle: number;
  BaseTerm: string;

  /** @format date-time */
  ScheduleAt: string;
  Status: string;

  /** @format date-time */
  RegDate: string;
  User?: TbUserInfo;
  Address?: TbAddress;
  EasyPayment?: TbEasyPayment;
  SubscriptionItems?: TbSubscriptionItems[];
  OnetimeItems?: TbNextSubscriptionItems[];
}

export interface TbSubscriptionItems {
  /** @format double */
  SNID: number;

  /** @format double */
  SID: number;

  /** @format double */
  ItemID: number;

  /** @format double */
  OptionID: number;

  /** @format double */
  Quantity: number;

  /** @format double */
  Cycle: number;
  Term: string;

  /** @format date-time */
  ScheduledDate?: string;

  /** @format date-time */
  RegDate: string;
  Subscription?: TbSubscription;
  Item?: TbItemV2;
  ItemOption?: TbItemOptionV2;
}

export interface TbNextSubscriptionItems {
  /** @format double */
  ID: number;

  /** @format double */
  SID: number;

  /** @format double */
  ItemID: number;

  /** @format double */
  OptionID: number;

  /** @format double */
  Quantity: number;

  /** @format date-time */
  RegDate: string;

  /** @format date-time */
  ModifiedDate?: string;
  Subscription?: TbSubscription;
  ItemOption?: TbItemOptionV2;
}

export interface ApiSuccessUserInfoTbUserInfo {
  success: true;
  data?: { userInfo: TbUserInfo };
}

export interface ApiFail {
  success: false;
  data?: any;
}

export type ApiResponseUserInfoTbUserInfo =
  | ApiSuccessUserInfoTbUserInfo
  | ApiFail
  | (ApiSuccessUserInfoTbUserInfo & ApiFail);

export interface SignupForm {
  email: string;
  pwd: string;
  fullName: string;
  dob: string;
  phone: string;
  gender: "M" | "F" | "";
  agreeNotifications: "Y" | "N" | "";
}

export interface ApiSuccessAny {
  success: true;
  data?: any;
}

export type ApiResponseAny = ApiSuccessAny | ApiFail | (ApiSuccessAny & ApiFail);

export interface ApiSuccessBillingKeyStatusBoolean {
  success: true;
  data?: { billingKeyStatus: boolean };
}

export type ApiResponseBillingKeyStatusBoolean =
  | ApiSuccessBillingKeyStatusBoolean
  | ApiFail
  | (ApiSuccessBillingKeyStatusBoolean & ApiFail);

export interface SQLParameter {
  type: string;
  key: string;
  value: any;
}

export type CloseAccountResponseCode = "success" | "active-subscription-exists";

export interface CodeOnlyCloseAccountResponseCode {
  code: CloseAccountResponseCode;
}

export interface CloseAccountRequestParam {
  comment: string;
  reasondetail: string;
  reason: string;
  isreturnwill: string;
}

export interface PaymentAPISuccessTbOrder {
  success: true;
  data: TbOrder;
}

export interface PaymentAPIFail {
  success: false;
  data: { message: string };
}

export type PaymentAPIResponseTbOrder =
  | PaymentAPISuccessTbOrder
  | PaymentAPIFail
  | (PaymentAPISuccessTbOrder & PaymentAPIFail);

/**
 * 가상계좌 환불 Param
 */
export interface CancelVBankParam {
  bank: string;
  accountNumber: string;
  holderName: string;

  /** @format double */
  refundAmount?: number;
}

export interface ApiSuccess {
  success: true;
  data?: object;
}

export type ApiResponse = ApiSuccess | ApiFail | (ApiSuccess & ApiFail);

export interface PaymentAPISuccessTbEasyPayment {
  success: true;
  data: TbEasyPayment;
}

export type PaymentAPIResponseTbEasyPayment =
  | PaymentAPISuccessTbEasyPayment
  | PaymentAPIFail
  | (PaymentAPISuccessTbEasyPayment & PaymentAPIFail);

export interface AdminNicePayRegisterSubscriptionParam {
  /** @format double */
  unid: number;
  cardNo: string;
  expDate: string;
  idNo: string;
  cardPw: string;
}

export interface WiselyApiSuccessWithSuccessCouponMainDtoArray {
  success: true;
  resultTypeCode: SUCCESS_RESULT_TYPE_CODE;
  data: CouponMainDto[];
}

export type WiselyApiResponseCouponMainDtoArray =
  | WiselyApiSuccessWithSuccessCouponMainDtoArray
  | WiselyApiSuccessWithResultCodeDEFAULTRESULTTYPECODE
  | WiselyApiFail
  | (WiselyApiSuccessWithSuccessCouponMainDtoArray &
      WiselyApiSuccessWithResultCodeDEFAULTRESULTTYPECODE &
      WiselyApiFail);

export interface GetCouponPagination {
  pagination: Pagination;
  couponDtos: CouponDto[];
}

export interface WiselyApiSuccessWithSuccessGetCouponPagination {
  success: true;
  resultTypeCode: SUCCESS_RESULT_TYPE_CODE;
  data: GetCouponPagination;
}

export type WiselyApiResponseGetCouponPagination =
  | WiselyApiSuccessWithSuccessGetCouponPagination
  | WiselyApiSuccessWithResultCodeDEFAULTRESULTTYPECODE
  | WiselyApiFail
  | (WiselyApiSuccessWithSuccessGetCouponPagination &
      WiselyApiSuccessWithResultCodeDEFAULTRESULTTYPECODE &
      WiselyApiFail);

export interface OnetimeItem {
  /** @format double */
  ItemID: number;

  /** @format double */
  OptionID: number;

  /** @format double */
  Quantity: number;
  Item: TbItemV2;
  ItemOption: TbItemOptionV2;
}

export interface TbTransactionLog {
  /** @format double */
  TID: number;
  TransactionID: string;
  OrderCID: string;
  StatusCode: string;
  LogData?: string;

  /** @format date-time */
  RegDate: string;
}

export interface NewSubscriptionInfo {
  failLog?: TbTransactionLog;
  onetimeItems: OnetimeItem[];
  subscriptionItems: TbSubscriptionItems[];
  subscription: TbSubscription;
  tpe: "new";
}

export type DataWithCodeNewSubscriptionInfoNotEnoughPrice =
  | { data: NewSubscriptionInfo; code: "success" }
  | { data?: any; code: "not-enough-price" }
  | ({ data: NewSubscriptionInfo; code: "success" } & { data?: any; code: "not-enough-price" });

export type ChangeSubscriptionResponse = DataWithCodeNewSubscriptionInfoNotEnoughPrice;

export interface CancelSubscriptionParam {
  Comment: string;
  CancelData: string;
  ReasonDetail: string;
  Reason: string;
  IsReturnWill: "Y" | "N";
}

export interface SkipItemParam {
  /** @format double */
  optionId: number;

  /** @format double */
  sid?: number;
  receiveThisTime: boolean;
  skip: boolean;
}

export interface UpdateSubscriptionParam {
  term?: "W";

  /** @format double */
  cycle?: number;

  /** @format double */
  quantity?: number;
  isSubscribed: boolean;

  /** @format double */
  optionId: number;

  /** @format double */
  itemId: number;
}

export interface TbSubscriptionSnapshot {
  /** @format double */
  ID: number;

  /** @format double */
  SID: number;
  Action: string;
  Param: string;
  SnapShot: string;

  /** @format double */
  AdminUNID?: number;

  /** @format date-time */
  RegDate: string;

  /** @format date-time */
  ModifiedDate: string;
}

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickTbUserInfoTypeofuserPublicPropsAtNumber {
  /** @format date-time */
  ModifiedDate?: string;

  /** @format double */
  UNID: number;

  /** @format date-time */
  RegDate: string;
  Email: string;
  FullName: string;
  DOB: string;
  Phone: string;
  Gender: string;
  AgreeNotifications: string;
  PayingCustomer: string;
  SubscriptionStatus: string;
  IsVerifiedEmail: string;
  IsVerifiedPhone: string;
  UserType: string;
  SupportSkipCycle: string;

  /** @format date-time */
  LastLoggedInDate?: string;
}

export type User = PickTbUserInfoTypeofuserPublicPropsAtNumber & { passwordExists: boolean };

/**
 * Construct a type with a set of properties K of type T
 */
export type RecordNumberBrandAgreeNotification = object;

export type AgreeNotification = RecordNumberBrandAgreeNotification;

export interface TbSocialLogin {
  /** @format double */
  SID: number;

  /** @format double */
  UNID?: number;
  UserIP?: string;
  Status?: string;
  SNType: string;
  SNID: string;
  NickName?: string;
  FullName?: string;
  FirstName?: string;
  LastName?: string;
  MiddleName?: string;
  Email?: string;
  Phone?: string;
  Location?: string;
  Birthday?: string;
  Gender?: string;
  ProfileImageURL?: string;
  Locale?: string;
  ChannelRelation?: string;

  /** @format date-time */
  LastLoggedInDate?: string;

  /** @format date-time */
  RegDate: string;
}

export interface UserInfo {
  easypay?: TbEasyPayment;
  address?: TbAddress;
  socialLogin?: TbSocialLogin;
  agreeNotification: AgreeNotification;
  user: User;
}

export interface UserEmail {
  Email: string;
  IsPassword: string;
  SubscriptionStatus: string;
  SocialLogin: string;
  RegDate: string;
}

/**
 * Make all properties in T optional
 */
export interface PartialPickTbUserInfoFullNameOrPhone {
  FullName?: string;
  Phone?: string;
}

export interface SucceededTbEasyPayment {
  success: true;
  data: TbEasyPayment;
}

export interface Failed {
  success: false;
  data?: any;
}

export type FallibleResponseTbEasyPayment = SucceededTbEasyPayment | Failed | (SucceededTbEasyPayment & Failed);

export interface SucceededString {
  success: true;
  data: string;
}

export type FallibleResponseString = SucceededString | Failed | (SucceededString & Failed);

export type SetAddressFailCode = "cannot-update-order-addr" | "cannot-change-shipping-fee";

export type DataWithCodeAddressTbAddressOrder3F3AtbOrderSetAddressFailCode =
  | { data: { order?: TbOrder; address: TbAddress }; code: "success" }
  | { data?: any; code: SetAddressFailCode }
  | ({ data: { order?: TbOrder; address: TbAddress }; code: "success" } & { data?: any; code: SetAddressFailCode });

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickTbAddressExcludeKeysAIDOrAddressOmitKey {
  FullName: string;
  Phone: string;
  Postcode: string;
  Country: string;
  City: string;
  Address1: string;
  Address2?: string;

  /** @format double */
  AddressType?: number;
  IsDefault: string;
  Memo?: string;
}

/**
 * Construct a type with the properties of T except for those in type K.
 */
export type OmitTbAddressAIDOrAddressOmitKey = PickTbAddressExcludeKeysAIDOrAddressOmitKey;

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickTbAddressExcludeKeysAddressOmitKey {
  FullName: string;
  Phone: string;

  /** @format double */
  AID: number;
  Postcode: string;
  Country: string;
  City: string;
  Address1: string;
  Address2?: string;

  /** @format double */
  AddressType?: number;
  IsDefault: string;
  Memo?: string;
}

/**
 * Construct a type with the properties of T except for those in type K.
 */
export type OmitTbAddressAddressOmitKey = PickTbAddressExcludeKeysAddressOmitKey;

/**
 * Construct a type with a set of properties K of type T
 */
export type RecordNumberAny = object;

export interface TbEvent {
  /** @format double */
  ID: number;

  /** @format double */
  OrderID?: number;

  /** @format double */
  UNID: number;
  EventType: string;
  EventStatus: string;

  /** @format date-time */
  UsedDate?: string;

  /** @format date-time */
  ExpireDate: string;
  MetaData?: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
}

export interface TbTrialSetInfo {
  /** @format double */
  TSID: number;

  /** @format double */
  TMID: number;
  CacheKey: string;

  /** @format double */
  OrderID?: number;

  /** @format double */
  UNID?: number;
  CartType: string;

  /** @format double */
  ProviderID: number;

  /** @format double */
  TrialPeriod: number;
  Interval: string;

  /** @format double */
  Price: number;
  Status: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
  TrialUserItems?: TbTrialUserItems[];
}

export interface TbTrialSetItem {
  /** @format double */
  TCID: number;

  /** @format double */
  TMID: number;

  /** @format double */
  ProviderID: number;

  /** @format double */
  ItemID: number;

  /** @format double */
  OptionID: number;

  /** @format double */
  RelatedOptionID?: number;

  /** @format double */
  Quantity: number;
  Status: string;

  /** @format date-time */
  RegDate: string;
  ItemOption?: TbItemOptionV2;
  Item?: TbItemV2;
}

export interface TbTrialUserItems {
  /** @format double */
  TUID: number;

  /** @format double */
  TMID: number;

  /** @format double */
  TSID: number;

  /** @format double */
  TCID: number;

  /** @format double */
  ProviderID: number;

  /** @format double */
  ItemID: number;

  /** @format double */
  OptionID: number;

  /** @format double */
  Quantity: number;
  Status: string;

  /** @format date-time */
  RegDate: string;
  TrialSetInfo?: TbTrialSetInfo;
  ItemOption?: TbItemOptionV2;
  Item?: TbItemV2;
  TrialSetItem?: TbTrialSetItem;
}

export type ProviderId = 100000000 | 200000000 | 300000000 | 400000000;

export interface SucceededProviderIdArray {
  success: true;
  data: ProviderId[];
}

export type SimpleResponseProviderIdArray = SucceededProviderIdArray;

export interface PromotionStatus {
  /** wiselyshave 무료 면도기 */
  receivedFreeZeroUp: boolean;

  /** wiselyshave 무료 면도기 */
  comingFreeZeroUp: boolean;
  receivedTrialSet: boolean;

  /** 관련된 아이템을 이미 구독중이어서 trialset 을 받을 수 없음 */
  subscribedRelatedItem: boolean;
}

export type DataWithCodeAddressTbAddressSetAddressFailCode =
  | { data: { address: TbAddress }; code: "success" }
  | { data?: any; code: SetAddressFailCode }
  | ({ data: { address: TbAddress }; code: "success" } & { data?: any; code: SetAddressFailCode });

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickTbUserInfoTypeofuserInfoFieldsAtNumber {
  /** @format date-time */
  ModifiedDate?: string;

  /** @format double */
  UNID: number;

  /** @format date-time */
  RegDate: string;
  Email: string;
  UserIP?: string;
  FullName: string;
  DOB: string;
  Phone: string;
  Gender: string;
  AgreeNotifications: string;
  SignupOrigin: string;
  PayingCustomer: string;
  SubscriptionStatus: string;
  IsVerifiedEmail: string;
  IsVerifiedPhone: string;
  UserType: string;
  UserStatus: string;

  /** @format date-time */
  LastLoggedInDate?: string;
}

/**
 * API 반환값으로 사용되는 Type
 */
export type UserInfos = PickTbUserInfoTypeofuserInfoFieldsAtNumber;

export interface ApiSuccessUserInfos {
  success: true;

  /** API 반환값으로 사용되는 Type */
  data?: UserInfos;
}

export type ApiResponseUserInfos = ApiSuccessUserInfos | ApiFail | (ApiSuccessUserInfos & ApiFail);

/**
* 회원 가입 시에
수신동의 데이터를 입력받을 때
사용되는 Param 객체
*/
export interface AgreeNotificationParam {
  /** @format double */
  ProviderID: number;
  SMS: string;
  Email: string;
  KakaoTalk: string;
}

/**
 * 회원가입시에 사용되는 Form Parameter
 */
export interface SignUpParam {
  email: string;
  pwd: string;
  fullName: string;
  dob: string;
  phone: string;
  gender: string;
  agreeNotifications: string;
  agreeNotificationsJson: AgreeNotificationParam[];
  referralType: string;
  referralNote: string;

  /** @format double */
  providerId: number;
  signupTag?: string;
}

/**
 * API 의 결과 반환값들을 명시한 Enum
 */
export type SignInEnum = "success" | "userAlreadyLoggedIn" | "passwordNotMatch";

export interface ApiSuccessWithContextSignInEnumUserInfos {
  success: true;

  /** API 의 결과 반환값들을 명시한 Enum */
  resultTypeCode: SignInEnum;

  /** API 반환값으로 사용되는 Type */
  data?: UserInfos;
}

export interface ApiFailWithContext {
  success: false;
  resultTypeCode: string;
  data?: any;
}

export type ApiResponseWithContextSignInEnumUserInfos =
  | ApiSuccessWithContextSignInEnumUserInfos
  | ApiFailWithContext
  | (ApiSuccessWithContextSignInEnumUserInfos & ApiFailWithContext);

export interface SignInParam {
  email: string;
  pwd: string;
}

export type LinkKakaoBySignInEnum = "passwordNotMatch" | "success";

export interface ApiSuccessWithContextLinkKakaoBySignInEnumUserInfos {
  success: true;
  resultTypeCode: LinkKakaoBySignInEnum;

  /** API 반환값으로 사용되는 Type */
  data?: UserInfos;
}

export type ApiResponseWithContextLinkKakaoBySignInEnumUserInfos =
  | ApiSuccessWithContextLinkKakaoBySignInEnumUserInfos
  | ApiFailWithContext
  | (ApiSuccessWithContextLinkKakaoBySignInEnumUserInfos & ApiFailWithContext);

export interface LinkKakaoBySignInParam {
  /** @format double */
  sid: number;
  token: string;
  email: string;
  pwd: string;
}

export type ChangePasswordEnum = "passwordNotMatch" | "success";

export interface ApiSuccessWithContextChangePasswordEnumUserInfos {
  success: true;
  resultTypeCode: ChangePasswordEnum;

  /** API 반환값으로 사용되는 Type */
  data?: UserInfos;
}

export type ApiResponseWithContextChangePasswordEnumUserInfos =
  | ApiSuccessWithContextChangePasswordEnumUserInfos
  | ApiFailWithContext
  | (ApiSuccessWithContextChangePasswordEnumUserInfos & ApiFailWithContext);

export interface ChangePasswordParam {
  pwd: string;
  newPwd: string;
}

export type VerifyEmailEnum = "invalidEmail" | "emailNotExists" | "emailExists";

export interface VerifyEmailResponse {
  EmailValidation: { UserType: string; IsPassword: string; SocialLogin: string; FullName: string };
}

export interface ApiSuccessWithContextVerifyEmailEnumVerifyEmailResponse {
  success: true;
  resultTypeCode: VerifyEmailEnum;
  data?: VerifyEmailResponse;
}

export type ApiResponseWithContextVerifyEmailEnumVerifyEmailResponse =
  | ApiSuccessWithContextVerifyEmailEnumVerifyEmailResponse
  | ApiFailWithContext
  | (ApiSuccessWithContextVerifyEmailEnumVerifyEmailResponse & ApiFailWithContext);

export interface VerifyEmailParam {
  email: string;
}

export interface SetPasswordParam {
  /** @format double */
  snid: number;
  pwd: string;
}

export type ChangePasswordByTokenEnum = "invalidToken" | "success";

export interface ApiSuccessWithContextChangePasswordByTokenEnumUserInfos {
  success: true;
  resultTypeCode: ChangePasswordByTokenEnum;

  /** API 반환값으로 사용되는 Type */
  data?: UserInfos;
}

export type ApiResponseWithContextChangePasswordByTokenEnumUserInfos =
  | ApiSuccessWithContextChangePasswordByTokenEnumUserInfos
  | ApiFailWithContext
  | (ApiSuccessWithContextChangePasswordByTokenEnumUserInfos & ApiFailWithContext);

export interface ChangePasswordByTokenParam {
  token: string;
  pwd: string;
}

export type AuthResponseEnum = "success" | "HasAccountNotMatched";

export interface AuthResponse {
  data?: any;
  resultTypeCode: AuthResponseEnum;
  success: boolean;
}

export interface LinkKakaoBySignUpParam {
  /** @format double */
  sid: number;
  token: string;
  email: string;
  fullname: string;
  dob: string;
  gender: string;
  agreenotifications: string;
  agreenotificationsjson: string;
  kakaoToken?: string;
  phone: string;
  referraltype: string;
  referralnote: string;

  /** @format double */
  providerid: number;
}

export interface LinkKakaoByEmailParam {
  /** @format double */
  sid: number;
  token: string;
  email: string;
}

export interface DiscountRate {
  /** @format double */
  rate: number;

  /** @format double */
  quantity: number;
  condition: "=" | ">=";
}

export interface CartItem {
  bulkDiscount?: DiscountRate[];

  /** @format double */
  quantity: number;
  option: TbItemOptionV2;
  item: TbItemV2;
}

export interface CartResponse {
  cartItems: CartItem[];
  priceInfo: {
    totalAmount: number;
    itemPriceAmount: number;
    discountAmount: number;
    additionalShippingFee: number;
    shippingFee: number;
  };
}

export interface CartItemParam {
  /** @format double */
  quantity: number;

  /** @format double */
  optionId: number;
}

/**
 * API 의 결과 반환값들을 명시한 Enum
 */
export type DeliveryResponseEnum = "success" | "deliveryListNotExists";

export interface DeliveryItemList {
  /** @format double */
  DID: number;
  BrandName: string;

  /** @format double */
  ExportItemNo: number;
  Blank: string;
  DCID: string;

  /** @format double */
  OrderID: number;
  OptionItemName: string;
  ProductCode: string;

  /** @format double */
  Quantity: number;
  SenderFullName: string;
  SenderPhone: string;
  RecipientFullName: string;
  RecipientPhone: string;
  RecipientPostcode: string;
  RecipientAddress: string;
  Memo: string;
  DeliveryType: string;

  /** @format double */
  ItemRowCount: number;

  /** @format double */
  OptionID: number;

  /** @format date-time */
  RegDate: string;

  /** @format date-time */
  ApprovedDate: string;
}

export interface TbDeliveryMain {
  /** @format double */
  DMID: number;

  /** @format date-time */
  ExportedDate?: string;

  /** @format double */
  DeliveryCount: number;

  /** @format double */
  OrderCount: number;

  /** @format double */
  ItemCount: number;

  /** @format double */
  CombinedCount: number;

  /** @format double */
  AdminUNID?: number;
  Status: string;

  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate: string;
}

export interface DeliveryResponseDeliveryItemListDeliveryItemListArrayDeliveryMainTbDeliveryMain {
  success: boolean;

  /** API 의 결과 반환값들을 명시한 Enum */
  resultTypeCode?: DeliveryResponseEnum;
  data?: { deliveryMain: TbDeliveryMain; deliveryItemList: DeliveryItemList[] };
}

export interface DeliveryResponseAny {
  success: boolean;

  /** API 의 결과 반환값들을 명시한 Enum */
  resultTypeCode?: DeliveryResponseEnum;
  data?: any;
}

export interface RegisterDeliveryTrackNoParam {
  dcid: string;
  trackNo: string;
}

export interface FixDeliveryParam {
  dcid: string;
}

export interface DeliveryMain {
  RN: string;

  /** @format double */
  DMID: number;
  ExportedDate: string;

  /** @format double */
  DeliveryCount: number;

  /** @format double */
  OrderCount: number;

  /** @format double */
  ItemCount: number;

  /** @format double */
  CombinedCount: number;

  /** @format double */
  AdminUNID: number;
  Status: string;
  ModifiedDate: string;
  RegDate: string;
  FullName: string;
}

export interface DeliveryResponsePaginationPaginationDeliveryMainListDeliveryMainArray {
  success: boolean;

  /** API 의 결과 반환값들을 명시한 Enum */
  resultTypeCode?: DeliveryResponseEnum;
  data?: { deliveryMainList: DeliveryMain[]; pagination: Pagination };
}

export type JWTUserInfo = User & { exp?: number };

export type CheckCouponCode =
  | "too-many-attempts"
  | "code-not-matched"
  | "coupon-expired"
  | "coupon-already-used"
  | "coupon-not-activated";

export type DataWithCodeCouponTbCouponVerificationTokenStringCheckCouponCode =
  | { data: { verificationToken: string; coupon: TbCoupon }; code: "success" }
  | { data?: any; code: CheckCouponCode }
  | ({ data: { verificationToken: string; coupon: TbCoupon }; code: "success" } & {
      data?: any;
      code: CheckCouponCode;
    });

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickTbReviewTypeofreviewFieldsAtNumber {
  /** @format date-time */
  ModifiedDate?: string;

  /** @format double */
  UNID: number;

  /** @format date-time */
  RegDate?: string;
  FullName: string;

  /** @format double */
  ProviderID?: number;

  /** @format double */
  ItemID?: number;

  /** @format double */
  RID: number;

  /** @format double */
  RGID: number;
  AgeGroup?: string;

  /** @format double */
  OptionID?: number;

  /** @format double */
  Point?: number;
  Contents: string;
  ProviderName?: string;
}

export interface ReviewWithOptValue {
  /** @format date-time */
  ModifiedDate?: string;

  /** @format double */
  UNID: number;

  /** @format date-time */
  RegDate?: string;
  FullName: string;

  /** @format double */
  ProviderID?: number;

  /** @format double */
  ItemID?: number;

  /** @format double */
  RID: number;

  /** @format double */
  RGID: number;
  AgeGroup?: string;

  /** @format double */
  OptionID?: number;

  /** @format double */
  Point?: number;
  Contents: string;
  ProviderName?: string;
  OptValue: string;
}

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickTbReviewMainTypeofreviewMainFieldsAtNumber {
  /** @format double */
  RGID: number;

  /** @format double */
  RMID: number;

  /** @format double */
  P1: number;

  /** @format double */
  P2: number;

  /** @format double */
  P3: number;

  /** @format double */
  P4: number;

  /** @format double */
  P5: number;

  /** @format double */
  TotalCount: number;

  /** @format double */
  NewCount: number;

  /** @format double */
  MaxPoint: number;
}

export type ReviewMain = PickTbReviewMainTypeofreviewMainFieldsAtNumber & { TotalRate: number };

export interface ReviewBoard {
  isWritable: boolean;
  reviewMain?: ReviewMain;
  reviews: ReviewWithOptValue[];
  myReview: ReviewWithOptValue[];
  pagination?: Pagination;
}

export interface ApiSuccessReviewBoard {
  success: true;
  data?: ReviewBoard;
}

export type ApiResponseReviewBoard = ApiSuccessReviewBoard | ApiFail | (ApiSuccessReviewBoard & ApiFail);

/**
 * Client 에 전달되어야 할 필드들 (DTO)
 */
export type Review = PickTbReviewTypeofreviewFieldsAtNumber;

export interface ApiSuccessReview {
  success: true;

  /** Client 에 전달되어야 할 필드들 (DTO) */
  data?: Review;
}

export type ApiResponseReview = ApiSuccessReview | ApiFail | (ApiSuccessReview & ApiFail);

export interface ApiSuccessCheckSkipAvailableBoolean {
  success: true;
  data?: { checkSkipAvailable: boolean };
}

export type ApiResponseCheckSkipAvailableBoolean =
  | ApiSuccessCheckSkipAvailableBoolean
  | ApiFail
  | (ApiSuccessCheckSkipAvailableBoolean & ApiFail);

export interface SucceededIsAvailableBoolean {
  success: true;
  data: { isAvailable: boolean };
}

export type SimpleResponseIsAvailableBoolean = SucceededIsAvailableBoolean;

export interface GetOrderResponse {
  deliveryInfo?: TbDeliveryOrders;
  vBankInfo?: TbOrderVBank;
  order: TbOrder;
}

export interface CheckoutInfo {
  priceInfo: {
    totalAmount: number;
    itemPriceAmount: number;
    discountAmount: number;
    additionalShippingFee: number;
    shippingFee: number;
  };
  itemsInfo: {
    amount: number;
    price: number;
    quantity: number;
    description: string;
    optSubvalue: string;
    optValue: string;
    categoryName: string;
    filePath: string;
    itemName: string;
    optionId: number;
  }[];
}

export interface InitOrderResponse {
  order: TbOrder;
  payments: TbPaymentV2[];
  checkoutInfo: CheckoutInfo;
}

export interface WiselyApiSuccessWithSuccessInitOrderResponse {
  success: true;
  resultTypeCode: SUCCESS_RESULT_TYPE_CODE;
  data: InitOrderResponse;
}

export type InitOrderResponseEnum = "addressNotExists" | "cannotSubscribeSameBrand" | "couponNotAvailable";

export type InitTrialOrderEnum =
  | InitOrderResponseEnum
  | "trialNotAvailable"
  | "invalidOptionId"
  | ((InitOrderResponseEnum & "trialNotAvailable") | "invalidOptionId");

export interface WiselyApiSuccessWithResultCodeInitTrialOrderEnum {
  success: true;
  resultTypeCode: InitTrialOrderEnum;
}

export type WiselyApiResponseInitOrderResponseInitTrialOrderEnum =
  | WiselyApiSuccessWithSuccessInitOrderResponse
  | WiselyApiSuccessWithResultCodeInitTrialOrderEnum
  | WiselyApiFail
  | (WiselyApiSuccessWithSuccessInitOrderResponse & WiselyApiSuccessWithResultCodeInitTrialOrderEnum & WiselyApiFail);

export interface ItemParam {
  /** @format double */
  optionId: number;

  /** @format double */
  quantity: number;
}

export type SubscriptionCycle = 4 | 8 | 16;

export interface CommonOrderParam {
  utmData: any;

  /** @format double */
  aid?: number;
  couponIds?: number[];
}

export type TrialOrderParam = {
  providerId: 100000000 | 200000000;
  tsid: number;
  cycle: SubscriptionCycle;
  subscriptionItems: ItemParam[];
  trialItems: ItemParam[];
} & CommonOrderParam;

export type InitSubscriptionOrderEnum =
  | InitOrderResponseEnum
  | "subscriptionExists"
  | "couponNotExists"
  | ((InitOrderResponseEnum & "subscriptionExists") | "couponNotExists");

export interface WiselyApiSuccessWithResultCodeInitSubscriptionOrderEnum {
  success: true;
  resultTypeCode: InitSubscriptionOrderEnum;
}

export type WiselyApiResponseInitOrderResponseInitSubscriptionOrderEnum =
  | WiselyApiSuccessWithSuccessInitOrderResponse
  | WiselyApiSuccessWithResultCodeInitSubscriptionOrderEnum
  | WiselyApiFail
  | (WiselyApiSuccessWithSuccessInitOrderResponse &
      WiselyApiSuccessWithResultCodeInitSubscriptionOrderEnum &
      WiselyApiFail);

export type SubscriptionOrderParam = {
  coupon?: { token: string; cid: number };
  cycle: SubscriptionCycle;
  items: ItemParam[];
} & CommonOrderParam;

export type InitOnetimeOrderEnum = InitOrderResponseEnum;

export interface WiselyApiSuccessWithResultCodeInitOnetimeOrderEnum {
  success: true;
  resultTypeCode: InitOnetimeOrderEnum;
}

export type WiselyApiResponseInitOrderResponseInitOnetimeOrderEnum =
  | WiselyApiSuccessWithSuccessInitOrderResponse
  | WiselyApiSuccessWithResultCodeInitOnetimeOrderEnum
  | WiselyApiFail
  | (WiselyApiSuccessWithSuccessInitOrderResponse & WiselyApiSuccessWithResultCodeInitOnetimeOrderEnum & WiselyApiFail);

export type OnetimeOrderParam = { giveaways?: { items: ItemParam[] }; items: ItemParam[] } & CommonOrderParam;

export type DataWithCodeTbOrderSetAddressFailCode =
  | { data: TbOrder; code: "success" }
  | { data?: any; code: SetAddressFailCode }
  | ({ data: TbOrder; code: "success" } & { data?: any; code: SetAddressFailCode });

export type SetAddressResult = DataWithCodeTbOrderSetAddressFailCode;

export interface GetOrderCountResponse {
  /** @format double */
  orderCount: number;
  orderCountList: Record<string, number>;
}

export interface ApiSuccessGetOrderCountResponse {
  success: true;
  data?: GetOrderCountResponse;
}

export type ApiResponseGetOrderCountResponse =
  | ApiSuccessGetOrderCountResponse
  | ApiFail
  | (ApiSuccessGetOrderCountResponse & ApiFail);

export interface ApiSuccessCheckoutInfo {
  success: true;
  data?: CheckoutInfo;
}

export type ApiResponseCheckoutInfo = ApiSuccessCheckoutInfo | ApiFail | (ApiSuccessCheckoutInfo & ApiFail);

export interface ApiSuccessOrderTbOrder {
  success: true;
  data?: { order: TbOrder };
}

export type ApiResponseOrderTbOrder = ApiSuccessOrderTbOrder | ApiFail | (ApiSuccessOrderTbOrder & ApiFail);

export type OrderClaim = "return" | "exchange";

export interface SetPaymentResponse {
  OrderCID: string;
  OrderName: string;

  /** @format double */
  TotalAmount: number;
  CustomerName: string;

  /** @format double */
  ProductCount: number;
  ProductName: string;
}

export interface PaymentAPISuccessSetPaymentResponse {
  success: true;
  data: SetPaymentResponse;
}

export type PaymentAPIResponseSetPaymentResponse =
  | PaymentAPISuccessSetPaymentResponse
  | PaymentAPIFail
  | (PaymentAPISuccessSetPaymentResponse & PaymentAPIFail);

export interface TossPayRequestPaymentResponse {
  code: 0;

  /** @format double */
  status: number;
  checkoutPage: string;
  payToken: string;
}

export interface PaymentAPISuccessTossPayRequestPaymentResponse {
  success: true;
  data: TossPayRequestPaymentResponse;
}

export type PaymentAPIResponseTossPayRequestPaymentResponse =
  | PaymentAPISuccessTossPayRequestPaymentResponse
  | PaymentAPIFail
  | (PaymentAPISuccessTossPayRequestPaymentResponse & PaymentAPIFail);

export interface KakaoPayReadyResponse {
  tid: string;
  next_redirect_app_url: string;
  next_redirect_mobile_url: string;
  next_redirect_pc_url: string;
  android_app_scheme: string;
  ios_app_scheme: string;
  created_at: string;
}

export interface PaymentAPISuccessKakaoPayReadyResponse {
  success: true;
  data: KakaoPayReadyResponse;
}

export type PaymentAPIResponseKakaoPayReadyResponse =
  | PaymentAPISuccessKakaoPayReadyResponse
  | PaymentAPIFail
  | (PaymentAPISuccessKakaoPayReadyResponse & PaymentAPIFail);

export interface PaymentAPISuccessCheckoutAndroidUriStringCheckoutIosUriStringCheckoutUriString {
  success: true;
  data: { checkoutUri: string; checkoutIosUri: string; checkoutAndroidUri: string };
}

export type PaymentAPIResponseCheckoutAndroidUriStringCheckoutIosUriStringCheckoutUriString =
  | PaymentAPISuccessCheckoutAndroidUriStringCheckoutIosUriStringCheckoutUriString
  | PaymentAPIFail
  | (PaymentAPISuccessCheckoutAndroidUriStringCheckoutIosUriStringCheckoutUriString & PaymentAPIFail);

export interface NaverPayRegisterRecurrentResponse {
  success: true;

  /** @format double */
  statusCode: number;
  data: any;
  code: "Success";
  message: string;
  body: { reserveId: string };
}

export interface PaymentAPISuccessNaverPayRegisterRecurrentResponse {
  success: true;
  data: NaverPayRegisterRecurrentResponse;
}

export type PaymentAPIResponseNaverPayRegisterRecurrentResponse =
  | PaymentAPISuccessNaverPayRegisterRecurrentResponse
  | PaymentAPIFail
  | (PaymentAPISuccessNaverPayRegisterRecurrentResponse & PaymentAPIFail);

/**
 * PG 목록
 */
export type PaymentType = "iamport" | "nicepayments" | "naverpay" | "toss" | "tosspay" | "kakaopay";

export interface PaymentAPISuccessEasyPaymentTbEasyPayment {
  success: true;
  data: { easyPayment: TbEasyPayment };
}

export type PaymentAPIResponseEasyPaymentTbEasyPayment =
  | PaymentAPISuccessEasyPaymentTbEasyPayment
  | PaymentAPIFail
  | (PaymentAPISuccessEasyPaymentTbEasyPayment & PaymentAPIFail);

export type PaymentAPIEasyPaymentResponse = PaymentAPIResponseEasyPaymentTbEasyPayment;

export interface TossPayReserveRegisterSubscriptionResponse {
  code: 0;
  billingKey: string;
  checkoutAndroidUri: string;
  checkoutIosUri: string;
  checkoutUri: string;
}

export interface PaymentAPISuccessTossPayReserveRegisterSubscriptionResponse {
  success: true;
  data: TossPayReserveRegisterSubscriptionResponse;
}

export type PaymentAPIResponseTossPayReserveRegisterSubscriptionResponse =
  | PaymentAPISuccessTossPayReserveRegisterSubscriptionResponse
  | PaymentAPIFail
  | (PaymentAPISuccessTossPayReserveRegisterSubscriptionResponse & PaymentAPIFail);

export interface PaymentAPISuccessIsActiveBoolean {
  success: true;
  data: { isActive: boolean };
}

export type PaymentAPIResponseIsActiveBoolean =
  | PaymentAPISuccessIsActiveBoolean
  | PaymentAPIFail
  | (PaymentAPISuccessIsActiveBoolean & PaymentAPIFail);

export interface NicePaySetEscrowResponse {
  ResultCode: string;
  ResultMsg: string;
  ProcessDate: string;
  ProcessTime: string;
}

export interface NicePayFailResponse {
  ResultCode: string;
  ResultMsg: string;
}

export interface PaymentAPISuccessNicePaySetEscrowResponseOrNicePayFailResponse {
  success: true;
  data: NicePaySetEscrowResponse | NicePayFailResponse | (NicePaySetEscrowResponse & NicePayFailResponse);
}

export type PaymentAPIResponseNicePaySetEscrowResponseOrNicePayFailResponse =
  | PaymentAPISuccessNicePaySetEscrowResponseOrNicePayFailResponse
  | PaymentAPIFail
  | (PaymentAPISuccessNicePaySetEscrowResponseOrNicePayFailResponse & PaymentAPIFail);

export interface TossVBankCallbackParam {
  secret: string;
  status: string;
  orderId: string;
}

export interface ApiSuccessReviewArray {
  success: true;
  data?: Review[];
}

export type ApiResponseReviewArray = ApiSuccessReviewArray | ApiFail | (ApiSuccessReviewArray & ApiFail);

export interface ApiSuccessReviewMain {
  success: true;
  data?: ReviewMain;
}

export type ApiResponseReviewMain = ApiSuccessReviewMain | ApiFail | (ApiSuccessReviewMain & ApiFail);

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickTbReviewGroupTypeofreviewGroupFieldsAtNumber {
  /** @format date-time */
  ModifiedDate?: string;

  /** @format date-time */
  RegDate?: string;

  /** @format double */
  ProviderID?: number;

  /** @format double */
  RGID: number;
  ProviderName: string;

  /** @format double */
  MaxPoint?: number;
  Image?: string;
  ImageThumbNail?: string;

  /** @format double */
  Position?: number;
}

export interface ReviewOption {
  OptValue: string;
  CategoryName: string;

  /** @format double */
  OptionID: number;

  /** @format double */
  ItemID: number;
}

export type AvailableReviewGroup = PickTbReviewGroupTypeofreviewGroupFieldsAtNumber & { Options: ReviewOption[] };

export interface ApiSuccessAvailableReviewGroupArray {
  success: true;
  data?: AvailableReviewGroup[];
}

export type ApiResponseAvailableReviewGroupArray =
  | ApiSuccessAvailableReviewGroupArray
  | ApiFail
  | (ApiSuccessAvailableReviewGroupArray & ApiFail);

export interface SucceededTbSubscription {
  success: true;
  data: TbSubscription;
}

export type FallibleResponseTbSubscription = SucceededTbSubscription | Failed | (SucceededTbSubscription & Failed);

export interface UpdateItemParam {
  /** @format double */
  quantity?: number;
  isSubscribed: boolean;

  /** @format double */
  optionId: number;
}

export interface ChangeItemOptionParam {
  /** @format double */
  newOptionId: number;

  /** @format double */
  oldOptionId: number;

  /** @format double */
  itemId: number;
}

export type OrderNowResponse =
  | { order: TbOrder; code: "success" }
  | { failLog?: TbTransactionLog; code: "payment-failed" }
  | ({ order: TbOrder; code: "success" } & { failLog?: TbTransactionLog; code: "payment-failed" });

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  private instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];

  constructor({ securityWorker, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "/" });
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  public request = async <T = any, E = any>({
    secure,
    path,
    type,
    query,
    format = "json",
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams = (secure && this.securityWorker && (await this.securityWorker(this.securityData))) || {};
    const requestParams = this.mergeRequestParams(params, secureParams);

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: format,
      data: body,
      url: path,
    });
  };
}

/**
 * @title api
 * @version 3.1.5-rc.12
 * @license ISC
 * @baseUrl /
 * @contact
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  webhook = {
    /**
     * No description
     *
     * @name HandleInfobipResult
     * @request POST:/webhook/infobip-result
     */
    handleInfobipResult: (data: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/webhook/infobip-result`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name KakaoChannelCallback
     * @request POST:/webhook/kakao-channel-callback
     */
    kakaoChannelCallback: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/webhook/kakao-channel-callback`,
        method: "POST",
        format: "json",
        ...params,
      }),
  };
  coupon = {
    /**
     * @description 유저의 쿠폰을 마이페이지/결제화면에서 등록한다. resultTypeCode - success: 성공
     *
     * @name RegisterCoupon
     * @request POST:/coupon/register-coupon
     * @secure
     */
    registerCoupon: (data: { couponCode: string }, params: RequestParams = {}) =>
      this.request<WiselyApiResponseCouponCouponDto, any>({
        path: `/coupon/register-coupon`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 특정 유저가 보유하고 있는 쿠폰들을 상황에 따라 처리하고 반환한다. - request에 items가 없으면 아래의 (1)번처럼 체크, 있으면 (2)번처럼 체크하는걸로 분기된다. - (1) coupon & couponMain의 기본 조건만 체크 (마이페이지) - (2) 유저가 담아온 items까지 고려하여 사용할 수 있는 쿠폰인지 체크 (결제 페이지) - ResultTypeCode -> success: 성공
     *
     * @name GetUserCoupons
     * @request POST:/coupon/get-user-coupons
     * @secure
     */
    getUserCoupons: (data: { orderType?: WiselyOrderType; items?: ItemParam[] }, params: RequestParams = {}) =>
      this.request<WiselyApiResponseCouponDtoArray, any>({
        path: `/coupon/get-user-coupons`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  admin = {
    /**
     * No description
     *
     * @name GetAdminUsers
     * @request POST:/admin/get-admin-users
     */
    getAdminUsers: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/admin/get-admin-users`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteAdminUser
     * @request POST:/admin/delete-admin-user
     * @secure
     */
    deleteAdminUser: (data: { id: number }, params: RequestParams = {}) =>
      this.request<DeleteResult, any>({
        path: `/admin/delete-admin-user`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AddAdminUser
     * @request POST:/admin/add-admin-user
     * @secure
     */
    addAdminUser: (data: { email: string }, params: RequestParams = {}) =>
      this.request<InsertResult, any>({
        path: `/admin/add-admin-user`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SetAdminInfo
     * @request POST:/admin/update-admin-user
     * @secure
     */
    setAdminInfo: (data: { fields: { roles: string[] }; id: number }, params: RequestParams = {}) =>
      this.request<UpdateResult, any>({
        path: `/admin/update-admin-user`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SignIn
     * @request POST:/admin/sign-in
     */
    signIn: (data: { accessToken: string }, params: RequestParams = {}) =>
      this.request<{ data: AdminUser; message: string }, any>({
        path: `/admin/sign-in`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetCherryPickers
     * @request GET:/admin/cherrypickers
     * @secure
     */
    getCherryPickers: (params: RequestParams = {}) =>
      this.request<object[], any>({
        path: `/admin/cherrypickers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetOrders
     * @request POST:/admin/orders.list
     * @secure
     */
    getOrders: (data: { orderIds: number[] }, params: RequestParams = {}) =>
      this.request<TbOrder[], any>({
        path: `/admin/orders.list`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AdminGetReviewList
     * @request GET:/admin/getReviewList
     * @secure
     */
    adminGetReviewList: (
      query?: {
        pageNumber?: number;
        perPage?: number;
        point?: number;
        rgid?: number;
        optionId?: number;
        isVisible?: string;
        providerId?: number;
        keyword?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<{ reviewList: AdminReview[]; pagination: Pagination }, any>({
        path: `/admin/getReviewList`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ChangeReviewStatus
     * @request POST:/admin/changeReviewStatus
     * @secure
     */
    changeReviewStatus: (data: AdminReviewStatusParam, params: RequestParams = {}) =>
      this.request<{ result: string }, any>({
        path: `/admin/changeReviewStatus`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetOldAdminUsers
     * @request GET:/admin/old-admin-users
     * @secure
     */
    getOldAdminUsers: (params: RequestParams = {}) =>
      this.request<TbAdmin[], any>({
        path: `/admin/old-admin-users`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetAlimtalkEvents
     * @request GET:/admin/alimtalk/events
     * @secure
     */
    getAlimtalkEvents: (params: RequestParams = {}) =>
      this.request<TbAlimtalkEvent[], any>({
        path: `/admin/alimtalk/events`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetAlimtalkTemplates
     * @request GET:/admin/alimtalk/templates
     * @secure
     */
    getAlimtalkTemplates: (params: RequestParams = {}) =>
      this.request<TbAlimtalkTemplate[], any>({
        path: `/admin/alimtalk/templates`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SaveAlimtalkTemplate
     * @request POST:/admin/alimtalk/template.save
     * @secure
     */
    saveAlimtalkTemplate: (data: PartialTbAlimtalkTemplate, params: RequestParams = {}) =>
      this.request<
        UpdateResult | (PartialAny & TbAlimtalkTemplate) | (UpdateResult & (PartialAny & TbAlimtalkTemplate)),
        any
      >({
        path: `/admin/alimtalk/template.save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SendAlimtalkTemplate
     * @request POST:/admin/alimtalk/template.send
     * @secure
     */
    sendAlimtalkTemplate: (data: { test?: boolean; args: object; templateCode: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin/alimtalk/template.send`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name SendSms
     * @request POST:/admin/send-sms
     * @secure
     */
    sendSms: (data: { contents: string; phone: string; unid: number }, params: RequestParams = {}) =>
      this.request<{ data: string; status: string }, any>({
        path: `/admin/send-sms`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 관리자 페이지 회원가입 API
     *
     * @name SignUp
     * @request POST:/admin/auth/sign-up
     * @secure
     */
    signUp: (data: SignupForm, params: RequestParams = {}) =>
      this.request<ApiResponseUserInfoTbUserInfo, any>({
        path: `/admin/auth/sign-up`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 관리자 페이지에서 비밀번호 변경
     *
     * @name ChangePassword
     * @request POST:/admin/auth/change-password
     * @secure
     */
    changePassword: (data: { newpwd: string; unid: number }, params: RequestParams = {}) =>
      this.request<ApiResponseAny, any>({
        path: `/admin/auth/change-password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 유저의 이벤트를 조회
     *
     * @name GetAvailableEvents
     * @request POST:/admin/get-available-events
     * @secure
     */
    getAvailableEvents: (data: { unid: number }, params: RequestParams = {}) =>
      this.request<ApiResponseAny, any>({
        path: `/admin/get-available-events`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CheckBillingKeyStatus
     * @request POST:/admin/billing-key/status
     * @secure
     */
    checkBillingKeyStatus: (data: { epid: number; unid: number }, params: RequestParams = {}) =>
      this.request<ApiResponseBillingKeyStatusBoolean, any>({
        path: `/admin/billing-key/status`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteEasypayment
     * @request POST:/admin/easypayments.delete
     * @secure
     */
    deleteEasypayment: (data: { epid: number; unid: number }, params: RequestParams = {}) =>
      this.request<ApiResponseAny, any>({
        path: `/admin/easypayments.delete`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 어드민에서 실행할 수 쿼리를 리스트를 반환해주는 API
     *
     * @name GetQueryInfo
     * @request POST:/admin/get-query-info
     * @secure
     */
    getQueryInfo: (params: RequestParams = {}) =>
      this.request<ApiResponseAny, any>({
        path: `/admin/get-query-info`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description 어드민 FE 에서 파라미터를 받아서 쿼리 실행시켜주는 API
     *
     * @name ExecuteQuery
     * @request POST:/admin/execute-query
     * @secure
     */
    executeQuery: (data: { parameter?: SQLParameter[]; queryName: string }, params: RequestParams = {}) =>
      this.request<ApiResponseAny, any>({
        path: `/admin/execute-query`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CloseAccount
     * @request POST:/admin/closeAccount
     * @secure
     */
    closeAccount: (data: { param: CloseAccountRequestParam; unid: number }, params: RequestParams = {}) =>
      this.request<CodeOnlyCloseAccountResponseCode, any>({
        path: `/admin/closeAccount`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SigninAsUser
     * @request POST:/admin/signin-as-user
     * @secure
     */
    signinAsUser: (data: { unid: number }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin/signin-as-user`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name SetOrderItem
     * @request POST:/admin/set-order-item
     * @secure
     */
    setOrderItem: (data: { quantity: number; optionId: number; orderId: number }, params: RequestParams = {}) =>
      this.request<TbOrderItems[], any>({
        path: `/admin/set-order-item`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  adminPayment = {
    /**
     * @description ******************* Payment *****************
     *
     * @name CancelPayment
     * @request POST:/admin-payment/cancel
     * @secure
     */
    cancelPayment: (
      data: { cancelVBankParam?: CancelVBankParam; amount?: number; unid: number; orderId: number },
      params: RequestParams = {},
    ) =>
      this.request<PaymentAPIResponseTbOrder, any>({
        path: `/admin-payment/cancel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 결제 전에 결제수단 등록
     *
     * @name SetPayments
     * @request POST:/admin-payment/set
     * @secure
     */
    setPayments: (data: { paymentId: number; orderId: number; unid: number }, params: RequestParams = {}) =>
      this.request<ApiResponse, any>({
        path: `/admin-payment/set`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RegisterAndPayToss
     * @request POST:/admin-payment/register-and-pay/toss
     * @secure
     */
    registerAndPayToss: (
      data: {
        cardInfo: { cardpwd: string; dob: string; expdate: string; cardno: string };
        orderId: number;
        unid: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<{ data: TbOrder; success: boolean }, any>({
        path: `/admin-payment/register-and-pay/toss`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SetEasyPayment
     * @request POST:/admin-payment/easypayment/set
     * @secure
     */
    setEasyPayment: (
      data: { cardpwd: string; dob: string; expdate: string; cardno: string; unid: number },
      params: RequestParams = {},
    ) =>
      this.request<PaymentAPIResponseTbEasyPayment, any>({
        path: `/admin-payment/easypayment/set`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SubscriptionExecute
     * @request POST:/admin-payment/subscription/execute
     * @secure
     */
    subscriptionExecute: (data: { unid: number; orderId: number }, params: RequestParams = {}) =>
      this.request<PaymentAPIResponseTbOrder, any>({
        path: `/admin-payment/subscription/execute`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RegisterNicepay
     * @request POST:/admin-payment/billing-key/register/nicepay
     * @secure
     */
    registerNicepay: (data: AdminNicePayRegisterSubscriptionParam, params: RequestParams = {}) =>
      this.request<PaymentAPIResponseTbEasyPayment, any>({
        path: `/admin-payment/billing-key/register/nicepay`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RegisterAndPayNicepay
     * @request POST:/admin-payment/register-and-pay/nicepay
     * @secure
     */
    registerAndPayNicepay: (data: AdminNicePayRegisterSubscriptionParam, params: RequestParams = {}) =>
      this.request<PaymentAPIResponseTbEasyPayment, any>({
        path: `/admin-payment/register-and-pay/nicepay`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ApproveOnetimeKeyIn
     * @request POST:/admin-payment/onetime/key-in
     * @secure
     */
    approveOnetimeKeyIn: (
      data: {
        cardInfo: { cardpwd: string; dob: string; expdate: string; cardno: string };
        orderId: number;
        unid: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaymentAPIResponseTbOrder, any>({
        path: `/admin-payment/onetime/key-in`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name InactivateSubscription
     * @request POST:/admin-payment/subscription/inactivate
     * @secure
     */
    inactivateSubscription: (data: { epid: number; unid: number }, params: RequestParams = {}) =>
      this.request<PaymentAPIResponseTbEasyPayment, any>({
        path: `/admin-payment/subscription/inactivate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CheckSubscription
     * @request POST:/admin-payment/subscription/check
     * @secure
     */
    checkSubscription: (data: { epid: number; unid: number }, params: RequestParams = {}) =>
      this.request<ApiResponse, any>({
        path: `/admin-payment/subscription/check`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name TossAdminCallback
     * @request GET:/admin-payment/onetime/callback/toss
     */
    tossAdminCallback: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin-payment/onetime/callback/toss`,
        method: "GET",
        ...params,
      }),
  };
  adminCoupon = {
    /**
     * No description
     *
     * @name GetUserCoupons
     * @request POST:/admin-coupon/get-user-coupons
     * @secure
     */
    getUserCoupons: (data: { unid: number }, params: RequestParams = {}) =>
      this.request<CouponDto[], any>({
        path: `/admin-coupon/get-user-coupons`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetCouponMains
     * @request POST:/admin-coupon/get-coupon-mains
     * @secure
     */
    getCouponMains: (params: RequestParams = {}) =>
      this.request<WiselyApiResponseCouponMainDtoArray, any>({
        path: `/admin-coupon/get-coupon-mains`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetCoupons
     * @request POST:/admin-coupon/get-coupons
     * @secure
     */
    getCoupons: (data: { code?: string; perPage?: number; page?: number }, params: RequestParams = {}) =>
      this.request<WiselyApiResponseGetCouponPagination, any>({
        path: `/admin-coupon/get-coupons`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateCoupon
     * @request POST:/admin-coupon/coupon.update
     * @secure
     */
    updateCoupon: (data: { status?: string; cid: number }, params: RequestParams = {}) =>
      this.request<WiselyApiResponseCouponCouponDto, any>({
        path: `/admin-coupon/coupon.update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  adminOrder = {
    /**
     * No description
     *
     * @name GetOrderCoupon
     * @request POST:/admin-order/get-order-coupon
     * @secure
     */
    getOrderCoupon: (data: { orderId: number }, params: RequestParams = {}) =>
      this.request<CouponDto[], any>({
        path: `/admin-order/get-order-coupon`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  adminSubscription = {
    /**
     * No description
     *
     * @name GetSubscription
     * @request GET:/admin-subscription/subscription
     * @secure
     */
    getSubscription: (query: { unid: number }, params: RequestParams = {}) =>
      this.request<NewSubscriptionInfo, any>({
        path: `/admin-subscription/subscription`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name TriggerSubscription
     * @request POST:/admin-subscription/trigger-subscription
     * @secure
     */
    triggerSubscription: (data: { mode: "daily" | "retry" | "admin"; unid: number }, params: RequestParams = {}) =>
      this.request<{ success: boolean }, any>({
        path: `/admin-subscription/trigger-subscription`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateSubscription
     * @request POST:/admin-subscription/updateSubscription
     * @secure
     */
    updateSubscription: (data: { ScheduleAt?: string; Cycle?: number; UNID: number }, params: RequestParams = {}) =>
      this.request<NewSubscriptionInfo, any>({
        path: `/admin-subscription/updateSubscription`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteSubscriptionItem
     * @request POST:/admin-subscription/deleteSubscriptionItem
     * @secure
     */
    deleteSubscriptionItem: (data: { OptionID: number; UNID: number }, params: RequestParams = {}) =>
      this.request<ChangeSubscriptionResponse, any>({
        path: `/admin-subscription/deleteSubscriptionItem`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CancelSubscription
     * @request POST:/admin-subscription/cancelSubscription
     * @secure
     */
    cancelSubscription: (data: { param: CancelSubscriptionParam; UNID: number }, params: RequestParams = {}) =>
      this.request<NewSubscriptionInfo, any>({
        path: `/admin-subscription/cancelSubscription`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name PauseSubscription
     * @request POST:/admin-subscription/pauseSubscription
     * @secure
     */
    pauseSubscription: (data: { param: CancelSubscriptionParam; UNID: number }, params: RequestParams = {}) =>
      this.request<NewSubscriptionInfo, any>({
        path: `/admin-subscription/pauseSubscription`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RestartSubscription
     * @request POST:/admin-subscription/restartSubscription
     * @secure
     */
    restartSubscription: (data: { ScheduleAt: string; UNID: number }, params: RequestParams = {}) =>
      this.request<NewSubscriptionInfo, any>({
        path: `/admin-subscription/restartSubscription`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SkipItem
     * @request POST:/admin-subscription/skipItem
     * @secure
     */
    skipItem: (data: { param: SkipItemParam; UNID: number }, params: RequestParams = {}) =>
      this.request<ChangeSubscriptionResponse, any>({
        path: `/admin-subscription/skipItem`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateSubscriptionItem
     * @request POST:/admin-subscription/updateSubscriptionItem
     * @secure
     */
    updateSubscriptionItem: (data: { param: UpdateSubscriptionParam; UNID: number }, params: RequestParams = {}) =>
      this.request<ChangeSubscriptionResponse, any>({
        path: `/admin-subscription/updateSubscriptionItem`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SubscriptionSnapshot
     * @request GET:/admin-subscription/subscriptionSnapshot
     * @secure
     */
    subscriptionSnapshot: (query: { sid: number }, params: RequestParams = {}) =>
      this.request<TbSubscriptionSnapshot[], any>({
        path: `/admin-subscription/subscriptionSnapshot`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @name GetUser
     * @request GET:/user/user
     * @secure
     */
    getUser: (params: RequestParams = {}) =>
      this.request<UserInfo, any>({
        path: `/user/user`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetUserEmails
     * @request GET:/user/emails
     */
    getUserEmails: (query: { dob: string; phone: string }, params: RequestParams = {}) =>
      this.request<UserEmail[], any>({
        path: `/user/emails`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateUser
     * @request POST:/user/user.update
     * @secure
     */
    updateUser: (data: PartialPickTbUserInfoFullNameOrPhone, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/user/user.update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetEasyPayment
     * @request GET:/user/payment/easy-payment
     * @secure
     */
    getEasyPayment: (params: RequestParams = {}) =>
      this.request<FallibleResponseTbEasyPayment, any>({
        path: `/user/payment/easy-payment`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetNaverRecurrentId
     * @request GET:/user/payment/naver/recurrent-id
     * @secure
     */
    getNaverRecurrentId: (params: RequestParams = {}) =>
      this.request<FallibleResponseString, any>({
        path: `/user/payment/naver/recurrent-id`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetAddress
     * @request GET:/user/address/{aid}
     * @secure
     */
    getAddress: (aid: number, params: RequestParams = {}) =>
      this.request<TbAddress, any>({
        path: `/user/address/${aid}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetAddresses
     * @request GET:/user/addresses
     * @secure
     */
    getAddresses: (params: RequestParams = {}) =>
      this.request<TbAddress[], any>({
        path: `/user/addresses`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateAddressV2
     * @request POST:/user/address.create.v2
     * @secure
     */
    createAddressV2: (data: { address: OmitTbAddressAIDOrAddressOmitKey }, params: RequestParams = {}) =>
      this.request<DataWithCodeAddressTbAddressOrder3F3AtbOrderSetAddressFailCode, any>({
        path: `/user/address.create.v2`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description response 의 order 필드는 orderIdToSetAddress 를 세팅했을때만 반환됨
     *
     * @name UpdateAddressV2
     * @request POST:/user/address.update.v2
     * @secure
     */
    updateAddressV2: (data: { address: OmitTbAddressAddressOmitKey }, params: RequestParams = {}) =>
      this.request<DataWithCodeAddressTbAddressOrder3F3AtbOrderSetAddressFailCode, any>({
        path: `/user/address.update.v2`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteAddress
     * @request POST:/user/address.delete
     * @secure
     */
    deleteAddress: (data: { AID: number }, params: RequestParams = {}) =>
      this.request<TbAddress, any>({
        path: `/user/address.delete`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ChangeNotificationSetting
     * @request POST:/user/notification.update
     * @secure
     */
    changeNotificationSetting: (
      data: { target?: string; providerId?: number; on: boolean; type: "all" | "brand" | "single" },
      params: RequestParams = {},
    ) =>
      this.request<RecordNumberAny, any>({
        path: `/user/notification.update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetAvailableEvents
     * @request GET:/user/get-available-events
     * @secure
     */
    getAvailableEvents: (params: RequestParams = {}) =>
      this.request<TbEvent[], any>({
        path: `/user/get-available-events`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetTrialSetInfo
     * @request GET:/user/trialset-info
     * @secure
     */
    getTrialSetInfo: (params: RequestParams = {}) =>
      this.request<TbTrialSetInfo[], any>({
        path: `/user/trialset-info`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description 기존 베타테스터 api 대체 [사용중] data 바로 내려주는 api 로 이전
     *
     * @name GetVisibleBrands
     * @request POST:/user/brands
     */
    getVisibleBrands: (data: { visit?: ProviderId }, params: RequestParams = {}) =>
      this.request<SimpleResponseProviderIdArray, any>({
        path: `/user/brands`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetPromotionStatus
     * @request GET:/user/promotion.status
     * @secure
     */
    getPromotionStatus: (query: { providerId: number }, params: RequestParams = {}) =>
      this.request<PromotionStatus, any>({
        path: `/user/promotion.status`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  adminUser = {
    /**
     * No description
     *
     * @name GetAddresses
     * @request GET:/admin-user/addresses
     * @secure
     */
    getAddresses: (query: { unid: number }, params: RequestParams = {}) =>
      this.request<TbAddress[], any>({
        path: `/admin-user/addresses`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateAddress
     * @request POST:/admin-user/address.create
     * @secure
     */
    createAddress: (data: { address: OmitTbAddressAIDOrAddressOmitKey; unid: number }, params: RequestParams = {}) =>
      this.request<DataWithCodeAddressTbAddressOrder3F3AtbOrderSetAddressFailCode, any>({
        path: `/admin-user/address.create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateAddress
     * @request POST:/admin-user/address.update
     * @secure
     */
    updateAddress: (data: { address: OmitTbAddressAddressOmitKey; unid: number }, params: RequestParams = {}) =>
      this.request<DataWithCodeAddressTbAddressSetAddressFailCode, any>({
        path: `/admin-user/address.update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteAddress
     * @request POST:/admin-user/address.delete
     * @secure
     */
    deleteAddress: (data: { unid: number; AID: number }, params: RequestParams = {}) =>
      this.request<TbAddress, any>({
        path: `/admin-user/address.delete`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  auth = {
    /**
     * @description refresh-token 에 성공하는 경우만 200 리턴 이외 모든 경우에 401 리턴. 탈퇴회원의 경우도 동일
     *
     * @name RefreshToken
     * @request GET:/auth/refresh-token
     */
    refreshToken: (params: RequestParams = {}) =>
      this.request<{ subscription?: TbSubscription; user: User }, any>({
        path: `/auth/refresh-token`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description 회원가입 API [dbMember].[dbo].[ext_v1_03_SignUp] - (400 - userAlreadyLoggedIn) 유저가 이미 로그인됨 - (400) (전화번호 || 이메일 || 생년월일) 양식이 이상함 - (400 - alreadySignedUp) 이미 회원가입한 이메일
     *
     * @name SignUp
     * @request POST:/auth/signUp
     */
    signUp: (data: SignUpParam, params: RequestParams = {}) =>
      this.request<ApiResponseUserInfos, any>({
        path: `/auth/signUp`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 로그인 API  [dbMember].[dbo].[ext_v1_01_SignIn] - (400 - emailNotExists) 이메일이 존재하지 않음 - (200 - userAlreadyLoggedIn) 유저 이미 로그인됨 - (200 - passwordNotMatch) 비밀번호 매칭 안됨 - (200 - success) 로그인 성공
     *
     * @name SignIn
     * @request POST:/auth/signIn
     */
    signIn: (data: SignInParam, params: RequestParams = {}) =>
      this.request<ApiResponseWithContextSignInEnumUserInfos, any>({
        path: `/auth/signIn`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 로그인 할 때, 카카오톡 소셜로그인 연결 - (200 - success) : 성공 - (200 - passwordNotMatch) : 비밀번호 일치하지 않음 - (400) : 카카오톡 토큰 일치하지 않음 || (회원 정보 || 소셜로그인 정보가 존재하지 않음) TODO 카카오싱크 도입되면 삭제될 수도 있는 API (체크 필요)
     *
     * @name LinkKakaoBySignIn
     * @request POST:/auth/linkKakaoBySignIn
     */
    linkKakaoBySignIn: (data: LinkKakaoBySignInParam, params: RequestParams = {}) =>
      this.request<ApiResponseWithContextLinkKakaoBySignInEnumUserInfos, any>({
        path: `/auth/linkKakaoBySignIn`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SendResetPasswordLink
     * @request POST:/auth/send-reset-password-link
     */
    sendResetPasswordLink: (data: { sendType: "email" | "phone"; email: string }, params: RequestParams = {}) =>
      this.request<
        | { data: string; status: number }
        | { data: string; status: string }
        | ({ data: string; status: number } & { data: string; status: string }),
        any
      >({
        path: `/auth/send-reset-password-link`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 마이페이지에서 비밀번호 바꾸는 API [dbMember].[dbo].[ext_v1_01_ChangePassword] - (200 - success) : 성공 - (200 - passwordNotMatch) : 비밀번호가 일치하지 않음 - (401) : 유저가 로그인되어있지 않음
     *
     * @name ChangePassword
     * @request POST:/auth/changePassword
     * @secure
     */
    changePassword: (data: ChangePasswordParam, params: RequestParams = {}) =>
      this.request<ApiResponseWithContextChangePasswordEnumUserInfos, any>({
        path: `/auth/changePassword`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 입력한 비밀번호가 valid 한지를 체크한다. - (200) emailNotExists - (200) emailExists - (200) invalidEmail
     *
     * @name VerifyEmail
     * @request POST:/auth/verifyEmail
     */
    verifyEmail: (data: VerifyEmailParam, params: RequestParams = {}) =>
      this.request<ApiResponseWithContextVerifyEmailEnumVerifyEmailResponse, any>({
        path: `/auth/verifyEmail`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 카카오톡 소셜로그인 회원들이 비밀번호를 설정할 때 사용되는 API - (400) : 유저 정보가 올바르지 않음
     *
     * @name SetPassword
     * @request POST:/auth/setPassword
     * @secure
     */
    setPassword: (data: SetPasswordParam, params: RequestParams = {}) =>
      this.request<ApiResponseUserInfos, any>({
        path: `/auth/setPassword`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 토큰을 받아서, 해당 토큰이 유효하면 비밀번호를 바꿔준다. - (200 - success) : 성공 - (200 - invalidToken) : 토큰이 유효하지 않음 (기간 지났거나, 이미 사용되었거나)
     *
     * @name ChangePasswordByToken
     * @request POST:/auth/changePasswordByToken
     */
    changePasswordByToken: (data: ChangePasswordByTokenParam, params: RequestParams = {}) =>
      this.request<ApiResponseWithContextChangePasswordByTokenEnumUserInfos, any>({
        path: `/auth/changePasswordByToken`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 카카오싱크 자동 로그인
     *
     * @name KakaoAutoLogin
     * @request GET:/auth/kakaoAutoLogin
     */
    kakaoAutoLogin: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/auth/kakaoAutoLogin`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description 카카오 계정으로 로그인하기
     *
     * @name KakaoCallback
     * @request POST:/auth/kakaoCallback
     */
    kakaoCallback: (data: { token: string }, params: RequestParams = {}) =>
      this.request<AuthResponse, any>({
        path: `/auth/kakaoCallback`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 카카오 계정으로 새롭게 가입하기
     *
     * @name LinkKakaoBySignUp
     * @request POST:/auth/linkKakaoBySignUp
     */
    linkKakaoBySignUp: (data: LinkKakaoBySignUpParam, params: RequestParams = {}) =>
      this.request<AuthResponse, any>({
        path: `/auth/linkKakaoBySignUp`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 카카오 계정과 기존 이메일 계정 연동하기
     *
     * @name LinkKakaoByEmail
     * @request POST:/auth/linkKakaoByEmail
     */
    linkKakaoByEmail: (data: LinkKakaoByEmailParam, params: RequestParams = {}) =>
      this.request<AuthResponse, any>({
        path: `/auth/linkKakaoByEmail`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 마이페이지에서 카카오 계정 연동하기
     *
     * @name LinkKakaoSocialAuth
     * @request POST:/auth/linkKakaoSocialAuth
     * @secure
     */
    linkKakaoSocialAuth: (data: { token: string }, params: RequestParams = {}) =>
      this.request<AuthResponse, any>({
        path: `/auth/linkKakaoSocialAuth`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 마이페이지에서 카카오 계정 연동 해제하기
     *
     * @name UnlinkKakaoSocialAuth
     * @request POST:/auth/unlinkKakaoSocialAuth
     * @secure
     */
    unlinkKakaoSocialAuth: (data: { sid: number; token: string }, params: RequestParams = {}) =>
      this.request<AuthResponse, any>({
        path: `/auth/unlinkKakaoSocialAuth`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 회원 탈퇴하기
     *
     * @name ClosingAccount
     * @request POST:/auth/closingAccount
     * @secure
     */
    closingAccount: (data: CloseAccountRequestParam, params: RequestParams = {}) =>
      this.request<CodeOnlyCloseAccountResponseCode, any>({
        path: `/auth/closingAccount`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  cart = {
    /**
     * No description
     *
     * @name GetItems
     * @request GET:/cart/items
     * @secure
     */
    getItems: (params: RequestParams = {}) =>
      this.request<CartResponse, any>({
        path: `/cart/items`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SaveItems
     * @request POST:/cart/items.save
     */
    saveItems: (data: CartItemParam[], params: RequestParams = {}) =>
      this.request<CartResponse, any>({
        path: `/cart/items.save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  staticData = {
    /**
     * @description getSubscriptionInfo 에 들어있던 필드 중 하나
     *
     * @name GetSubscriptionDisplayItems
     * @request GET:/static-data/subscription-display-items
     */
    getSubscriptionDisplayItems: (params: RequestParams = {}) =>
      this.request<
        { reviewRates: { ItemID: number; TotalRate: number; TotalCount: number }[]; items: TbItemV2[] },
        any
      >({
        path: `/static-data/subscription-display-items`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetDisplayItems
     * @request POST:/static-data/display-items
     */
    getDisplayItems: (data: { onetime: boolean; providerId: number }, params: RequestParams = {}) =>
      this.request<{ items: any; recommendation: any }, any>({
        path: `/static-data/display-items`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetItems
     * @request GET:/static-data/items
     */
    getItems: (params: RequestParams = {}) =>
      this.request<TbItemV2[], any>({
        path: `/static-data/items`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetPayments
     * @request GET:/static-data/payments
     */
    getPayments: (query: { type: "Subscription" | "Onetime" }, params: RequestParams = {}) =>
      this.request<TbPaymentV2[], any>({
        path: `/static-data/payments`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  delivery = {
    /**
     * @description [WMS] 발주서 추출 API WMS 에서 발주 처리하고, 발주서를 추출한다. [dbDelivery].[dbo].[adm_v1_02_InitDeliveryList] - (200 - orderNotExists : 발주 처리할 주문이 없음) - (200 - alreadyDone : 과거에 초기화된 발주가 있는 경우)
     *
     * @name GetDeliveryList
     * @request POST:/delivery/getDeliveryList
     */
    getDeliveryList: (data: { debug?: boolean; isLive: boolean }, params: RequestParams = {}) =>
      this.request<DeliveryResponseDeliveryItemListDeliveryItemListArrayDeliveryMainTbDeliveryMain, any>({
        path: `/delivery/getDeliveryList`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description [WMS] 송장번호 업데이트 API WMS 시스템에서 송장번호가 발급되면, 와이즐리 DB 에 송장번호를 입력해 준다.
     *
     * @name SetDeliveryTrackNo
     * @request POST:/delivery/setDeliveryTrackNo
     */
    setDeliveryTrackNo: (data: RegisterDeliveryTrackNoParam[], params: RequestParams = {}) =>
      this.request<DeliveryResponseAny, any>({
        path: `/delivery/setDeliveryTrackNo`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description [WMS] 출고 확정 API WMS 에서 택배 출고가 확정되면, 와이즐리 DB 에 이를 업데이트 해 준다.
     *
     * @name FixDelivery
     * @request POST:/delivery/fixDelivery
     */
    fixDelivery: (data: FixDeliveryParam[], params: RequestParams = {}) =>
      this.request<DeliveryResponseAny, any>({
        path: `/delivery/fixDelivery`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description [Admin] 어드민 발주 처리 API 어드민 페이지에서, 발주 처리해주는 API (WMS 에서 문제가 발생할 경우에, 어드민에서 직접 발주 처리할 경우를 위해 존재) [dbDelivery].[dbo].[adm_v1_02_InitDeliveryList] - (200 - orderNotExists : 발주 처리할 주문이 없음) - (200 - alreadyDone : 과거에 초기화된 발주가 있는 경우)
     *
     * @name InitDeliveryList
     * @request POST:/delivery/init-delivery-list
     * @secure
     */
    initDeliveryList: (data: { adminUnid: number; isLive: boolean }, params: RequestParams = {}) =>
      this.request<DeliveryResponsePaginationPaginationDeliveryMainListDeliveryMainArray, any>({
        path: `/delivery/init-delivery-list`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description [Admin] 어드민 발주서 추출 API 어드민 페이지에서, 발주서 추출해주는 API (WMS 에서 문제가 발생할 경우에, 어드민에서 직접 발주서를 추출할 경우를 위해 존재) - (200 - deliveryItemNotExists : 추출할 상품 데이터 없는 경우) - (200 - dataNotExists : 추출할 주문 데이터가 없는 경우)
     *
     * @name ExportDeliveryList
     * @request POST:/delivery/export-delivery-list
     * @secure
     */
    exportDeliveryList: (data: { dmid: number; isLive?: boolean; adminUnid?: number }, params: RequestParams = {}) =>
      this.request<DeliveryResponseDeliveryItemListDeliveryItemListArrayDeliveryMainTbDeliveryMain, any>({
        path: `/delivery/export-delivery-list`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description [Admin] 발주 취소 API WMS 에서 문제가 발생할 경우에, 어드민이 직접 발주 취소하는 API - (200 - dataNotExists : 추출할 주문 데이터가 없는 경우)
     *
     * @name CancelDeliveryList
     * @request POST:/delivery/cancel-delivery-list
     * @secure
     */
    cancelDeliveryList: (data: { dmid: number; isLive: boolean; adminUnid: number }, params: RequestParams = {}) =>
      this.request<DeliveryResponsePaginationPaginationDeliveryMainListDeliveryMainArray, any>({
        path: `/delivery/cancel-delivery-list`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  dev = {
    /**
     * No description
     *
     * @name Ping
     * @request GET:/dev/ping
     */
    ping: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/dev/ping`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetUserInfo
     * @request GET:/dev/userinfo
     * @secure
     */
    getUserInfo: (params: RequestParams = {}) =>
      this.request<JWTUserInfo, any>({
        path: `/dev/userinfo`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name Error
     * @request GET:/dev/error
     */
    error: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/dev/error`,
        method: "GET",
        ...params,
      }),
  };
  event = {
    /**
     * No description
     *
     * @name CheckCouponCode
     * @request POST:/event/check-coupon-code
     */
    checkCouponCode: (data: { code: string }, params: RequestParams = {}) =>
      this.request<DataWithCodeCouponTbCouponVerificationTokenStringCheckCouponCode, any>({
        path: `/event/check-coupon-code`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  habitual = {
    /**
     * @description providerId: 400000000
     *
     * @name GetItemsFromProvider
     * @request GET:/habitual/items/{providerId}
     */
    getItemsFromProvider: (providerId: number, query?: { categoryName?: string }, params: RequestParams = {}) =>
      this.request<TbItemV2[], any>({
        path: `/habitual/items/${providerId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description page: 1 perpage: 5 optionIds: [400101011] itemIds: []
     *
     * @name GetReviewList
     * @request POST:/habitual/get-review-list
     */
    getReviewList: (
      data: { itemIds?: number[]; optionIds?: number[]; perpage?: number; page?: number },
      params: RequestParams = {},
    ) =>
      this.request<ApiResponseReviewBoard, any>({
        path: `/habitual/get-review-list`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 리뷰 삭제 API 해당 유저가 작성한 리뷰면 삭제한다.
     *
     * @name DeleteReview
     * @request POST:/habitual/delete-review/{rid}
     * @secure
     */
    deleteReview: (rid: number, params: RequestParams = {}) =>
      this.request<ApiResponseReview, any>({
        path: `/habitual/delete-review/${rid}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description refresh-token 에 성공하는 경우만 200 리턴 이외 모든 경우에 401 리턴. 탈퇴회원의 경우도 동일
     *
     * @name RefreshToken
     * @request GET:/habitual/refresh-token
     */
    refreshToken: (params: RequestParams = {}) =>
      this.request<{ subscription?: TbSubscription; user: User }, any>({
        path: `/habitual/refresh-token`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  kakaoi = {
    /**
     * @description 카카오싱크 로그인 callback
     *
     * @name KakaosyncLogin
     * @request GET:/kakaoi/kakaosync-login
     */
    kakaosyncLogin: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/kakaoi/kakaosync-login`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description T1. 카카오 원클릭결제 주문 생성하기
     *
     * @name Ordersheet
     * @request GET:/kakaoi/ordersheet
     */
    ordersheet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/kakaoi/ordersheet`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description T2. 카카오 원클릭결제 tid와 배송정보 수신
     *
     * @name OrderTransaction
     * @request POST:/kakaoi/order-transaction
     */
    orderTransaction: (data: any, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/kakaoi/order-transaction`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description T3. 카카오 원클릭결제 T1에서 지정한 callback URL STEP1. 원클릭결제를 위한 카카오페이결제 인증 성공 결과로 pg_token을 수신한다 STEP2. 수신받은 pg_token으로 카카오페이에 결제승인을 요청하고 결제완료처리를 한다.
     *
     * @name OrderCallback
     * @request POST:/kakaoi/order-callback
     */
    orderCallback: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/kakaoi/order-callback`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * @description T4. 카카오 원클릭결제 주문으로 생성된 주문의 상태를 확인시켜준다
     *
     * @name OrderStatus
     * @request GET:/kakaoi/order-status
     */
    orderStatus: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/kakaoi/order-status`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  member = {
    /**
     * @description 마이페이지에서, "배송 패턴" 선택 가능한 유저를 판별해 줌 (건너받기 가능한 유저인지 체크해 준다.)
     *
     * @name CheckSkipAvailable
     * @request GET:/member/check-skip-available
     * @secure
     */
    checkSkipAvailable: (query: { unid: number }, params: RequestParams = {}) =>
      this.request<ApiResponseCheckSkipAvailableBoolean, any>({
        path: `/member/check-skip-available`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  order = {
    /**
     * No description
     *
     * @name IsAvailableTrialSet
     * @request POST:/order/is-available-trial-set
     * @secure
     */
    isAvailableTrialSet: (data: { providerId: number }, params: RequestParams = {}) =>
      this.request<SimpleResponseIsAvailableBoolean, any>({
        path: `/order/is-available-trial-set`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetOrder
     * @request POST:/order/order
     * @secure
     */
    getOrder: (data: { orderId: number }, params: RequestParams = {}) =>
      this.request<GetOrderResponse, any>({
        path: `/order/order`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetOrders
     * @request POST:/order/orders
     * @secure
     */
    getOrders: (data: { perPage?: number; page?: number }, params: RequestParams = {}) =>
      this.request<{ pagination: Pagination; orders: TbOrder[] }, any>({
        path: `/order/orders`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetRecentOrders
     * @request POST:/order/recent-orders
     * @secure
     */
    getRecentOrders: (data: { day?: number }, params: RequestParams = {}) =>
      this.request<TbOrder[], any>({
        path: `/order/recent-orders`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description [체험세트 initOrder 관련 테이블] [dbMember].[dbo].[tbTrialSetMain] -체험세트 신청 가능한 브랜드들을 담고 있는 테이블 [dbMember].[dbo].[tbTrialSetItem] -체험세트 상품 테이블 역할 [dbMember].[dbo].[tbTrialUserItems] -실질적으로 체험세트용 장바구니 역할을 한다. [dbMember].[dbo].[tbTrialSetInfo] -어떤 유저가 어떤 브랜드에 어떤 주기로 신청했는지에 대한 정보가 있음 [수행해야 할 작업들] 0. 프론트에서 trialItems, 정기구독 subscriptionItems 를 파라미터로 받는다. 1. 프론트에서 넘어은 trialItems 를 기반으로 주문을 생성한다. 2. 구독 관련 처리 2.1. Status = Y 인 구독이 있는 경우. -SubscriptionStash 에 subscriptionItems 를 추가한다. 2.2 Status = ["I", "X"] 인 구독이 있는 경우. -ScheduledAt, Cycle 을 변경한다. -SubscriptionStash 에 subscriptionItems 를 추가한다. 2.3 Subscription 이 없는 경우 -Subscription 을 생성한다. -SubscriptionStash 에 subscriptionItems 를 추가한다. -subscriptionItems 를 바탕으로, 다음에 결제할 Order 를 생성한다. - (200: trialNotAvailable : 체험세트 신청 불가능한 유저) - (200: addressNotExists) : 유저의 주소 정보가 없음
     *
     * @name InitTrialOrder
     * @request POST:/order/init-trial-order
     * @secure
     */
    initTrialOrder: (data: TrialOrderParam, params: RequestParams = {}) =>
      this.request<WiselyApiResponseInitOrderResponseInitTrialOrderEnum, any>({
        path: `/order/init-trial-order`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 정기구매 initOrder - (200: success) : 성공 - (200: subscriptionExists) : 이미 구독 하고 있는 유저일 경우. 마이페이지로 이동하게 된다. - (200: couponNotExists) : (와이즐리) 쿠폰을 사용하려 했는데, 쿠폰 정보가 없는 경우. - (200: addressNotExists) : 유저의 주소 정보가 없음
     *
     * @name InitSubscriptionOrder
     * @request POST:/order/init-subscription-order
     * @secure
     */
    initSubscriptionOrder: (data: SubscriptionOrderParam, params: RequestParams = {}) =>
      this.request<WiselyApiResponseInitOrderResponseInitSubscriptionOrderEnum, any>({
        path: `/order/init-subscription-order`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 1회구매 initOrder - (200: success) : 성공 - (200: addressNotExists) : 유저의 주소 정보가 없음
     *
     * @name InitOnetimeOrder
     * @request POST:/order/init-onetime-order
     * @secure
     */
    initOnetimeOrder: (data: OnetimeOrderParam, params: RequestParams = {}) =>
      this.request<WiselyApiResponseInitOrderResponseInitOnetimeOrderEnum, any>({
        path: `/order/init-onetime-order`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Order 에 주소지 업데이트
     *
     * @name SetAddress
     * @request POST:/order/set-address
     * @secure
     */
    setAddress: (
      data: { makeItDefaultAddr: boolean; addressId: number; orderId: number },
      params: RequestParams = {},
    ) =>
      this.request<SetAddressResult, any>({
        path: `/order/set-address`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description OrderCount 해주는 API
     *
     * @name GetOrderCount
     * @request POST:/order/get-order-count
     * @secure
     */
    getOrderCount: (params: RequestParams = {}) =>
      this.request<ApiResponseGetOrderCountResponse, any>({
        path: `/order/get-order-count`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description OrderCount 해주는 API
     *
     * @name GetCheckoutInfo
     * @request POST:/order/get-checkout-info
     */
    getCheckoutInfo: (data: { items: ItemParam[] }, params: RequestParams = {}) =>
      this.request<ApiResponseCheckoutInfo, any>({
        path: `/order/get-checkout-info`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 반품 / 교환신청
     *
     * @name RequestClaim
     * @request POST:/order/request-claim
     * @secure
     */
    requestClaim: (
      data: { reasonDetail: string; reason: string; type: OrderClaim; orderId: number },
      params: RequestParams = {},
    ) =>
      this.request<ApiResponseOrderTbOrder, any>({
        path: `/order/request-claim`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 반품 / 교환 취소
     *
     * @name CancelClaim
     * @request POST:/order/cancel-claim
     * @secure
     */
    cancelClaim: (data: { orderId: number }, params: RequestParams = {}) =>
      this.request<{ data: { order: TbOrder }; success: boolean }, any>({
        path: `/order/cancel-claim`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RegisterTrial
     * @request POST:/order/register-trial
     */
    registerTrial: (data: { items: ItemParam[]; providerId: number }, params: RequestParams = {}) =>
      this.request<{ data: { trialSetInfo: TbTrialSetInfo }; success: boolean }, any>({
        path: `/order/register-trial`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetTrialItems
     * @request GET:/order/get-trial-items
     */
    getTrialItems: (query: { providerId: number }, params: RequestParams = {}) =>
      this.request<TbItemV2[], any>({
        path: `/order/get-trial-items`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description 유저가 신청한 trial set 아이템을 표시해 준다.
     *
     * @name GetTrialCheckoutInfo
     * @request GET:/order/get-trial-checkout-info
     */
    getTrialCheckoutInfo: (query: { tsid: number }, params: RequestParams = {}) =>
      this.request<{ data: { subscriptionInfo: CheckoutInfo; trialSetInfo: CheckoutInfo }; success: boolean }, any>({
        path: `/order/get-trial-checkout-info`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  payments = {
    /**
     * @description 결제 전에 결제수단 등록
     *
     * @name SetPayment
     * @request POST:/payments/set
     * @secure
     */
    setPayment: (data: { couponId?: number; paymentId: number; orderId: number }, params: RequestParams = {}) =>
      this.request<PaymentAPIResponseSetPaymentResponse, any>({
        path: `/payments/set`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description (Tosspay) 1회구매를 토스페이 준비 API
     *
     * @name OnetimeTosspay
     * @request POST:/payments/onetime/tosspay
     * @secure
     */
    onetimeTosspay: (data: { orderId: number }, params: RequestParams = {}) =>
      this.request<PaymentAPIResponseTossPayRequestPaymentResponse, any>({
        path: `/payments/onetime/tosspay`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name OnetimeKakaopay
     * @request POST:/payments/onetime/kakaopay
     * @secure
     */
    onetimeKakaopay: (data: { orderId: number }, params: RequestParams = {}) =>
      this.request<PaymentAPIResponseKakaoPayReadyResponse, any>({
        path: `/payments/onetime/kakaopay`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name OnetimeFree
     * @request POST:/payments/onetime/free
     * @secure
     */
    onetimeFree: (data: { orderId: number }, params: RequestParams = {}) =>
      this.request<PaymentAPIResponseTbOrder, any>({
        path: `/payments/onetime/free`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name BillingKeyReadyKakaopay
     * @request POST:/payments/billing-key/ready/kakaopay
     * @secure
     */
    billingKeyReadyKakaopay: (data: { orderId: number }, params: RequestParams = {}) =>
      this.request<PaymentAPIResponseKakaoPayReadyResponse, any>({
        path: `/payments/billing-key/ready/kakaopay`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description (Tosspay) 토스페이는 다른 결제사들과 첫 정기결제 플로우가 달라서 따로 뺌
     *
     * @name BillingKeyReadyTosspay
     * @request POST:/payments/billing-key/ready/tosspay
     * @secure
     */
    billingKeyReadyTosspay: (data: { ordercid: string; orderId: number }, params: RequestParams = {}) =>
      this.request<PaymentAPIResponseCheckoutAndroidUriStringCheckoutIosUriStringCheckoutUriString, any>({
        path: `/payments/billing-key/ready/tosspay`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description (네이버페이) 첫 정기결제를 위해 주문 등록 (first-subscription)
     *
     * @name BillingKeyReadyNaverpay
     * @request POST:/payments/billing-key/ready/naverpay
     * @secure
     */
    billingKeyReadyNaverpay: (data: { orderId: number }, params: RequestParams = {}) =>
      this.request<PaymentAPIResponseNaverPayRegisterRecurrentResponse, any>({
        path: `/payments/billing-key/ready/naverpay`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description (TossPay, Kakaopay, Naverpay, Toss) 간편결제 등록 완료 처리 각 결제사 간편결제 등록 후 리디렉션
     *
     * @name BillingKeyRegister
     * @request GET:/payments/billing-key/register/{pg}
     */
    billingKeyRegister: (pg: PaymentType, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payments/billing-key/register/${pg}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @name BillingKeyRegisterToss
     * @request POST:/payments/billing-key/register/toss
     * @secure
     */
    billingKeyRegisterToss: (
      data: { cardpwd: string; dob: string; expdate: string; cardNo: string },
      params: RequestParams = {},
    ) =>
      this.request<PaymentAPIEasyPaymentResponse, any>({
        path: `/payments/billing-key/register/toss`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name BillingKeyRegisterKakaopay
     * @request POST:/payments/billing-key/register/kakaopay
     * @secure
     */
    billingKeyRegisterKakaopay: (params: RequestParams = {}) =>
      this.request<PaymentAPIResponseKakaoPayReadyResponse, any>({
        path: `/payments/billing-key/register/kakaopay`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description (Tosspay) 마이페이지에서 정기결제 수단 변경
     *
     * @name BillingKeyRegisterTosspay
     * @request POST:/payments/billing-key/register/tosspay
     * @secure
     */
    billingKeyRegisterTosspay: (params: RequestParams = {}) =>
      this.request<PaymentAPIResponseTossPayReserveRegisterSubscriptionResponse, any>({
        path: `/payments/billing-key/register/tosspay`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description (네이버페이) 마이페이지에서 네이버페이로 결제수단 변경
     *
     * @name BillingKeyRegisterNaverpay
     * @request POST:/payments/billing-key/register/naverpay
     * @secure
     */
    billingKeyRegisterNaverpay: (params: RequestParams = {}) =>
      this.request<PaymentAPIResponseNaverPayRegisterRecurrentResponse, any>({
        path: `/payments/billing-key/register/naverpay`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name BillingKeyRegisterAndPay
     * @request GET:/payments/billing-key/register-and-pay/{pg}
     */
    billingKeyRegisterAndPay: (pg: PaymentType, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payments/billing-key/register-and-pay/${pg}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @name BillingKeyInactivate
     * @request POST:/payments/billing-key/inactivate
     * @secure
     */
    billingKeyInactivate: (data: { epid?: number }, params: RequestParams = {}) =>
      this.request<PaymentAPIResponseTbEasyPayment, any>({
        path: `/payments/billing-key/inactivate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CheckBillingKeyStatus
     * @request POST:/payments/billing-key/status
     * @secure
     */
    checkBillingKeyStatus: (data: { epid: number; unid: number }, params: RequestParams = {}) =>
      this.request<PaymentAPIResponseIsActiveBoolean, any>({
        path: `/payments/billing-key/status`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name BillingKeyPay
     * @request POST:/payments/billing-key/pay
     * @secure
     */
    billingKeyPay: (data: { orderId: number }, params: RequestParams = {}) =>
      this.request<PaymentAPIResponseTbOrder, any>({
        path: `/payments/billing-key/pay`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CancelPayment
     * @request POST:/payments/cancel
     * @secure
     */
    cancelPayment: (data: { cancelVBankParam?: CancelVBankParam; orderId: number }, params: RequestParams = {}) =>
      this.request<PaymentAPIResponseTbOrder, any>({
        path: `/payments/cancel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SetEscrowNicepay
     * @request POST:/payments/escrow/set/nicepay
     */
    setEscrowNicepay: (data: { ordercid: string }, params: RequestParams = {}) =>
      this.request<PaymentAPIResponseNicePaySetEscrowResponseOrNicePayFailResponse, any>({
        path: `/payments/escrow/set/nicepay`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SetEscrowToss
     * @request POST:/payments/escrow/set/toss
     */
    setEscrowToss: (data: { trackNo: string; orderCid: string }, params: RequestParams = {}) =>
      this.request<{ data: string; statusCode: number; success: boolean }, any>({
        path: `/payments/escrow/set/toss`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description (Toss PG) 토스 에스크로 처리결과 수신 Callback 고객이 에스크로 구매 취소를 한 경우에 Slack 알림을 줌
     *
     * @name VbankEscrowCallbackTossGet
     * @request GET:/payments/vbank/escrow/callback/toss
     */
    vbankEscrowCallbackTossGet: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payments/vbank/escrow/callback/toss`,
        method: "GET",
        ...params,
      }),

    /**
     * @description 토스 문서에는 GET 으로 요청이 온다고 되어있지만, 실제로는 POST 로 요청이 옴.
     *
     * @name VbankEscrowCallbackTossPost
     * @request POST:/payments/vbank/escrow/callback/toss
     */
    vbankEscrowCallbackTossPost: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payments/vbank/escrow/callback/toss`,
        method: "POST",
        ...params,
      }),

    /**
     * @description (Tosspay, Toss, NaverPay, KakaoPay) 결제 처리 후 FE 의 성공/실패 페이지로 리다이렉트 https://n-api.wiselycompany.com/payments/onetime/toss?paymentKey=5zJ4xY7m0kODnyRpQWGrN2xqGlNvLrKwv1M9ENjbeoPaZdL6&orderId=vyDLDP5vjGxHEdpEDYn2i&amount=15000
     *
     * @name CallbackOnetime
     * @request GET:/payments/onetime/callback/{pg}
     */
    callbackOnetime: (pg: PaymentType, query?: { code?: string; message?: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payments/onetime/callback/${pg}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * @description { "secret": "ps_ZORzdMaqN3weJkOmRgNr5AkYXQGw", "status": "DONE", "orderId": "EtpTychY9VrYJPBNep6hB" }
     *
     * @name CallbackVBankToss
     * @request POST:/payments/vbank/callback/toss
     */
    callbackVBankToss: (data: TossVBankCallbackParam & Record<string, any>, params: RequestParams = {}) =>
      this.request<{ success: boolean }, any>({
        path: `/payments/vbank/callback/toss`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description (TossPay) 토스 서버로부터 결제수단 변경시 받게되는 Callback
     *
     * @name CallbackBillingKeyRegisterTosspay
     * @request POST:/payments/billing-key/register/callback/tosspay
     */
    callbackBillingKeyRegisterTosspay: (
      data: { displayId?: string; billingKey: string; userId: string; processedTs: string; action: string } & Record<
        string,
        any
      >,
      params: RequestParams = {},
    ) =>
      this.request<PaymentAPIEasyPaymentResponse, any>({
        path: `/payments/billing-key/register/callback/tosspay`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RedirectToFailPage
     * @request GET:/payments/redirect/fail
     */
    redirectToFailPage: (query?: { code?: string; message?: string; ordercid?: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payments/redirect/fail`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name RedirectToEasypaymentFailPage
     * @request GET:/payments/redirect/easypayment/fail
     */
    redirectToEasypaymentFailPage: (
      query: { unid: number; paymentId: number; code: string; message: string },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/payments/redirect/easypayment/fail`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name RedirectCancel
     * @request GET:/payments/redirect/cancel
     */
    redirectCancel: (query?: { ordercid?: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payments/redirect/cancel`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name RedirectBillingKeyRegisterTosspay
     * @request GET:/payments/billing-key/redirect/tosspay
     */
    redirectBillingKeyRegisterTosspay: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payments/billing-key/redirect/tosspay`,
        method: "GET",
        ...params,
      }),
  };
  reviewsV2 = {
    /**
     * @description review 가 존제하면 update, 존재하지 않으먼 insert 를 수행한다.
     *
     * @name SetReview
     * @request POST:/reviews-v2/set-review
     * @secure
     */
    setReview: (
      data: { providerId: number; point: number; contents: string; optionId: number; itemId: number },
      params: RequestParams = {},
    ) =>
      this.request<ApiResponseReview, any>({
        path: `/reviews-v2/set-review`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description (와이즐리) Review 를 RGID 기반으로 등록한다.
     *
     * @name SetReviewWithRgid
     * @request POST:/reviews-v2/set-review-with-rgid
     * @secure
     */
    setReviewWithRgid: (
      data: { providerId: number; point: number; contents: string; rgid: number },
      params: RequestParams = {},
    ) =>
      this.request<ApiResponseReview, any>({
        path: `/reviews-v2/set-review-with-rgid`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description SQL 검색 API SQL Search 로 구현할 경우, 성능 이슈가 있음. 나중에 다른 방식으로 다시 구현해야 함
     *
     * @name GetReviewList
     * @request POST:/reviews-v2/get-review-list
     */
    getReviewList: (
      data: { itemIds?: number[]; optionIds?: number[]; perpage?: number; page?: number },
      params: RequestParams = {},
    ) =>
      this.request<ApiResponseReviewBoard, any>({
        path: `/reviews-v2/get-review-list`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 로그인 되어있는 유저의 Review 들을 추출해 준다.
     *
     * @name GetReviewInfo
     * @request GET:/reviews-v2/get-review-info
     * @secure
     */
    getReviewInfo: (query: { optionId: number }, params: RequestParams = {}) =>
      this.request<ApiResponseReviewArray, any>({
        path: `/reviews-v2/get-review-info`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description 로그인 되어있는 유저의 Review 들을 추출해 준다.
     *
     * @name GetReviewInfoWithRgid
     * @request GET:/reviews-v2/getReviewInfoWithRgid
     * @secure
     */
    getReviewInfoWithRgid: (query: { rgid: number }, params: RequestParams = {}) =>
      this.request<ApiResponseReviewArray, any>({
        path: `/reviews-v2/getReviewInfoWithRgid`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description 로그인된 유저의 리뷰를 수정해 준다.
     *
     * @name UpdateReview
     * @request POST:/reviews-v2/update-review
     * @secure
     */
    updateReview: (
      data: { providerId: number; point: number; contents: string; optionId: number; itemId: number },
      params: RequestParams = {},
    ) =>
      this.request<ApiResponseReview, any>({
        path: `/reviews-v2/update-review`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description rgid 를 Param으로 받아서, 해당 ReviewMain 에 해당하는 집계 데이터를 반환한다.
     *
     * @name GetReviewMain
     * @request GET:/reviews-v2/get-review-main
     */
    getReviewMain: (query: { rgid: number }, params: RequestParams = {}) =>
      this.request<ApiResponseReviewMain, any>({
        path: `/reviews-v2/get-review-main`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description 로그인 되어있는 유저가 작성할 수 있는 Review Group 을 반환한다.
     *
     * @name CheckAvailableReviewGroup
     * @request GET:/reviews-v2/check-available-review-group
     * @secure
     */
    checkAvailableReviewGroup: (query?: { providerId?: number }, params: RequestParams = {}) =>
      this.request<ApiResponseAvailableReviewGroupArray, any>({
        path: `/reviews-v2/check-available-review-group`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description 리뷰 삭제 API 해당 유저가 작성한 리뷰면 삭제한다.
     *
     * @name DeleteReview
     * @request POST:/reviews-v2/delete-review/{rid}
     * @secure
     */
    deleteReview: (rid: number, params: RequestParams = {}) =>
      this.request<ApiResponseReview, any>({
        path: `/reviews-v2/delete-review/${rid}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  store = {
    /**
     * No description
     *
     * @name GetItemsFromAllProviders
     * @request GET:/store/items/all
     */
    getItemsFromAllProviders: (query?: { categoryName?: string }, params: RequestParams = {}) =>
      this.request<TbItemV2[], any>({
        path: `/store/items/all`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetItemsFromProvider
     * @request GET:/store/items/{providerId}
     */
    getItemsFromProvider: (providerId: number, query?: { categoryName?: string }, params: RequestParams = {}) =>
      this.request<TbItemV2[], any>({
        path: `/store/items/${providerId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  subscription = {
    /**
     * No description
     *
     * @name GetSubscription
     * @request GET:/subscription/subscription
     * @secure
     */
    getSubscription: (params: RequestParams = {}) =>
      this.request<FallibleResponseTbSubscription, any>({
        path: `/subscription/subscription`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetFullSubscriptionInfo
     * @request GET:/subscription/full-subscription-info
     * @secure
     */
    getFullSubscriptionInfo: (params: RequestParams = {}) =>
      this.request<NewSubscriptionInfo, any>({
        path: `/subscription/full-subscription-info`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name PauseSubscription
     * @request POST:/subscription/subscription.pause
     * @secure
     */
    pauseSubscription: (data: CancelSubscriptionParam, params: RequestParams = {}) =>
      this.request<NewSubscriptionInfo, any>({
        path: `/subscription/subscription.pause`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CancelSubscription
     * @request POST:/subscription/subscription.cancel
     * @secure
     */
    cancelSubscription: (data: CancelSubscriptionParam, params: RequestParams = {}) =>
      this.request<NewSubscriptionInfo, any>({
        path: `/subscription/subscription.cancel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description scheduleAt 은 적어도 내일 이후여야 함. 아닌 값이 들어오면 내일로 세팅됨
     *
     * @name RestartSubscription
     * @request POST:/subscription/subscription.restart
     * @secure
     */
    restartSubscription: (data: { ScheduleAt: string }, params: RequestParams = {}) =>
      this.request<NewSubscriptionInfo, any>({
        path: `/subscription/subscription.restart`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateSubscription
     * @request POST:/subscription/subscription.update
     * @secure
     */
    updateSubscription: (data: { scheduleAt?: string; cycle?: number }, params: RequestParams = {}) =>
      this.request<NewSubscriptionInfo, any>({
        path: `/subscription/subscription.update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 곧 제거
     *
     * @name UpdateSubscriptionItem
     * @request POST:/subscription/item.update
     * @secure
     */
    updateSubscriptionItem: (
      data: UpdateItemParam & { term?: "W"; cycle?: number; itemId: number },
      params: RequestParams = {},
    ) =>
      this.request<ChangeSubscriptionResponse, any>({
        path: `/subscription/item.update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateSubscriptionItems
     * @request POST:/subscription/items.update
     * @secure
     */
    updateSubscriptionItems: (data: UpdateItemParam[], params: RequestParams = {}) =>
      this.request<ChangeSubscriptionResponse, any>({
        path: `/subscription/items.update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ChangeSubscriptionItemOption
     * @request POST:/subscription/item.change-option
     * @secure
     */
    changeSubscriptionItemOption: (data: ChangeItemOptionParam, params: RequestParams = {}) =>
      this.request<NewSubscriptionInfo, any>({
        path: `/subscription/item.change-option`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteSubscriptionItem
     * @request POST:/subscription/item.delete
     * @secure
     */
    deleteSubscriptionItem: (data: { optionId: number }, params: RequestParams = {}) =>
      this.request<ChangeSubscriptionResponse, any>({
        path: `/subscription/item.delete`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 마이페이지에서, 해당 구독 아이템을 건너받기 / 건너받기 해제해 준다.
     *
     * @name SkipItem
     * @request POST:/subscription/item.skip
     * @secure
     */
    skipItem: (data: SkipItemParam, params: RequestParams = {}) =>
      this.request<ChangeSubscriptionResponse, any>({
        path: `/subscription/item.skip`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetFailLog
     * @request GET:/subscription/get-fail-log
     * @secure
     */
    getFailLog: (params: RequestParams = {}) =>
      this.request<{ message: string; updatedAfterLastTry: boolean; paymentFailed: boolean }, any>({
        path: `/subscription/get-fail-log`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description 특정 상품만 골라서, "골라서 당겨받기" 하는 API - (200: paymentFail - 결제 실패함) - (200: success - 성공)
     *
     * @name OrderNow
     * @request POST:/subscription/order-now
     * @secure
     */
    orderNow: (data: { optionIds: number[] }, params: RequestParams = {}) =>
      this.request<OrderNowResponse, any>({
        path: `/subscription/order-now`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  migration = {
    /**
     * No description
     *
     * @name SendOrderCompleteEmail
     * @request POST:/migration/unsafe-public-api/mail/em001-ordercomplete
     */
    sendOrderCompleteEmail: (data: { orderId: number; userId: number }, params: RequestParams = {}) =>
      this.request<
        | { data: string; status: number }
        | { data: string; status: string }
        | ({ data: string; status: number } & { data: string; status: string }),
        any
      >({
        path: `/migration/unsafe-public-api/mail/em001-ordercomplete`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SendRequestVBankEmail
     * @request POST:/migration/unsafe-public-api/mail/em003-request-vbank
     */
    sendRequestVBankEmail: (data: { orderId: number; userId: number }, params: RequestParams = {}) =>
      this.request<
        | { data: string; status: number }
        | { data: string; status: string }
        | ({ data: string; status: number } & { data: string; status: string }),
        any
      >({
        path: `/migration/unsafe-public-api/mail/em003-request-vbank`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SendCancelOrderEmail
     * @request POST:/migration/unsafe-public-api/mail/em004-cancel-order
     */
    sendCancelOrderEmail: (data: { orderId: number; userId: number }, params: RequestParams = {}) =>
      this.request<
        | { data: string; status: number }
        | { data: string; status: string }
        | ({ data: string; status: number } & { data: string; status: string }),
        any
      >({
        path: `/migration/unsafe-public-api/mail/em004-cancel-order`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SendPasswordResetEmail
     * @request POST:/migration/unsafe-public-api/mail/em007-password-reset
     */
    sendPasswordResetEmail: (data: { email: string }, params: RequestParams = {}) =>
      this.request<
        | { data: string; status: number }
        | { data: string; status: string }
        | ({ data: string; status: number } & { data: string; status: string }),
        any
      >({
        path: `/migration/unsafe-public-api/mail/em007-password-reset`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SendOrderCompleteAlimtalk
     * @request POST:/migration/unsafe-public-api/alimtalk/a01-02-order-complete
     */
    sendOrderCompleteAlimtalk: (data: { orderId: number; userId: number }, params: RequestParams = {}) =>
      this.request<
        | { data: string; status: number }
        | { data: string; status: string }
        | ({ data: string; status: number } & { data: string; status: string }),
        any
      >({
        path: `/migration/unsafe-public-api/alimtalk/a01-02-order-complete`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SendCancelOrderAlimtalk
     * @request POST:/migration/unsafe-public-api/alimtalk/ba03-02-cancel-order
     */
    sendCancelOrderAlimtalk: (data: { orderId: number; userId: number }, params: RequestParams = {}) =>
      this.request<
        | { data: string; status: number }
        | { data: string; status: string }
        | ({ data: string; status: number } & { data: string; status: string }),
        any
      >({
        path: `/migration/unsafe-public-api/alimtalk/ba03-02-cancel-order`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SendUnsubscribeAdsAlimtalk
     * @request POST:/migration/unsafe-public-api/alimtalk/ca03-02-unsubscribe-ads
     */
    sendUnsubscribeAdsAlimtalk: (data: { UNID: number }, params: RequestParams = {}) =>
      this.request<
        | { data: string; status: number }
        | { data: string; status: string }
        | ({ data: string; status: number } & { data: string; status: string }),
        any
      >({
        path: `/migration/unsafe-public-api/alimtalk/ca03-02-unsubscribe-ads`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SendFreeTrialOrderCompletedAlimtalk
     * @request POST:/migration/unsafe-public-api/alimtalk/combined-free-trial-order-completed
     */
    sendFreeTrialOrderCompletedAlimtalk: (data: { OrderID: number; UNID: number }, params: RequestParams = {}) =>
      this.request<
        | { data: string; status: number }
        | { data: string; status: string }
        | ({ data: string; status: number } & { data: string; status: string }),
        any
      >({
        path: `/migration/unsafe-public-api/alimtalk/combined-free-trial-order-completed`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SendSubscriptionOrderCompletedAlimtalk
     * @request POST:/migration/unsafe-public-api/alimtalk/a03-05-subscription-order-complete
     */
    sendSubscriptionOrderCompletedAlimtalk: (data: { OrderID: number; UNID: number }, params: RequestParams = {}) =>
      this.request<
        | { data: string; status: number }
        | { data: string; status: string }
        | ({ data: string; status: number } & { data: string; status: string }),
        any
      >({
        path: `/migration/unsafe-public-api/alimtalk/a03-05-subscription-order-complete`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SendVBankInfoAlimtalk
     * @request POST:/migration/unsafe-public-api/alimtalk/ba01-02-vbankinfo
     */
    sendVBankInfoAlimtalk: (data: { OrderID: number; UNID: number }, params: RequestParams = {}) =>
      this.request<
        | { data: string; status: number }
        | { data: string; status: string }
        | ({ data: string; status: number } & { data: string; status: string }),
        any
      >({
        path: `/migration/unsafe-public-api/alimtalk/ba01-02-vbankinfo`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SendVBankOrderCompleteAlimtlak
     * @request POST:/migration/unsafe-public-api/alimtalk/ba-02-vbank-order-complete
     */
    sendVBankOrderCompleteAlimtlak: (data: { OrderID: number; UNID: number }, params: RequestParams = {}) =>
      this.request<
        | { data: string; status: number }
        | { data: string; status: string }
        | ({ data: string; status: number } & { data: string; status: string }),
        any
      >({
        path: `/migration/unsafe-public-api/alimtalk/ba-02-vbank-order-complete`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SendDelayDeliveryNotice
     * @request POST:/migration/unsafe-public-api/alimtalk/delay-delivery-notice
     */
    sendDelayDeliveryNotice: (data: { UNID: number }, params: RequestParams = {}) =>
      this.request<
        | { data: string; status: number }
        | { data: string; status: string }
        | ({ data: string; status: number } & { data: string; status: string }),
        any
      >({
        path: `/migration/unsafe-public-api/alimtalk/delay-delivery-notice`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SendAlimtalk
     * @request POST:/migration/unsafe-public-api/alimtalk/send
     */
    sendAlimtalk: (data: { args: any; code: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/migration/unsafe-public-api/alimtalk/send`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
}
