import React from "react";
import ReactPaginate from "react-paginate";

import { numberFormat } from "../utils/UtilFunc";

export const PageBody = ({ childs }) => (
  <div className="row">
    <div className="col-lg-12">
      {childs.map((child, idx) => (
        <div key={idx} className="card mb-3">
          {child}
        </div>
      ))}
    </div>
  </div>
);

export const Card = ({ title, body }) => (
  <div className="card mb-3">
    <div className="card-header text-primary">{title}</div>
    <div className="card-body">{body}</div>
  </div>
);

export function Pagination({ pagination, query, changePage }) {
  return (
    <div className="text-right">
      <div style={{ display: "flex" }}>
        <div className="pt-2">
          총 {numberFormat(pagination.TotalCount ?? pagination.totalCount)}건
        </div>
        <div className="ml-auto">
          <ReactPaginate
            forcePage={parseInt(
              query.nowPage ? (query.nowPage as any) - 1 : (0 as any)
            )}
            previousLabel="이전"
            nextLabel="다음"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={
              (pagination.TotalCount ?? pagination.totalCount) /
                (pagination.DisplayRow ?? pagination.displayRow) +
              1
            }
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={(data) =>
              changePage(data.selected ? data.selected + 1 : 1)
            }
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
          />
        </div>
      </div>
    </div>
  );
}
