import { loadTossPayments } from "@tosspayments/sdk";
import moment from "moment";
import React, { Fragment, useContext, useState } from "react";
import Drawer from "react-drag-drawer";
import { IconContext } from "react-icons";
import { IoIosRefresh } from "react-icons/io";
import { Link } from "react-router-dom";
import { Payment } from "../../../constants";
import OrderContext from "../../../providers/ProviderOrderDetail";
import {
  BankCode,
  ClaimReason,
  ClaimStatus,
  ClaimType,
  IsReturnWill_TYPE,
  NodeServerDomain,
  tossApiKey,
} from "../../../Store";
import { numberFormat } from "../../../utils/UtilFunc";
import * as utils from "../../../utils/Utils";
import { AppContext } from "../../../index";
import { return_normal } from "../../../utils/Utils";

const OrderPayment = () => {
  const ctx = useContext(AppContext);
  const orderCtx = useContext(OrderContext);
  const getTossPayments = async ({
    type,
    amount,
    orderCid: orderId,
    orderName,
    customerName,
    successUrl,
    failUrl,
  }: {
    type: "vbank" | "card";
    amount: number;
    orderCid: string;
    orderName: string;
    customerName: string;
    successUrl: string;
    failUrl: string;
  }) => {
    const tossPayments = await loadTossPayments(tossApiKey);
    if (type === "vbank") {
      tossPayments.requestPayment("가상계좌", {
        amount,
        orderId,
        orderName,
        customerName,
        successUrl,
        failUrl,
        useEscrow: true,
      });
    }
    if (type === "card") {
      tossPayments.requestPayment("카드", {
        amount,
        orderId,
        orderName,
        customerName,
        successUrl,
        failUrl,
      });
    }
  };

  const easyPaymentPayNow = async (
    unid: number,
    orderId: number,
    refreshFunc
  ) => {
    const result = await ctx.api.adminPayment.subscriptionExecute({
      unid,
      orderId,
    });

    if (result) {
      refreshFunc.getOrderData(orderId);
    }

    return return_normal(result, "Y");
  };

  // 토스 간편결제하기
  const tossEasypaymentDo = async (unid, orderId, cardinfo, refreshFunc) => {
    const result = await ctx.api.adminPayment.approveOnetimeKeyIn({
      cardInfo,
      orderId,
      unid,
    });

    if (result) {
      refreshFunc.getOrderData(orderId);
    }
    return return_normal(result, "Y");
  };

  /** Modal 띄우기 */
  const [isOpened, setIsOpened] = useState(false);
  const showModal = () => {
    setCardInfo({
      cardno: "",
      expdate: "",
      dob: "",
      cardpwd: "",
    });
    setIsOpened(true); // Modal 띄우기
  };

  const [cardInfo, setCardInfo] = useState<any>();
  const handleChangeCardinfo = (event) => {
    setCardInfo({
      ...cardInfo,
      [event.target.name]: event.target.value,
    });
  };

  /** Modal2 띄우기 */
  const [isOpened2, setIsOpened2] = useState(false);
  const [getAmount, setAmount] = useState(0);
  const handleChangeRefundAmount = (event) => {
    setAmount(event.target.value);
  };

  // 주문 CX처리하기 - 결제금액 조정하기(환불금액으로 처리하기)
  const setRefundAmount = async (orderid, actions) => {
    const getResult = await utils.setRefundAmount(orderid, getAmount);
    if (getResult) {
      actions.refreshOrderData();
    }
  };

  const openTossVBankWindow = (unid: number, orderId: number) => {
    ctx.api.adminPayment
      .setPayments({
        paymentId: Payment.TossVbankOnetime,
        unid,
        orderId,
      })
      .then((response) => {
        const { TotalAmount, OrderCID, OrderName } = response.data.data.order;
        getTossPayments({
          type: "vbank",
          amount: TotalAmount,
          orderCid: OrderCID,
          orderName: OrderName,
          customerName: orderCtx.data.userData.UserInfo.FullName,
          successUrl: `${NodeServerDomain}/admin-payment/onetime/callback/toss?redirectUrl=${window.location.href}`,
          failUrl: window.location.href,
        });
      });
  };

  return (
    <OrderContext.Consumer>
      {({ data, actions }) =>
        data.orderData && (
          <>
            <div className="card mb-1">
              <div className="card-header text-primary">
                결제정보
                <div className="btn-actions-pane-right">
                  <button
                    className="btn btn-link p-0"
                    onClick={() => actions.refreshOrderData()}
                  >
                    <IconContext.Provider
                      value={{
                        size: "2em",
                        style: { verticalAlign: "top", padding: "3px" },
                      }}
                    >
                      <IoIosRefresh />
                    </IconContext.Provider>
                  </button>
                  {/*
									<button className="btn btn-link p-0" onClick={() => setIsOpened2(true)}>
										<IconContext.Provider value={{ size: "2em", style: { verticalAlign: "top", padding: "3px" } }}>
											<FiSliders />
										</IconContext.Provider>
									</button>
									*/}
                </div>
              </div>

              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-borderless table-sm mb-0">
                    <tbody>
                      {data.orderData.PaymentMethodInfo &&
                        data.orderData.PaymentMethodInfo.PaymentID > 0 && (
                          <>
                            <tr>
                              <th style={{ width: "150px" }}>결제상태</th>
                              <td>
                                {data.orderData.OrderInfo.PaymentStatus ===
                                "Y" ? (
                                  <span className="badge badge-success">
                                    결제됨
                                  </span>
                                ) : (
                                  <span className="badge badge-secondary">
                                    결제안됨
                                  </span>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th>결제ID</th>
                              <td>{data.orderData.OrderInfo.PaymentID}</td>
                            </tr>
                            <tr>
                              <th>결제유형</th>
                              <td>
                                {data.orderData.PaymentMethodInfo.PaymentType}
                              </td>
                            </tr>
                            <tr>
                              <th>PG</th>
                              <td>
                                {data.orderData.PaymentMethodInfo.GatewayName}
                              </td>
                            </tr>
                            <tr>
                              <th>결제수단</th>
                              <td>
                                {data.orderData.PaymentMethodInfo.PaymentName} (
                                {data.orderData.PaymentMethodInfo.Description})
                              </td>
                            </tr>
                            <tr>
                              <th>결제승인번호</th>
                              <td>
                                {data.orderData.OrderInfo.TransactionID
                                  ? data.orderData.OrderInfo.TransactionID
                                  : "-"}
                              </td>
                            </tr>
                            {data.orderData.vBankInfo && (
                              <>
                                <tr>
                                  <td colSpan={2}>
                                    <div
                                      style={{ borderTop: "1px solid #ddd" }}
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <th>가상계좌 발급일시</th>
                                  <td>
                                    {data.orderData.vBankInfo.BankIssuedAt}
                                  </td>
                                </tr>
                                <tr>
                                  <th>가상계좌 입금은행</th>
                                  <td>{data.orderData.vBankInfo.BankName}</td>
                                </tr>
                                <tr>
                                  <th>가상계좌 계좌번호</th>
                                  <td>{data.orderData.vBankInfo.BankNumber}</td>
                                </tr>
                                <tr>
                                  <th>가상계좌 입금기한</th>
                                  <td>{data.orderData.vBankInfo.BankDate}</td>
                                </tr>
                                <tr>
                                  <th>가상계좌 만료여부</th>
                                  <td>
                                    {data.orderData.vBankInfo
                                      .ExpirationStatus === "Y"
                                      ? "입금가능"
                                      : "만료"}
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan={2}>
                                    <div
                                      style={{ borderTop: "1px solid #ddd" }}
                                    />
                                  </td>
                                </tr>
                              </>
                            )}
                            {data.orderData.vBankCancelInfo && (
                              <>
                                <tr>
                                  <td colSpan={2}>
                                    <div
                                      style={{ borderTop: "1px solid #ddd" }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <th>환불계좌 예금주</th>
                                  <td>
                                    {data.orderData.vBankCancelInfo.HolderName}
                                  </td>
                                </tr>
                                <tr>
                                  <th>환불계좌 입금은행</th>
                                  <td>
                                    {
                                      BankCode[
                                        data.orderData.vBankCancelInfo
                                          .HolderBankCode
                                      ]
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th>환불계좌 계좌번호</th>
                                  <td>
                                    {
                                      data.orderData.vBankCancelInfo
                                        .HolderBankNumber
                                    }
                                  </td>
                                </tr>
                              </>
                            )}
                          </>
                        )}
                      <tr>
                        <th style={{ width: "150px" }}>반환금액</th>
                        <td>
                          {numberFormat(data.orderData.OrderInfo.RefundAmount)}
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: "150px" }}>취소일시</th>
                        <td>
                          {data.orderData.OrderInfo.CancelDate
                            ? moment(
                                data.orderData.OrderInfo.CancelDate
                              ).format("YYYY-MM-DD HH:mm:ss")
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: "150px" }}>환불일시</th>
                        <td>
                          {data.orderData.OrderInfo.RefundDate
                            ? moment(
                                data.orderData.OrderInfo.RefundDate
                              ).format("YYYY-MM-DD HH:mm:ss")
                            : "-"}
                        </td>
                      </tr>
                      {data.orderData.ClaimOrderList &&
                        data.orderData.ClaimOrderList.find(
                          (order) => order.Status === "Y"
                        ) && (
                          <>
                            <tr>
                              <td colSpan={2}>
                                <div style={{ borderTop: "1px solid #ddd" }} />
                              </td>
                            </tr>
                            <tr>
                              <th style={{ width: "150px" }}>교환/반품 종류</th>
                              <td>
                                <span className="badge badge-info">
                                  {
                                    ClaimType[
                                      data.orderData.ClaimOrderList.find(
                                        (order) => order.Status === "Y"
                                      ).Type
                                    ]
                                  }
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th style={{ width: "150px" }}>처리일시</th>
                              <td>
                                {data.orderData.ClaimOrderList.find(
                                  (order) => order.Status === "Y"
                                ).RegDate
                                  ? moment(
                                      data.orderData.ClaimOrderList.find(
                                        (order) => order.Status === "Y"
                                      ).RegDate
                                    ).format("YYYY-MM-DD HH:mm:ss")
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th style={{ width: "150px" }}>접수내용</th>
                              <td>
                                {data.orderData.ClaimOrderList.find(
                                  (order) => order.Status === "Y"
                                ).Reason
                                  ? ClaimReason[
                                      data.orderData.ClaimOrderList.find(
                                        (order) => order.Status === "Y"
                                      ).Reason
                                    ]
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th style={{ width: "150px" }}>회수여부</th>
                              <td>
                                {data.orderData.ClaimOrderList.find(
                                  (order) => order.Status === "Y"
                                ).IsReturnWill
                                  ? IsReturnWill_TYPE[
                                      data.orderData.ClaimOrderList.find(
                                        (order) => order.Status === "Y"
                                      ).IsReturnWill
                                    ]
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th style={{ width: "150px" }}>상세사유</th>
                              <td>
                                {data.orderData.ClaimOrderList.find(
                                  (order) => order.Status === "Y"
                                ).ReasonDetail
                                  ? data.orderData.ClaimOrderList.find(
                                      (order) => order.Status === "Y"
                                    ).ReasonDetail
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th style={{ width: "150px" }}>자세한 설명</th>
                              <td>
                                {data.orderData.ClaimOrderList.find(
                                  (order) => order.Status === "Y"
                                ).Comment
                                  ? data.orderData.ClaimOrderList.find(
                                      (order) => order.Status === "Y"
                                    ).Comment
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th style={{ width: "150px" }}>처리상태</th>
                              <td>
                                {data.orderData.ClaimOrderList.find(
                                  (order) => order.Status === "Y"
                                ).Status
                                  ? ClaimStatus[
                                      data.orderData.ClaimOrderList.find(
                                        (order) => order.Status === "Y"
                                      ).Status
                                    ]
                                  : "-"}
                              </td>
                            </tr>

                            {data.orderData.OrderInfo.LinkedOrderID && (
                              <tr>
                                <th>원주문</th>
                                <td>
                                  <Link
                                    to={`/orders/detail/${data.orderData.OrderInfo.LinkedOrderID}`}
                                    target="_blank"
                                  >
                                    <span className="badge badge-sm badge-info">
                                      {data.orderData.OrderInfo.LinkedOrderID}
                                    </span>
                                  </Link>
                                </td>
                              </tr>
                            )}
                            {data.orderData.ClaimOrderList.find(
                              (order) => order.Status === "Y"
                            ).CancelData &&
                              data.orderData.ClaimOrderList.find(
                                (order) => order.Status === "Y"
                              ).CancelData.trackNo && (
                                <tr>
                                  <th style={{ width: "150px" }}>원송장번호</th>
                                  <td>
                                    <a
                                      href={`https://www.doortodoor.co.kr/parcel/doortodoor.do?fsp_action=PARC_ACT_002&fsp_cmd=retrieveInvNoACT&invc_no=${
                                        data.orderData.ClaimOrderList.find(
                                          (order) => order.Status === "Y"
                                        ).CancelData.trackNo
                                      }`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="badge badge-dark">
                                        {
                                          data.orderData.ClaimOrderList.find(
                                            (order) => order.Status === "Y"
                                          ).CancelData.trackNo
                                        }
                                      </span>
                                    </a>
                                  </td>
                                </tr>
                              )}
                          </>
                        )}
                    </tbody>
                  </table>
                </div>

                {(data.orderData.OrderInfo.OrderStatus === "Initialized" ||
                  data.orderData.OrderInfo.OrderStatus === "Processing") &&
                  data.userData && (
                    <div className="table-responsive">
                      <table className="table table-borderless table-sm">
                        <tbody>
                          <tr>
                            <th
                              style={{
                                width: "150px",
                              }}
                            >
                              결제하기
                            </th>
                            <td>
                              {data.userData.EasyPaymentInfo &&
                                data.userData.EasyPaymentInfo.map((v, idx) => (
                                  <Fragment key={idx}>
                                    {v.Status === "Y" && (
                                      <button
                                        className="btn btn-danger mr-1 mb-1"
                                        onClick={() => {
                                          if (
                                            window.confirm(
                                              "정말로 결제하시겠습니까?"
                                            )
                                          ) {
                                            easyPaymentPayNow(
                                              data.userData.UserInfo.UNID,
                                              data.orderData.OrderInfo.OrderID,
                                              actions
                                            );
                                          }
                                        }}
                                      >
                                        등록된
                                        <strong
                                          className="text-warning"
                                          style={{ marginTop: "0.5em" }}
                                        >
                                          {v.CardName} {v.Description}
                                        </strong>
                                      </button>
                                    )}
                                  </Fragment>
                                ))}

                              {data.orderData.OrderInfo.IsSubscribed ===
                                "N" && (
                                <>
                                  <button
                                    className="btn btn-danger mr-1 mb-1"
                                    onClick={() => showModal()}
                                  >
                                    카드간편결제
                                  </button>
                                  <button
                                    className="btn btn-danger mr-1 mb-1"
                                    type="button"
                                    onClick={() => {
                                      openTossVBankWindow(
                                        data.userData.UserInfo.UNID,
                                        data.orderData.OrderInfo.OrderID
                                      );
                                    }}
                                  >
                                    가상계좌
                                  </button>
                                </>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
              </div>
            </div>

            {cardInfo && (
              <Drawer
                open={isOpened}
                onRequestClose={() => setIsOpened(!isOpened)}
                allowClose={false}
              >
                <div className="row">
                  <div className="col">
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="card-title">카드 간편결제</div>
                        <div>
                          <div className="form-group position-relative">
                            <div className="input-group input-group-sm">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text mb-1"
                                  style={{ minWidth: "150px" }}
                                >
                                  카드번호
                                </span>
                              </div>
                              <input
                                className="form-control form-control-sm"
                                name="cardno"
                                onChange={handleChangeCardinfo}
                                value={cardInfo.cardno}
                                style={{ minWidth: "300px" }}
                                maxLength={16}
                              />
                            </div>

                            <div className="input-group input-group-sm">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text mb-1"
                                  style={{ minWidth: "150px" }}
                                >
                                  만료일(MMYY)
                                </span>
                              </div>
                              <input
                                className="form-control form-control-sm"
                                name="expdate"
                                onChange={handleChangeCardinfo}
                                value={cardInfo.expdate}
                                style={{ minWidth: "300px" }}
                                maxLength={4}
                              />
                            </div>

                            <div className="input-group input-group-sm">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text mb-1"
                                  style={{ minWidth: "150px" }}
                                >
                                  생년월일(801022)
                                </span>
                              </div>
                              <input
                                className="form-control form-control-sm"
                                name="dob"
                                onChange={handleChangeCardinfo}
                                value={cardInfo.dob}
                                style={{ minWidth: "300px" }}
                              />
                            </div>

                            <div className="input-group input-group-sm">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text mb-1"
                                  style={{ minWidth: "150px" }}
                                >
                                  비밀번호앞2자리(**)
                                </span>
                              </div>
                              <input
                                className="form-control form-control-sm"
                                type="password"
                                name="cardpwd"
                                onChange={handleChangeCardinfo}
                                value={cardInfo.cardpwd}
                                style={{ minWidth: "300px" }}
                                maxLength={2}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            className="btn btn-light"
                            onClick={() => setIsOpened(!isOpened)}
                          >
                            닫기
                          </button>
                          <button
                            className="btn btn-success ml-2"
                            onClick={() => {
                              tossEasypaymentDo(
                                data.userData.UserInfo.UNID,
                                data.orderData.OrderInfo.OrderID,
                                cardInfo,
                                actions
                              );
                              setIsOpened(!isOpened);
                            }}
                          >
                            간편결제하기
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Drawer>
            )}

            <Drawer
              open={isOpened2}
              onRequestClose={() => setIsOpened2(!isOpened2)}
              allowClose={false}
            >
              <div className="row">
                <div className="col">
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="card-title">추가</div>
                      <div>
                        <div className="form-group position-relative">
                          <div className="input-group input-group-sm">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text mb-1"
                                style={{ minWidth: "150px" }}
                              >
                                총결제금액
                              </span>
                            </div>
                            <div className="form-control form-control-sm">
                              {data.orderData.OrderInfo.TotalAmount}
                            </div>
                          </div>
                          <div className="input-group input-group-sm">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text mb-1"
                                style={{ minWidth: "150px" }}
                              >
                                환불금액
                              </span>
                            </div>
                            <input
                              className="form-control form-control-sm"
                              onChange={handleChangeRefundAmount}
                              value={getAmount}
                              style={{ minWidth: "300px" }}
                              maxLength={16}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-center">
                        <button
                          className="btn btn-light"
                          onClick={() => setIsOpened2(!isOpened2)}
                        >
                          닫기
                        </button>

                        <button
                          className="btn btn-success ml-2"
                          onClick={() => {
                            setRefundAmount(
                              data.orderData.OrderInfo.OrderID,
                              actions
                            );
                            setIsOpened2(!isOpened2);
                          }}
                        >
                          환불금액 입력하기
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Drawer>
          </>
        )
      }
    </OrderContext.Consumer>
  );
};

export default OrderPayment;
