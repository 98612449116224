export const CALENDAR_DATE_START = {
  name: "startdate",
  placeholder: "시작날짜",
};

export const CALENDAR_DATE_END = {
  name: "enddate",
  placeholder: "종료날짜",
};

export const BUTTON_SEARCH = {
  name: "검색",
  classNames: ["btn", "btn-primary", "btn-sm", "he-100"],
};

export const BUTTON_DOWNLOAD = {
  name: "다운로드",
  classNames: ["btn", "btn-danger", "btn-sm", "he-100"],
};
