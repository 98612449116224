import React, { useEffect, useState } from "react";

import axios from "axios";
import TitleContent from "../../components/TitleContent";
import { simpleTable, half } from "./AlimtalkTemplate";
import { ADMIN_AUTH_COOKIE } from "../../constants";

const getCookie = (name) => {
  const value = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return value ? value[2] : null;
};

export const getAxios = () => {
  const wat = getCookie(ADMIN_AUTH_COOKIE);

  return axios.create({
    headers: {
      Authorization: `Bearer ${wat}`,
    },
    withCredentials: true,
    baseURL: "http://localhost:3000",
  });
};

const AlimtalkEvent = () => {
  /* 전체 List 받기 */
  const [templates, setTemplates] = useState([] as any);
  const [template, setTemplate] = useState({} as any);
  const [events, setEvents] = useState([] as any);
  const [event, setEvent] = useState({} as any);

  /* 화면 로딩 초기에 리스트 불러오기 */
  useEffect(() => {
    getAxios()
      .get("admin/alimtalk/templates")
      .then((res) => {
        setTemplates(res.data);
      });
    getAxios()
      .get("admin/alimtalk/events")
      .then((res) => {
        setEvents(res.data);
      });
  }, []);

  // const handle = () => {
  //   getAxios()
  //     .post("admin/alimtalk/template.send", {
  //       template,
  //       args: {
  //         user: 10000317,
  //       },
  //     })
  //     .then((res) => console.log(res));
  // };

  // function trim(obj) {
  //   return fields.reduce((pv, { name, type }) => {
  //     if (obj[name]) {
  //       if (type === "number") {
  //         pv[name] = +obj[name];
  //       } else {
  //         pv[name] = (obj[name] ?? "").trim() || undefined;
  //       }
  //     }
  //     return pv;
  //   }, {});
  // }

  // const handleSave = () => {
  //   getAxios()
  //     .post("admin/alimtalk/template.save", trim(template))
  //     .then((res) => console.log(res));
  // };

  // const setAttr = (attr) => (ev) => {
  //   const v = ev.target.value;
  //   setTemplate((old) => ({ ...old, ...{ [attr]: v } }));
  // };

  const selectEvent = (idx) => () => {
    setEvent(event.id ? {} : events[idx]);
  };

  const select = (idx) => () => {
    setTemplate(template.id ? {} : templates[idx]);
  };

  const eventFields = ["id", "description", "name", "templateId"];
  const tableFields = ["id", "name", "code", "createdAt"];

  // const fields = [
  //   { name: "id", disabled: true, type: "number" },
  //   { name: "name" },
  //   { name: "code" },
  //   { name: "content", textarea: true },
  //   { name: "button1Name" },
  //   { name: "button1Url" },
  //   { name: "button2Name" },
  //   { name: "button2Url" },
  // ];

  return (
    <>
      <TitleContent title="알림톡 관리" subHeading="알림톡 관리" />

      <div className="row">
        {half(
          "이벤트",
          simpleTable(
            events,
            eventFields,
            selectEvent,
            (idx) => events[idx].id === event.id
          )
        )}
        {half("템플릿", simpleTable(templates, tableFields, select, undefined))}
      </div>
    </>
  );
};

export default AlimtalkEvent;
