import React from "react";

const Input = ({ name, classNames, value, onChange, placeholder }) => (
  <input
    name={name}
    className={classNames.join(" ")}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
  />
);

export default Input;
