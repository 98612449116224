export const SELECT_PROVIDER_NAME = {
  name: "providerId",
  classNames: ["form-control", "form-control-sm"],
  options: [
    { "": "브랜드전체" },
    { 100000000: "와이즐리" },
    { 200000000: "오픈워크" },
    { 300000000: "헤드웍스" },
    { 400000000: "해비추얼" },
    { 500000000: "플로버" },
  ],
};

export const SELECT_POINT = {
  name: "point",
  classNames: ["form-control", "form-control-sm"],
  options: [
    { "": "별점전체" },
    { 0: "별점 0" },
    { 1: "별점 1" },
    { 2: "별점 2" },
    { 3: "별점 3" },
    { 4: "별점 4" },
    { 5: "별점 5" },
  ],
};

export const SELECT_OPTION_ID = {
  name: "optionId",
  classNames: ["form-control", "form-control-sm"],
  options: {
    // api 로 받아옴.
  },
};

export const SELECT_INVISIBLE = {
  name: "isVisible",
  classNames: ["form-control", "form-control-sm"],
  options: [{ "": "노출전체" }, { N: "미노출" }, { Y: "노출" }],
};

export const INPUT_KEYWORD = {
  name: "keyword",
  classNames: ["form-control", "form-control-sm"],
  placeholder: "고객명, 내용",
};

export const BUTTON_DATA_UPDATE = {
  name: "데이터 업데이트 요청(3분소요)",
  classNames: ["btn", "btn-danger", "btn-sm", "he-100"],
};

// export const SEARCH_COUNT = {
//   0: "별점0",
//   1: "별점1",
//   2: "별점2",
//   3: "별점3",
//   4: "별점4",
//   5: "별점5",
// };
