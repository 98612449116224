import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Drawer from "react-drag-drawer";
import moment from "moment";
import { IconContext } from "react-icons";
import { IoIosRefresh, IoIosSettings } from "react-icons/io";
import {
  FaKey,
  FaRegComment,
  FaRegEnvelope,
  FaUserAltSlash,
} from "react-icons/fa";
import { MdVisibility } from "react-icons/md";

import { CommonServerDomain } from "../../../Store";
import { DetailContext } from "../../../DetailContext";
import * as utils from "../../../utils/Utils";
import { AppContext } from "../../..";
import {
  GetUserOutDto,
  UpdateNotificationInDto,
  UpdateUserInDto,
  UserDetailInfoOutDto,
} from "../../../api/wc-api";
import { withAutoToastCode } from "../../../utils/Utils";

// 2022. 01. 25. 삭제한 내용 기록용
//  getAvailableEvent
//  제로업
//    utils.getFreeZeroUpStatus
//    utils.setFreeZeroUpStatus
//  오픈워크 체험세트
//  칼리버 트레블커버
//    getAvailableEvents
//  가입경로 (api 반환값에 추가하면 다시 원복)

const UserInfo = ({ userid }) => {
  const ctx = useContext(AppContext);

  /** Modal 띄우기 */
  const [isOpened, setIsOpened] = useState(false);
  const showModal = (data) => {
    setUserData(data); // 임시로 값 저장
    setIsOpened(true); // Modal 띄우기
  };

  /** Modal에서 수정하는 내용 임시 저장 */
  const [userData, setUserData] = useState(null as any);
  const handleChangeItem = (event) => {
    setUserData({
      ...(userData as any),
      [event.target.name]: event.target.value,
    });
  };

  const updateUser = withAutoToastCode(async (data: any) => {
    // TODO: 'data: any' 자체를 UpdateUserInDto 로 받을 수 있도록 하면 좋을듯..
    const updateParams: UpdateUserInDto = {
      unid: data.UNID,
      fullName: data.FullName,
      email: data.Email,
      dob: data.DOB,
      phone: data.Phone,
      gender: data.Gender,
    };

    const result = await ctx.wcApi.adminUser.updateUser(updateParams);
    if (result.data.code === "success") {
      await getUserInfo(data.UNID);
    }

    return result;
  });

  /** Modal2 띄우기 */
  const [isOpened2, setIsOpened2] = useState(false);
  const showModal2 = () => {
    setIsOpened2(true); // Modal 띄우기
  };

  /** Modal2에서 수정하는 내용 임시 저장 */
  const [closeInfo, setCloseInfo] = useState({
    isreturnwill: "",
    reason: "",
    reasondetail: "",
    comment: "[관리자를 통한 회원탈퇴]",
  });
  const [checkedReasons, setCheckedReasons] = useState({});
  const handleChangeCloseInfo = (event) => {
    if (event.target.name === "closeReason") {
      const tmpReason = {
        ...checkedReasons,
        [event.target.id]: event.target.checked,
      };
      const keys = Object.keys(tmpReason);
      keys.map((key) => {
        if (tmpReason[key] === false) {
          delete tmpReason[key];
        }
        return "";
      });
      setCheckedReasons(tmpReason);
      setCloseInfo({
        ...closeInfo,
        reason: Object.keys(tmpReason).join("|"),
      });
    } else {
      setCloseInfo({
        ...closeInfo,
        [event.target.name]: event.target.value,
      });
    }
  };

  /** 비밀번호 변경 */
  const [newPwd, setNewPwd] = useState("");
  const handleChangePwd = (event) => {
    setNewPwd(event.target.value);
  };

  const changePassword = (actions, unid) => {
    if (window.confirm("정말 변경하시겠습니까?")) {
      actions.changePassword({ unid: unid, newpwd: newPwd });
      setNewPwd("");
    }
  };

  const unlinkSocialLogin = async (param) => {
    if (window.confirm("정말 연결을 끊으시겠습니까?")) {
      const getResult = await utils.unlinkSocialLogin(param);
      if (getResult) {
        await getUserInfo(+param.unid);
      }
    }
  };

  const goUserLogin = async (unid) => {
    if (window.confirm("정말 사용자로 로그인하시겠습니까?")) {
      const getResult = await ctx.api.admin
        .signinAsUser({ unid })
        .catch((error) => {
          console.log(error);
          toast.error("사용자로 로그인 요청 실패함", { autoClose: 3000 });
          return undefined;
        });
      if (getResult) {
        window.open(CommonServerDomain);
      }
    }
  };

  // 발주중인 주문 확인하기
  const [orderProcessingInfo, setOrderProcessingInfo] = useState(null as any);
  const getOrderProcessingInfo = async (unid) => {
    const getResult = await utils.getOrderProcessingInfo(unid);
    if (getResult) {
      setOrderProcessingInfo(getResult.OrderProcessingInfo);
    }
  };

  const updateNotification = withAutoToastCode(
    async (param: UpdateNotificationInDto) => {
      const result = await ctx.wcApi.adminUser.updateNotification({
        ...param,
        unid: userid,
      });
      const { data } = result;
      if (data.code === "success") {
        await getUserInfo(userid);
      }
      return result;
    }
  );

  const checkUpcomingBirthday = (dob: string) => {
    if (dob.length !== 6) {
      return false;
    }
    const today = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    );
    const birthday = new Date(
      today.getFullYear(),
      parseInt(dob.slice(2, 4)) - 1,
      parseInt(dob.slice(4, 6))
    );
    const remains = birthday.getTime() - today.getTime();
    const twoDays = 60 * 60 * 24 * 1000 * 2;
    return remains <= twoDays && remains >= 0;
  };

  const [notification, setNotification] = useState<INotification>(null as any);
  const initNotification = (userDetailInfo: UserDetailInfoOutDto) => {
    setNotification({
      AdSMS: {
        name: "광고문자",
        value: userDetailInfo.AdSMS,
      },
      AdEmail: {
        name: "광고메일",
        value: userDetailInfo.AdEmail,
      },
      ServiceSMS: {
        name: "안내문자",
        value: userDetailInfo.ServiceSMS,
      },
      ServiceEmail: {
        name: "안내메일",
        value: userDetailInfo.ServiceEmail,
      },
    });
  };

  const [userInfo, setUserInfo] = useState<GetUserOutDto>(null as any);
  const getUserInfo = async (unid: number) => {
    const result = await ctx.wcApi.adminUser.getUser({ unid });
    if (result.data.code === "success") {
      setUserInfo(result.data.data);
      initNotification(result.data.data.user.UserDetailInfo);
    }
  };

  function getAgreeNotification() {
    return (
      <div>
        {notification ? (
          <table className="table" style={{ marginBottom: "0px" }}>
            <tbody>
              <tr>
                <td
                  style={{
                    paddingLeft: "0px",
                    paddingTop: "0px",
                  }}
                >
                  {Object.keys(notification).map((notiKey, idx) => (
                    <button
                      key={idx}
                      onClick={() =>
                        // notiKey 에 매칭되지 않는다면, 데이터베이스에 있는 값과 동일한 값을 넘겨주고,
                        updateNotification({
                          adSms: getUpdateNotificationValue(notiKey, "AdSMS"),
                          adEmail: getUpdateNotificationValue(
                            notiKey,
                            "AdEmail"
                          ),
                          serviceSms: getUpdateNotificationValue(
                            notiKey,
                            "ServiceSMS"
                          ),
                          serviceEmail: getUpdateNotificationValue(
                            notiKey,
                            "ServiceEmail"
                          ),
                        })
                      }
                      className={`mr-1 btn btn-sm ${
                        notification[notiKey].value === "Y"
                          ? "btn-info"
                          : "btn-light"
                      }`}
                      style={{
                        fontSize: "0.8em",
                      }}
                    >
                      {notification[notiKey].name}
                    </button>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </div>
    );

    function getUpdateNotificationValue(
      notiKey: string,
      notiTargetKey: string
    ) {
      return notiKey === notiTargetKey
        ? notification[notiKey].value === "Y"
          ? "N"
          : "Y"
        : notification[notiTargetKey].value;
    }
  }

  /** 초기 로딩할 때 1회 실행 */
  useEffect(() => {
    getUserInfo(userid);
  }, []);

  return (
    <DetailContext.Consumer>
      {({ actions }) =>
        userInfo && (
          <>
            <div className="card mb-1">
              <div className="card-header text-primary">
                고객기본정보
                <div className="btn-actions-pane-right">
                  <button
                    className="btn btn-link p-0"
                    onClick={() => getUserInfo(userInfo.user.UNID)}
                  >
                    <IconContext.Provider
                      value={{
                        size: "2em",
                        style: { verticalAlign: "top", padding: "3px" },
                      }}
                    >
                      <IoIosRefresh />
                    </IconContext.Provider>
                  </button>

                  <button
                    className="btn btn-link"
                    onClick={() => showModal(userInfo.user)}
                  >
                    <IconContext.Provider
                      value={{
                        size: "2em",
                        style: { verticalAlign: "top", padding: "3px" },
                      }}
                    >
                      <IoIosSettings />
                    </IconContext.Provider>
                  </button>

                  {userInfo.user.UserStatus === "Y" && (
                    <button
                      className="btn btn-link pl-0"
                      onClick={() => showModal2()}
                    >
                      <IconContext.Provider
                        value={{
                          size: "2em",
                          style: { verticalAlign: "top", padding: "3px" },
                        }}
                      >
                        <FaUserAltSlash />
                      </IconContext.Provider>
                    </button>
                  )}
                  {userInfo.user.UserStatus === "Y" && (
                    <button
                      className="btn btn-link pl-0 text-danger"
                      onClick={() => goUserLogin(userInfo.user.UNID)}
                    >
                      <IconContext.Provider
                        value={{
                          size: "2em",
                          style: { verticalAlign: "top", padding: "3px" },
                        }}
                      >
                        <MdVisibility />
                      </IconContext.Provider>
                    </button>
                  )}
                </div>
              </div>
              <div className="card-body" style={{ position: "relative" }}>
                {userInfo && (
                  <div className="table-responsive">
                    <table className="table table-borderless table-sm">
                      <tbody>
                        <tr>
                          <th style={{ width: "150px" }}>회원번호</th>
                          <td>
                            <Link to={`/users/detail/${userInfo.user.UNID}`}>
                              {userInfo.user.UNID}
                            </Link>
                            {userInfo.user.UserStatus === "N" && (
                              <span className="badge badge-danger ml-2">
                                탈퇴회원
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>이름</th>
                          <td>{userInfo.user.FullName}</td>
                        </tr>
                        <tr>
                          <th>이메일</th>
                          <td style={{ wordBreak: "break-all" }}>
                            {userInfo.user.Email}
                          </td>
                        </tr>
                        <tr>
                          <th>전화번호</th>
                          <td>{userInfo.user.Phone}</td>
                        </tr>
                        <tr>
                          <th>성별</th>
                          <td>
                            {userInfo.user.Gender === "M"
                              ? "남성"
                              : userInfo.user.Gender === "F"
                              ? "여성"
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>생년월일</th>
                          <td>
                            {checkUpcomingBirthday(`${userInfo.user.DOB}`) && (
                              <span className="badge badge-danger mr-1">
                                생일
                              </span>
                            )}
                            {userInfo.user.DOB}
                          </td>
                        </tr>
                        <tr>
                          <th>카카오 로그인</th>
                          <td>
                            {userInfo.socialLogin &&
                            userInfo.socialLogin.Status === "Y" ? (
                              <>
                                <span className="badge badge-success p-2">
                                  연결됨 |{" "}
                                  {userInfo.socialLogin.LastLoggedInDate
                                    ? moment(
                                        userInfo.socialLogin.LastLoggedInDate
                                      ).format("YYYY-MM-DD HH:mm:ss")
                                    : moment(
                                        userInfo.socialLogin.RegDate
                                      ).format("YYYY-MM-DD HH:mm:ss")}
                                </span>
                                <button
                                  className="btn btn-light btn-sm ml-1"
                                  onClick={() =>
                                    unlinkSocialLogin({
                                      unid: userInfo.user.UNID,
                                      sid: userInfo.socialLogin!.SID,
                                      actions: actions,
                                    })
                                  }
                                >
                                  연결끊기
                                </button>
                              </>
                            ) : (
                              <span className="badge badge-secondary">
                                연결안됨
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>가입일시</th>
                          <td>
                            {userInfo.user.RegDate
                              ? moment(userInfo.user.RegDate).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              : "-"}
                          </td>
                        </tr>
                        {/*{data.userData.UserReferral &&*/}
                        {/*  data.userData.UserReferral.ReferralType &&*/}
                        {/*  data.userData.UserReferral.ReferralType !== "" && (*/}
                        {/*    <tr>*/}
                        {/*      <th>가입경로</th>*/}
                        {/*      <td>*/}
                        {/*        <div className="mb-2">[가입경로]</div>*/}
                        {/*        {data.userData.UserReferral.ReferralType.toString()*/}
                        {/*          .split("|")*/}
                        {/*          .map((item, key) => (*/}
                        {/*            <li key={key}>{ReferralType[item]}</li>*/}
                        {/*          ))}*/}
                        {/*        <div className="mt-1 opacity-6">*/}
                        {/*          {data.userData.UserReferral.Note}*/}
                        {/*        </div>*/}
                        {/*      </td>*/}
                        {/*    </tr>*/}
                        {/*  )}*/}
                        <tr>
                          <th>마지막 로그인 일시</th>
                          <td>
                            {userInfo.user.LastLoggedInDate
                              ? moment(userInfo.user.LastLoggedInDate).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              : "-"}
                          </td>
                        </tr>
                        {userInfo.user.UserStatus === "N" && (
                          <tr>
                            <th>데이터 변경 일시</th>
                            <td>
                              {userInfo.user.ModifiedDate
                                ? moment(userInfo.user.ModifiedDate).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                : "-"}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <th>비밀번호변경</th>
                          <td>
                            <button
                              className="btn btn-light btn-sm mr-1"
                              onClick={() => {
                                if (
                                  window.confirm("정말로 발송하시겠습니까?")
                                ) {
                                  actions.forgotPassword(
                                    userInfo.user.Email,
                                    "phone"
                                  );
                                }
                              }}
                            >
                              <FaRegComment /> 변경안내문 알림톡발송
                            </button>
                            <button
                              className="btn btn-light btn-sm"
                              onClick={() => {
                                if (
                                  window.confirm("정말로 발송하시겠습니까?")
                                ) {
                                  actions.forgotPassword(
                                    userInfo.user.Email,
                                    "email"
                                  );
                                }
                              }}
                            >
                              <FaRegEnvelope /> 변경안내문 메일발송
                            </button>

                            <div className="form-inline mt-1">
                              <div className="position-relative form-group">
                                <input
                                  name="newPwd"
                                  type="text"
                                  value={newPwd}
                                  onChange={handleChangePwd}
                                  placeholder="변경할 비밀번호를 입력하세요"
                                  className="form-control form-control-sm"
                                  style={{
                                    width: "200px",
                                    display: "inline-block",
                                  }}
                                />
                              </div>
                              <button
                                className="btn btn-light ml-1"
                                onClick={() =>
                                  changePassword(actions, userInfo.user.UNID)
                                }
                              >
                                <FaKey /> 직접변경
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>마케팅 수신동의</th>
                          <td>{getAgreeNotification()}</td>
                        </tr>
                        <tr>
                          <td colSpan={2} />
                        </tr>
                        <tr style={{ borderTop: "1px solid #eee" }}>
                          <td colSpan={2} />
                        </tr>
                        <tr>
                          <th>발주중인 주문</th>
                          <td>
                            {orderProcessingInfo &&
                              (orderProcessingInfo.length < 1 ? (
                                <span className="badge badge-secondary p-2 mr-1">
                                  없음
                                </span>
                              ) : (
                                orderProcessingInfo.map((item, idx) => (
                                  <Link
                                    to={`/delivery/detail/${item.DID}`}
                                    target="_blank"
                                    key={idx}
                                  >
                                    <button className="btn btn-sm btn-success mr-1">
                                      {item.DID}
                                    </button>
                                  </Link>
                                ))
                              ))}
                            <button
                              className="btn btn-sm btn-light"
                              onClick={() =>
                                getOrderProcessingInfo(userInfo.user.UNID)
                              }
                            >
                              확인하기
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} />
                        </tr>
                        <tr style={{ borderTop: "1px solid #eee" }}>
                          <td colSpan={2} />
                        </tr>
                        {/*<tr>*/}
                        {/*  <th>베타서비스</th>*/}
                        {/*  <td>*/}
                        {/*    {data.userData.BetaTesterList &&*/}
                        {/*    data.userData.BetaTesterList.length > 0 ? (*/}
                        {/*      <>*/}
                        {/*        {data.userData.BetaTesterList.map(*/}
                        {/*          (item, idx) => (*/}
                        {/*            <div key={idx} className="mb-1">*/}
                        {/*              <span*/}
                        {/*                className={`badge badge-${*/}
                        {/*                  providerColor[item.ProviderID]*/}
                        {/*                } p-2 mr-1`}*/}
                        {/*              >*/}
                        {/*                {item.IsPaid === "Y"*/}
                        {/*                  ? providerList[item.ProviderID] +*/}
                        {/*                    " 결제한 베타테스터"*/}
                        {/*                  : providerList[item.ProviderID] +*/}
                        {/*                    " 결제안한 베타테스터"}*/}
                        {/*              </span>*/}
                        {/*              {item.Status === "Y" ? (*/}
                        {/*                <span*/}
                        {/*                  className={`badge badge-${*/}
                        {/*                    providerColor[item.ProviderID]*/}
                        {/*                  } p-2 mr-1`}*/}
                        {/*                >*/}
                        {/*                  {providerList[item.ProviderID]} 베타*/}
                        {/*                  이용가능*/}
                        {/*                </span>*/}
                        {/*              ) : (*/}
                        {/*                <span className="badge badge-secondary p-2 mr-1">*/}
                        {/*                  {providerList[item.ProviderID]} 베타*/}
                        {/*                  이용 불가능*/}
                        {/*                </span>*/}
                        {/*              )}*/}
                        {/*            </div>*/}
                        {/*          )*/}
                        {/*        )}*/}
                        {/*      </>*/}
                        {/*    ) : (*/}
                        {/*      <span className="badge badge-secondary p-2 mr-1">*/}
                        {/*        베타테스터 아님*/}
                        {/*      </span>*/}
                        {/*    )}*/}
                        {/*  </td>*/}
                        {/*</tr>*/}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>

            {userData && (
              <Drawer
                open={isOpened}
                onRequestClose={() => setIsOpened(!isOpened)}
                allowClose={false}
              >
                <div className="row">
                  <div className="col">
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="card-title">변경</div>
                        <div>
                          <form>
                            <div className="form-group position-relative">
                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text mb-1"
                                    style={{ minWidth: "150px" }}
                                  >
                                    이름
                                  </span>
                                </div>
                                <input
                                  className="form-control form-control-sm"
                                  name="FullName"
                                  onChange={handleChangeItem}
                                  value={userData.FullName}
                                  style={{ minWidth: "300px" }}
                                />
                              </div>

                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text mb-1"
                                    style={{ minWidth: "150px" }}
                                  >
                                    이메일
                                  </span>
                                </div>
                                <input
                                  className="form-control form-control-sm"
                                  name="Email"
                                  onChange={handleChangeItem}
                                  value={userData.Email}
                                  style={{ minWidth: "300px" }}
                                />
                              </div>

                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text mb-1"
                                    style={{ minWidth: "150px" }}
                                  >
                                    전화번호
                                  </span>
                                </div>
                                <input
                                  className="form-control form-control-sm"
                                  name="Phone"
                                  onChange={handleChangeItem}
                                  value={userData.Phone}
                                  style={{ minWidth: "300px" }}
                                />
                              </div>

                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text mb-1"
                                    style={{ minWidth: "150px" }}
                                  >
                                    성별
                                  </span>
                                </div>
                                <select
                                  className="form-control form-control-sm"
                                  name="Gender"
                                  onChange={handleChangeItem}
                                  value={userData.Gender}
                                  style={{ minWidth: "300px" }}
                                >
                                  <option value="">선택</option>
                                  <option value="M">남성</option>
                                  <option value="F">여성</option>
                                </select>
                              </div>

                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text mb-1"
                                    style={{ minWidth: "150px" }}
                                  >
                                    생년월일
                                  </span>
                                </div>
                                <input
                                  className="form-control form-control-sm"
                                  name="DOB"
                                  onChange={handleChangeItem}
                                  value={userData.DOB}
                                  style={{ minWidth: "300px" }}
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="text-center">
                          <button
                            className="btn btn-light"
                            onClick={() => setIsOpened(!isOpened)}
                          >
                            닫기
                          </button>

                          <button
                            className="btn btn-success ml-2"
                            onClick={async () => {
                              await updateUser(userData);
                              setIsOpened(!isOpened);
                            }}
                          >
                            변경하기
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Drawer>
            )}

            {closeInfo && (
              <Drawer
                open={isOpened2}
                onRequestClose={() => setIsOpened(!isOpened2)}
                allowClose={false}
              >
                <div className="row">
                  <div className="col">
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="card-title">회원탈퇴</div>
                        <div>
                          <form>
                            <div className="form-group position-relative">
                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text mb-1"
                                    style={{ minWidth: "150px" }}
                                  >
                                    회원탈퇴를 원하는 이유
                                  </span>
                                </div>
                                <div className="p-2">
                                  {reasons.map((item, idx) => (
                                    <div
                                      className="position-relative form-check pt-1"
                                      key={idx}
                                    >
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="closeReason"
                                        id={item.code}
                                        onChange={(e) =>
                                          handleChangeCloseInfo(e)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={item.code}
                                      >
                                        {item.text}
                                      </label>
                                    </div>
                                  ))}
                                  <textarea
                                    className="form-control"
                                    name="reasondetail"
                                    disabled={checkedReasons[99] !== true}
                                    onChange={(e) => handleChangeCloseInfo(e)}
                                    value={closeInfo.reasondetail}
                                  />
                                </div>
                              </div>

                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text mb-1"
                                    style={{ minWidth: "150px" }}
                                  >
                                    서비스 개선을 위한 의견
                                  </span>
                                </div>
                                <input
                                  className="form-control form-control-sm"
                                  name="comment"
                                  onChange={handleChangeCloseInfo}
                                  value={closeInfo.comment}
                                  style={{ minWidth: "300px" }}
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="text-center">
                          <button
                            className="btn btn-light"
                            onClick={() => setIsOpened2(false)}
                          >
                            닫기
                          </button>

                          <button
                            className="btn btn-danger ml-2"
                            onClick={() => {
                              utils
                                .closingAccount(userInfo.user.UNID, closeInfo)
                                .then((res) => {
                                  setIsOpened2(false);
                                  if (res) {
                                    getUserInfo(userInfo.user.UNID);
                                  }
                                });
                            }}
                          >
                            회원 탈퇴하기
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Drawer>
            )}
          </>
        )
      }
    </DetailContext.Consumer>
  );
};

export default UserInfo;

const reasons = [
  { code: "1", text: "제품이 마음에 들지 않아서" },
  { code: "2", text: "새로운 아이디로 가입하고 싶어서" },
  { code: "3", text: "개인정보 보안이 걱정되어서" },
  { code: "4", text: "와이즐리 브랜드 자체가 마음에 들지 않아서" },
  { code: "5", text: "홈페이지가 불만족스러워서" },
  { code: "6", text: "고객응대 등 서비스가 불만족스러워서" },
  { code: "99", text: "기타" },
];

interface INotification {
  AdSMS: {
    name: string;
    value: string;
  };
  AdEmail: {
    name: string;
    value: string;
  };
  ServiceSMS: {
    name: string;
    value: string;
  };
  ServiceEmail: {
    name: string;
    value: string;
  };
}
