import React, { useState } from "react";
import { IconContext } from "react-icons";
import { IoIosRefresh, IoIosSettings } from "react-icons/io";
import { FaTrashAlt } from "react-icons/fa";

import * as utils from "../../../utils/Utils";
import { DetailContext } from "../../../DetailContext";
import { ImageServerDomain, providerColor, providerList } from "../../../Store";
import { numberFormat } from "../../../utils/UtilFunc";

const UserCart = () => {
  // 현재 선택한 브랜드
  const [nowProviderID, setNowProviderID] = useState(100000000);

  /** 상품 리스트 띄우기 */
  const [isOpenedCartItem, setIsOpenedCartItem] = useState(false);
  const showCartItemList = (itemList, cartItem) => {
    if (isOpenedCartItem) {
      setCartItem(null);
      setIsOpenedCartItem(!isOpenedCartItem);
      return null;
    }
    setIsOpenedCartItem(!isOpenedCartItem);

    const calcList = [] as any;
    itemList.ItemList.map((item) => {
      const getItemOption = itemList.ItemOptionList.filter(
        (optionItem) =>
          item.ItemID === optionItem.ItemID &&
          optionItem.SubscriptionType !== "Y"
      );
      getItemOption.map((calcItem) => {
        const getMatchItem = cartItem.filter(
          (cartItem) => cartItem.OptionID === calcItem.OptionID
        );
        if (getMatchItem.length > 0) {
          getMatchItem.map((matchItem) => {
            calcList.push({
              ...calcItem,
              Cycle: matchItem.cycle ? matchItem.cycle : 0,
              Quantity: matchItem.Quantity,
              OrderID: matchItem.OrderID,
              OrderItemID: matchItem.OrderItemID,
              ItemID: matchItem.ItemID,
              OptionID: matchItem.OptionID,
              Term: "W",
              IsSubscribed: matchItem.SubscriptionType,
              CartID: matchItem.CartID,
            });
            return null;
          });
        } else {
          calcList.push({
            ...calcItem,
            Cycle: 0,
            Quantity: 0,
            Term: "W",
            IsSubscribed: calcItem.SubscriptionType,
          });
        }
        return null;
      });
      return null;
    });
    setCartItem({ ...itemList, ItemOptionList: calcList }); // 임시로 값 저장
  };

  /** 상품 리스트에서 수정하는 내용 임시 저장 */
  const [getCartItem, setCartItem] = useState(null as any);

  const deleteCartItem = async (unid, item, actions) => {
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      const getResult = await actions.deleteCartItem(unid, item);
      if (getResult) {
        actions.getUserCartList(unid);
        setCartItem(null);
        setIsOpenedCartItem(!isOpenedCartItem);
      }
    }
  };

  const deleteCartItemAll = async (unid, actions) => {
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      const getResult = await utils.deleteCartItemAll(unid);
      if (getResult) {
        actions.getUserCartList(unid);
        setCartItem(null);
        setIsOpenedCartItem(false);
      }
    }
  };

  //const [baseTerm, setBaseTerm] = useState([0, 1, 2, 4, 8, 12, 16]);

  return (
    <DetailContext.Consumer>
      {({ data, actions }) =>
        data.userCart && (
          <>
            <div className="card mb-1">
              <div className="card-header text-primary">
                고객장바구니정보
                <div className="btn-actions-pane-right">
                  <button
                    className="btn btn-link p-0"
                    onClick={() =>
                      actions.getUserCartList(data.userData.UserInfo.UNID)
                    }
                  >
                    <IconContext.Provider
                      value={{
                        size: "2em",
                        style: { verticalAlign: "top", padding: "3px" },
                      }}
                    >
                      <IoIosRefresh />
                    </IconContext.Provider>
                  </button>

                  <button
                    className="btn btn-link"
                    onClick={() => {
                      showCartItemList(
                        data.itemList,
                        data.userCart.CartItemList
                      );
                      setIsOpenedCartItem(!isOpenedCartItem);
                    }}
                  >
                    <IconContext.Provider
                      value={{
                        size: "2em",
                        style: { verticalAlign: "top", padding: "3px" },
                      }}
                    >
                      <IoIosSettings />
                    </IconContext.Provider>
                  </button>

                  <button
                    className="btn btn-link p-0"
                    onClick={() =>
                      deleteCartItemAll(data.userData.UserInfo.UNID, actions)
                    }
                  >
                    <IconContext.Provider
                      value={{
                        size: "2em",
                        style: { verticalAlign: "top", padding: "3px" },
                      }}
                    >
                      <FaTrashAlt />
                    </IconContext.Provider>
                  </button>
                </div>
              </div>
              {data.userCart.CartItemList.length > 0 && (
                <div className="card-body" style={{ position: "relative" }}>
                  <table className="table table-borderless table-striped table-hover table-sm">
                    <thead>
                      <tr>
                        <th>상품</th>
                        <th className="text-right">판매가</th>
                        <th className="text-right">수량</th>
                        <th className="text-right">총계</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.userCart &&
                        data.userCart.CartItemList.filter(
                          (v) => v.SKU !== "DISCOUNT"
                        ).map((item, idx) => (
                          <tr key={idx}>
                            <th>
                              <div className="widget-content p-0">
                                <div className="widget-content-outer">
                                  <div className="widget-content-wrapper">
                                    <div className="widget-content-left">
                                      <div className="widget-heading">
                                        {item.SubscriptionType === "Y" ? (
                                          <span className="badge badge-info mr-1">
                                            정기
                                          </span>
                                        ) : (
                                          <span className="badge badge-secondary mr-1">
                                            일반
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="ml-1">
                                      <div>
                                        {item.ItemName}
                                        {item.ItemID === 101040000 &&
                                          item.Price === 0 && (
                                            <span
                                              className="text-danger ml-1"
                                              style={{ fontSize: "0.7em " }}
                                            >
                                              무료증정
                                            </span>
                                          )}
                                      </div>
                                      <div
                                        className="opacity-6"
                                        style={{
                                          fontWeight: "normal",
                                          fontSize: "0.8em",
                                        }}
                                      >
                                        {item.OptionItemName}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <td className="text-right">
                              {numberFormat(item.Price)}
                            </td>
                            <td className="text-right">
                              {numberFormat(item.Quantity)}
                            </td>
                            <td className="text-right">
                              {numberFormat(item.Price * item.Quantity)}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>

                  {getCartItem && (
                    <div
                      style={{ borderTop: "1px solid #ddd", marginTop: "1em" }}
                    >
                      <div className="text-right mt-2">
                        <div role="group" className="m-2 btn-group">
                          {Object.keys(providerList).map((key, idx) => (
                            <button
                              key={idx}
                              className={`btn btn-shadow btn-primary ${
                                parseInt(key) === nowProviderID && "active"
                              }`}
                              onClick={() => setNowProviderID(parseInt(key))}
                            >
                              {providerList[key]}
                            </button>
                          ))}
                        </div>
                      </div>

                      <table className="table table-borderless table-hover table-sm mt-3 mb-0">
                        <tbody>
                          {getCartItem.ItemList.map((item, idx1) => {
                            const getItemOption = getCartItem.ItemOptionList.filter(
                              (optionItem) =>
                                item.ProviderID === nowProviderID &&
                                item.ItemID === optionItem.ItemID
                            )
                              .filter(
                                (optionItem) =>
                                  optionItem.IsVisible === "Y" ||
                                  (optionItem.ItemID === 101040000 &&
                                    optionItem.Price === 0)
                              )
                              .filter(
                                (optionItem) => optionItem.SKU !== "DISCOUNT"
                              );

                            return getItemOption.map((optionItem, idx2) => (
                              <tr key={idx1 + idx2}>
                                <td>
                                  <div className="widget-content p-1">
                                    <div className="widget-content-wrapper">
                                      <div className="widget-content-left mr-1">
                                        <span
                                          className={`badge badge-${
                                            providerColor[item.ProviderID]
                                          }`}
                                        >
                                          {providerList[item.ProviderID]}
                                        </span>
                                      </div>
                                      <div className="widget-content-left mr-2">
                                        {optionItem.SubscriptionType === "Y" ? (
                                          <span className="badge badge-success">
                                            정기
                                          </span>
                                        ) : (
                                          <span className="badge badge-secondary">
                                            일반
                                          </span>
                                        )}
                                      </div>
                                      <div className="widget-content-left mr-2">
                                        <img
                                          className="rounded-circle"
                                          src={
                                            ImageServerDomain +
                                            item.FileKey +
                                            item.FileName
                                          }
                                          alt=""
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                          }}
                                        />
                                      </div>
                                      <div className="widget-content-left flex2">
                                        <div className="widget-heading">
                                          {item.ItemName}
                                          {optionItem.ItemID === 101040000 &&
                                            optionItem.Price === 0 && (
                                              <span
                                                className="text-danger ml-1"
                                                style={{ fontSize: "0.7em " }}
                                              >
                                                무료증정
                                              </span>
                                            )}
                                          {optionItem.SubscriptionType ===
                                            "Y" && (
                                            <span className="badge badge-success badge-sm ml-2">
                                              정기
                                            </span>
                                          )}
                                          {optionItem.IsVisible === "N" && (
                                            <span className="badge badge-warning ml-1">
                                              미노출
                                            </span>
                                          )}
                                        </div>
                                        <div
                                          className="widget-subheading opacity-10"
                                          style={{
                                            fontWeight: "normal",
                                            fontSize: "0.9em",
                                          }}
                                        >
                                          {optionItem.OptionItemName}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                {/*
																	<td width="120">
																		{optionItem.SubscriptionType === "Y" && (
																			<div className="input-group input-group-sm">
																				<select
																					className="form-control text-center"
																					name="Cycle"
																					defaultValue={data.userData.SubscriptionItemList.filter((v) => v.OptionID === optionItem.OptionID).length > 0 ? data.userData.SubscriptionItemList.filter((v) => v.OptionID === optionItem.OptionID)[0].Cycle : 0}
																					onChange={(e) => handleChangeCartItem(e, optionItem.OptionID)}
																					style={{ width: "45px" }}
																					disabled={optionItem.SubscriptionType === "Y" ? false : true}
																				>
																					<option value="0">{!baseTerm.includes(0) && "X "}0주</option>
																					<option value="1">{!baseTerm.includes(1) && "X "}1주</option>
																					<option value="2">{!baseTerm.includes(2) && "X "}2주</option>
																					<option value="4">{!baseTerm.includes(4) && "X "}4주</option>
																					<option value="8">{!baseTerm.includes(8) && "X "}8주</option>
																					<option value="12">{!baseTerm.includes(12) && "X "}12주</option>
																					<option value="16">{!baseTerm.includes(16) && "X "}16주</option>
																				</select>
																			</div>
																		)}
																	</td>
																	<td width="100">
																		<div className="input-group input-group-sm">
																			<div className="input-group-prepend">
																				<button className="btn btn-light" onClick={(e) => handleChangeCartItem(e, optionItem.OptionID, "minus")}>
																					-
																				</button>
																			</div>
																			<input className={`form-control text-center ${optionItem.Quantity <= 0 && "bg-light text-secondary"}`} type="text" name="Quantity" style={{ width: "30px" }} value={optionItem.Quantity} onChange={(e) => handleChangeCartItem(e, optionItem.OptionID)} />
																			<div className="input-group-append">
																				<button className="btn btn-light" onClick={(e) => handleChangeCartItem(e, optionItem.OptionID, "plus")}>
																					+
																				</button>
																			</div>
																		</div>
																	</td>
																	*/}
                                <td width="120" className="text-right">
                                  {/*
																		<button className={`btn btn-sm mr-1 ${optionItem.isChanged === "changed" ? "btn-danger" : "btn-light"}`} onClick={() => updateCart(data.userData.UserInfo.UNID, optionItem, actions)}>
																			변경
																		</button>
																		*/}
                                  {optionItem.Quantity !== 0 ? (
                                    <button
                                      className="btn btn-sm btn-info"
                                      onClick={() =>
                                        deleteCartItem(
                                          data.userData.UserInfo.UNID,
                                          optionItem,
                                          actions
                                        )
                                      }
                                    >
                                      제거
                                    </button>
                                  ) : (
                                    <span className="badge badge-secondary p-2">
                                      없음
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ));
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )
      }
    </DetailContext.Consumer>
  );
};

export default UserCart;
