import React, { useState } from "react";
import { IconContext } from "react-icons";
import { IoIosRefresh } from "react-icons/io";
import { FaBox, FaBolt, FaTruck } from "react-icons/fa";

import * as utils from "../../../utils/Utils";
import OrderContext from "../../../providers/ProviderOrderDetail";
import { numberFormat } from "../../../utils/UtilFunc";
import { ImageServerDomain, providerColor, providerList } from "../../../Store";

const OrderItems = () => {
  /** 상품 리스트 띄우기 */
  const [isOpenedCartItem, setIsOpenedCartItem] = useState(false);
  const toggle = (itemList, itemData) => {
    if (isOpenedCartItem) {
      setItem(null);
      setIsOpenedCartItem(false);
    } else {
      getItemList(itemList, itemData);
      setIsOpenedCartItem(true);
    }
  };

  // 현재 선택한 브랜드
  const [nowProviderID, setNowProviderID] = useState(100000000);

  const getItemList = (itemList, itemData) => {
    const calcList = [] as any[];
    itemList.ItemList.map((item) => {
      const getItemOption = itemList.ItemOptionList.filter(
        (optionItem) => item.ItemID === optionItem.ItemID
      );

      getItemOption.map((calcItem) => {
        const getMatchItem = itemData.filter(
          (cartItem) => cartItem.OptionID === calcItem.OptionID
        );
        if (getMatchItem.length > 0) {
          getMatchItem.map((matchItem) => {
            calcList.push({
              ...calcItem,
              Cycle: matchItem.cycle ? matchItem.cycle : 0,
              Quantity: matchItem.Quantity,
              OrderID: matchItem.OrderID,
              OrderItemID: matchItem.OrderItemID,
              ItemID: matchItem.ItemID,
              OptionID: matchItem.OptionID,
              Term: "W",
            });
            return null;
          });
        } else {
          calcList.push({
            ...calcItem,
            Cycle: 0,
            Quantity: 0,
            Term: "W",
          });
        }
        return null;
      });
      return null;
    });

    setItem({ ...itemList, ItemOptionList: calcList }); // 임시로 값 저장
  };

  /** 상품 리스트에서 수정하는 내용 임시 저장 */
  const [getItem, setItem] = useState<any>(null);
  const handleChangeCartItem = (event, optionID, mode = "") => {
    const changedItem = getItem.ItemOptionList.map((optionItem) => {
      let returnResult;
      if (optionItem.OptionID === optionID) {
        if (mode !== "") {
          let nextQuantity = 0;
          if (mode === "plus") {
            nextQuantity = optionItem.Quantity + 1;
          } else {
            nextQuantity =
              optionItem.Quantity - 1 > 0 ? optionItem.Quantity - 1 : 0;
          }
          returnResult = {
            ...optionItem,
            isChanged: "changed",
            Quantity: nextQuantity,
          };
        } else {
          returnResult = {
            ...optionItem,
            isChanged: "changed",
            [event.target.name]: event.target.value,
          };
        }
      } else {
        returnResult = optionItem;
      }
      return returnResult;
    });
    setItem({
      ...getItem,
      ItemOptionList: changedItem,
    });
  };

  const updateItem = async (item, itemList, actions) => {
    const getResult = await actions.setOrderItem({
      orderId: item.orderid,
      optionId: item.OptionID,
      quantity: item.Quantity,
    });
    if (getResult) {
      getItemList(itemList, getResult.data);
    }
  };

  const deleteItem = async (item, itemList, actions) => {
    const getResult = await actions.deleteOrderItem({
      orderId: item.orderid,
      optionId: item.OptionID,
      quantity: 0,
    });
    if (getResult) {
      getItemList(itemList, getResult.data);
    }
  };

  const [showAll, setShowAll] = useState(false);

  // CX주문 만들때 가격 지정하기
  const [paymentAmount, setPaymentAmount] = useState(null);
  const handlePaymentAmount = (event) => {
    setPaymentAmount(event.target.value);
  };

  const resetPrice = async (unid, orderid, actions) => {
    if (window.confirm("정말 결제금액을 변경하시겠습니까?")) {
      const getResult = await utils.resetOrderPrice(
        unid,
        orderid,
        paymentAmount
      );
      if (getResult) {
        actions.refreshOrderData();
      }
    }
  };

  return (
    <OrderContext.Consumer>
      {({ data, actions }) =>
        data.orderData && (
          <>
            {data.orderData.OrderItemList && (
              <div className="row mb-1">
                <div className="col">
                  <div className="card">
                    <div className="card-header text-primary">
                      주문상품정보
                      <div className="btn-actions-pane-right">
                        <button
                          className="btn btn-link p-0"
                          onClick={() => actions.refreshOrderData()}
                        >
                          <IconContext.Provider
                            value={{
                              size: "2em",
                              style: { verticalAlign: "top", padding: "3px" },
                            }}
                          >
                            <IoIosRefresh />
                          </IconContext.Provider>
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-borderless table-hover table-sm">
                          <tbody>
                            {data.orderData.OrderItemList.filter(
                              (v) => v.SKU !== "DISCOUNT"
                            ).map((v, idx) => (
                              <tr key={idx}>
                                <td>
                                  <div className="widget-content p-0">
                                    <div className="widget-content-outer">
                                      <div className="widget-content-wrapper">
                                        <div className="widget-content-left mr-1">
                                          <span
                                            className={`badge badge-${
                                              providerColor[v.ProviderID]
                                            }`}
                                          >
                                            {providerList[v.ProviderID]}
                                          </span>
                                        </div>
                                        <div className="widget-content-left mr-1">
                                          {v.SubscriptionType === "Y" ? (
                                            <span className="badge badge-success">
                                              정기
                                            </span>
                                          ) : (
                                            <span className="badge badge-secondary">
                                              일반
                                            </span>
                                          )}
                                        </div>
                                        <div className="widget-content-left">
                                          <div className="widget-heading">
                                            <span
                                              style={{ fontWeight: "bold" }}
                                            >
                                              {v.ItemName}
                                            </span>
                                            {v.ItemID === 101040000 &&
                                              v.Price === 0 && (
                                                <span
                                                  className="text-danger ml-1"
                                                  style={{ fontSize: "0.7em " }}
                                                >
                                                  무료증정
                                                </span>
                                              )}
                                          </div>
                                          <div
                                            className="widget-subheading opacity-10"
                                            style={{
                                              fontWeight: "normal",
                                              fontSize: "0.9em",
                                            }}
                                          >
                                            {v.OptionItemName}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="text-right">
                                  <div>{numberFormat(v.Price)}</div>
                                </td>
                                <td className="text-right">{v.Quantity}개</td>
                                <td className="text-right">
                                  <div>
                                    {numberFormat(v.Price * v.Quantity)}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        {data.orderData &&
                          priceComponent(
                            data.orderData.OrderInfo,
                            // TODO: item 은 할인상품이 들어있더라도 내려주고, items 로 부터 추론하는것이 맞지 않을지?
                            data.orderData.AdminDiscountInfo.AdminDiscountAmount
                          )}

                        {(data.orderData.OrderInfo.OrderStatus ===
                          "Initialized" ||
                          data.orderData.OrderInfo.OrderStatus ===
                            "Processing" ||
                          data.orderData.OrderInfo.OrderStatus ===
                            "Awaiting-Subscription") && (
                          <>
                            <div className="text-right mb-3">
                              <div style={{ display: "inline-block" }}>
                                <div className="form-inline mt-1">
                                  <div className="position-relative form-group">
                                    <input
                                      type="text"
                                      onChange={handlePaymentAmount}
                                      placeholder="총 결제금액"
                                      className="form-control form-control-sm text-center"
                                      style={{
                                        width: "100px",
                                        display: "inline-block",
                                      }}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-danger ml-1"
                                    onClick={() =>
                                      resetPrice(
                                        data.userData.UserInfo.UNID,
                                        data.orderData.OrderInfo.OrderID,
                                        actions
                                      )
                                    }
                                  >
                                    총 결제금액 변경
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="text-right">
                              <button
                                className="btn btn-sm btn-light"
                                onClick={() => {
                                  toggle(
                                    data.itemList,
                                    data.orderData.OrderItemList
                                  );
                                }}
                              >
                                이번배송 상품변경
                              </button>
                            </div>
                          </>
                        )}

                        <div>
                          {getItem && (
                            <div
                              style={{
                                borderTop: "1px solid #ddd",
                                marginTop: "1em",
                              }}
                            >
                              <div className="text-right mt-3">
                                <div role="group" className="m-2 btn-group">
                                  {Object.keys(providerList).map((key, idx) => (
                                    <button
                                      key={idx}
                                      className={`btn btn-shadow btn-primary ${
                                        parseInt(key) === nowProviderID &&
                                        "active"
                                      }`}
                                      onClick={() =>
                                        setNowProviderID(parseInt(key))
                                      }
                                    >
                                      {providerList[key]}
                                    </button>
                                  ))}
                                </div>

                                {showAll ? (
                                  <button
                                    className="btn btn-info mr-1"
                                    onClick={() => setShowAll(false)}
                                  >
                                    노출 상품만 보기
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-info mr-1"
                                    onClick={() => setShowAll(true)}
                                  >
                                    상품 모두보기
                                  </button>
                                )}
                              </div>

                              <table className="table table-borderless table-hover table-sm mt-3 mb-0">
                                <tbody>
                                  {getItem.ItemList.map((item, idx1) => {
                                    let getItemOption;
                                    if (
                                      data.orderData.OrderInfo.IsSubscribed ===
                                      "Y"
                                    ) {
                                      if (showAll) {
                                        getItemOption = getItem.ItemOptionList.filter(
                                          (optionItem) =>
                                            item.ProviderID === nowProviderID &&
                                            item.ItemID === optionItem.ItemID
                                        )
                                          .filter(
                                            (optionItem) =>
                                              ![
                                                109030000,
                                                209030000,
                                                209040000,
                                                101010000,
                                                // 와이즐리 스타터 세트
                                                101020000,
                                                // 와이즐리 2.0 면도기 세트
                                                101030000,
                                                // 와이즐리 2.0 면도기 체험세트
                                                108010000,
                                              ].includes(optionItem.ItemID)
                                          )
                                          .filter(
                                            (optionItem) =>
                                              ![
                                                // 애프터쉐이브 구형 디자인 (지성용)
                                                104010800,
                                                // 애프터쉐이브 구형 디자인 (건성용)
                                                104010900,
                                              ].includes(optionItem.OptionID)
                                          )
                                          .filter(
                                            (optionItem) =>
                                              optionItem.SKU !== "DISCOUNT"
                                          );
                                      } else {
                                        getItemOption = getItem.ItemOptionList.filter(
                                          (optionItem) =>
                                            item.ProviderID === nowProviderID &&
                                            item.ItemID === optionItem.ItemID
                                        )
                                          .filter(
                                            (optionItem) =>
                                              optionItem.IsVisible === "Y" ||
                                              (optionItem.ItemID ===
                                                101040000 &&
                                                optionItem.Price === 0)
                                          )
                                          .filter(
                                            (optionItem) =>
                                              optionItem.ItemID !== 109030000
                                          )
                                          .filter(
                                            (optionItem) =>
                                              optionItem.ItemID !== 209030000
                                          )
                                          .filter(
                                            (optionItem) =>
                                              optionItem.ItemID !== 209040000
                                          )
                                          .filter(
                                            (optionItem) =>
                                              optionItem.SKU !== "DISCOUNT"
                                          );
                                      }
                                    } else {
                                      getItemOption = getItem.ItemOptionList.filter(
                                        (optionItem) =>
                                          item.ProviderID === nowProviderID &&
                                          item.ItemID === optionItem.ItemID
                                      )
                                        .filter(
                                          (optionItem) =>
                                            optionItem.IsVisible === "Y" ||
                                            (optionItem.ItemID === 101040000 &&
                                              optionItem.Price === 0)
                                        )
                                        .filter(
                                          (optionItem) =>
                                            optionItem.ItemID !== 109030000
                                        )
                                        .filter(
                                          (optionItem) =>
                                            optionItem.ItemID !== 209030000
                                        )
                                        .filter(
                                          (optionItem) =>
                                            optionItem.ItemID !== 209040000
                                        )
                                        .filter(
                                          (optionItem) =>
                                            optionItem.SKU !== "DISCOUNT"
                                        )
                                        .filter(
                                          (optionItem) =>
                                            optionItem.SubscriptionType !== "Y"
                                        );
                                      if (showAll) {
                                        getItemOption = getItem.ItemOptionList.filter(
                                          (optionItem) =>
                                            item.ProviderID === nowProviderID &&
                                            item.ItemID === optionItem.ItemID
                                        )
                                          .filter(
                                            (optionItem) =>
                                              optionItem.ItemID !== 109030000
                                          )
                                          .filter(
                                            (optionItem) =>
                                              optionItem.ItemID !== 209030000
                                          )
                                          .filter(
                                            (optionItem) =>
                                              optionItem.ItemID !== 209040000
                                          )
                                          .filter(
                                            (optionItem) =>
                                              optionItem.SKU !== "DISCOUNT"
                                          );
                                      }
                                    }

                                    return getItemOption.map(
                                      (optionItem, idx2) => (
                                        <tr key={idx1 + idx2}>
                                          <td>
                                            <div className="widget-content p-1">
                                              <div className="widget-content-wrapper">
                                                <div className="widget-content-left mr-2">
                                                  <span
                                                    className={`badge badge-${
                                                      providerColor[
                                                        item.ProviderID
                                                      ]
                                                    }`}
                                                  >
                                                    {
                                                      providerList[
                                                        item.ProviderID
                                                      ]
                                                    }
                                                  </span>
                                                </div>
                                                <div className="widget-content-left mr-1">
                                                  {optionItem.SubscriptionType ===
                                                  "Y" ? (
                                                    <span className="badge badge-success">
                                                      정기
                                                    </span>
                                                  ) : (
                                                    <span className="badge badge-secondary">
                                                      일반
                                                    </span>
                                                  )}
                                                </div>
                                                <div className="widget-content-left mr-2">
                                                  <img
                                                    className="rounded-circle"
                                                    src={
                                                      item.FileName
                                                        ? ImageServerDomain +
                                                          item.FileKey +
                                                          item.FileName
                                                        : "/assets/images/favicon.png"
                                                    }
                                                    alt=""
                                                    style={{
                                                      width: "40px",
                                                      height: "40px",
                                                    }}
                                                  />
                                                </div>
                                                <div className="widget-content-left flex2">
                                                  <div className="widget-heading">
                                                    <span
                                                      style={{
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      {item.ItemName}
                                                    </span>
                                                    {optionItem.ItemID ===
                                                      101040000 &&
                                                      optionItem.Price ===
                                                        0 && (
                                                        <span
                                                          className="text-danger ml-1"
                                                          style={{
                                                            fontSize: "0.7em ",
                                                          }}
                                                        >
                                                          무료증정
                                                        </span>
                                                      )}
                                                    {optionItem.IsVisible ===
                                                      "N" && (
                                                      <span className="badge badge-warning ml-1">
                                                        미노출
                                                      </span>
                                                    )}
                                                  </div>
                                                  <div
                                                    className="widget-subheading opacity-10"
                                                    style={{
                                                      fontWeight: "normal",
                                                      fontSize: "0.9em",
                                                    }}
                                                  >
                                                    {optionItem.OptionItemName}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td width="100">
                                            <div className="input-group input-group-sm">
                                              <div className="input-group-prepend">
                                                <button
                                                  className="btn btn-light"
                                                  onClick={(e) =>
                                                    handleChangeCartItem(
                                                      e,
                                                      optionItem.OptionID,
                                                      "minus"
                                                    )
                                                  }
                                                >
                                                  -
                                                </button>
                                              </div>
                                              <input
                                                className={`form-control text-center ${
                                                  optionItem.Quantity <= 0 &&
                                                  "bg-light text-secondary"
                                                }`}
                                                type="text"
                                                name="Quantity"
                                                style={{ width: "30px" }}
                                                value={optionItem.Quantity}
                                                onChange={(e) =>
                                                  handleChangeCartItem(
                                                    e,
                                                    optionItem.OptionID
                                                  )
                                                }
                                              />
                                              <div className="input-group-append">
                                                <button
                                                  className="btn btn-light"
                                                  onClick={(e) =>
                                                    handleChangeCartItem(
                                                      e,
                                                      optionItem.OptionID,
                                                      "plus"
                                                    )
                                                  }
                                                >
                                                  +
                                                </button>
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            width="120"
                                            className="text-right"
                                          >
                                            <button
                                              className={`btn btn-sm mr-1 ${
                                                optionItem.isChanged ===
                                                "changed"
                                                  ? "btn-danger"
                                                  : "btn-light"
                                              }`}
                                              onClick={() => {
                                                updateItem(
                                                  {
                                                    ...optionItem,
                                                    issubscribed:
                                                      optionItem.SubscriptionType,
                                                    unid:
                                                      data.userData.UserInfo
                                                        .UNID,
                                                    orderid:
                                                      data.orderData.OrderInfo
                                                        .OrderID,
                                                  },
                                                  data.itemList,
                                                  actions
                                                );
                                              }}
                                            >
                                              변경
                                            </button>

                                            <button
                                              className="btn btn-light btn-sm"
                                              onClick={() => {
                                                deleteItem(
                                                  {
                                                    ...optionItem,
                                                    unid:
                                                      data.userData.UserInfo
                                                        .UNID,
                                                    orderid:
                                                      data.orderData.OrderInfo
                                                        .OrderID,
                                                  },
                                                  data.itemList,
                                                  actions
                                                );
                                              }}
                                            >
                                              제거
                                            </button>
                                          </td>
                                        </tr>
                                      )
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )
      }
    </OrderContext.Consumer>
  );
};

export default OrderItems;

type Price = {
  ItemPriceAmount: number;
  DiscountAmount: number;
  ShippingFee: number;
  AdditionalShippingFee?: number;
  TotalAmount: number;
};
export function priceComponent(price: Price, cxDiscount?) {
  return (
    <div className="row mt-3 mb-3">
      {priceSubComponent(<FaBox />, "상품합계", price.ItemPriceAmount)}
      {priceSubComponent(
        <FaBolt />,
        "할인합계",
        price.DiscountAmount,
        cxDiscount
      )}
      {priceSubComponent(<FaBolt />, "배송비", price.ShippingFee)}
      {priceSubComponent(
        <FaTruck />,
        "추가배송비",
        price.AdditionalShippingFee ?? 0
      )}
      <div className="col-md-4">
        <div className="text-right">
          <div>총 결제금액</div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.3rem",
            }}
          >
            {numberFormat(price && price.TotalAmount)}
          </div>
        </div>
      </div>
    </div>
  );
}
function priceSubComponent(
  badgeContent: any,
  contentText: string,
  amount: number,
  cxDiscount?: number
) {
  return (
    <div className="col-md-2">
      <div className="widget-content p-0 text-center">
        <div className="widget-outer">
          <div className="widget-wrapper">
            <div className="badge badge-dark">
              {badgeContent} {contentText}
            </div>
            <div>{numberFormat(amount)}</div>
            {cxDiscountPrice(cxDiscount)}
          </div>
        </div>
      </div>
    </div>
  );
}
function cxDiscountPrice(amount?: number) {
  if (amount) {
    return (
      <div className="text-danger opacity-7 mr-1" style={{ fontSize: "0.8em" }}>
        <div>
          <span className="badge badge-sm badge-danger">CX할인</span>
        </div>
        {numberFormat(amount)}
      </div>
    );
  }
}
