import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
import EditDisplayProduct from "../pages/product_list/editDisplayProduct";
import AddDisplayProduct from "../pages/product_list/addDisplayProduct";
import { ProductIndex } from "../pages/product_list";

const ProductRoute = () => {
  const match = useRouteMatch();

  return (
    <>
      <Route path={`${match.path}/product-list`} component={ProductIndex} />
      <Route
        path={`${match.path}/edit-display-product/:itemId([0-9]+)`}
        component={EditDisplayProduct}
      />
      <Route
        path={`${match.path}/add-display-product`}
        component={AddDisplayProduct}
      />
    </>
  );
};

export default ProductRoute;
