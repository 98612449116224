import React, { useState } from "react";
import axios from "axios";
import qs from "qs";
import { isAdminDevServer } from "./index";
import { ADMIN_AUTH_COOKIE } from "./constants";

const Store = React.createContext<any>(undefined);
export default Store;

export const AppProvider = ({ children }) => {
  const [state, setState] = useState();
  const StoreValue = { state, setState };

  return <Store.Provider value={StoreValue}>{children}</Store.Provider>;
};

const getCookie = (name) => {
  const value = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return value ? value[2] : null;
};

export function GetWithJwt(url) {
  const wat = getCookie(ADMIN_AUTH_COOKIE);
  const config = { headers: { Authorization: `Bearer ${wat}` } };
  const requestResult = axios.get(url, config);
  return requestResult;
}

/* API 서버에 요청하기 */
export const ApiCall = (passData, credential = true) => {
  const wat = getCookie(ADMIN_AUTH_COOKIE);
  const IoConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${wat}`,
    },
    withCredentials: credential,
  };
  const requestResult = axios.post(
    passData.url,
    qs.stringify(passData.params),
    IoConfig
  );
  return requestResult;
};

export const ApiCallFormData = (url, formData, credential = true) => {
  const wat = getCookie(ADMIN_AUTH_COOKIE);
  const IoConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${wat}`,
    },
    withCredentials: credential,
  };
  return axios.post(`${WcApiDomain}${url}`, formData, IoConfig);
};

/* API 서버에 요청하기 */
export const ApiCallMethod = async (passData, method, credential = true) => {
  const wat = getCookie(ADMIN_AUTH_COOKIE);
  const IoConfig = {
    headers: { Authorization: `Bearer ${wat}` },
    withCredentials: credential,
  };

  let requestResult;
  switch (method) {
    case "post":
      requestResult = await axios.post(
        passData.url,
        qs.stringify(passData.params),
        IoConfig
      );
      break;

    case "get":
      requestResult = await axios.get(passData.url, IoConfig);
      break;

    default:
      break;
  }

  return requestResult;
};

let impKey = "imp62355947";
let tossApiKey = "live_ck_N5OWRapdA8d5BbA24XX3o1zEqZKL";
let CommonServerDomain = "https://www.wiselycompany.com";
let ImageServerDomain =
  "https://wiselystatic.s3.amazonaws.com/THUMBNAIL/prod/assets/images";
let NodeServerDomain = isAdminDevServer()
  ? "https://n-api-stage.wiselycompany.com"
  : "https://n-api.wiselycompany.com";
let WcApiDomain = isAdminDevServer()
  ? "https://wc-api-stage.wiselycompany.com"
  : "https://wc-api.wiselycompany.com";

let LambdaLive = true;
let WcAdminApiDomain = isAdminDevServer()
  ? "https://localhost:3000"
  : "https://localhost:3000";

export const apiMode = localStorage.getItem("apiMode");

// DEV MODE
if (apiMode === "DEV") {
  impKey = "imp02274253";
  tossApiKey = "test_ck_jZ61JOxRQVEg7bKkMKw8W0X9bAqw";
  CommonServerDomain = "https://develop-www.wiselycompany.com";
  ImageServerDomain =
    "https://wiselystatic.s3.amazonaws.com/THUMBNAIL/dev/assets/images";
  NodeServerDomain = "https://n-api-dev.wiselycompany.com";
  // NodeServerDomain = "https://dev.wiselycompany.com:3001";
  WcApiDomain = "https://wc-api-dev.wiselycompany.com";
  // WcApiDomain = "https://dev.wiselycompany.com:3000";
  LambdaLive = false;
  WcAdminApiDomain = "https://localhost:3000";
}

// local host
if (apiMode === "LOCAL") {
  impKey = "imp02274253";
  tossApiKey = "test_ck_jZ61JOxRQVEg7bKkMKw8W0X9bAqw";
  CommonServerDomain = "https://develop-www.wiselycompany.com";
  ImageServerDomain =
    "https://wiselystatic.s3.amazonaws.com/THUMBNAIL/dev/assets/images";
  NodeServerDomain = "https://dev.wiselycompany.com:3001";
  // NodeServerDomain = "https://n-api-dev.wiselycompany.com";
  WcApiDomain = "https://dev.wiselycompany.com:3000";
  // WcApiDomain = "https://wc-api-dev.wiselycompany.com";
  LambdaLive = false;
  WcAdminApiDomain = "https://localhost:3000";
}

export {
  impKey,
  tossApiKey,
  CommonServerDomain,
  ImageServerDomain,
  NodeServerDomain,
  WcApiDomain,
  LambdaLive,
  WcAdminApiDomain,
};

// 브랜드 구분
export const providerList = {
  100000000: "와이즐리",
  110000000: "선물세트",
  120000000: "이지킵",
  130000000: "킨킨",
  140000000: "와이즐리 타임",
  200000000: "오픈워크",
  300000000: "헤드웍스",
  400000000: "해비추얼",
  500000000: "플로버",
  600000000: "펠트",
  700000000: "리즌",
};

// 브랜드 컬러
export const providerColor = {
  100000000: "primary",
  200000000: "openwork",
  300000000: "warning",
  400000000: "habitual",
  500000000: "plover",
  600000000: "felt",
  700000000: "rizn",
};

// UserServiceChangeLog.js - 고객서비스변경내역 - 해지/탈퇴 사유
export const ServiceType = {
  CancelSubscription: "정기구매해지",
  ClosingAccount: "회원탈퇴",
  PauseSubscription: "정기구매중지",
};

// UserServiceChangeLog.js - 고객서비스변경내역 - 해지/탈퇴 사유
export const TypeReason = {
  CancelSubscription: {
    1: "면도날이 너무 자주 와서",
    2: "나도 모르게 결제되는 것이 싫어서",
    3: "군입대, 해외 체류, 이사 등으로 더 이상 이용하기가 어려워서",
    4: "전기면도기, 제모 등으로 면도날이 필요 없어져서",
    5: "와이즐리 클럽 혜택이 많지 않아서",
    6: "1회구매로 구매하는게 좋아보여서",
    7: "수염이 잘 깎이지 않아서",
    8: "자주 베거나, 피부 트러블이 생겨서",
    9: "가격이 불만족스러워서",
    10: "면도기 핸들이 불만족스러워서",
    11: "결제일정을 변경하고 관리하는 것이 불편해서",
    99: "기타",
    A1: "수염이 잘 깎이지 않아서",
    A2: "면도 후 피부 트러블이나 상처가 자주 생겨서",
    A3: "면도날이 아직 남아있어서",
    A4: "쉐이빙젤이나 애프터쉐이브가 아직 남아있어서",
    A5: "자동으로 결제되는 것이 싫어서",
    A6: "결제일과 상품을 변경하고 관리하는 것이 불편해서",
    A7: "군입대, 이사, 제모, 전기면도기 사용 등으로 계속 이용하기 어려워서",
  },
  ClosingAccount: {
    1: "제품이 마음에 들지 않아서",
    2: "새로운 아이디로 가입하고 싶어서",
    3: "개인정보 보안이 걱정되어서",
    4: "와이즐리 브랜드 자체가 마음에 들지 않아서",
    5: "홈페이지가 불만족스러워서",
    6: "고객응대 등 서비스가 불만족스러워서",
    99: "기타",
    A1: "수염이 잘 깎이지 않아서",
    A2: "면도 후 피부 트러블이나 상처가 자주 생겨서",
    A3: "면도날이 아직 남아있어서",
    A4: "쉐이빙젤이나 애프터쉐이브가 아직 남아있어서",
    A5: "자동으로 결제되는 것이 싫어서",
    A6: "결제일과 상품을 변경하고 관리하는 것이 불편해서",
    A7: "군입대, 이사, 제모, 전기면도기 사용 등으로 계속 이용하기 어려워서",
  },
  PauseSubscription: {
    1: "면도날이 너무 자주 와서",
    2: "나도 모르게 결제되는 것이 싫어서",
    3: "군입대, 해외 체류, 이사 등으로 더 이상 이용하기가 어려워서",
    4: "전기면도기, 제모 등으로 면도날이 필요 없어져서",
    5: "와이즐리 클럽 혜택이 많지 않아서",
    6: "1회구매로 구매하는게 좋아보여서",
    99: "기타",
  },
};

// OrderPayment.js - 교환 vs 반품
export const ClaimType = {
  Return: "반품",
  Exchange: "교환",
};

// OrderPayment.js - 교환반품사유
export const ClaimReason = {
  inferiorProducts: "제품불량",
  changeOfMind: "단순변심",
  etc: "기타",
  changeOfAddress: "주소지변경",
  duplicated: "중복출고",
};

// OrderPayment.js - 교환반품 처리상태
export const ClaimStatus = {
  Y: "처리중",
  R: "처리취소함",
  E: "처리완료",
};

// (CX주문 생성) OrdersAdd.tsx, (반품 주문 생성) OrderInfo.tsx
export const ClaimReasonDetail = {
  면도기: ["메탈분리(상단)", "메탈분리(하단)", "버튼 파손 및 작동불량"],
  "리필면도날_PRO,SENS": ["윤활밴드변형", "날변형"],
  "쉐이빙젤_150mL,여행용쉐이빙젤_75mL": [
    "펌프/뚜껑파손(첫개봉)",
    "분사불량(첫개봉)",
    "분사불량(사용중)",
    "제형불량",
  ],
  "리페어애프터쉐이브_60mL(지성),60mL(건성)": ["내용물변질"],
  면도기커버: ["파손"],
  "스타터세트_PRO,SENS": [
    "메탈분리(상단)",
    "메탈분리(하단)",
    "메탈분리(상,하단)",
    "버튼파손 및 작동불량",
    "윤활밴드변형",
    "날변형",
  ],
  "로션100mL_지성,복합성,건성,민감성,여행용로션10mL_지성,복합성,건성,민감성": [
    "냉해",
    "내용물변질(변색,변취,이물질,곰팡이)",
    "공타불량(첫 개봉시)",
    "공타불량(사용중)",
    "분사불량",
    "펌프 및 용기파손(첫 개봉시)",
    "펌프 및 용기파손(사용중)",
    "새어나옴",
  ],
  "부스터80mL_탄력,잡티,모공,여행용부스터10mL_탄력,잡티,모공": [
    "냉해",
    "내용물변질(변색,변취,이물질,곰팡이)",
    "공타불량(첫 개봉시)",
    "공타불량(사용중)",
    "분사불량",
    "펌프 및 용기파손(첫 개봉시)",
    "펌프 및 용기파손(사용중)",
    "새어나옴",
  ],
  "선크림45mL_지/복합성,건/민감성,여행용선크림10mL_지/복합성,건/민감성": [
    "뚜껑파손",
    "내용물변질(변색,변취,이물질,곰팡이)",
  ],
  "클렌징폼180mL_지성/복합성,건성,민감성,여행용클렌징폼15mL_지성/복합성,건성,민감성": [
    "냉해",
    "내용물변질(변색,변취,이물질,곰팡이)",
    "공타불량(첫 개봉시)",
    "공타불량(사용중)",
    "분사불량",
    "펌프 및 용기파손(첫 개봉시)",
    "펌프 및 용기파손(사용중)",
    "새어나옴",
  ],
  기타: [
    "외부압력으로 인한 훼손",
    "고객주관",
    "피부자극",
    "상처",
    "배송중파손",
  ],
  "샴푸_탈모고민용(지성/중성/건성),비듬고민용(지성/건성),두피건강개선용(지성/중성/건성)": [
    "내용물변질(변색,변취,이물질,곰팡이)",
    "공타불량(첫 개봉시)",
    "공타불량(사용중)",
    "분사불량",
    "펌프 및 용기파손(첫 개봉시)",
    "펌프 및 용기파손(사용중)",
    "펌프불량(개폐관련)",
    "새어나옴",
  ],
  "부스터_탈모고민용(프로그레시브,리액셔널),비듬고민용(지성,건성)": [
    "내용물변질(변색,변취,이물질,곰팡이)",
    "공타불량(첫 개봉시)",
    "공타불량(사용중)",
    "분사불량",
    "펌프 및 용기파손(첫 개봉시)",
    "펌프 및 용기파손(사용중)",
    "펌프불량(개폐관련)",
    "새어나옴",
  ],
  "여행용샴푸60mL_탈모고민용(지성/중성/건성),비듬고민용(지성/건성),두피건강개선용(지성/중성/건성)": [
    "뚜껑파손",
    "내용물변질(변색,변취,이물질,곰팡이)",
  ],
  해비추얼: [
    "배송파손(용기파손 및 내용물파손)",
    "뚜껑 개폐불량",
    "용기 패키징불량(씰,라벨)",
  ],
  "잇몸케어칫솔-작은헤드": [
    "칫솔모 변형/손상",
    "칫솔모 뽑힘",
    "칫솔 오염",
    "칫솔네크/핸들 파손",
    "칫솔 사출불량",
  ],
  "잇몸케어칫솔-큰헤드": [
    "칫솔모 변형/손상",
    "칫솔모 뽑힘",
    "칫솔 오염",
    "칫솔네크/핸들 파손",
    "칫솔 사출불량",
  ],
  "치석케어칫솔-작은헤드": [
    "칫솔모 변형/손상",
    "칫솔모 뽑힘",
    "칫솔 오염",
    "칫솔네크/핸들 파손",
    "칫솔 사출불량",
  ],
  "치석케어칫솔-큰헤드": [
    "칫솔모 변형/손상",
    "칫솔모 뽑힘",
    "칫솔 오염",
    "칫솔네크/핸들 파손",
    "칫솔 사출불량",
  ],
  "치약(페퍼민트향)120g": [
    "내용물 변질",
    "새어나옴",
    "튜브 뚜껑 개폐불량",
    "튜브 뚜껑 파손",
    "튜브 파손(찢어짐/터짐/찌그러짐)",
  ],
  칫솔거치대: [
    "거치대 파손",
    "스티커 미부착",
    "스티커 손상(껍데기 떨어짐)",
    "스티커 탈/부착 불량",
  ],
};
export const PRODUCTS = {
  와이즐리: [
    "면도기",
    "리필면도날_PRO,SENS",
    "쉐이빙젤_150mL,여행용쉐이빙젤_75mL",
    "리페어애프터쉐이브_60mL(지성),60mL(건성)",
    "면도기커버",
    "스타터세트_PRO,SENS",
    "기타",
  ],
  오픈워크: [
    "로션100mL_지성,복합성,건성,민감성,여행용로션10mL_지성,복합성,건성,민감성",
    "부스터80mL_탄력,잡티,모공,여행용부스터10mL_탄력,잡티,모공",
    "선크림45mL_지/복합성,건/민감성,여행용선크림10mL_지/복합성,건/민감성",
    "클렌징폼180mL_지성/복합성,건성,민감성,여행용클렌징폼15mL_지성/복합성,건성,민감성",
    "기타",
  ],
  헤드웍스: [
    "샴푸_탈모고민용(지성/중성/건성),비듬고민용(지성/건성),두피건강개선용(지성/중성/건성)",
    "부스터_탈모고민용(프로그레시브,리액셔널),비듬고민용(지성,건성)",
    "여행용샴푸60mL_탈모고민용(지성/중성/건성),비듬고민용(지성/건성),두피건강개선용(지성/중성/건성)",
    "기타",
  ],
  해비추얼: [
    "에너지포커스 멀티비타민 앤 미네랄(60정)",
    "혈행,눈건강,기억력 오메가3(120정)",
    "눈건강 아스타잔틴(60정)",
    "뼈,신경,근육엔 칼슘마그네슘(60정)",
    "항산화,면역 프로폴리스(60정)",
    "기타",
  ],
  플로버: [
    "잇몸케어칫솔-작은헤드",
    "잇몸케어칫솔-큰헤드",
    "치석케어칫솔-작은헤드",
    "치석케어칫솔-큰헤드",
    "치약(페퍼민트향)120g",
    "칫솔거치대",
  ],
};
export const CLAIM_REASON_GROUP = {
  면도기: "면도기",
  "리필면도날_PRO,SENS": "리필면도날_PRO,SENS",
  "쉐이빙젤_150mL,여행용쉐이빙젤_75mL": "쉐이빙젤_150mL,여행용쉐이빙젤_75mL",
  "리페어애프터쉐이브_60mL(지성),60mL(건성)":
    "리페어애프터쉐이브_60mL(지성),60mL(건성)",
  면도기커버: "면도기커버",
  "스타터세트_PRO,SENS": "스타터세트_PRO,SENS",
  "로션100mL_지성,복합성,건성,민감성,여행용로션10mL_지성,복합성,건성,민감성":
    "로션100mL_지성,복합성,건성,민감성,여행용로션10mL_지성,복합성,건성,민감성",
  "부스터80mL_탄력,잡티,모공,여행용부스터10mL_탄력,잡티,모공":
    "부스터80mL_탄력,잡티,모공,여행용부스터10mL_탄력,잡티,모공",
  "선크림45mL_지/복합성,건/민감성,여행용선크림10mL_지/복합성,건/민감성":
    "선크림45mL_지/복합성,건/민감성,여행용선크림10mL_지/복합성,건/민감성",
  "클렌징폼180mL_지성/복합성,건성,민감성,여행용클렌징폼15mL_지성/복합성,건성,민감성":
    "클렌징폼180mL_지성/복합성,건성,민감성,여행용클렌징폼15mL_지성/복합성,건성,민감성",
  기타: "기타",
  "샴푸_탈모고민용(지성/중성/건성),비듬고민용(지성/건성),두피건강개선용(지성/중성/건성)":
    "샴푸_탈모고민용(지성/중성/건성),비듬고민용(지성/건성),두피건강개선용(지성/중성/건성)",
  "부스터_탈모고민용(프로그레시브,리액셔널),비듬고민용(지성,건성)":
    "부스터_탈모고민용(프로그레시브,리액셔널),비듬고민용(지성,건성)",
  "여행용샴푸60mL_탈모고민용(지성/중성/건성),비듬고민용(지성/건성),두피건강개선용(지성/중성/건성)":
    "여행용샴푸60mL_탈모고민용(지성/중성/건성),비듬고민용(지성/건성),두피건강개선용(지성/중성/건성)",
  "에너지포커스 멀티비타민 앤 미네랄(60정)": "해비추얼",
  "혈행,눈건강,기억력 오메가3(120정)": "해비추얼",
  "눈건강 아스타잔틴(60정)": "해비추얼",
  "뼈,신경,근육엔 칼슘마그네슘(60정)": "해비추얼",
  "항산화,면역 프로폴리스(60정)": "해비추얼",
  "잇몸케어칫솔-작은헤드": "잇몸케어칫솔-작은헤드",
  "잇몸케어칫솔-큰헤드": "잇몸케어칫솔-큰헤드",
  "치석케어칫솔-작은헤드": "치석케어칫솔-작은헤드",
  "치석케어칫솔-큰헤드": "치석케어칫솔-큰헤드",
  "치약(페퍼민트향)120g": "치약(페퍼민트향)120g",
  칫솔거치대: "칫솔거치대",
};
// orderInfo.js - 교환반품 처리 상세사유
export const ClaimStatusDetail = [
  "포장불량",
  "단순변심",
  "주소 오입력",
  "오배송",
  "기타",
];

// OrderPayment.js - 가상계좌 은행 코드
export const BankCode = {
  "004": "KB국민은행",
  "023": "SC제일은행",
  "039": "경남은행",
  "034": "광주은행",
  "003": "기업은행",
  "011": "농협",
  "031": "대구은행",
  "032": "부산은행",
  "002": "산업은행",
  "045": "새마을금고",
  "007": "수협",
  "088": "신한은행",
  "048": "신협",
  "005": "외환은행",
  "020": "우리은행",
  "071": "우체국",
  "037": "전북은행",
  "090": "카카오뱅크",
  "000894": "케이뱅크",
  "081": "하나은행(서울은행)",
  "027": "한국씨티은행(한미은행)",
};

// UserInfo.js - 유입경로
export const ReferralType = {
  1: "오프라인 광고 (엘리베이터 등)",
  2: "온라인 배너 광고",
  3: "소셜미디어 후기 (블로그, 인스타그램 등)",
  4: "주변 지인 추천",
  99: "기타",
};

// 정기구매 해지사유
export const CancelReason = [
  {
    code: "Q1",
    text: "자동으로 결제되는 것이 싫어서",
  },
  {
    code: "Q2",
    text: "결제일과 상품을 변경하는 것이 불편해서",
  },
  {
    code: "Q3",
    text: "제품이 만족스럽지 않아서",
    optionList: [
      {
        providerID: 100000000,
        text: "와이즐리는 어떤점이 만족스럽지 않았나요?",
        options: [
          {
            code: "Q3WO1",
            text: "수염이 매끄럽게 잘 깎이지 않아서",
          },
          {
            code: "Q3WO2",
            text: "면도 후 상처나 트러블이 자주생겨서",
          },
          {
            code: "Q3WO9",
            text: "기타",
          },
        ],
      },
      {
        providerID: 200000000,
        text: "오픈워크는 어떤점이 만족스럽지 않았나요?",
        options: [
          {
            code: "Q3OO1",
            text: "피부 트러블이 났어요",
          },
          {
            code: "Q3OO2",
            text: "효능(보습, 탄력 등)이 별로에요",
          },
          {
            code: "Q3OO3",
            text: "사용감(향, 끈적임 등)이 안맞아요",
          },
          {
            code: "Q3OO9",
            text: "기타",
          },
        ],
      },
      {
        providerID: 300000000,
        text: "헤드웍스는 어떤점이 만족스럽지 않았나요?",
        options: [
          {
            code: "Q3HO1",
            text: "제품을 사용하고 두피에 문제가 생겼어요",
          },
          {
            code: "Q3HO2",
            text: "효능(탈모/비듬 완화 등)이 만족스럽지 않아요",
          },
          {
            code: "Q3HO3",
            text: "사용감 (향, 점도, 거품의 양 등)이 안맞아요",
          },
          {
            code: "Q3HO9",
            text: "기타",
          },
        ],
      },
      {
        providerID: 400000000,
        text: "해비추얼은 어떤점이 만족스럽지 않았나요?",
        options: [
          {
            code: "Q3BO1",
            text: "생각보다 크게 효능을 느낄 수 없었어요",
          },
          {
            code: "Q3BO2",
            text: "다른 제품을 구매하게 되었어요",
          },
          {
            code: "Q3BO3",
            text: "다른 제품을 선물받게 되었어요",
          },
          {
            code: "Q3BO4",
            text: "몸에 잘 맞지 않거나 부작용이 있는 것 같아요",
          },
          {
            code: "Q3BO5",
            text: "알약 목넘김, 냄새, 색깔 등이 마음에 들지 않아요",
          },
          {
            code: "Q3BO9",
            text: "기타",
          },
        ],
      },
      {
        providerID: 500000000,
        text: "플로버는 어떤 점이 만족스럽지 않았나요?",
        options: [
          {
            code: "Q3PO1",
            text: "잇몸/치아에 문제가 생겼어요",
          },
          {
            code: "Q3PO2",
            text: "칫솔의 품질이 기대에 미치지 못했어요",
          },
          {
            code: "Q3PO3",
            text: "치약 효능이 마음에 들지 않아요",
          },
          {
            code: "Q3PO4",
            text: "치약 맛이 별로에요",
          },
          {
            code: "Q3PO5",
            text: "다른 제품을 선물받거나 구매했어요",
          },
          {
            code: "Q3PO9",
            text: "기타",
          },
        ],
      },
    ],
  },
  {
    code: "Q4",
    text: "제품이 많이 남아서",
    showSubscription: true,
    showSubscriptionText: "어떤 제품이 남았나요?",
  },
  {
    code: "Q5",
    text: "군입대, 해외이사 등으로 구독하기 어려워서",
  },
  {
    code: "99",
    text: "기타",
  },
];

// Orders.js - 반품주문 회수 여부
export const IsReturnWill_TYPE = {
  X: "회수안함",
  Y: "회수함",
  C: "회수 및 검수",
};
