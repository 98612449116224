import React from "react";

const Loading = () => (
  <div className="progress">
    <div
      className="progress-bar progress-bar-animated progress-bar-striped bg-success"
      aria-valuenow={100}
      aria-valuemin={0}
      aria-valuemax={100}
      style={{ width: "100%" }}
    >
      Loading....
    </div>
  </div>
);

export default Loading;
