import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Button, Table, Image } from "antd";
import TitleContent from "../../components/TitleContent";
import { WcAdminApiDomain } from "../../Store";
import { ListHeader } from "../../components/ListHeader";
import "../../App.css";

interface ItemData {
  itemBrandName: string;
  itemId: number;
  itemName: string;
  itemImage: string;
  itemRegularPrice: number;
  itemDiscountPrice: number;
  key: any;
}

export const ProductList = () => {
  const history = useHistory();

  const [itemList, setItemList] = useState<ItemData[]>([]);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    axios
      .get(`${WcAdminApiDomain}/products`, {
        params: {
          page: currentPage,
          limit: 10,
        },
      })
      .then(({ data }) => {
        console.log(data);

        setItemList(
          data.items.map((item) => ({
            itemBrandName: item.Provider?.BrandName || "",
            itemId: item.ItemID,
            itemName: item.ItemName,
            itemImage: item.ImageUrl,
            itemRegularPrice: item.RegularPrice,
            itemDiscountPrice: item.DiscountPrice,
            key: item.ItemID,
          }))
        );
        setTotalItemCount(data.meta.totalItems);
      });
  }, [currentPage]);

  const columns = [
    {
      title: "브랜드 이름",
      dataIndex: "itemBrandName",
      key: "itemBrandName",
    },
    {
      title: "아이템 ID",
      dataIndex: "itemId",
      key: "itemId",
      sorter: (a, b) => a.itemId - b.itemId,
    },
    {
      title: "아이템 이름",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "이미지",
      key: "itemImage",
      dataIndex: "itemImage",
      render: (value) => <Image width={40} src={value} />,
    },
    {
      title: "아이템 일반 가격",
      dataIndex: "itemRegularPrice",
      key: "itemRegularPrice",
      render: (value) => `${value.toLocaleString()}원`,
    },
    {
      title: "아이템 할인 가격",
      dataIndex: "itemDiscountPrice",
      key: "itemDiscountPrice",
      render: (value) => `${value.toLocaleString()}원`,
    },
    {
      title: "수정",
      key: "action",
      render: (text, record) => (
        <Button
          type="primary"
          onClick={() =>
            history.push(`/product/edit-display-product/${record.itemId}`)
          }
        >
          수정
        </Button>
      ),
    },
  ];

  const changePage = (config, recode) => {
    setCurrentPage(config.current);
  };

  return (
    <>
      <TitleContent title="상품 리스트" subHeading="상품 리스트" />
      <ListHeader
        title="전시상품 목록"
        button={
          <Button
            type="primary"
            onClick={() => history.push("/product/add-display-product")}
          >
            전시상품 추가
          </Button>
        }
      />
      <Table
        dataSource={itemList}
        onChange={(config, recode) => changePage(config, recode)}
        columns={columns}
        pagination={{ position: ["bottomCenter"], total: totalItemCount }}
      />
    </>
  );
};
