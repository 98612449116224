import React from "react";

import { showUpValue } from "./UtilFunc";

const InputFormat = ({ formatType, value, onChange, ...props }) => {
  if (!formatType) throw new Error("formatType을 입력하세요.");

  const onChangeHandler = (e) => {
    const text = e.target.value.replace(/[-/]/g, "");

    if (Number.isNaN(text.slice(-1))) return;

    if (formatType === "phone") {
      if (text.length > 11) return;
    } else if (formatType === "birth") {
      if (text.length > 6) return;
    }

    onChange(e);
  };

  return (
    <input
      value={showUpValue(value, formatType)}
      onChange={onChangeHandler}
      {...props}
    />
  );
};

export default InputFormat;
