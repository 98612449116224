import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import moment from "moment";
import * as utils from "../../utils/Utils";
import { getObjectFromArray, numberFormat } from "../../utils/UtilFunc";

import TitleContent from "../../components/TitleContent";
import { SELECT_DELIVERY_STATUS } from "./constants";

const DeliveryDetail = () => {
  const match = useRouteMatch();

  const orderStatus = {
    Completed: "결제완료",
    "On-Hold": "보류",
    Cancelled: "결제취소",
    Processing: "결제진행중",
    "Awaiting-vbank": "가상계좌입금대기중",
    "Cancelled-vbank": "가상계좌결제취소",
    Failed: "결제실패",
    Refunded: "환불됨",
    "Pending-cancel": "보류취소",
    "Partially-Refunded": "부분환불",
    "Requested-Refund": "환불요청",
    Fraud: "부정결제",
    Initialized: "주문생성",
    "Awaiting-Subscription": "정기결제대기중",
    "Initialized-EasyPayment": "간편결제주문초기화",
    "Requested-Return": "반품신청",
    "Requested-Exchange": "교환신청",
    "Return-Processing": "반품진행중",
    "Exchange-Processing": "교환진행중",
    Returned: "반품완료",
    Exchanged: "교환완료",
    "Out-for-Delivery": "배송중",
    Delivered: "배달완료",
    Paid: "결제완료",
    "Order-Processing": "배송접수",
    Pending: "대기중",
  };

  const deliveryStatus = useMemo(
    () => getObjectFromArray(SELECT_DELIVERY_STATUS.options),
    []
  );

  /** Parameter에서 고객번호 받아오기 */
  const did = useMemo(() => match.params.did, [match]);

  const [getInfo, setInfo] = useState<any>(null);
  const getDeliveryInfo = useCallback(async () => {
    const getResult = await utils.getDeliveryInfo(did);
    if (getResult) {
      setInfo(getResult);
    }
  }, [did]);

  // 발주 철회하기
  const cancelDeliveryAfterExport = async () => {
    const getResult = await utils.cancelDeliveryAfterExport(did);
    if (getResult) {
      getDeliveryInfo();
    }
  };

  // 주문의 상태 변경하기
  const changeOrderStatus = async (param) => {
    const getResult = await utils.changeOrderStatus({
      ...param,
      orderstatus: "Completed",
      // note: "ROLLBACK_FROM_DELIVERY_TO_COMPLETED"
    });
    if (getResult) {
      window.location.reload();
    }
  };

  useEffect(() => {
    getDeliveryInfo();
  }, [getDeliveryInfo]);

  return (
    <>
      <TitleContent title="발주 상세 정보" />

      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card mb-1">
            <div className="card-header text-primary">
              주문 정보
              <div className="widget-content-right pl-3">
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    if (window.confirm("정말 변경하시겠습니까?")) {
                      cancelDeliveryAfterExport();
                    }
                  }}
                >
                  발주철회하기
                </button>
              </div>
            </div>
            <div className="card-body" style={{ position: "relative" }}>
              {getInfo && (
                <div className="table-responsive">
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: "100px" }}>등록일</th>
                        <th>OrderID / OrderCID</th>
                        <th>주문상태</th>
                        <th>주문명</th>
                        <th style={{ width: "70px" }} className="text-center">
                          정기여부
                        </th>
                        <th style={{ width: "70px" }} className="text-center">
                          상품수
                        </th>
                        <th style={{ width: "70px" }} className="text-center">
                          결제상태
                        </th>
                        <th style={{ width: "70px" }} className="text-center">
                          배송비
                        </th>
                        <th style={{ width: "70px" }} className="text-center">
                          추가배송
                        </th>
                        <th style={{ width: "70px" }} className="text-center">
                          상품합계
                        </th>
                        <th style={{ width: "70px" }} className="text-center">
                          할인합계
                        </th>
                        <th style={{ width: "70px" }} className="text-center">
                          총합계
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(getInfo as any).DeliveryOrderList.map((item, idx) => (
                        <tr key={idx}>
                          <td>
                            {item.RegDate &&
                              moment(item.RegDate).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                          </td>
                          <td>
                            <Link to={`/orders/detail/${item.OrderID}`}>
                              {item.OrderID}
                            </Link>
                            <div className="opacity-6">{item.OrderCID}</div>
                          </td>
                          <td>
                            {orderStatus[item.OrderStatus]}
                            {item.OrderStatus === "Order-Processing" && (
                              <div>
                                <button
                                  className="btn btn-sm btn-danger p-0 pl-2 pr-2"
                                  onClick={() => {
                                    if (
                                      window.confirm("정말 변경하시겠습니까?")
                                    ) {
                                      changeOrderStatus({
                                        unid: item.UNID,
                                        orderid: item.OrderID,
                                      });
                                    }
                                  }}
                                >
                                  결제완료로 되돌리기
                                </button>
                              </div>
                            )}
                          </td>
                          <td>{item.OrderName}</td>
                          <td className="text-center">{item.IsSubscribed}</td>
                          <td className="text-center">{item.ItemCount}</td>
                          <td className="text-center">{item.PaymentStatus}</td>
                          <td className="text-center">
                            {numberFormat(item.ShippingFee)}
                          </td>
                          <td className="text-center">
                            {numberFormat(item.AdditionalShippingFee)}
                          </td>
                          <td className="text-center">
                            {numberFormat(item.ItemPriceAmount)}
                          </td>
                          <td className="text-center">
                            {numberFormat(item.DiscountAmount)}
                          </td>
                          <td className="text-center">
                            {numberFormat(item.TotalAmount)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>

          <div className="card mb-1">
            <div className="card-header text-primary">상품 정보</div>
            <div className="card-body" style={{ position: "relative" }}>
              {getInfo && (
                <div className="table-responsive">
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: "100px" }}>등록일</th>
                        <th>DCID</th>
                        <th>배송상태</th>
                        <th style={{ width: "70px" }} className="text-center">
                          OrderID
                        </th>
                        <th style={{ width: "70px" }} className="text-center">
                          OrderItemID
                        </th>
                        <th>Item</th>
                        <th>Option</th>
                        <th style={{ width: "70px" }} className="text-center">
                          수량
                        </th>
                        <th style={{ width: "70px" }} className="text-center">
                          정기여부
                        </th>
                        <th style={{ width: "70px" }} className="text-center">
                          정상가
                        </th>
                        <th style={{ width: "70px" }} className="text-center">
                          할인가
                        </th>
                        <th style={{ width: "70px" }} className="text-center">
                          배송여부
                        </th>
                        <th style={{ width: "70px" }} className="text-center">
                          배송타입
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {getInfo.DeliveryItemList.map((item, idx) => (
                        <tr key={idx}>
                          <td>
                            {item.RegDate &&
                              moment(item.RegDate).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                          </td>
                          <td>{item.DCID}</td>
                          <td>{deliveryStatus[item.DeliveryStatus]}</td>
                          <td className="text-center">{item.OrderID}</td>
                          <td className="text-center">{item.OrderItemID}</td>
                          <td>
                            {item.ItemName}
                            <div className="opacity-4">{item.ItemID}</div>
                          </td>
                          <td>
                            {item.OptionItemName}
                            <div className="opacity-4">{item.OptionID}</div>
                            {/*
														{(item.OptionID === 101040310 || item.OptionID === 101040300 || item.OptionID === 101030300) && <span className="badge badge-danger">재고없음</span>}
														*/}
                          </td>

                          <td className="text-center">{item.Quantity}</td>
                          <td className="text-center">
                            {item.SubscriptionType}
                          </td>
                          <td className="text-center">
                            {numberFormat(item.RegularPrice)}
                          </td>
                          <td className="text-center">
                            {numberFormat(item.Price)}
                          </td>
                          <td className="text-center">{item.Status}</td>
                          <td className="text-center">{item.DeliveryType}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>

          <div className="card mb-1">
            <div className="card-header text-primary">배송지 정보</div>
            <div className="card-body" style={{ position: "relative" }}>
              {getInfo && (
                <div className="table-responsive">
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: "100px" }}>등록일</th>
                        <th style={{ width: "70px" }}>DCID</th>
                        <th>배송상태</th>
                        <th>보내는이</th>
                        <th>받는이</th>
                        <th>배송메모</th>
                        <th style={{ width: "70px" }} className="text-center">
                          배송타입
                        </th>
                        <th style={{ width: "100px" }} className="text-center">
                          대표주문번호
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {getInfo &&
                        getInfo.DeliveryAddress.map((item, idx) => (
                          <tr key={idx}>
                            <td>
                              {item.RegDate &&
                                moment(item.RegDate).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )}
                            </td>
                            <td>{item.DCID}</td>
                            <td>{deliveryStatus[item.DeliveryStatus]}</td>

                            <td>
                              <Link to={`/users/detail/${item.UNID}`}>
                                {item.SenderFullName}
                              </Link>
                              <div className="opacity-6">
                                {item.SenderPhone}
                              </div>
                              <div className="opacity-6">
                                {item.SenderEmail}
                              </div>
                            </td>
                            <td>
                              {item.RecipientFullName}
                              <div className="opacity-6">
                                {item.RecipientPhone}
                              </div>
                              <div className="opacity-6">
                                [{item.RecipientPostcode}{" "}
                                {item.RecipientAddress1}{" "}
                                {item.RecipientAddress2}
                              </div>
                            </td>
                            <td>{item.Memo}</td>
                            <td className="text-center">{item.DeliveryType}</td>
                            <td className="text-center">
                              {item.PrimaryOrderID}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryDetail;
