export const SELECT_STATUS = {
  name: "status",
  classNames: ["form-control", "form-control-sm"],
  options: [
    { "": "상태" },
    { I: "초기화" },
    { Y: "활성" },
    { N: "비활성" },
    { X: "해지" },
  ],
};

export const SELECT_PAYMENT_ID = {
  name: "paymentId",
  classNames: ["form-control", "form-control-sm"],
  options: [
    { "": "결제수단" },
    { 7: "[정기]카카오페이" },
    { 8: "[정기-나이스]신용/체크카드" },
    { 9: "[정기-아임포트]신용/체크카드" },
    { 10: "[정기]네이버페이" },
  ],
};

export const CALENDAR_SCHEDULE_AT = {
  name: "scheduleAt",
  placeholder: "날짜",
};

export const INPUT_KEYWORD = {
  name: "keyword",
  classNames: ["form-control", "form-control-sm"],
  placeholder: "전화, 메일, 이름, 회원번호",
};
