import React, { useState, useMemo, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { IoIosRefresh, IoMdAdd } from "react-icons/io";

import { formatters } from "../components/const";
import { DetailContext, DetailCtx } from "../DetailContext";

export interface DetailViewCardParam {
  card: {
    type: CardType;
    bodyMaxHeight: string;
  };
  headerInfo: {
    title: string;
    buttons: ButtonName[];
  };
  getData: (ctx: DetailCtx) => Promise<any>;
  dataName: string;
  ctxName?: string;
  cardInfo: CardContent[];
}

type CardType = "list" | "information" | "memo";
// TODO: add 는 이런 구조에서 구현하기 어려울 수 있으므로 card param 구조를 많이 변경해야할 수 있음
type ButtonName = "refresh" | "add";

interface CardContent {
  name: string;
  width?: number;
  center?: boolean;
  cellCommonStyle?: any;
  cell?: CellInterface;
  cells?: CellInterface[];
}

interface CellInterface {
  field: string | Function;
  style?: any;
  className?: string | Function;
  fmt?: Function | keyof typeof formatters;
  link?: any;
}

export function DetailViewCard(t: DetailViewCardParam) {
  const [data, setData] = useState([]);

  const detailContext = useContext(DetailContext);

  const {
    card: { type, bodyMaxHeight },
    headerInfo: { title, buttons },
    getData,
    dataName,
    cardInfo,
  } = t;

  const cardClassName = {
    list: "table table-striped table-sm",
  };
  const buttonIcons = {
    refresh: {
      component: <IoIosRefresh />,
      className: "btn btn-link p-0",
    },
    add: {
      component: <IoMdAdd />,
      className: "btn btn-link",
    },
  };

  const saveData = async (detailContext) => {
    const data = await getData(detailContext);

    setData(data[dataName]);
  };

  useEffect(() => {
    if (detailContext?.data.userData?.UserInfo?.UNID) {
      saveData(detailContext);
    }
  }, [detailContext]);

  const cardHeader = useMemo(
    () => (
      <div className="card-header text-primary">
        {title}
        <div className="btn-actions-pane-right">
          {buttons.map((buttonType, _) => {
            const { component, className } = buttonIcons[buttonType];

            return (
              <button
                key={className}
                className={className}
                onClick={() => saveData(detailContext)}
              >
                <IconContext.Provider
                  value={{
                    size: "2em",
                    style: { verticalAlign: "top", padding: "3px" },
                  }}
                >
                  {component}
                </IconContext.Provider>
              </button>
            );
          })}
        </div>
      </div>
    ),
    [detailContext]
  );

  // list component
  const cardListHeader = useMemo(
    () =>
      data.length > 0 && (
        <tr>
          {cardInfo.map((content, i) => {
            const style = {
              ...(content.width ? { width: `${content.width}px` } : {}),
              ...(content.center ? { className: "text-center" } : {}),
            };

            return (
              <th key={i} style={style}>
                {content.name}
              </th>
            );
          })}
        </tr>
      ),
    [cardInfo, data]
  );
  const cardList = useMemo(
    () =>
      data.length > 0 &&
      data.map((item, i) => (
        <tr key={i}>
          {cardInfo.map((content, index) => {
            const cellInfos = content.cell ? [content.cell] : content.cells;
            const contents = cellInfos?.map((cellInfo, i) => {
              const { cellCommonStyle } = content;

              return (
                <div key={i} style={cellCommonStyle}>
                  {Cell(cellInfo, item, detailContext)}
                </div>
              );
            });

            return <td key={index}>{contents}</td>;
          })}
        </tr>
      )),
    [data]
  );

  return (
    <div className="card mb-1">
      {cardHeader}

      {data.length > 0 && (
        <div
          className="card-body"
          style={{ overflow: "auto", maxHeight: `${bodyMaxHeight}` }}
        >
          <div className="table-responsive">
            <table className={cardClassName[type]}>
              <tbody>
                {cardListHeader}

                {cardList}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

function Cell(cell: CellInterface, item, ctx) {
  const { field, link, style, className, fmt } = cell;

  const cellItem = typeof field === "string" ? item[field] : field(item, ctx);
  const classProperty = className
    ? typeof className === "string"
      ? className
      : className(item)
    : "";
  const makeFormat =
    typeof fmt === "string" ? formatters[fmt] : fmt ?? ((v) => v);

  if (link) {
    return <Link to={link(item)}>{cellItem}</Link>;
  }

  return (
    <span className={classProperty} style={style}>
      {makeFormat(cellItem)}
    </span>
  );
}
