import React from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import { toast } from "react-toastify";
import { AppProvider } from "./Store";

import HeadContents from "./components/HeadContents";
import SideContents from "./components/SideContents";

/* Routes */
import Home from "./pages/home/Home";
import Cherrypicker from "./pages/util/Cherrypicker";
import Coupon from "./pages/util/Coupon";
import AlimtalkTemplate from "./pages/util/AlimtalkTemplate";
import AlimtalkEvent from "./pages/util/AlimtalkEvent";

import OrdersRoute from "./routes/OrderRoute";
import UserRoute from "./routes/UserRoute";
import ReviewRoute from "./routes/ReviewRoute";
import AdminRoute from "./routes/AdminRoute";
import DeliveryRoute from "./routes/DeliveryRoute";
import ProductRoute from "./routes/productRoute";

/** toast */
import "react-toastify/dist/ReactToastify.css";

toast.configure({ newestOnTop: true });

function App() {
  return (
    <BrowserRouter basename="/">
      <CookiesProvider>
        <AppProvider>
          <Switch>
            <CommonWrapper>
              <Route path="/" component={Home} exact />
              <Route path="/index.html" component={Home} exact />

              <Route path="/orders" component={OrdersRoute} />
              <Route path="/users" component={UserRoute} />
              <Route path="/reviews" component={ReviewRoute} />
              <Route path="/admin" component={AdminRoute} />
              <Route path="/delivery" component={DeliveryRoute} />
              <Route path="/cherrypicker" component={Cherrypicker} />
              <Route path="/coupon" component={Coupon} />
              <Route path="/product" component={ProductRoute} />

              <Route path="/alimtalk-template" component={AlimtalkTemplate} />
              <Route path="/alimtalk-event" component={AlimtalkEvent} />
            </CommonWrapper>
          </Switch>
        </AppProvider>
      </CookiesProvider>
    </BrowserRouter>
  );
}

const CommonWrapper = ({ children }) => (
  <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
    <HeadContents />
    <div className="app-main">
      <SideContents />
      <div className="app-main__outer">
        <div className="app-main__inner">{children}</div>
      </div>
    </div>
  </div>
);

export default App;
