import React, { useEffect, useState } from "react";
import XLSX from "xlsx";
import FileSaver from "file-saver";
import moment from "moment";
import * as utils from "../../utils/Utils";

interface SQLParameter {
  type: string;
  key: string;
  value: any;
}

const QueryDetail = ({ query }: { query: any }) => {
  const [executeQueryParam, setExecuteQueryParam] = useState<SQLParameter[]>(
    []
  );
  const [executeQueryResult, setExecuteQueryResult] = useState([] as any);
  const [resultCount, setResultCount] = useState(0);
  const [showQuery, setShowQuery] = useState(false);
  const [columns, setColumns] = useState([] as any);

  useEffect(() => {
    setExecuteQueryParam([]);
    setExecuteQueryResult([]);
  }, [query]);

  const handleFormChange = (e, param) => {
    setExecuteQueryParam(makeExecuteQueryParam(e, param));
  };

  const makeExecuteQueryParam = (e, param) => {
    const newParam = {
      type: param.type,
      key: param.key,
      value: e.target.value,
    };
    const index = executeQueryParam.findIndex((it) => it.key === param.key);
    if (index < 0) {
      return executeQueryParam.concat(newParam);
    }
    const arrayCopy = [...executeQueryParam];
    arrayCopy.splice(index, 1);
    return [
      ...arrayCopy,
      {
        type: param.type,
        key: param.key,
        value: e.target.value,
      },
    ];
  };

  const executeQuery = async () => {
    setColumns([] as any);
    const result = await utils.ExecuteQuery({
      queryName: query.queryName,
      parameter: executeQueryParam,
    });

    if (result.success && result.data.queryResult.rowsAffected > 0) {
      setColumns(Object.keys(result.data.queryResult.recordset[0]));
      setExecuteQueryResult(result.data.queryResult.recordset);
      setResultCount(result.data.queryResult.rowsAffected);
    } else {
      setColumns([]);
      setExecuteQueryResult([]);
      setResultCount(0);
    }
  };

  const downloadExcel = (exportData: any[]) => {
    if (exportData.length < 1) {
      window.alert("처리할 데이터가 없습니다.");
      return;
    }
    const sheets = ["WISELY"];

    // 배송타입 순서로 sheet를 만들기 위해 정렬 후 sheet별 XLSX 데이터 생성
    const resultData: any = {};
    resultData.WISELY = XLSX.utils.json_to_sheet(exportData);

    // XLSX 만들기
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const wb = { Sheets: resultData, SheetNames: sheets };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      `wisely_query_exported_${moment().format(
        "YYYYMMDD_HHmmss"
      )}${fileExtension}`
    );
  };

  return (
    <div className="card mb-1">
      <div className="card-header">{query?.title ?? "쿼리실행"}</div>
      <div className="card-body">
        <div
          onClick={() => setShowQuery(!showQuery)}
          className="pb-2 mb-2 card-link"
          style={{ cursor: "pointer" }}
        >
          ▶쿼리보기
        </div>
        {showQuery ? (
          <div className="mb-3">
            <pre>{query.queryTemplate}</pre>
          </div>
        ) : (
          <></>
        )}
        <div className="input-group">
          <div className="input-group-prepend">
            {query?.parameter.length > 0 ? (
              <div className="table-responsive">
                {query?.parameter.map((p) => (
                  <div className="input-group mb-1" key={p.key}>
                    <div className="pt-2 pb-2" style={{ minWidth: "100px" }}>
                      {p.key}
                    </div>
                    {p.type === "radio" &&
                      p.options.map((option, idx) => (
                        <>
                          <input
                            key={`radio-option-${idx}`}
                            type="radio"
                            onChange={(e) => handleFormChange(e, p)}
                            name={p.key}
                            value={option.value}
                          />
                          {option.name}
                        </>
                      ))}
                    {p.type === "string" && (
                      <input
                        className="form-control"
                        style={{ minWidth: "250px" }}
                        onKeyUp={(e) => handleFormChange(e, p)}
                        type={p.type === "number" ? "number" : "text"}
                        placeholder={`${p.placeholder}`}
                      />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => executeQuery()}
          >
            실행
          </button>

          {executeQueryResult.length > 0 && (
            <button
              onClick={() => downloadExcel(executeQueryResult)}
              className="btn btn-sm btn-danger ml-1"
            >
              다운로드
            </button>
          )}
        </div>
        <div className="border-bottom mt-2 mb-2" />
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-3">
              {executeQueryResult.length === 0 ? (
                <></>
              ) : (
                <div className="card-body">
                  <div className="card-title">조회결과 : {resultCount}건</div>
                  <div className="table-responsive">
                    <table className="mb-3 table table-striped table-hover dataTable">
                      <thead>
                        <tr>
                          {columns.map((item, idx) => (
                            <th key={idx}>{item}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {executeQueryResult.length > 0 &&
                          executeQueryResult.map((item, idx) => (
                            <tr key={idx}>
                              {columns.map((c, idx2) =>
                                c.endsWith("일시") && item[c] !== null ? (
                                  <td key={idx2} style={{ minWidth: "200px" }}>
                                    {moment(item[c]).format(
                                      "YYYY-MM-DD HH:mm:ss"
                                    )}
                                  </td>
                                ) : (
                                  <td key={idx2} style={{ maxWidth: "250px" }}>
                                    {item[c]}
                                  </td>
                                )
                              )}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default QueryDetail;
