import React, { useContext, useState } from "react";
import Drawer from "react-drag-drawer";
import moment from "moment";
import { IconContext } from "react-icons";
import { IoIosRefresh, IoMdAdd } from "react-icons/io";

import { return_normal } from "../../../utils/Utils";
import { DetailContext } from "../../../DetailContext";
import { billingKeyCheckablePayments } from "../../../constants";
import { AppContext } from "../../../index";

const UserPayment = (props) => {
  const ctx = useContext(AppContext);
  /** Modal 띄우기 */
  const [isOpened, setIsOpened] = useState(false);
  const showModal = () => {
    setCardInfo({
      unid: props.userid,
      cardno: "",
      expdate: "",
      dob: "",
      cardpwd: "",
    });
    setIsOpened(true); // Modal 띄우기
  };

  const [cardInfo, setCardInfo] = useState<any>();
  const handleChangeCardinfo = (event) => {
    setCardInfo({
      ...cardInfo,
      [event.target.name]: event.target.value,
    });
  };

  const [billingKeyStatus, setBillingKeyStatus] = useState({});
  const checkBillingKeyStatus = async (unid: number, epid: number) => {
    const result = await ctx.api.admin.checkBillingKeyStatus({
      epid,
      unid,
    });
    setBillingKeyStatus({
      ...billingKeyStatus,
      [epid]: result.data.data.billingKeyStatus,
    });
    return return_normal(result, "Y");
  };

  const deleteEasypayment = async (
    unid: number,
    epid: number,
    actions: any
  ) => {
    const showMsg = "정말로 삭제하시겠습니까?";
    if (window.confirm(showMsg)) {
      const result = await ctx.api.admin.deleteEasypayment({ unid, epid });
      if (result) {
        actions.getUserData(unid);
      }
      return return_normal(result, "Y");
    }
  };

  return (
    <DetailContext.Consumer>
      {({ data, actions }) =>
        data.userData && (
          <>
            <div className="card mb-1">
              <div className="card-header text-primary">
                고객결제수단정보
                <div className="btn-actions-pane-right">
                  <button
                    className="btn btn-link p-0"
                    onClick={() =>
                      actions.getUserData(data.userData.UserInfo.UNID)
                    }
                  >
                    <IconContext.Provider
                      value={{
                        size: "2em",
                        style: { verticalAlign: "top", padding: "3px" },
                      }}
                    >
                      <IoIosRefresh />
                    </IconContext.Provider>
                  </button>

                  <button className="btn btn-link" onClick={() => showModal()}>
                    <IconContext.Provider
                      value={{
                        size: "2em",
                        style: { verticalAlign: "top", padding: "3px" },
                      }}
                    >
                      <IoMdAdd />
                    </IconContext.Provider>
                  </button>
                </div>
              </div>
              {data.userData.EasyPaymentInfo &&
                data.userData.EasyPaymentInfo.Status !== "N" && (
                  <div className="card-body">
                    <ul className="list-group">
                      {data.userData.EasyPaymentInfo &&
                        data.userData.EasyPaymentInfo.map(
                          (v, idx) =>
                            v.PaymentID && (
                              <li className="list-group-item" key={idx}>
                                <div className="widget-content p-0">
                                  <div className="widget-content-outer">
                                    <div className="widget-content-wrapper">
                                      <div className="widget-content-left">
                                        <div className="widget-heading mb-2">
                                          <div>
                                            {v.Status === "N" && (
                                              <span className="badge badge-sm badge-dark mr-1">
                                                사용안함
                                              </span>
                                            )}
                                            {v.IsDefault === "Y" && (
                                              <span className="badge badge-success mr-1">
                                                기본
                                              </span>
                                            )}
                                            #{v.EPID} {v.CardName}
                                          </div>
                                        </div>
                                        {v.CardNo && (
                                          <div>XXXX-XXXX-XXXX-{v.CardNo}</div>
                                        )}
                                        <div>{v.Description}</div>
                                        <div className="opacity-6">
                                          등록일{" "}
                                          {moment(v.RegDate).format(
                                            "YYYY-MM-DD HH:mm"
                                          )}
                                        </div>
                                        {v.ModifiedDate && (
                                          <div className="opacity-6">
                                            변경일{" "}
                                            {moment(v.ModifiedDate).format(
                                              "YYYY-MM-DD HH:mm"
                                            )}
                                          </div>
                                        )}
                                        {billingKeyStatus &&
                                          v.EPID in billingKeyStatus && (
                                            <div>
                                              {billingKeyStatus[v.EPID] ? (
                                                <span className="badge badge-info">
                                                  빌링키 상태 - 활성
                                                </span>
                                              ) : (
                                                <span className="badge badge-danger">
                                                  빌링키 상태 - 비활성
                                                </span>
                                              )}
                                            </div>
                                          )}
                                      </div>

                                      <div className="widget-content-right">
                                        {billingKeyCheckablePayments.includes(
                                          v.PaymentID
                                        ) && (
                                          <button
                                            className="btn btn-light btn-sm"
                                            onClick={() =>
                                              checkBillingKeyStatus(
                                                data.userData.UserInfo.UNID,
                                                v.EPID
                                              )
                                            }
                                          >
                                            빌링키 상태확인
                                          </button>
                                        )}
                                        {v.IsDefault !== "Y" && (
                                          <button
                                            className="btn btn-danger btn-sm ml-1"
                                            onClick={() =>
                                              deleteEasypayment(
                                                data.userData.UserInfo.UNID,
                                                v.EPID,
                                                actions
                                              )
                                            }
                                          >
                                            삭제
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )
                        )}
                    </ul>
                  </div>
                )}
            </div>

            {cardInfo && (
              <Drawer
                open={isOpened}
                onRequestClose={() => setIsOpened(!isOpened)}
                allowClose={false}
              >
                <div className="row">
                  <div className="col">
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="card-title">추가</div>
                        <div>
                          <form>
                            <div className="form-group position-relative">
                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text mb-1"
                                    style={{ minWidth: "150px" }}
                                  >
                                    카드번호
                                  </span>
                                </div>
                                <input
                                  className="form-control form-control-sm"
                                  name="cardno"
                                  onChange={handleChangeCardinfo}
                                  value={cardInfo.cardno}
                                  style={{ minWidth: "300px" }}
                                  maxLength={16}
                                />
                              </div>

                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text mb-1"
                                    style={{ minWidth: "150px" }}
                                  >
                                    만료일(MMYY)
                                  </span>
                                </div>
                                <input
                                  className="form-control form-control-sm"
                                  name="expdate"
                                  onChange={handleChangeCardinfo}
                                  value={cardInfo.expdate}
                                  style={{ minWidth: "300px" }}
                                  maxLength={4}
                                />
                              </div>

                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text mb-1"
                                    style={{ minWidth: "150px" }}
                                  >
                                    생년월일(801022)
                                  </span>
                                </div>
                                <input
                                  className="form-control form-control-sm"
                                  name="dob"
                                  onChange={handleChangeCardinfo}
                                  value={cardInfo.dob}
                                  style={{ minWidth: "300px" }}
                                  maxLength={6}
                                />
                              </div>

                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text mb-1"
                                    style={{ minWidth: "150px" }}
                                  >
                                    비밀번호앞2자리(**)
                                  </span>
                                </div>
                                <input
                                  className="form-control form-control-sm"
                                  type="password"
                                  name="cardpwd"
                                  onChange={handleChangeCardinfo}
                                  value={cardInfo.cardpwd}
                                  style={{ minWidth: "300px" }}
                                  maxLength={2}
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="text-center">
                          <button
                            className="btn btn-light"
                            onClick={() => setIsOpened(!isOpened)}
                          >
                            닫기
                          </button>

                          <button
                            className="btn btn-success ml-2"
                            onClick={() => {
                              actions.setEasyPaymentToToss(cardInfo);
                              setIsOpened(!isOpened);
                            }}
                          >
                            카드추가하기
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Drawer>
            )}
          </>
        )
      }
    </DetailContext.Consumer>
  );
};

export default UserPayment;
